import { Injectable, isDevMode } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TcDataService } from '../../data-services/services/tc-data.service';
import {
  createItem,
  createItemSuccess,
  deleteItem,
  deleteItemSuccess,
  loadTcData,
  loadTcDataSuccess,
  loadTcDataTotalSuccess,
  loadTcMoreData,
  loadTcMoreDataSuccess,
  refreshTcData,
  setTcDataFilters,
  softDeleteItem,
  updateItem,
  updateItemSuccess,
} from '../actions/tc-data-actions';
import {
  CreateItemPayload,
  DeleteItemPayload,
  LoadTcDataPayload,
  LoadTcMoreDataPayload,
  RefreshTcDataPayload,
  UpdateItemPayload,
} from '../actions/tc-data-payload';
import {
  getTcDataFilters,
  getTcDataProvider,
  getTcDataSeparatedInlineCountCall,
  getTcDataSkip,
  getTcDataSort,
  getTcDataTake,
  getTcWriteDataProvider,
} from '../selectors/tc-data-selectors';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
} from '../state/tc-data-state';
import { selectValueByKey, TcSpinnerService } from '@tc/store';
import { hasValue } from '@tc/utils';
import { TcNotificationService } from '../../../tc-core/services/tc-notification.service';
import * as R from 'ramda';
import { ITcDataService } from '@tc/abstract';
import { TcTranslateService } from '../../../tc-core/services/tc-translate.service';

@Injectable()
export class TcDataEffects {
  dataStore$: Observable<NgRxTcDataState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dataService: TcDataService,
    private readonly spinner: TcSpinnerService,
    private notification: TcNotificationService,
    private translateService: TcTranslateService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  private async getDataServiceForStoreKey(
    storeKey: string,
    isWrite: boolean = false
  ): Promise<ITcDataService<any>> {
    const dataProvider = await selectValueByKey(
      isWrite ? getTcWriteDataProvider : getTcDataProvider,
      this.dataStore$,
      storeKey
    );

    return this.dataService.getService<any>(storeKey, dataProvider);
  }

  /**
   * Load tc data effect
   */
  loadTcData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcData),
        tap(async (payload: LoadTcDataPayload) => {
          const { storeKey, skip, take, filter, sort } = payload;

          const action = `${payload.storeKey} - spinner`;

          let pageSize;
          if (take) {
            pageSize = take;
          } else {
            pageSize = await selectValueByKey(
              getTcDataTake,
              this.dataStore$,
              storeKey
            );
          }

          let storeFilter;
          // If the filters property is defined we use it as the new filter
          // because if it's an empty array it means that the filters got cleared
          if (filter?.filters !== undefined) {
            storeFilter = filter;
          } else {
            storeFilter = await selectValueByKey(
              getTcDataFilters,
              this.dataStore$,
              storeKey
            );
          }

          let storeSort;
          if (sort !== undefined) {
            storeSort = sort;
          } else {
            storeSort = await selectValueByKey(
              getTcDataSort,
              this.dataStore$,
              storeKey
            );
          }

          // If you got some filters, register them directly into the store, as default filters must be up before the first loading of the list.
          this.store$.dispatch(
            setTcDataFilters({
              storeKey: payload.storeKey,
              filter: storeFilter,
            })
          );

          const dataService = await this.getDataServiceForStoreKey(storeKey);
          const result = await dataService.getData(
            skip,
            pageSize,
            storeFilter,
            storeSort
          );

          const { data, total } = result;
          this.store$.dispatch(
            loadTcDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
              filter: storeFilter,
              sort: storeSort,
              createdOn: new Date().toISOString(),
            })
          );

          const separatedInlineCountCall = await selectValueByKey(
            getTcDataSeparatedInlineCountCall,
            this.dataStore$,
            storeKey
          );

          if (separatedInlineCountCall) {
            const total = await dataService.getDataInlineCount(storeFilter);

            this.store$.dispatch(
              loadTcDataTotalSuccess({
                storeKey: payload.storeKey,
                total,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Load more data effect
   */
  loadTcMoreData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcMoreData),
        tap(async (payload: LoadTcMoreDataPayload) => {
          const { storeKey, skip } = payload;

          const pageSize = await selectValueByKey(
            getTcDataTake,
            this.dataStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridTake, {storeKey }), take(1)).toPromise();
          const storeFilter = await selectValueByKey(
            getTcDataFilters,
            this.dataStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridFilters, {storeKey}), take(1)).toPromise();
          const storeSort = await selectValueByKey(
            getTcDataSort,
            this.dataStore$,
            storeKey
          );

          const dataService = await this.getDataServiceForStoreKey(storeKey);
          const result = await dataService.getData(
            skip,
            pageSize,
            storeFilter,
            storeSort
          );

          const { data, total } = result;

          this.store$.dispatch(
            loadTcMoreDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Refresh tc data effect
   */
  refreshTcData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTcData),
        tap(async (payload: RefreshTcDataPayload) => {
          const { storeKey, filter } = payload;

          // RefreshTcData is called only by tc-filter when the filter changes.
          // When data is refreshed because of changes in the filter the skip should be set to 0
          const skip = 0;

          this.store$.dispatch(
            loadTcData({
              storeKey,
              skip,
              filter,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Add new item in data effect
   */
  createItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createItem),
        tap(async (payload: CreateItemPayload) => {
          const { storeKey, item } = payload;

          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            // save to db
            const newItem = await dataService.upsert(R.clone(item));

            // Show notification message
            const translatedCreateMessage =
              this.translateService.instant('create-successful');
            this.notification.success(translatedCreateMessage);

            this.store$.dispatch(
              createItemSuccess({
                storeKey,
                item: newItem,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Update item in data effect
   */
  updateItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItem),
        tap(async (payload: UpdateItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            // save to db
            await dataService.upsert(R.clone(item));

            // Show notification message
            const translatedUpdateMessage =
              this.translateService.instant('update-successful');
            this.notification.success(translatedUpdateMessage);

            this.store$.dispatch(
              updateItemSuccess({
                storeKey,
                item,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Sets item as deleted
   */
  softDelteItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(softDeleteItem),
        tap(async (payload: UpdateItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            // save to db
            await dataService.upsert(R.clone(item));

            // Show notification message
            const translatedUpdateMessage =
              this.translateService.instant('delete-successful');
            this.notification.success(translatedUpdateMessage);

            this.store$.dispatch(
              deleteItemSuccess({
                storeKey,
                item,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Delete item in data effect
   */
  deleteItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteItem),
        tap(async (payload: DeleteItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          // save to db
          await dataService.delete(R.clone(item));

          // Show notification message
          const translatedUpdateMessage =
            this.translateService.instant('delete-successful');
          this.notification.success(translatedUpdateMessage);

          this.store$.dispatch(
            deleteItemSuccess({
              storeKey,
              item,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
