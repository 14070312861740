import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { TcSpinnerService } from '@tc/store';
import { Injectable, Optional, isDevMode } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { TcAppState } from '@tc/store';
import { Store } from '@ngrx/store';
import { AddHttpError } from '@tc/store';
import { TcNotificationService } from '../services/tc-notification.service';
import { TcTranslateService } from '../services/tc-translate.service';
import { TcApiConfig } from '../config/tc-api.config';

@Injectable()
export class HTTPListenerInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<TcAppState>,
    private spinner: TcSpinnerService,
    private notification: TcNotificationService,
    private translateService: TcTranslateService,
    @Optional() private apiConfig?: TcApiConfig
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = null;

    const key = request.method + '-' + request.url;

    let showSpinner = true;

    if (request.url.indexOf('/account/users/') >= 0) {
      showSpinner = false;
    }

    if (
      request.url.indexOf('assets') >= 0 ||
      request.url.indexOf('/oidc/') >= 0 ||
      (request.url.includes('top=0') &&
        request.url.includes('inlinecount=allpages'))
    ) {
      showSpinner = false;
    }

    const req = authReq != null ? authReq : request;

    if (this.apiConfig && this.apiConfig.spinnerExceptions) {
      const exception = this.apiConfig.spinnerExceptions.find(
        (e) => e.method === req.method && req.url.includes(e.url)
      );
      if (exception) {
        showSpinner = false;
      }
    }

    if (showSpinner) {
      this.spinner.showSpinner(key);
    }

    return next.handle(req).pipe(
      map((event) => {
        return event;
      }),
      catchError((error) => {
        if (this.apiConfig?.errorStatusExceptions?.length && error?.status) {
          const isExceptionStatus = this.apiConfig.errorStatusExceptions.some(
            (status) => status === error.status
          );
          if (isExceptionStatus) {
            this.store.dispatch(new AddHttpError(error));
            return of(error);
          }
        }

        let showToastError = true;

        if (this.apiConfig && this.apiConfig.customToastErrors) {
          const customToastError = this.apiConfig.customToastErrors.find(
            (e) =>
              e.method === req.method &&
              e.status === error.status &&
              req.url.includes(e.url)
          );
          if (customToastError) {
            showToastError = false;

            const message = this.translateService.instant(
              customToastError.translateKey
            );

            if (customToastError.toastType === 'success') {
              this.notification.success(message);
            }

            if (customToastError.toastType === 'error') {
              this.notification.error(message);
            }

            if (customToastError.toastType === 'warning') {
              this.notification.warning(message);
            }
          }
        }

        if (
          showToastError &&
          this.apiConfig &&
          this.apiConfig.toastErrorExceptions
        ) {
          const exception = this.apiConfig.toastErrorExceptions.find(
            (e) =>
              e.method === req.method &&
              e.status === error.status &&
              req.url.includes(e.url)
          );
          if (exception) {
            showToastError = false;
          }
        }

        if (error.status === 504 || error.status + '' === '504') {
          showToastError = false;
        }

        if (showToastError) {
          this.handleToastError(error);
        }

        this.store.dispatch(new AddHttpError(error));

        return of<HttpEvent<any>>();
      }),
      finalize(() => {
        if (showSpinner) {
          this.spinner.hideSpinner(key);
        }
      })
    );
  }

  handleToastError(sourceError: any): void {
    const errors: string[] = [];

    const { error, body, message } = sourceError;

    if (error) {
      // prefer HttpErrorResponse.error to its message property
      if (typeof error === 'string') {
        errors.push(error);
      } else if (Array.isArray(error)) {
        error.forEach((e) => {
          errors.push(e.message);
        });
      } else if (typeof error === 'object') {
        if (error.error) {
          if (error.error === 'invalid_grant') {
            errors.push(
              this.translateService.instant('errors.auth.invalid_grant')
            );
          }
        }
        if (error.message) {
          errors.push(this.translateService.instant(error.message));
        }
      } else {
        errors.push(JSON.stringify(error));
      }
    } else if (message) {
      errors.push(message);
    } else if (body) {
      // try the body if no error or message property
      errors.push(typeof body === 'string' ? body : body.error);
    }

    errors.forEach((e) => {
      this.showToastError(e);
    });
  }

  showToastError(errMessage: string) {
    if (isDevMode()) {
      this.notification.error(errMessage, null, {
        closeButton: true,
        timeOut: 0,
      });
    } else {
      this.notification.error(errMessage);
    }
  }
}
