import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomeService } from '../services/home.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadVersionDetails, loadVersionDetailsSuccess } from './home.actions';
import { take, tap } from 'rxjs/operators';
import { SetVersionDetailsPayload } from './home.payloads';
import { getAuthenticatedUser } from '../../../../modules/auth/store/auth.selectors';
import { getBankIdFromUserDetails } from '../../../shared/utils/user.utils';

@Injectable()
export class HomeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly homeService: HomeService
  ) {}

  loadVersionDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadVersionDetails),
        tap(async () => {
          const productReference = await this.homeService.getProductReference();

          const curUser = await this.store$
            .select(getAuthenticatedUser)
            .pipe(take(1))
            .toPromise();

          const baId =
            getBankIdFromUserDetails(curUser.additionalDetails) ?? -1;

          const associationsResp =
            await this.homeService.getAssociationsAwaitingValidation(baId);

          const versionDetails: SetVersionDetailsPayload = {
            version: productReference ? productReference.version : '',
            date: productReference ? productReference.date : '',
            associationsToBeValidated: associationsResp
              ? associationsResp.result
              : 0,
          };

          this.store$.dispatch(
            loadVersionDetailsSuccess({ payload: versionDetails })
          );
        })
      ),
    { dispatch: false }
  );
}
