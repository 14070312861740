import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcLayoutComponent } from './components/dumb/tc-layout/tc-layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TcPanelComponent } from './components/dumb/tc-panel/tc-panel.component';
import { TcVerticalLayoutComponent } from './components/dumb/tc-vertical-layout/tc-vertical-layout.component';

const components = [
  TcLayoutComponent,
  TcVerticalLayoutComponent,
  TcPanelComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
  ],
  exports: [...components],
})
export class TcLayoutModule {}
