/**
 * Method that is removing properties specified in nested array / sub-objects. Use it to remove all occurencies of a property in an object.
 * @param object Object to clean
 * @param properties Array of properties names to remove
 * @returns Object cleaned
 */
export function removePropertiesFromObject(
  object: any,
  properties: string[]
): any {
  if (!object || !(typeof object === 'object')) {
    return object;
  }
  if (Array.isArray(object) === false) {
    // Copy of the object
    object = { ...object };

    // Iterate on an properties
    for (const prop of properties) {
      if (object[prop]) {
        // Delete property if found
        delete object[prop];
      }
    }

    // Foreach remaining keys, recursive call to cleanup children
    Object.keys(object).forEach((key) => {
      object[key] = removePropertiesFromObject(object[key], properties);
    });
  } else {
    // Iterate on all array node to remove the properties in array child
    object = [...object];
    for (const index in object) {
      object[index] = removePropertiesFromObject(object[index], properties);
    }
  }

  return object;
}
