import { Injectable } from '@angular/core';
import { TcAppConfig } from './tc-app.config';

@Injectable({
  providedIn: 'root',
})
export class GetConfigService {
  appConfig: TcAppConfig;

  constructor() {}
}
