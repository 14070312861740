import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAbilities } from '@tc/permissions';
import { IPageState } from '@tc/store';
import { take } from 'rxjs/operators';

import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-received-delivery-notes-page',
  templateUrl: './received-delivery-notes-page.component.html',
  styleUrls: ['./received-delivery-notes-page.component.scss'],
})
export class ReceivedDeliveryNotesPageComponent
  extends AmsPage
  implements OnInit
{
  constructor(store: Store<any>) {
    super(store);
  }

  async ngOnInit() {
    const abilities = await this.store
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    const pageInfo: IPageState = {
      title: 'received-delivery-notes-page.title',
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
