import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TcGridButtonParams } from '../../interfaces/tc-grid.interface';

@Component({
  selector: 'tc-grid-buttons-renderer',
  templateUrl: './tc-grid-buttons-renderer.component.html',
  styleUrls: ['./tc-grid-buttons-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcGridButtonsRendererComponent implements AgRendererComponent {
  public params: ICellRendererParams;
  public buttons: TcGridButtonParams[];

  agInit(params) {
    this.params = params;
    this.buttons = params?.buttons;
  }

  public onClick(button: TcGridButtonParams) {
    button?.action(this.params?.node?.data);
  }

  public isDisabled(button: TcGridButtonParams) {
    if (!button.disabled) {
      return false;
    }

    const isDisabled = button.disabled(this.params?.node?.data);
    return !!isDisabled;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  public isHidden(button: TcGridButtonParams) {
    if (!button.hidden) {
      return false;
    }

    const isHidden = button.hidden(this.params?.node?.data);
    return !!isHidden;
  }
}
