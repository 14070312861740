import { Component, Input } from "@angular/core";
import { TcDumbComponent } from "@tc/abstract";

@Component({
    selector: 'app-ams-info-block',
    templateUrl: './ams-info-block.component.html',
    styleUrls: ['./ams-info-block.component.scss'],
  })
  export class AmsInfoBlockComponent extends TcDumbComponent {
    @Input() title = ""; 
    
  }