import { createReducer, on } from '@ngrx/store';
import {
  activateOfflineModeSuccess,
  terminateOfflineModeSuccess,
} from '../actions/offline-mode.actions';

export interface TcOfflineModeState {
  offlineModeInfo: OfflineModeState;
}

export interface OfflineModeState {
  offlineModeActivated: boolean;
}

export const initialOfflineModeState: OfflineModeState = {
  offlineModeActivated: false,
};

export const offlineModeReducer = createReducer<OfflineModeState>(
  initialOfflineModeState,
  on(activateOfflineModeSuccess, (state) => ({
    ...state,
    offlineModeActivated: true,
  })),
  on(terminateOfflineModeSuccess, (state) => ({
    ...state,
    offlineModeActivated: false,
  }))
);
