import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { ExampleDialogComponent } from './components/smart/example-dialog/example-dialog.component';
import { ExampleTcTextareaComponent } from './components/smart/example-tc-textarea/example-tc-textarea.component';

const dialogs = [ExampleDialogComponent, ExampleTcTextareaComponent];

@NgModule({
  declarations: [...dialogs],
  imports: [CommonModule, TcCoreModule],
  exports: [...dialogs],
})
export class ExamplesModule {}
