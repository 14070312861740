import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MaterialColor,
  TcDumbComponent,
  TcHelpDialogPayload,
} from '@tc/abstract';
import { TcSmartButton } from '../../../tc-buttons/interfaces';
import { MaterialButtonType } from '@tc/buttons';
import { TcComponentLookup } from '../../../tc-core/decorators';

/**
 * A help dialog that provides the user multiple ways of receiving help.
 * - via help manual
 * - via email
 */
@Component({
  selector: 'tc-help-dialog',
  templateUrl: './tc-help-dialog.component.html',
  styleUrls: ['./tc-help-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@TcComponentLookup('TcHelpDialogComponent')
export class TcHelpDialogComponent extends TcDumbComponent {
  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `help.close-button`,
  };

  openManualButtonConfig: TcSmartButton = {
    label: 'open-help-manual',
    color: MaterialColor.Primary,
    type: MaterialButtonType.Raised,
    name: `help.open-help-manual`,
  };

  contactSupportConfig: TcSmartButton = {
    label: 'contact-support-via-email',
    color: MaterialColor.Primary,
    type: MaterialButtonType.Raised,
    name: `help.contact-support-via-email`,
    action: () => {
      let mailToText = `mailto:${this.data.mailToEmail}`;
      if (this.data.mailToSubject)
        mailToText += `?subject=${this.data.mailToSubject}`;

      window.location.href = mailToText;
    },
  };

  constructor(
    private dialogRef: MatDialogRef<TcHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TcHelpDialogPayload
  ) {
    super();
  }

  onClose(result: boolean) {
    this.dialogRef.close(result);
  }
}
