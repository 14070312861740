import { TcSmartFilterConfig } from '@tc/abstract';
import { TcFilterDef } from '@tc/abstract';

export const DEFAULT_TC_FILTER_STATE_KEY = 'tc-filter';

export interface NgRxTcFilterState {
  [key: string]: TcFilterState;
}

export interface TcFilterState {
  storeKey: string;
  filter: TcFilterDef;
  isPersistant?: boolean;
  config?: TcSmartFilterConfig;
}
