import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tc-panel',
  templateUrl: './tc-panel.component.html',
  styleUrls: ['./tc-panel.component.scss'],
})
export class TcPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
