import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginMfaInterface } from '../../modules/auth/interfaces/login-mfa.interface';
import { LoginResponseInterface } from '../../modules/auth/interfaces/login-response.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CrudService {
  constructor(public config: ConfigService, public httpClient: HttpClient) {
    super(CustomEntitiesEnum.Auth, httpClient, config);
  }

  async login(
    username: string,
    password: string
  ): Promise<LoginResponseInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<LoginResponseInterface>(
        `${url}/login`,
        {
          username,
          password,
        },
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<LoginResponseInterface>();
  }

  async loginWindows(): Promise<LoginResponseInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<LoginResponseInterface>(
        `${url}/loginWindows`,
        {},
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<LoginResponseInterface>();
  }

  async refresh(): Promise<{ accessToken: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get<{ accessToken: string }>(`${url}/refresh`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise<{ accessToken: string }>();
  }

  async mfaLogin(code: string): Promise<{ accessToken: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<{ accessToken: string }>(
        `${url}/mfa`,
        {
          code,
        },
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<{ accessToken: string }>();
  }

  async mfaStatus(): Promise<LoginMfaInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get<LoginMfaInterface>(`${url}/mfa/status`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise<LoginMfaInterface>();
  }

  googleLogin() {
    const url = this.getEntityServiceEndpoint();

    location.href = `${url}/google`;
  }

  facebookLogin() {
    const url = this.getEntityServiceEndpoint();

    location.href = `${url}/facebook`;
  }
}
