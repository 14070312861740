import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule, TcCoreModule } from '@tc/core';
import { NgxMaskModule } from 'ngx-mask';

import {
  AmsGridDatepickerRendererComponent,
} from './components/ams-grid-datapicker-renderer/ams-grid-datepicker-renderer.component';
import {
  AmsGridDatepickerEditorComponent,
} from './components/ams-grid-datepicker-editor/ams-grid-datepicker-editor.component';
import { AmsInfoBlockComponent } from './components/ams-info-block/ams-info-block.component';
import { AmsStatsTableComponent } from './components/ams-stats-table/ams-stats-table.component';
import { BanksService } from './services/banks.service';
import { AmsTableInputEditorComponent } from './components/ams-table-input-editor/ams-table-input-editor.component';

const components = [
  AmsInfoBlockComponent,
  AmsStatsTableComponent,
  AmsGridDatepickerEditorComponent,
  AmsGridDatepickerRendererComponent,
  AmsTableInputEditorComponent,
];

const services = [
  BanksService,
  DecimalPipe
];

@NgModule({
  imports: [
    CommonModule,
    TcCoreModule,
    MaterialModule,
    NgxMaskModule.forRoot({}),
  ],
  providers: [...services],
  declarations: [...components],
  exports: [...components, CommonModule, FormsModule, TcCoreModule],
})
export class SharedModule {}
