import { Injectable } from '@angular/core';

/**
 * Base class for all tc services
 */
@Injectable({
  providedIn: 'root',
})
export class TcService {
  constructor() {}
}
