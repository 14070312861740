import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-login-oauth-failed-page',
  templateUrl: './login-oauth-failed-page.component.html',
  styleUrls: ['./login-oauth-failed-page.component.scss'],
})
export class LoginOauthFailedPageComponent extends TcPage {
  constructor() {
    super();
  }
}
