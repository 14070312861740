import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TcDataProviderType } from '../../tc-abstract/component-configs';
import { TcOfflineModeService } from '../../tc-breeze/interfaces/tc-offline-mode.interface';
import { TcDataServiceFactory } from '../../tc-data-store/data-services';
import { TcPromptDialogComponent } from '@tc/dialog';
import {
  activateOfflineMode,
  activateOfflineModeSuccess,
  hasOfflineMode,
  terminateOfflineMode,
  terminateOfflineModeSuccess,
} from '../actions/offline-mode.actions';
import { TcAppState } from '../store';
import { MatDialog } from '@angular/material/dialog';
import { TcTranslateService } from '../../tc-core/services/tc-translate.service';
import { TcNotificationService } from '../../tc-core/services/tc-notification.service';
import { TcComponentLookupRegistry } from '../../tc-core/decorators';
import { SyncResponsePopupPayload } from '../../tc-breeze/interfaces';

@Injectable()
export class OfflineModeEffects {
  offlineModeServiceFactory: (
    providerType: TcDataProviderType
  ) => TcOfflineModeService;

  constructor(
    private actions$: Actions,
    protected tcAppStore$: Store<TcAppState>,
    private tcdataServiceFactory: TcDataServiceFactory,
    private readonly dialog: MatDialog,
    private readonly translateService: TcTranslateService,
    private readonly notificationService: TcNotificationService
  ) {
    this.offlineModeServiceFactory =
      this.tcdataServiceFactory.getOfflineModeService;
  }

  hasOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hasOfflineMode),
        map(async ({ providerType, relaunch }) => {
          const offlineModeStatus = await this.offlineModeServiceFactory(
            providerType
          ).hasOfflineMode();
          if (offlineModeStatus) {
            this.tcAppStore$.dispatch(activateOfflineModeSuccess());
          } else {
            this.tcAppStore$.dispatch(terminateOfflineModeSuccess());
            // Option to activate offline mode if he is not launched
            if (relaunch === true) {
              this.tcAppStore$.dispatch(activateOfflineMode({ providerType }));
            }
          }
          return offlineModeStatus;
        })
      ),
    { dispatch: false }
  );

  activateOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateOfflineMode),
        map(async ({ providerType, popupComponentName }) => {
          const dialogRef = this.dialog.open(TcPromptDialogComponent, {
            width: '37.5em',
            data: {
              title: this.translateService.instant('globalLabels.sync'),
              disableTextTranslation: true,
              text: this.translateService.instant(
                'globalMessages.wait-for-sync'
              ),
              displayCancelButton: false,
              displayConfirmButton: false,
              disableClose: true,
            },
          });
          const result = await this.offlineModeServiceFactory(
            providerType
          ).activateOfflineMode();
          if (!result.success) {
            dialogRef.close();
            console.error(result?.errors);
            this.notificationService.error(
              this.translateService.instant('globalMessages.error-during-sync')
            );
            return;
          }
          dialogRef.close();

          if (popupComponentName) {
            const data: SyncResponsePopupPayload = {
              title: 'sync-result',
              ...result,
            };
            await this.showCustomPopup(popupComponentName, data);
          }

          this.tcAppStore$.dispatch(activateOfflineModeSuccess());
          return result;
        })
      ),
    { dispatch: false }
  );

  terminateOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(terminateOfflineMode),
        map(async ({ providerType, relaunch, popupComponentName }) => {
          const result = await this.offlineModeServiceFactory(
            providerType
          ).terminateOfflineMode();
          if (!result.success) {
            console.error(result?.errors);
            this.notificationService.error(
              this.translateService.instant('globalMessages.error-during-sync')
            );
            return;
          }

          if (popupComponentName) {
            const data = {
              title: 'sync-data-sent',
              ...result,
            };
            await this.showCustomPopup(popupComponentName, data);
          }

          this.tcAppStore$.dispatch(terminateOfflineModeSuccess());
          if (relaunch === true) {
            this.tcAppStore$.dispatch(
              activateOfflineMode({ providerType, popupComponentName })
            );
          }
          return result;
        })
      ),
    { dispatch: false }
  );

  showCustomPopup(popupComponentName: string, data: SyncResponsePopupPayload) {
    return new Promise((resolve) => {
      const popupComponent = TcComponentLookupRegistry.get(popupComponentName);
      const dialog = this.dialog.open(popupComponent, {
        width: '27.5em',
        disableClose: true,
        data,
      });
      dialog.afterClosed().subscribe(resolve);
    });
  }
}
