import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent extends TcPage {
  constructor() {
    super();
  }
}
