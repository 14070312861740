import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { FormlyConfig } from '@ngx-formly/core';
import { merge, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { TcTranslateService } from '../services/tc-translate.service';
import { SettingsActions, SettingsActionTypes } from './settings.actions';
import { State } from './settings.model';
import { selectSettingsState } from './settings.selectors';

export const SETTINGS_KEY = 'SETTINGS';

@Injectable()
export class SettingsEffects implements OnDestroy {
  private readonly themeIdentifier = '--tc-theme';

  private translationSubscription: Subscription;

  constructor(
    private actions$: Actions<SettingsActions>,
    private store: Store<State>,
    private translateService: TcTranslateService,
    private formlyConfig: FormlyConfig,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @Effect({ dispatch: false })
  changeTheme$ = merge(
    this.actions$.pipe(ofType(SettingsActionTypes.CHANGE_THEME))
  ).pipe(
    tap(({ payload }) => {
      const toRemove = Array.from(this.document.body.classList).filter(
        (item) => item.indexOf(this.themeIdentifier) !== -1
      );
      this.document.body.classList.remove(...toRemove);
      this.document.body.classList.add(`${payload}${this.themeIdentifier}`);
    })
  );

  @Effect({ dispatch: false })
  setTranslateServiceLanguage = this.store.pipe(
    select(selectSettingsState),
    map((settings) => settings.translate.language),
    distinctUntilChanged(),
    tap(async (language) => {
      await this.translateService.use(language).toPromise();

      const VALIDATIONS_REQUIRED = 'validations.required';
      const VALIDATIONS_MINLENGTH = 'validations.minlength';
      const VALIDATIONS_MAXLENGTH = 'validations.maxlength';
      const VALIDATIONS_MIN = 'validations.min';
      const VALIDATIONS_MAX = 'validations.max';

      this.translationSubscription = this.translateService
        .get([
          VALIDATIONS_REQUIRED,
          VALIDATIONS_MINLENGTH,
          VALIDATIONS_MAXLENGTH,
          VALIDATIONS_MIN,
          VALIDATIONS_MAX,
        ])
        .subscribe((translate) => {
          this.formlyConfig.addValidatorMessage('required', (err, field) =>
            translate[VALIDATIONS_REQUIRED].replace(
              '{{field}}',
              field.templateOptions.label
            )
          );
          this.formlyConfig.addValidatorMessage('minlength', (err, field) =>
            translate[VALIDATIONS_MINLENGTH].replace(
              '{{number}}',
              field.templateOptions.minLength
            )
          );
          this.formlyConfig.addValidatorMessage('maxlength', (err, field) =>
            translate[VALIDATIONS_MAXLENGTH].replace(
              '{{number}}',
              field.templateOptions.maxLength
            )
          );
          this.formlyConfig.addValidatorMessage('min', (err, field) =>
            translate[VALIDATIONS_MIN].replace(
              '{{number}}',
              field.templateOptions.min
            )
          );
          this.formlyConfig.addValidatorMessage('max', (err, field) =>
            translate[VALIDATIONS_MAX].replace(
              '{{number}}',
              field.templateOptions.max
            )
          );
        });
    })
  );

  ngOnDestroy(): void {
    this.translationSubscription?.unsubscribe();
  }
}
