import { FormControl, ValidationErrors } from '@angular/forms';
import {
  FormlyConfig,
  FormlyFieldConfig,
  FormlyTemplateOptions,
} from '@ngx-formly/core';
import { FilterTypesEnum, InputType } from '@tc/abstract';
import { TcFormlyComponent, TcTranslateService } from '@tc/core';
import { ServiceLocator } from '../services/service-locator';

export const TC_FILTER_INTERNAL = '_tc-filter-internal_';

export function formlyRow(rowConfig: {
  fields: FormlyFieldConfig[];
  colSpan?: number;
  xsColSpan?: number;
  smColSpan?: number;
  mdColSpan?: number;
  lgColSpan?: number;
  xlColSpan?: number;
  xxlColSpan?: number;
  className?: string;
  wrappers?: string[];
  templateOptions?: any;
  hide?: boolean;
}) {
  let {
    fields,
    colSpan,
    xsColSpan = 12,
    smColSpan = 12,
    mdColSpan = 12,
    lgColSpan = 12,
    xlColSpan = 12,
    xxlColSpan = 12,
    className = '',
    wrappers,
    templateOptions,
    hide,
  } = rowConfig;

  if (colSpan) {
    className = `${className} flex-${colSpan}`;
  } else {
    className =
      `${className} column-formly-field flex-xs-${xsColSpan} flex-sm-${smColSpan} flex-md-${mdColSpan}` +
      ` flex-lg-${lgColSpan} flex-xl-${xlColSpan} flex-xxl-${xxlColSpan}`;
  }

  return {
    className,
    wrappers,
    fieldGroupClassName: 'display-flex flex-wrap row',
    fieldGroup: fields,
    templateOptions: {
      ...templateOptions,
    },
    hide,
  };
}

export function formlyColumn(colConfig: {
  fields: FormlyFieldConfig[];
  colSpan?: number;
  xsColSpan?: number;
  smColSpan?: number;
  mdColSpan?: number;
  lgColSpan?: number;
  xlColSpan?: number;
  xxlColSpan?: number;
  className?: string;
  wrappers?: string[];
  templateOptions?: any;
}) {
  let {
    fields,
    colSpan,
    xsColSpan = 12,
    smColSpan = 12,
    mdColSpan = 12,
    lgColSpan = 12,
    xlColSpan = 12,
    xxlColSpan = 12,
    className = '',
    wrappers,
    templateOptions,
  } = colConfig;

  if (colSpan) {
    className = `${className} flex-${colSpan}`;
  } else {
    className =
      `${className} column-formly-field flex-xs-${xsColSpan} flex-sm-${smColSpan} flex-md-${mdColSpan}` +
      ` flex-lg-${lgColSpan} flex-xl-${xlColSpan} flex-xxl-${xxlColSpan}`;
  }

  return {
    wrappers,
    className,
    fieldGroupClassName: 'display-flex column',
    fieldGroup: fields,
    templateOptions: {
      ...templateOptions,
    },
  };
}

export function formlyControl(config: {
  key: string;
  label?: string;
  colSpan?: number;
  xsColSpan?: number;
  smColSpan?: number;
  mdColSpan?: number;
  lgColSpan?: number;
  xlColSpan?: number;
  xxlColSpan?: number;
  className?: string;
  type?: TcFormlyComponent;
  templateOptions?: FormlyTemplateOptions;
  defaultValue?: any;
  validation?: any;
  expressionProperties?: any;
  hideExpression?: any;
  hide?: boolean;
  hooks?: any;
  validators?: any;
}): FormlyFieldConfig {
  let {
    key,
    label,
    colSpan,
    xsColSpan = 12,
    smColSpan = 6,
    mdColSpan = 6,
    lgColSpan = 4,
    xlColSpan = 3,
    xxlColSpan = 2,
    type,
    templateOptions,
    validation,
    expressionProperties,
    hideExpression,
    hide,
    hooks,
    validators,
    className = '',
    defaultValue,
  } = config;

  if (!type) {
    type = TcFormlyComponent.FormlyInput;
  }

  const formlyConfig = ServiceLocator.injector.get(FormlyConfig);

  const defaultClassName =
    formlyConfig.getType(type).defaultOptions?.className || '';

  className = `${className ? className : defaultClassName}`;

  if (colSpan) {
    className = `${className} flex-${colSpan}`;
  } else {
    className =
      `${className} column-formly-field flex-xs-${xsColSpan} flex-sm-${smColSpan} flex-md-${mdColSpan}` +
      ` flex-lg-${lgColSpan} flex-xl-${xlColSpan} flex-xxl-${xxlColSpan}`;
  }

  let controlConfig: FormlyFieldConfig = {
    key,
    type,
    className,
    defaultValue,
    templateOptions: {
      label,
      ...templateOptions,
    },
  };

  if (hide !== null && hide !== undefined) {
    controlConfig.hide = hide;
  }

  if (validation !== null && validation !== undefined) {
    controlConfig.validation = validation;
  }

  if (hideExpression !== null && hideExpression !== undefined) {
    controlConfig.hideExpression = hideExpression;
  }

  if (expressionProperties !== null && expressionProperties !== undefined) {
    controlConfig.expressionProperties = expressionProperties;
  }

  if (hooks !== null && hooks !== undefined) {
    controlConfig.hooks = hooks;
  }

  if (validators !== null && validators !== undefined) {
    controlConfig.validators = validators;
  }

  if (
    controlConfig?.templateOptions?.type === null ||
    controlConfig?.templateOptions?.type === undefined
  ) {
    switch (controlConfig.type) {
      case TcFormlyComponent.FormlyCheckbox:
      case TcFormlyComponent.FormlyToggle:
      case TcFormlyComponent.FormlyRadio:
        controlConfig.templateOptions.type = InputType.checkbox;
        break;
      case TcFormlyComponent.FormlySelect:
      case TcFormlyComponent.TcSmartSelect:
        if (controlConfig?.templateOptions?.multiple) {
          controlConfig.templateOptions.type = InputType.multipleSelect;
        } else if (
          (controlConfig?.templateOptions?.options as any[])?.length > 0
        ) {
          if (
            typeof controlConfig?.templateOptions?.options[0]?.value ===
            'number'
          ) {
            // This helps us devs not having to specify the type of a formly select if the options have number values
            controlConfig.templateOptions.type = InputType.number;
          } else {
            // Not necessarily the true type but this is the default case
            controlConfig.templateOptions.type = InputType.text;
          }
        }
        break;
      default:
        controlConfig.templateOptions.type = InputType.text;
    }
  }

  if (
    controlConfig?.templateOptions?.filterType ===
    FilterTypesEnum.DateGreaterThanOrEqual
  ) {
    controlConfig.key = `${key}${TC_FILTER_INTERNAL}start-date`;
  }

  if (
    controlConfig?.templateOptions?.filterType ===
    FilterTypesEnum.DateLessThanOrEqual
  ) {
    controlConfig.key = `${key}${TC_FILTER_INTERNAL}end-date`;
  }

  return controlConfig;
}

/**
 * Enum with all the custom formly validators so far
 */
export enum FormlyCustomValidators {
  NonNullField = 'nonNullField',
  EmptyOrEmail = 'emptyOrEmail',
}

/**
 * Simple non-null field validator
 */
export function NonNullFieldValidator(control: FormControl): ValidationErrors {
  return control.value !== null
    ? null
    : { [FormlyCustomValidators.NonNullField]: true };
}

/**
 * Simple email field validator that allows for the field to either be a email or empty
 */
export function EmptyOrEmailFieldValidator(
  control: FormControl
): ValidationErrors {
  // Email regex from ajv package. We are also doing a email validation on backend via ajv with this exact regex
  // So you modify this you might end up having a email that is valid on frontend but not on backend which will lead to errors.
  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  return !control.value ||
    (emailRegex.test(control.value) === true && !control.value.includes(':'))
    ? null
    : { [FormlyCustomValidators.EmptyOrEmail]: true };
}

/**
 * Helps us tranlsate validation messages for formly custom validators
 * Check out https://github.com/ngx-formly/ngx-formly/issues/2140#issuecomment-638505172 for more details
 */
export function registerTranslateExtension(translate: TcTranslateService) {
  return {
    validationMessages: [
      {
        name: FormlyCustomValidators.EmptyOrEmail,
        message() {
          return translate.instant('validations.email-invalid');
        },
      },
    ],
  };
}
