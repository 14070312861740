import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcComponent } from '@tc/abstract';
import { TcSmartButton } from '../../interfaces/tc-smart-button.interface';

/**
 * This component is used for every button list of buttons in the app and it is nothing more than a
 * container for the TcSmartButtons
 */
@Component({
  selector: 'tc-buttons-list',
  templateUrl: './tc-buttons-list.component.html',
  styleUrls: ['./tc-buttons-list.component.scss'],
})
export class TcButtonsListComponent extends TcComponent {
  /**
   * Array of button configurations
   */
  @Input()
  buttonsList: TcSmartButton[] = [];

  // TODO: Maybe move this to the TcSmartButton level or create a TcListButton
  // that extends TcSmartButton and has a disable
  /**
   * Array of the disabled states of the button
   */
  @Input()
  disableButtons: boolean[] = [];

  /**
   * Array of the hidden states of the button
   */
  @Input()
  hiddenButtons: boolean[] = [];

  /**
   * In order to know if this is used inside a tc-grid component
   */
  @Input()
  tcGridColumn: boolean = false;

  /**
   * Node of the TcGrid row if used inside a tc-grid component
   */
  @Input()
  tcGridNode: any = null;

  /**
   * Click event emmiter
   */
  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  /**
   * Emits the click event and the clicked button's index to the parent component
   * @param clickEvent Native click event
   * @param buttonIndex Button index
   */
  onClickHandler(clickEvent, buttonIndex) {
    this.onClick.emit({ clickEvent, index: buttonIndex });
  }
}
