import { Component, Input, isDevMode, OnInit } from '@angular/core';
import { TcDialogContentConfig, TcDumbComponent } from '@tc/abstract';
import { TcNotificationService } from '../../../tc-core/services/tc-notification.service';

@Component({
  selector: 'tc-dialog-content',
  templateUrl: './tc-dialog-content.component.html',
  styleUrls: ['./tc-dialog-content.component.scss'],
})
export class TcDialogContentComponent
  extends TcDumbComponent
  implements OnInit
{
  @Input() config: TcDialogContentConfig;

  displayBadImplementationWarningMessage(actionSlot: string): void {
    const message = `You have enabled the ${actionSlot} actions slot on both header and footer. 
    Due to how portals work the portal can be displayed in only one place
    thus the action slot will only be present in the header.`;

    this.notification.warning(message);
    console.warn(message);
  }

  constructor(private notification: TcNotificationService) {
    super();
  }

  ngOnInit() {
    if (isDevMode() && this.config.hasHeader && this.config.hasFooter) {
      if (
        this.config.headerConfig?.enableActionsSlot &&
        this.config.footerConfig?.enableActionsSlot
      ) {
        this.displayBadImplementationWarningMessage('primary');
      }

      if (
        this.config.headerConfig?.enableSecondaryActionsSlot &&
        this.config.footerConfig?.enableSecondaryActionsSlot
      ) {
        this.displayBadImplementationWarningMessage('secondary');
      }
    }
  }
}
