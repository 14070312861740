import { createAction, props } from '@ngrx/store';
import { TermsOfUse } from '../interfaces/terms-of-use.interface';

export const verifyTerms = createAction('[TermsAndConditions] Verify Terms');

export const acceptTerms = createAction(
  '[TermsAndConditions] Accept Terms and Conditions',
  props<{ id: number }>()
);

export const lastTermsAccepted = createAction(
  '[TermsAndConditions] Set Terms Accepted'
);

export const saveTermsToValidate = createAction(
  '[TermsAndConditions] Save Terms to Validate',
  props<{ options: TermsOfUse }>()
);

export const openTermsOfUsePage = createAction(
  '[TermsAndConditions] Open Terms of Use Page'
);
