import {
  HttpErrorsActionTypes,
  AddHttpError,
} from '../actions/http-error.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpErrorEffects {
  @Effect() public onLoadError$ = this.actions$.pipe(
    ofType(HttpErrorsActionTypes.AddHttpError),
    switchMap((error: AddHttpError) => {
      if (error instanceof HttpErrorResponse) {
        const appErrorHandler = this.injector.get(ErrorHandler);
        appErrorHandler.handleError(error);
      }

      return of();
    })
  );

  constructor(private actions$: Actions, private injector: Injector) {}
}
