import { Injectable } from '@angular/core';
import moment from 'moment';
import { TcValidatorType } from '../../enums/tc-validations.enum';
import {
  TcValidatorError,
  TcValidatorFn,
} from '../../interfaces/tc-validations.interfaces';

@Injectable({ providedIn: 'root' })
export class TcValidationsService {
  // tslint:disable-next-line: max-line-length
  private readonly emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  public validate(
    validators: TcValidatorFn[],
    value: any,
    data?: any
  ): TcValidatorError[] {
    if (!validators?.length) {
      return;
    }

    const errors = [];
    for (const validator of validators) {
      const error = validator(value, data);
      if (error) {
        errors.push(error);
      }
    }

    return errors;
  }

  public required(message?: string): TcValidatorFn {
    return (value) => {
      if (!value) {
        return { type: TcValidatorType.Required, message };
      }
    };
  }

  public min(min: number, message?: string): TcValidatorFn {
    return (value) => {
      if (value && value < min) {
        return { type: TcValidatorType.Min, message };
      }
    };
  }

  public max(max: number, message?: string): TcValidatorFn {
    return (value) => {
      if (value && value > max) {
        return { type: TcValidatorType.Max, message };
      }
    };
  }

  public isNumber(message?: string): TcValidatorFn {
    return (value) => {
      if (!value || isNaN(value)) {
        return { type: TcValidatorType.IsNumber, message };
      }
    };
  }

  public isEmail(message?: string): TcValidatorFn {
    return (value) => {
      if (!value || !this.emailRegex.test(value)) {
        return { type: TcValidatorType.IsEmail, message };
      }
    };
  }

  public isDate(message?: string, format = 'DD/MM/YYYY'): TcValidatorFn {
    return (value) => {
      const isValidDate = moment(value, format, true)?.isValid();
      if (value && !isValidDate) {
        return { type: TcValidatorType.IsDate, message };
      }
    };
  }

  public isOneOf(values: any[], message?: string): TcValidatorFn {
    return (value) => {
      if (value && !values.includes(value)) {
        return { type: TcValidatorType.IsOneOf, message };
      }
    };
  }
}
