import { I } from '@angular/cdk/keycodes';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { from, Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import {
  getAuthenticationState
} from '../../modules/auth/store/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly store$: Store<TcAppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getAuthData()).pipe(
      mergeMap((authData) => {
        let headers = req.headers;
        if(authData?.loginAs){
          headers = headers.set('login-as', authData.loginAs);
        }
        
        const { token } = authData;
        if(token){
          headers=headers.set('Authorization', `Bearer ${token}`);
        }

        const request = req.clone({
              headers: headers
            });

        return next.handle(request);
      })
    );
  }

  private async getAuthData(): Promise<{token: string, loginAs: string}> {
    const authenticationState = await this.store$
      .select(getAuthenticationState)
      .pipe(take(1))
      .toPromise();

    return { token: authenticationState.mfaOptions?.token || authenticationState.token, loginAs: authenticationState.loginAs};
  }
}
