import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TcReportingQueryService } from './../../services/tc-reporting-query.service';
import { TcSpinnerService } from '@tc/store';
import { TcCsvService } from './../../services/tc-csv.service';
import { TcReportingQuery } from './../../interfaces/tc-reporting-query';
import { TcTranslateService } from '../../services/tc-translate.service';
import { TcNotificationService } from '../../services/tc-notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'tc-reporting-details',
  templateUrl: './tc-reporting-details.component.html',
  styleUrls: ['./tc-reporting-details.component.scss'],
})
export class TcReportingDetailsComponent implements OnInit, OnChanges {
  @Input() query: TcReportingQuery;
  @ViewChild('formContainer', { static: false, read: ViewContainerRef })
  container: ViewContainerRef;

  constructor(
    private tcReportingQueryService: TcReportingQueryService,
    public translate: TcTranslateService,
    private csv: TcCsvService,
    private notification: TcNotificationService,
    private httpClient: HttpClient,
    private spinner: TcSpinnerService,
    private dateAdapter: DateAdapter<any>
  ) {}

  /**
   * Results of the query to show it on HTML template
   */
  public results = [];

  /**
   * Columns of the result array
   */
  public resultsColumns = [];

  /**
   * Filters submitted on form validation
   */
  public filters = {};

  /**
   * Define if component has been loaded for the fisrt time
   */
  public firstLoading = true;

  ngOnInit() {
    // Resets comopnent properties on load
    this.resetResults();
    this.filters = {};

    // After init, if there is a query and form has no filter fields, we can set the query ready to return data.
    if (
      this.query &&
      (!this.query.filters || this.query.filters.length === 0)
    ) {
      this.loadList();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      const newQuery = changes.query.currentValue;
      const oldQuery = changes.query.previousValue;
      // If there's a new query incoming on the component and if is id has changed, we reload the component by calling ngOnInit()
      if (newQuery && (oldQuery === undefined || newQuery.id !== oldQuery.id)) {
        this.firstLoading = false;
        this.ngOnInit();
      }
    }
  }

  /**
   * Handle the click on the export button
   */
  public export() {
    this.loadExport();
  }

  /**
   * Handle the filter form submission
   * @param event Model of submitted form on TcReportingFormComponent
   */
  public getFilters(event) {
    // Replace empty values for NULL to avoid problems with stored procedures in the backend
    const data = Object.assign({}, event);
    for (const key in data) {
      if (data[key] === '') {
        data[key] = null;
      }
    }
    this.filters = data;
    this.loadList();
  }

  /**
   * Get data for limited list display and shows a notification if list > 500 rows
   */
  private loadList() {
    this.spinner.showSpinner('reportingDataLoading');
    this.getData(true)
      .then((values) => {
        this.setListData(values);
      })
      .catch((error) => this.notification.error(error));
  }

  /**
   * Remove results from the objet properties
   */
  private resetResults() {
    this.results = [];
    this.resultsColumns = [];
  }

  /**
   * Get data without limitation and get CSV file to download
   */
  private loadExport() {
    this.spinner.showSpinner('reportingDataLoading');
    this.getData(false)
      .then((values) => {
        this.spinner.hideSpinner('reportingDataLoading');
        const filename = this.query.name + '.csv';
        this.csv.download(JSON.stringify(values), filename);
      })
      .catch((error) => this.notification.error(error));
  }

  /**
   * Set data for a limited list display and shows a notification if list rows > to screen limit in query
   */
  private setListData(values: []) {
    this.spinner.hideSpinner('reportingDataLoading');
    if (values && values.length > 0) {
      this.results = values;
      this.resultsColumns = Object.keys(this.results[0]);
      if (this.results.length >= this.query.limit) {
        this.notification.info(
          this.translate.instant('tc-reporting-details.resultAreLimited')
        );
      }
    } else {
      this.resetResults();
      this.notification.info(
        this.translate.instant('tc-reporting-details.noResults')
      );
    }
    // MTU
    // console.log('Start test SUM');
    const total = {};
    let totalLabel = 0;
    for (const line of values) {
      Object.keys(line).forEach((key) => {
        const val = line[key];
        const numVal = parseFloat(val);
        if (!isNaN(val)) {
          // console.log(key + ' is a number for value ' + line[key]);
          if (total[key] === null || total[key] === undefined || !total[key]) {
            total[key] = 0;
          }
          total[key] += numVal;
        } else {
          if (totalLabel === 0) {
            total[key] = 'TOTAL';
            totalLabel = 1;
          } else {
            if (total[key] !== 'TOTAL') {
              total[key] = '';
            }
          }
        }
      });
    }
    // console.log(total);

    // Round the numbers
    Object.keys(total).forEach((key) => {
      if (total[key] !== '' && total[key] !== 'TOTAL') {
        // console.log(key + ' is a number for value ' + total[key]);
        total[key] = Number.parseFloat(total[key]).toFixed(2);
      }
    });
    this.results.push(total);
    // MTU
  }

  /**
   * Get data json array with limit or not.
   * @param limit number if true, the results will be limited to an harcoded value
   * @return Promise
   */
  private async getData(limit: boolean): Promise<[]> {
    const limitKey = 'limit';
    // Parameters to apply to the request : we clone filters object to be sure that the property remains untouched by reference usage : we don't want to alter filters.
    let params = Object.assign({}, this.filters);

    params = this.formatDateFilters(params);

    // Set up limitation if needed
    if (limit) {
      params[limitKey] = this.query.limit;
    }

    // Get the url from the reporting service
    const url = this.tcReportingQueryService.getBackEndUrl(this.query);

    // Set headers to define content-type
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    // Set a promise as return and post params as JSON string to the url
    return this.httpClient
      .post<[]>(url, JSON.stringify(params), httpOptions)
      .toPromise();
  }

  /*
   * Transform date inputs from filter to ignore timezone
   */
  private formatDateFilters(filters) {
    const formatedFilters = {};

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && moment.isMoment(value)) {
        formatedFilters[key] = this.dateAdapter
          .deserialize(value)
          .format('YYYY-MM-DD');
      } else {
        formatedFilters[key] = value;
      }
    });

    return formatedFilters;
  }
}
