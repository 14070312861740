import {
  DefaultDataService,
  HttpUrlGenerator,
  DefaultDataServiceConfig,
  QueryParams,
  Update,
} from 'ngrx-data';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ITcListDataService } from '../../interfaces/list/tc-list-data-service.interface';

export class TcListDataAdapter<T> extends DefaultDataService<T> {
  defaultNbLines = this.nbLines || 50;

  first = this.defaultNbLines; // number of items to load
  orderByAsString = null; // last sort expresion serialized as JSON string
  filterAsString = null; // last filter expresion serialized as JSON string
  endCursor: string | number = null; // cursor of last extracted item

  resObserver: Subscriber<any>;
  results$: Observable<any> = new Observable<any>(
    (o) => (this.resObserver = o)
  );

  updateObserver: Subscriber<any>;
  updatedItem$: Observable<any> = new Observable<any>(
    (o) => (this.updateObserver = o)
  );

  constructor(
    entityName: string,
    private tcListDataService: ITcListDataService<T>,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private nbLines: number,
    config?: DefaultDataServiceConfig
  ) {
    super(entityName, http, httpUrlGenerator, config);
  }

  getWithQuery(queryParams: QueryParams): Observable<T[]> {
    console.log('queryParams', queryParams);

    if (queryParams.initial) {
      const isInitial = JSON.parse(queryParams.initial as string);
      if (isInitial) {
        this.endCursor = null;
        this.filterAsString = null;
        this.orderByAsString = null;
      }
    }

    if (queryParams.orderBy) {
      if (queryParams.orderBy !== this.orderByAsString) {
        this.orderByAsString = queryParams.orderBy;
        this.endCursor = null;
      }
    }

    if (queryParams.filter) {
      if (queryParams.filter !== this.filterAsString) {
        this.filterAsString = queryParams.filter;
        this.endCursor = null;
      }
    }

    this.getData();

    return this.results$;
  }

  getAll(): Observable<T[]> {
    this.getData();

    return this.results$;
  }

  getData = () => {
    const resultsQuery = this.tcListDataService.getData(
      JSON.parse(this.filterAsString),
      JSON.parse(this.orderByAsString),
      { first: this.first, after: this.endCursor }
    );

    resultsQuery.then((value) => {
      const connectionResult = value;
      this.endCursor = connectionResult.pageInfo.endCursor;
      this.resObserver.next({
        data: connectionResult.edges,
        total: connectionResult.total,
      });
    });
  };

  update(update: Update<T>): Observable<T> {
    const subscription = super.update(update).subscribe((s) => {
      this.getItem(update.id);
      subscription.unsubscribe();
    });

    return this.updatedItem$;
  }

  add(add: T): Observable<T> {
    const subscription = super.add(add).subscribe((s) => {
      this.getItem((s as any).id);
      subscription.unsubscribe();
    });

    return this.updatedItem$;
  }

  getItem = (id: string | number) => {
    const updatedItem = this.tcListDataService.getItem(id);
    updatedItem.then((item) => {
      this.updateObserver.next(item);
    });
  };
}
