import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IVersionState } from '../reducers/version.reducers';

export const getVersionState =
  createFeatureSelector<IVersionState>('versionInfo');

export const getCurrentVersion = createSelector(
  getVersionState,
  (state: IVersionState) => state.version
);
