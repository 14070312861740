import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { TcComponentLookupRegistry } from '@tc/core';

/**
 * cdk-virtual-scroll-viewport actually uses a finite number of these panels.
 * The thing that happens when we scroll is that the same (let's say) 7 or 8 pannels instances
 * are being used and when we scroll there are no new pannels being created, it's just that
 * the data is being swaped.
 */
@Component({
  selector: 'tc-panel-list-item',
  templateUrl: './tc-panel-list-item.component.html',
  styleUrls: ['./tc-panel-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcPanelListItemComponent {
  _component: string;
  _componentRef: ComponentRef<any>;
  _data: any;
  _storeKey: string;

  @Input()
  set component(component: string) {
    this._component = component;

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<any>(
        TcComponentLookupRegistry.get(this._component) as any
      );
    this._componentRef = this.myRef.createComponent(componentFactory);
  }

  @Input()
  set data(data: any) {
    this._data = data;

    if (this._componentRef) {
      this._componentRef.instance.data = this._data;
    }
  }

  @Input()
  set storeKey(storeKey: any) {
    this._storeKey = storeKey;

    if (this._componentRef) {
      this._componentRef.instance.storeKey = this._storeKey;
    }
  }

  @ViewChild('tcPanelItemHost', { static: true, read: ViewContainerRef })
  myRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
}
