import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';
import { ConfigKeys } from '../interfaces/config.interface';
import { ConfigService } from '../services/config.service';

@Injectable()
export class RouteResolver implements Resolve<any> {
  constructor(
    private readonly store$: Store<any>,
    private readonly router: Router,
    private readonly config: ConfigService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    const permissions = route.routeConfig?.data?.permissions;

    if (permissions?.length) {
      let access = false;
      for (const permission of permissions) {
        const { action, subject } = permission;
        if (abilities.can(action, subject)) {
          access = true;
          break;
        }
      }

      if (access === false) {
        // Navigate to the default forbidden page or the url provided in the route if the user has no rights to access the page
        let redirectTo =
          '/' + this.config.get(ConfigKeys.defaultForbiddenRoute);
        if (route.routeConfig?.data?.permissions?.redirectTo) {
          redirectTo = route.routeConfig?.data?.permissions?.redirectTo;
        }
        this.router.navigateByUrl(redirectTo);
      }
    }
  }
}
