import { Component, OnInit } from '@angular/core';
import { TcComponent } from '@tc/abstract';
import { TcVersionService } from '../../services/tc-version.service';

@Component({
  selector: 'tc-version',
  templateUrl: './tc-version.component.html',
  styleUrls: ['./tc-version.component.scss'],
})
export class TcVersionComponent extends TcComponent implements OnInit {
  public version: string;

  constructor(private readonly tcVersionService: TcVersionService) {
    super();
  }

  ngOnInit() {
    // Display version info on console
    this.tcVersionService.displayVersionInConsole();

    // Set the version to be displayed
    this.version = this.tcVersionService.getVersionAsString();
  }
}
