import {
  ResetTcFilterPayload,
  UpdateTcFilterSuccessPayload,
  InitTcFilterSuccessPayload,
} from '@tc/data-store';
import { NgRxTcFilterState, TcFilterState } from '../state/tc-filter-state';
import * as R from 'ramda';
import { createReducer, on } from '@ngrx/store';
import * as TcFilterActions from '../actions/tc-filter-actions';

/**
 * initialNgRxTcFilterState
 */
export const initialNgRxTcFilterState: NgRxTcFilterState = {};

/**
 * the initial state of tc filter
 */
export const initialTcFilterState: TcFilterState = {
  storeKey: null,
  filter: {},
  isPersistant: false,
};

/**
 * this handler will init tc filter in store
 */
const initTcFilterSuccessHandler = (
  state: TcFilterState,
  newState: InitTcFilterSuccessPayload
): TcFilterState => {
  const { storeKey, filter, isPersistant, config } = newState;

  return R.mergeRight(state, {
    storeKey,
    filter,
    isPersistant,
    config,
  });
};

/**
 *  this handler will handle update tc filter store
 * */
const updateTcFilterSuccessHandler = (
  state: TcFilterState,
  newState: UpdateTcFilterSuccessPayload
): TcFilterState => {
  const { filter } = newState;

  return {
    ...state,
    filter,
  };
};

/**
 * this handler will handle rest of tc filter store
 */
const resetTcFilterHandler = (
  state: TcFilterState,
  newState: ResetTcFilterPayload
): TcFilterState => {
  return {
    ...state,
    filter: {},
    isPersistant: false,
  };
};

/**
 * ngrx tc filter state reducer
 */
const ngRxTcFilterStateReducer = createReducer(initialNgRxTcFilterState);

/**
 * reducer
 */
const reducer = createReducer(
  initialTcFilterState,
  on(TcFilterActions.initTcFilterSuccess, initTcFilterSuccessHandler),
  on(TcFilterActions.updateTcFilterSuccess, updateTcFilterSuccessHandler),
  on(TcFilterActions.resetTcFilter, resetTcFilterHandler)
);

/**
 *  check if action is a TcFilterAction (action starts with TC_FILTER_ACTIONS_PREFIX)
 */
const isTcFilterAction = R.startsWith(TcFilterActions.TC_FILTER_ACTIONS_PREFIX);

/**
 * tc filter reducer
 */
export function tcFilterReducer(state = initialNgRxTcFilterState, action) {
  if (!isTcFilterAction(action.type)) {
    return state;
  }

  const { storeKey } = action;

  const nextState = ngRxTcFilterStateReducer(state, action);
  const tcFilterState = nextState[storeKey];

  return R.mergeRight(nextState, {
    [storeKey]: reducer(tcFilterState, action),
  });
}
