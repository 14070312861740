import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { IPageState, getPageInfo } from '@tc/store';

/**
 * This component sole purpose is to get the pageTitleButtons from the store and display it in a smart buttons list.
 */
@Component({
  selector: 'tc-page-buttons',
  templateUrl: './tc-page-buttons.component.html',
  styleUrls: ['./tc-page-buttons.component.scss'],
})
export class TCPageButtonsComponent extends TcSmartComponent implements OnInit {
  /**
   * Observable of the page info from the store
   */
  pageInfo$: Observable<IPageState>;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.pageInfo$ = this.store$.pipe(select(getPageInfo));
  }
}
