import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcButtonsModule } from '@tc/buttons';
import { HomeDetailsComponent } from './home-details/home-details.component';
import { SharedModule } from '../../shared/shared.module';
import { HomeService } from './services/home.service';
import { EffectsModule } from '@ngrx/effects';
import { HomeEffects } from './store/home.effects';
import { homeReducer } from './store/home.reducer';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';

const components = [HomeDetailsComponent];
const modules = [SharedModule, RouterModule];
const services = [HomeService];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    CommonModule,
    TcButtonsModule,
    TcCoreModule,
    EffectsModule.forFeature([HomeEffects]),
    StoreModule.forFeature('home', homeReducer),
  ],
  exports: [...components],
  providers: [...services],
})
export class HomeModule {}
