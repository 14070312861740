import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FilterTypesEnum, ListOrder, TcConfigTypes, TcDataProviderType, TcSmartFilterConfig } from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  formlyColumn,
  formlyControl,
  formlyRow,
  getTcFilters,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcTranslateService,
} from '@tc/core';
import { NgRxTcDataState } from '@tc/data-store';
import { getAbilities } from '@tc/permissions';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { AmsGridComponents } from '../../../../../typings/ams-grid-components.enum';
import { loadAssociationsStats } from '../../../store/associations.actions';
import { AssociationCategorie, Logiciel } from '../../../types';
import { getAuthenticatedUser } from '../../../../../../modules/auth/store/auth.selectors';
import * as R from 'ramda';
import { DEFAULT_GRID_HEADER_HEIGHT, DEFAULT_GRID_ROW_HEIGHT } from '../../../../../shared/utils/constants';
import { getBankIdFromUserDetails } from '../../../../../shared/utils/user.utils';

@Component({
  selector: 'app-associations-grid',
  templateUrl: './associations-grid.component.html',
  styleUrls: ['./associations-grid.component.scss'],
})
export class AssociationsGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  @ViewChild('calendarTrigger', { static: true })
  calendarTrigger: TemplateRef<any>;

  storeKey = 'associations-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  private filterStore$: Observable<NgRxTcDataState>;
  private subscription = new Subscription();

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translateService: TcTranslateService
  ) {
    super(store$);
    this.filterStore$ = store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnInit() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    // Subscription to update Association Stats tables
    const statsSub = selectByKey(
      getTcFilters,
      this.filterStore$,
      this.storeKey
    ).subscribe((curFilter) => {
      // Only request stats if the filter is not empty. The initial filter is empty and
      // we end up with errored stats.
      if (!R.isEmpty(curFilter)) {
        this.store$.dispatch(loadAssociationsStats(curFilter));
      }
    });

    const authenticatedUser = await this.store$
    .select(getAuthenticatedUser)
    .pipe(take(1))
    .toPromise();

    // Authenticated user contains extra details that we require if the user is a bank
    // to set the default bank on the bank filter and disable the control.
    // For admin, the control is enabled and all banks are present in the control.
    this.setFilterConfig(authenticatedUser?.additionalDetails);

    this.subscription.add(statsSub);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'associations-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: DEFAULT_GRID_ROW_HEIGHT,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().associations,
        fields: '',
      },
      columns: [
        {
          field: 'code',
          minWidth: 230,
          maxWidth: 230,
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => isDescending ? -1 : 1,          
        },
        {
          field: 'nom',
        },
        {
          field: 'banqueNom',
        },
        {
          field: 'categorie',
          cellRenderer: (params: any) =>
            this.associationsGridCellRenderer('categorie', params.value),
          minWidth: 100,
          maxWidth: 100
        },
        {
          field: 'decryptedPassword',
          minWidth: 180,
          maxWidth: 180
        },
        {
          field: 'active',
          cellRenderer: (params: any) =>
            this.associationsGridCellRenderer('active', params.value),
          minWidth: 80,
          maxWidth: 80
        },
        {
          field: 'numeroVersionDisplayed',
          minWidth: 100,
          maxWidth: 100
        },
        {
          field: 'logiciel',
          minWidth: 100,
          maxWidth: 100
        },
        {
          field: 'dateDebutTicadi',
          cellEditor: AmsGridComponents.AmsGridDatepickerEditor,
          cellRenderer: AmsGridComponents.AmsGridDatepickerRenderer,
          editable: (params) => this.isDateEditable(params),
          minWidth: 120,
          maxWidth: 120
        },
      ],
      filterConfig: this.filterConfig,
    };

    super.ngOnInit();
  }

  private isDateEditable(params) {
    return params?.data?.logiciel?.toLowerCase() !== Logiciel.Ticadi.valueOf();
  }

  private associationsGridCellRenderer(field: string, value: string): string {
    return this.translateService.instant(
      `${this.storeKey}.table.values.${field}.${value}`
    );
  }

  setFilterConfig(userDetails: { [key: string]: any }) {
    // If the logged in user is a bank, we need to set the default selected value to the bank
    // and disable the select form
    const bankId = getBankIdFromUserDetails(userDetails) ?? "";
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'banqueId',
                type: TcFormlyComponent.TcSmartSelect,
                defaultValue: bankId,
                templateOptions: {
                  disabled: !!bankId,
                  clearButtonEnabled: false,
                  filterType: FilterTypesEnum.Equal,
                  multiple: false,
                  labelFieldName: 'nom',
                  valueFieldName: 'id',
                  defaultValue: '',
                  defaultValueLabel: this.translateService.instant(
                    `${this.storeKey}.filter.values.banqueId.allBanques`
                  ),
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.RestApi,
                    dataSet: this.routeProvider.getRoutes().banques,
                    sortOrder: {
                      key: 'nom',
                      order: ListOrder.Asc,
                    },
                  },
                },
                lgColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'categorie',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: AssociationCategorie.TousCategories,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: AssociationCategorie.TousCategories,
                      label: this.translateService.instant(
                        `associationCategorie.tousCategories`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie1,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie1}`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie2,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie2}`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie3,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie3}`
                      ),
                    },
                  ],
                },
                colSpan: 2,
              }),
              formlyControl({
                key: 'active',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: true,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(
                        `globalLabels.tous`
                      ),
                    },
                    {
                      value: true,
                      label: this.translateService.instant(
                        `globalLabels.oui`
                      ),
                    },
                    {
                      value: false,
                      label: this.translateService.instant(
                        `globalLabels.non`
                      ),
                    },
                  ],
                },
                colSpan: 1,
              }),
              formlyControl({
                key: 'logiciel',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: Logiciel.TousLogiciel,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: Logiciel.TousLogiciel,
                      label: this.translateService.instant(
                        `${this.storeKey}.filter.logiciel.values.tousLogiciel`
                      ),
                    },
                    {
                      value: Logiciel.Aucun,
                      label: this.translateService.instant(
                        `${this.storeKey}.filter.logiciel.values.${Logiciel.Aucun}`
                      ),
                    },
                    {
                      value: Logiciel.Passerelle,
                      label: this.translateService.instant(
                        `${this.storeKey}.filter.logiciel.values.${Logiciel.Passerelle}`
                      ),
                    },
                    {
                      value: Logiciel.Ticadi,
                      label: this.translateService.instant(
                        `${this.storeKey}.filter.logiciel.values.${Logiciel.Ticadi}`
                      ),
                    },
                  ],
                },
                colSpan: 2
              }),
              formlyControl({
                key: 'dateDebutTicadi.start',
                type: TcFormlyComponent.TcDatePicker,
                colSpan: 2
              }),
              formlyControl({
                key: 'dateDebutTicadi.end',
                type: TcFormlyComponent.TcDatePicker,
                colSpan: 2
              })
            ],
          }),
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true,
        },
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }
}
