import { TcAction } from './tc-action';
import { IPageState } from '../reducers/page.reducers';

export enum PageActionTypes {
  SET_PAGE_INFO = '[Page] Set Page Info',
}

export class SetPageInfo implements TcAction {
  readonly type = PageActionTypes.SET_PAGE_INFO;
  constructor(public payload: IPageState) {}
}

export type PageActions = SetPageInfo;
