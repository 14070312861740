import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState } from '@tc/store';
import { TcSmartComponent } from '@tc/abstract';
import { take } from 'rxjs/operators';
import { LoginMfaInterface } from '../../../interfaces/login-mfa.interface';
import { clearMfaOptions, loginMfa } from '../../../store/auth.actions';
import { getAuthenticationMfaOptions } from '../../../store/auth.selectors';

@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss'],
})
export class LoginMfaComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  public plainTextCode: string;
  public src = null;

  constructor(
    store$: Store<TcAppState>,
    private readonly translate: TranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {
    const options: LoginMfaInterface = await this.store$
      .select(getAuthenticationMfaOptions)
      .pipe(take(1))
      .toPromise();

    if (options?.code) {
      this.plainTextCode = `${this.translate.instant(
        'login-mfa.plain-code'
      )}\n${options.code}`;
    }

    this.src = options?.image;
  }

  public login(data) {
    this.store$.dispatch(loginMfa(data));
  }

  ngOnDestroy() {
    this.store$.dispatch(clearMfaOptions());
  }
}
