import { HttpClient } from '@angular/common/http';
import {
  ITcDataResult,
  ITcDataService,
  TcDataProviderConfig,
  TcFilterDef,
  TcSortDef,
} from '@tc/abstract';
import { IConfigService } from '@tc/config';
import * as R from 'ramda';

import { TcCrudService } from '../tc-breeze/tc-crud.service';

/**
 * A basic, generic entity data service
 */
export class TcRestApiDataService<T> implements ITcDataService<T> {
  protected _name: string;
  protected storeKey: string;
  dataProvider: TcDataProviderConfig;
  private readonly crudService: TcCrudService;

  get name() {
    return this._name;
  }

  constructor(
    storeKey: string,
    //    private readonly tcGridPaginationService: TcGridPaginationService,
    dataProvider: TcDataProviderConfig,
    public httpClient: HttpClient,
    // @Inject(CONFIG_SERVICE)
    public config: IConfigService
  ) {
    this._name = `TcRestApiDataService`;
    this.storeKey = storeKey;
    this.dataProvider = dataProvider;
    this.crudService = new TcCrudService(this.httpClient, config);
    this.crudService.collection = this.dataProvider.dataSet;
  }

  async getData(
    skip: number,
    take: number,
    filter?: TcFilterDef,
    sort?: TcSortDef
  ): Promise<ITcDataResult<T>> {
    if (!filter && this.dataProvider?.filter) {
      filter = this.dataProvider?.filter;
    }

    let queryString = this.getQueryParameters(filter, skip, take, sort);

    var queryResult = await this.crudService.getWithQueryParams(queryString);

    // Convert entity to raw JSON
    let results: any;
    results = queryResult;

    return results;
  }

  private getQueryParameters(
    filterDef?: TcFilterDef,
    skip?: number,
    take?: number,
    orderBy?: TcSortDef
  ): string {
    const queries = [];
    const filters = filterDef?.filters;
    if (filters && filters.length) {
      for (const filter of filters) {
        const queryParameterValue = filter.value;
        if (
          queryParameterValue !== undefined &&
          queryParameterValue !== '' &&
          queryParameterValue !== null
        ) {
          const queryParameterKey = filter.key;
          const query = `${queryParameterKey}=${queryParameterValue}`;
          queries.push(query);
        }
      }
    }
    if (skip) {
      queries.push(`skip=${skip}`);
    }
    if (take) {
      queries.push(`take=${take}`);
    }
    if (orderBy) {
      queries.push(`orderBy=${orderBy.key},${orderBy.order}`);
    }
    return queries.join('&');
  }

  async getDataInlineCount(filter?: TcFilterDef): Promise<number> {
    return 0;
  }

  /**
   * Creates or updates a document based on whether or not it had an id
   * NOTE: Wrap this in a try catch block and only display the real error in dev mode
   * so that we don't show English error messages coming from the server/breeze or form the
   * method itself directly to the client.
   * @param item, i.e the document
   * @returns the newly created/updated document
   */
  async upsert(item: T): Promise<T> {
    const itemCopy = R.clone(item);
    return itemCopy;
  }

  /**
   * Remove a document
   * @param item
   */
  async delete(item: T): Promise<any> {}
}
