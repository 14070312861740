import { createAction, props } from '@ngrx/store';

import { IndicateursEtat } from '../interfaces/indicateurs-etat.interface';
import { Trimestre } from '../typings/trimestre.enum';
import {
  GetIndicateursEtatDetailsPayload,
  GetIndicateursEtatDetailsSuccessPayload,
  IndicatorRowData,
  IndicatorsGridActionButtonClickedPayload,
  LoadIndicatorAssociationDetailsPayload,
  LoadIndicatorAssociationDetailsSuccessPayload,
} from './indicators.payloads';

export const getIndicateursEtatDetails = createAction(
  '[Indicateurs] Get Indicateurs Etat Details',
  props<{ payload: GetIndicateursEtatDetailsPayload }>()
);

export const getIndicateursEtatDetailsSuccess = createAction(
  '[Indicateurs] Get Indicateurs Etat Details Success',
  props<{ payload: GetIndicateursEtatDetailsSuccessPayload }>()
);

export const indicatorsGridActionButtonClicked = createAction(
  '[Indicateurs] Indicators Grid Action Button Clicked',
  props<IndicatorsGridActionButtonClickedPayload>()
);

export const exportIndicatorsAsPdf = createAction(
  '[Indicateurs] Export Indicators as PDF',
  props<{ payload: IndicatorRowData }>()
);

export const exportIndicateursCsv = createAction(
  '[Indicateurs] Export Indicateurs CSV'
);

export const exportIndicatorsDetailCsv = createAction(
  '[Indicateurs] Export Indicators Details Csv',
  props<{ payload: IndicatorRowData }>()
);

export const saveIndicatorsDetail = createAction(
  '[Indicateurs] Save Indicator Details Information'
);

export const saveTicadiIndicatorDetails = createAction(
  '[Indicateurs Details] Save Ticadi Indicator Details'
);

export const validateIndicatorsDetail = createAction(
  '[Indicateurs] Validate Indicators Details'
);

export const validateTicadiIndicatorsDetail = createAction(
  '[Indicateurs Details] Validate Ticadi Indicator Details'
)

export const loadIndicatorAssociationDetails = createAction(
  '[Indicateurs] Load Indicator Association Details',
  props<{ payload: LoadIndicatorAssociationDetailsPayload }>()
);

export const loadIndicatorAssociationDetailsSuccess = createAction(
  '[Indicateurs] Load Indicator Association Details Success',
  props<{ payload: LoadIndicatorAssociationDetailsSuccessPayload }>()
);

export const setIndicatorToUpdate = createAction(
  '[Indicateurs Details] Set Indicateurs to Update',
  props<{ indicateur: IndicateursEtat }>()
);

export const setTicadiIndicatorInitialValuesToUpdate = createAction(
  '[Indicateurs Details] Set Ticadi Indicator to Update',
  props<{ indicateur: IndicateursEtat }>()
);

export const setTicadiIndicatorsValuesToUpdate = createAction(
  '[Indicateurs Details] Set Ticadi Indicator Values to Update',
  props<{ anneeIndicateur: IndicateursEtat | null, trimestreIndicateur: IndicateursEtat | null }>()
);

export const setTicadiIndicatorsToModify = createAction(
  '[Indicateurs Details] Set Ticadi Indicators To Modify',
  props<{ indicators: IndicateursEtat[], trimestre: Trimestre}>() 
);

export const clearTicadiIndicatorsToModify = createAction(
  '[Indicateurs Details] Clear Ticadi Indicators To Modify'
);

export const setInitialValuesVisible = createAction(
  '[Indicateurs Details] Set Initial Values Visible',
  props<{ initialValuesVisible: boolean }>()
);
