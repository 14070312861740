import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { NgRxTcSmartFormState, TcSmartFormState } from '..';

export const getTcSmartFormState =
  createFeatureSelector<NgRxTcSmartFormState>('tc-smart-form');

/**
 * Get TcSmartForm whole state by storeKey
 */
export const getTcSmartFormByStoreKey = (
  state: NgRxTcSmartFormState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

/**
 * Get TcSmartForm config
 */
export const getTcSmartFormConfig = createSelector(
  getTcSmartFormByStoreKey,
  (state: TcSmartFormState) => {
    return R.propOr(null, 'config')(state);
  }
);

/**
 * Get TcSmartForm currentModel
 */
export const getTcSmartFormCurrentModel = createSelector(
  getTcSmartFormByStoreKey,
  (state: TcSmartFormState) => {
    return R.propOr(null, 'currentModel')(state);
  }
);

/**
 * Get TcSmartForm model
 */
export const getTcSmartFormModel = createSelector(
  getTcSmartFormByStoreKey,
  (state: TcSmartFormState) => {
    return R.propOr(null, 'model')(state);
  }
);

/**
 * Get TcSmartForm submit model
 */
export const getTcSmartFormSubmitModel = createSelector(
  getTcSmartFormByStoreKey,
  (state: TcSmartFormState) => {
    return R.propOr(null, 'submitModel')(state);
  }
);

/**
 * Get TcSmartForm invalidity of form
 */
export const getTcSmartFormInvalidity = createSelector(
  getTcSmartFormByStoreKey,
  (state: TcSmartFormState) => {
    return R.propOr(null, 'invalid')(state);
  }
);

export const getTcSmartFormModelByKey = (storeKey: string) =>
  createSelector(getTcSmartFormState, (state) => state[storeKey] ?? null);

export const getTcSmartFormIsChangedState = (storeKey: string) =>
  createSelector(getTcSmartFormModelByKey(storeKey), (state) =>
    Boolean(state?.isChanged)
  );
