import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import { closeDialogAndNavigateButtonClicked, TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import { TcComponentLookup, TcTranslateService } from '@tc/core';

import { CustomRoutes } from '../../../../typings/custom-routes.enum';
import { loadDeliveryNoteHeaderInfo } from '../../store/delivery-notes.actions';
import { getBLDateData, getBLMainData } from '../../store/delivery-notes.selectors';

@TcComponentLookup('DeliveryNoteDetailsComponent')
@Component({
  selector: 'app-delivery-note-details',
  templateUrl: './delivery-note-details.component.html',
  styleUrls: ['./delivery-note-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryNoteDetailsComponent
  extends TcSmartDetailPopupComponent
  implements OnInit
{
  public mainDataSelector;
  public dateInfoSelector;
  public deliveryNoteId: number;

  formStoreKey = 'delivery-notes-grid';

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);
  }

  async ngOnInit() {
    this.deliveryNoteId = this.data?.id;

    this.store$.dispatch(
      loadDeliveryNoteHeaderInfo({ deliveryNote: this.data })
    );

    this.mainDataSelector = getBLMainData;
    this.dateInfoSelector = getBLDateData;

    super.ngOnInit();
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasFooter = true;
    (this.dialogConfig.footerConfig = {
      configType: TcConfigTypes.TcDetailHeader,
      buttonsList: this.getFooterButtons(),
    }),
      (this.formConfig = {
        configType: TcConfigTypes.TcForm,
        storeKey: this.formStoreKey,
        autoSubmit: false,
        fieldConfigs: [],
        titleConfig: {
          storeKey: this.formStoreKey,
          configType: TcConfigTypes.TcDetailTitle,
          titlePrefix: 'delivery-note-details.title',
          hasGenericSaveButton: false,
        },
      });
  }

  getFooterButtons(): TcSmartButton[] {
    const headerButtons = [];

    headerButtons.push({
      label: 'close',
      color: MaterialColor.Primary,
      action: closeDialogAndNavigateButtonClicked,
      actionPayload: {
        storeKey: this.formStoreKey,
        route: `/${CustomRoutes.ListeBl}`,
        queryParams: {},
      },
    });

    return headerButtons;
  }
}
