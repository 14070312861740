import { Component, Input, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import {
  GridApi,
  IAfterGuiAttachedParams,
  IHeaderParams,
} from 'ag-grid-community';

@Component({
  selector: 'tc-grid-header-selection',
  templateUrl: './tc-grid-header-selection.component.html',
  styleUrls: ['./tc-grid-header-selection.component.scss'],
})
export class TcGridHeaderSelectionComponent
  implements IHeaderAngularComp, OnInit
{
  @Input()
  defaultValue: boolean;

  public label: string;
  public checked: boolean;

  private gridApi: GridApi;

  ngOnInit() {
    this.checked = this.defaultValue;
  }

  public onSelect(event: { checked: boolean }) {
    this.gridApi?.forEachNode((node) => node.setSelected(event?.checked));
  }

  refresh(params: IHeaderParams): boolean {
    this.checked = this.defaultValue;
    return false;
  }

  agInit(params) {
    this.label = params?.label;
    this.gridApi = params?.api;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams) {}
}
