import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { menuReducer } from './store/menu.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('menu', menuReducer)],
})
export class MenuModule {}
