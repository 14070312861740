import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { LoginResponseInterface } from '../modules/auth/interfaces/login-response.interface';
import { AuthService } from './business-services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends TcService {
  constructor(private readonly authService: AuthService) {
    super();
  }

  public async login(
    username: string,
    password: string
  ): Promise<LoginResponseInterface> {
    if (!username || !password) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    const res = await this.authService.login(username, password);

    if (!res?.accessToken) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    return res;
  }

  public async loginWindows(): Promise<LoginResponseInterface> {
    const res = await this.authService.loginWindows();

    if (!res?.accessToken) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    return res;
  }

  public async mfa(code: string): Promise<{ accessToken: string }> {
    const res = await this.authService.mfaLogin(code);

    if (!res?.accessToken) {
      throw new Error('login-mfa-form.errors.inavlid-code');
    }

    return res;
  }

  public async refresh(): Promise<{ accessToken: string }> {
    return await this.authService.refresh();
  }
}
