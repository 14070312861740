import { Store } from '@ngrx/store';
import { TcSmartListComponent } from './tc-smart-list-component';
import { TcSmartGridConfig } from '@tc/abstract';
import { Directive } from "@angular/core";

/**
 * Base class for a custom component with a grid list
 */
@Directive()
export class TcSmartGridComponent extends TcSmartListComponent {
  protected listConfig: TcSmartGridConfig;
  protected store$: Store<any>;

  constructor(store$: Store<any> = null) {
    super(store$);
  }
}
