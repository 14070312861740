import * as R from 'ramda';
import { createReducer, on } from '@ngrx/store';
import * as TcSmartFormActions from '../actions/tc-smart-form-actions';
import { NgRxTcSmartFormState, TcSmartFormState } from '../state';
import {
  InitTcSmartFormPayload,
  SetTcSmartFormModelPayload,
  UpdateTcSmartFormCurrentModelPayload,
} from '../payloads';
import { BaseTcStorePayload } from '@tc/abstract';

/**
 * Initial NgRx TcSmartForm State
 */
export const initialNgRxTcSmartFormState: NgRxTcSmartFormState = {};

/**
 * The initial state of the TcSmartForm
 */
export const initialTcSmartFormState: TcSmartFormState = {
  storeKey: null,
  config: null,
  invalid: null,
  model: null,
  currentModel: null,
  submitModel: null,
  isChanged: false,
};

/**
 * Initializes the TcSmartForm State
 */
const initTcSmartFormSuccessHandler = (
  state: TcSmartFormState,
  newState: InitTcSmartFormPayload
): TcSmartFormState => {
  const { storeKey, model, config } = newState;

  return R.mergeRight(state, {
    storeKey,
    config,
    model,
    // submitModel needs to be set to null, otherwise if we have updated a detail in the past,
    // whenever we reopen the detail and the form gets initialized the submitModel selector
    // will be triggered and another save will occur
    // Alternatives for the future in case we need them:
    //   - trigger an effect for submitTcSmartFormCurrentModel that sets the submit model back to null
    //   - clean up the smartForm store afer we are done with it.
    submitModel: config.autoSubmit ? model : null,
    isChanged: false,
  });
};

/**
 * Updates the currentModel
 */
const updateTcSmartFormCurrentModelHandler = (
  state: TcSmartFormState,
  newState: UpdateTcSmartFormCurrentModelPayload
): TcSmartFormState => {
  const { currentModel, invalid } = newState;

  return {
    ...state,
    invalid,
    currentModel,
  };
};

/**
 * Submits the currentModel to the model.
 */
const submitTcSmartFormCurrentModelHandler = (
  state: TcSmartFormState,
  newState: BaseTcStorePayload
): TcSmartFormState => {
  const { currentModel } = state;

  return {
    ...state,
    model: currentModel,
    submitModel: currentModel,
    isChanged: false,
  };
};

/**
 * Resets the currentModel to the model value.
 * This will trigger a form.valueChanged event that will update the current model
 */
const resetTcSmartFormCurrentModelHandler = (
  state: TcSmartFormState,
  newState: BaseTcStorePayload
): TcSmartFormState => {
  const { model } = state;

  return {
    ...state,
    model: R.clone(model),
  };
};

/**
 * Marks form state as changed
 */
const markTcSmartFormStateAsChangedHandler = (
  state: TcSmartFormState,
  newState: BaseTcStorePayload
): TcSmartFormState => {
  return {
    ...state,
    isChanged: true,
  };
};

/**
 * Set the model.
 */
const setTcSmartFormModelHandler = (
  state: TcSmartFormState,
  newState: SetTcSmartFormModelPayload
): TcSmartFormState => {
  const { model } = newState;

  return {
    ...state,
    model: R.clone(model),
    submitModel: null,
  };
};

/**
 * Set the model.
 */
const clearTcSmartFormSubmitModelHandler = (
  state: TcSmartFormState
): TcSmartFormState => {
  return {
    ...state,
    submitModel: null,
  };
};

/**
 * ngRx TcSmartForm State Reducer
 */
const ngRxTcSmartFormStateReducer = createReducer(initialNgRxTcSmartFormState);

/**
 * Reducer function that handles the state transitions.
 */
const reducer = createReducer(
  initialTcSmartFormState,
  on(TcSmartFormActions.initTcSmartFormSuccess, initTcSmartFormSuccessHandler),
  on(
    TcSmartFormActions.updateTcSmartFormCurrentModel,
    updateTcSmartFormCurrentModelHandler
  ),
  on(
    TcSmartFormActions.submitTcSmartFormCurrentModel,
    submitTcSmartFormCurrentModelHandler
  ),
  on(
    TcSmartFormActions.markTcSmartFormStateAsChanged,
    markTcSmartFormStateAsChangedHandler
  ),
  on(
    TcSmartFormActions.resetTcSmartFormCurrentModel,
    resetTcSmartFormCurrentModelHandler
  ),
  on(TcSmartFormActions.setTcSmartFormModel, setTcSmartFormModelHandler),
  on(
    TcSmartFormActions.clearTcSmartFormSubmitModel,
    clearTcSmartFormSubmitModelHandler
  )
);

/**
 *  Check if action is a TcSmartFormAction
 *  (action starts with TC_SMART_FORM_ACTIONS_PREFIX)
 */
const isTcSmartFormAction = R.startsWith(
  TcSmartFormActions.TC_SMART_FORM_ACTIONS_PREFIX
);

/**
 * TcSmartForm Reducer
 */
export function tcSmartFormReducer(
  state = initialNgRxTcSmartFormState,
  action
) {
  if (!isTcSmartFormAction(action.type)) {
    return state;
  }

  const { storeKey } = action;

  const nextState = ngRxTcSmartFormStateReducer(state, action);
  const tcSmartFormState = nextState[storeKey];

  return R.mergeRight(nextState, {
    [storeKey]: reducer(tcSmartFormState, action),
  });
}
