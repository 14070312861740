import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent extends AmsPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'forbidden-page.title',
      displayPageTitle: false
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
