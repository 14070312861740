import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, ObservableInput, of } from 'rxjs';
import { TcAppConfig } from './tc-app.config';
import { GetConfigService } from './get-config.service';

export function getConfig(
  http: HttpClient,
  getConfigService: GetConfigService
): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      http
        .get('app/custom/assets/config.json')
        .pipe(
          map((x: TcAppConfig) => {
            getConfigService.appConfig = x;
            resolve(true);
          }),
          catchError(
            (
              x: { status: number },
              caught: Observable<void>
            ): ObservableInput<{}> => {
              if (x.status !== 404) {
                resolve(false);
              }
              resolve(true);
              return of({});
            }
          )
        )
        .subscribe();
    });
  };
}
