import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { dateCellRenderer } from '../../../shared/utils/format.utils';
import { ReceivedDeliveryNotesService } from '../services/received-delivery-notes.service';
import {
  loadAssociationBLDetsSuccess,
  loadBanqueBLDetsSuccess,
  loadEnvoyeLeDetsSuccess,
  loadNumeroBLDetsSuccess,
  loadReceivedDeliveryNoteHeaderInfo,
  loadResultantBLDetsSuccess,
} from './received-delivery-notes.actions';

@Injectable()
export class ReceivedDeliveryNotesEffects {
  storeKey = 'received-delivery-notes-grid';

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly receivedDeliveryNotesService: ReceivedDeliveryNotesService
  ) {}

  loadReceivedDeliveryNoteHeaderInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadReceivedDeliveryNoteHeaderInfo),
        tap(async ({ receivedDeliveryNote }) => {
          const { numBeBl, reception, codeBA, codeAssociation, resultat } =
            receivedDeliveryNote;

          const blNo: AmsStatsInterface = {
            label: 'received-delivery-notes-details.label.numBeBl',
            value: numBeBl,
          };
          this.store$.dispatch(loadNumeroBLDetsSuccess({ payload: [blNo] }));

          const receivedDate: AmsStatsInterface = {
            label: 'received-delivery-notes-details.label.reception',
            value: dateCellRenderer(reception),
          };
          this.store$.dispatch(
            loadEnvoyeLeDetsSuccess({ payload: [receivedDate] })
          );

          const codeBanque: AmsStatsInterface = {
            label: 'received-delivery-notes-details.label.codeBA',
            value: codeBA,
          };
          this.store$.dispatch(
            loadBanqueBLDetsSuccess({ payload: [codeBanque] })
          );

          const associationDets: AmsStatsInterface = {
            label: 'received-delivery-notes-details.label.codeAssociation',
            value: codeAssociation,
          };
          this.store$.dispatch(
            loadAssociationBLDetsSuccess({ payload: [associationDets] })
          );

          const resultatDets: AmsStatsInterface = {
            label: 'received-delivery-notes-details.label.resultat',
            value: resultat,
          };
          this.store$.dispatch(
            loadResultantBLDetsSuccess({ payload: [resultatDets] })
          );
        })
      ),
    { dispatch: false }
  );
}
