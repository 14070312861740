export interface TcSpinnerState {
  loading: SpinnerState;
}

export interface SpinnerState {
  actionsInProgress: number;
}

export const initialSpinnerState: SpinnerState = {
  actionsInProgress: 0,
};
