import { Component, ElementRef, Output, EventEmitter } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'tc-grid-mass-update-header',
  templateUrl: './tc-grid-mass-update-header.component.html',
  styleUrls: ['./tc-grid-mass-update-header.component.scss'],
})
export class TcGridMassUpdateHeaderComponent implements IHeaderAngularComp {
  public params: any;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  @Output() massUpdate = new EventEmitter();

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  onMassUpdate(event) {
    console.log('Mass Update clicked');
    this.massUpdate.emit();
  }
}
