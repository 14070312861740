import {
  Component,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs/internal/Subject';
import { TcFormlyTemplateOptions } from '../../../abstract/tc-formly-template-options';

@Component({
  selector: 'formly-clear-input-suffix-wrapper',
  templateUrl: 'clear-input-suffix-wrapper.component.html',
  styleUrls: ['clear-input-suffix-wrapper.component.scss'],
})
export class ClearInputSuffixWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
  @ViewChild('clearInputSuffix') clearInputSuffix: TemplateRef<any>;

  inputCleared$: Subject<Symbol> = new Subject<Symbol>();
  clearButtonEnabled: boolean = false;
  get shouldDisplay(): boolean {
    return this.clearButtonEnabled && this.formControl.value != undefined;
  }

  ngOnInit() {
    (this.to as TcFormlyTemplateOptions).inputCleared$ =
      this.inputCleared$.asObservable();
  }

  ngAfterViewInit() {
    if (this.clearInputSuffix) {
      setTimeout(() => {
        this.clearButtonEnabled = this.to.clearButtonEnabled;
        this.to.suffix = this.clearInputSuffix;
      });
    }
  }

  onClearClicked(clickEvent: Event): void {
    this.formControl.reset();
    this.inputCleared$.next(Symbol());

    clickEvent.stopPropagation();
  }
}
