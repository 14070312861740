import { TcNavMenuNodes } from './../interfaces/tc-nav-menu-nodes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class TcAppMenuService {
  items: TcNavMenuNodes[] = [];

  initMenu(items: TcNavMenuNodes[]) {
    this.items = items;
  }

  getMenu() {
    return this.items;
  }

  addItem(item: TcNavMenuNodes) {
    this.items.push(item);
  }

  addItems(items: TcNavMenuNodes[]) {
    this.items.push(...items);
  }
}
