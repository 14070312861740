(function (definition) {
  var factory = function (breeze) {
    return definition(breeze);
  };

  if (typeof breeze === 'object') {
    factory(breeze);
  } else if (
    typeof require === 'function' &&
    typeof exports === 'object' &&
    typeof module === 'object'
  ) {
    // CommonJS or Node: hard-coded dependency on "breeze-client"
    factory(require('breeze-client'));
  } else if (typeof define === 'function' && define['amd']) {
    // AMD anonymous module with hard-coded dependency on "breeze-client"
    define(['breeze-client'], factory);
  }
})(function (breeze) {
  'use strict';
  var JsonResultsAdapter = breeze.JsonResultsAdapter;
  var AbstractDataServiceAdapter = breeze.AbstractDataServiceAdapter;

  var ctor = function DataServiceMongoAdapter() {
    this.name = 'mongo';
  };

  var proto = (ctor.prototype = new AbstractDataServiceAdapter());

  proto._prepareSaveBundle = function (saveContext, saveBundle) {
    var changeRequestInterceptor = this._createChangeRequestInterceptor(
      saveContext,
      saveBundle
    );
    var em = saveContext.entityManager;
    var metadataStore = em.metadataStore;
    var helper = em.helper;
    var metadata = {};

    saveBundle.entities = saveBundle.entities.map(function (e, ix) {
      var rawEntity = helper.unwrapInstance(e);
      var entityTypeName = e.entityType.name;
      var etInfo = metadata[entityTypeName];
      if (!etInfo) {
        etInfo = {};
        var entityType = e.entityType;
        etInfo.entityTypeName = entityTypeName;
        etInfo.defaultResourceName = entityType.defaultResourceName;
        etInfo.autoGeneratedKeyType = entityType.autoGeneratedKeyType.name;
        etInfo.dataProperties = entityType.dataProperties.map(function (dp) {
          var p = { name: dp.nameOnServer, dataType: dp.dataType.name };
          if (dp.relatedNavigationProperty != null) {
            p.isFk = true;
          }
          if (dp.concurrencyMode && dp.concurrencyMode === 'Fixed') {
            p.isConcurrencyProp = true;
          }
          return p;
        });

        metadata[entityTypeName] = etInfo;
        if (!metadata.defaultNamespace) {
          metadata.defaultNamespace = e.entityType.namespace;
        }
      }
      var originalValuesOnServer = helper.unwrapOriginalValues(
        e,
        metadataStore
      );

      rawEntity.entityAspect = {
        entityTypeName: entityTypeName,
        entityState: e.entityAspect.entityState.name,
        originalValuesMap: originalValuesOnServer,
      };
      rawEntity = changeRequestInterceptor.getRequest(rawEntity, e, ix);
      return rawEntity;
    });

    saveBundle.metadata = metadata;
    saveBundle.saveOptions = { tag: saveBundle.saveOptions.tag };
    changeRequestInterceptor.done(saveBundle.entities);
    return saveBundle;
  };

  proto._prepareSaveResult = function (saveContext, data) {
    var em = saveContext.entityManager;
    var keys = data.insertedKeys.concat(data.updatedKeys, data.deletedKeys);
    var entities = [];
    keys.forEach(function (key) {
      var entity = em.getEntityByKey(key.entityTypeName, key._id);
      // entities created on the server will not be available via getEntityByKey and hence null;
      if (entity) {
        entities.push(entity);
      }
    });

    if (data.entitiesCreatedOnServer.length > 0) {
      entities = entities.concat(data.entitiesCreatedOnServer);
    }

    return {
      entities: entities,
      keyMappings: data.keyMappings,
      httpResponse: data.httpResponse,
    };
  };

  proto.jsonResultsAdapter = new JsonResultsAdapter({
    name: 'mongo',

    visitNode: function (node, mappingContext /*, nodeContext*/) {
      if (node == null) return {};
      var result = {};
      // this will only be set on saveResults and projections.
      if (node.$type) {
        result.entityType =
          mappingContext.entityManager.metadataStore._getEntityType(
            node.$type,
            true
          );
      }
      return result;
    },
  });

  breeze.config.registerAdapter('dataService', ctor);
}, this);
