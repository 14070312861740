import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FilterTypesEnum } from '@tc/abstract';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { Observable } from 'rxjs/internal/Observable';
import { TcFormSmartFieldConfig } from '../../../abstract/tc-form-smart-field-config';
import { TcFormlyTemplateOptions } from '../../../abstract/tc-formly-template-options';
import { TcSmartAutocompleteOptions } from '../../../interfaces/tc-smart-autocomplete-options';

@Component({
  selector: 'tc-formly-autocomplete-type',
  templateUrl: './tc-formly-autocomplete.component.html',
})
export class TcFormlyAutocompleteComponent
  extends FieldType
  implements OnInit, AfterViewInit
{
  /**
   * Default options
   */
  private readonly defaultMultiselectOptions: TcSmartAutocompleteOptions = {
    autocompleteMinLength: 3,
    maxOptionsNumber: 10,
    filterOptionsType: FilterTypesEnum.StartsWith,
    allowNewItem: false,
    showPromptIfNotSelected: false,
    showPromptMessage: 'warning',
    disabled: false,
  };

  /**
   * Options
   */
  public autocompleteOptions: TcSmartAutocompleteOptions;

  /**
   * Smart filed config for conecting to the store
   */
  public smartConfigs: TcFormSmartFieldConfig;

  /**
   * Used for emiting only certing properties of an item to the form model
   */
  private emitFields: string | string[];

  /**
   * inputClearedObservable object to pass from formly-clear-input-suffix-wrapper to internal formControl of autocomplete
   */
  get inputClearedObservable(): Observable<Symbol> {
    return (this.to as TcFormlyTemplateOptions)?.inputCleared$;
  }

  /**
   * Used as a bridge between the autocomplete component and the formControl
   */
  selectedValue: any;

  /**
   * The name of the input field. For testing purposes
   */
  fieldName: string;

  constructor(private readonly config: ConfigService) {
    super();

    const defaultAutocompleteMinLength = this.config.get(
      ConfigKeys.defaultAutocompleteMinLength
    ) as number;

    if (defaultAutocompleteMinLength)
      this.defaultMultiselectOptions.autocompleteMinLength =
        defaultAutocompleteMinLength;
  }

  ngOnInit() {
    const {
      storeKey,
      dataProvider: { fields: defaultLabelFieldName, distinct },
      labelFieldName,
      emitFields,

      autocompleteMinLength,
      maxOptionsNumber,
      filterOptionsType,
      allowNewItem,
      showPromptIfNotSelected,
      showPromptMessage,
      disabled,
    } = this.to;

    this.smartConfigs = {
      storeKey: storeKey,
      labelFieldName: labelFieldName || defaultLabelFieldName,
    };

    this.autocompleteOptions = {
      autocompleteMinLength:
        autocompleteMinLength ||
        this.defaultMultiselectOptions.autocompleteMinLength,
      maxOptionsNumber:
        maxOptionsNumber || this.defaultMultiselectOptions.maxOptionsNumber,
      filterOptionsType:
        filterOptionsType || this.defaultMultiselectOptions.filterOptionsType,
      allowNewItem: allowNewItem || this.defaultMultiselectOptions.allowNewItem,
      showPromptIfNotSelected,
      showPromptMessage:
        showPromptMessage || this.defaultMultiselectOptions.showPromptMessage,
      disabled,
    };

    this.emitFields = emitFields;

    /**
     * If a distinct field is specified the backend will return an array of objects
     * that have only one property (the distinct filed name specified) thus, the
     * label field name and the field that the autocomplete will emit should be
     * the distinct filed name specified
     */
    if (distinct) {
      this.smartConfigs.labelFieldName = defaultLabelFieldName;
      this.emitFields = defaultLabelFieldName;
    }

    this.selectedValue = this.formControl.value;

    this.fieldName = (this.formControl as any)._fields[0]?.name;
  }

  /**
   * Called whenever a item is selected
   */
  public onChangeSelected(item: any) {
    this.selectedValue = item;

    let mappedItem = {};

    if (this.autocompleteOptions.allowNewItem || !this.emitFields || !item) {
      mappedItem = item;
    } else {
      if (Array.isArray(this.emitFields)) {
        this.emitFields.forEach((field) => {
          mappedItem[field] = item[field];
        });
      } else {
        mappedItem[this.emitFields] = item[this.emitFields];
      }
    }

    // Setting form control value also sets the model value.
    this.formControl.setValue(mappedItem);
    this.updateModel(mappedItem);
  }

  /**
   * Update the form model
   */
  private updateModel(data: any | null) {
    // Clear one time to avoid reference problems
    if (
      this.field.key &&
      (typeof this.field.key === 'string' || typeof this.field.key === 'number')
    ) {
      // Update nested key
      var schema = this.model;
      var keys = (this.field.key as string).split('.');
      var len = keys.length;

      for (var i = 0; i < len - 1; i++) {
        var elem = keys[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }

      schema[keys[len - 1]] = null;
      // Update the model
      schema[keys[len - 1]] = data;
    }
  }

  /**
   * Mark form as touched on blur
   */
  public onBlur() {
    this.formControl.markAsTouched();
  }

  /**
   * Change label floting property
   * @param floatType : string = always | auto
   */
  public onChangeFloat(floatType) {
    this.formField.floatLabel = floatType;
  }
}
