import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { IPageState, getPageInfo } from '@tc/store';

/**
 * This component sole purpose is to get the pageTitle from the store and display in inside a span
 * If the title text needs to be customized use the .page-title css class
 */
@Component({
  selector: 'tc-page-title',
  templateUrl: './tc-page-title.component.html',
  styleUrls: ['./tc-page-title.component.scss'],
})
export class TCPageTitleComponent extends TcSmartComponent implements OnInit {
  /**
   * Observable of the page info from the store
   */
  pageInfo$: Observable<IPageState>;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.pageInfo$ = this.store$.pipe(select(getPageInfo));
  }
}
