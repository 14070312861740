import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MaterialColor,
  TcDumbComponent,
  TcPromptDialogPayload,
} from '@tc/abstract';
import { TcSmartButton } from '../../../tc-buttons/interfaces';
import { MaterialButtonType } from '@tc/buttons';
import { TcComponentLookup } from '../../../tc-core/decorators';

/**
 * A prompt dialog used when you want to make sure the user realises the consequences of his action before he does them.
 */
@Component({
  selector: 'tc-prompt-dialog',
  templateUrl: './tc-prompt-dialog.component.html',
  styleUrls: ['./tc-prompt-dialog.component.scss'],
})
@TcComponentLookup('TcPromptDialogComponent')
export class TcPromptDialogComponent extends TcDumbComponent {
  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  cancelButtonConfig: TcSmartButton = {
    label: 'no',
    color: MaterialColor.Primary,
    name: `prompt.decline-button`,
  };

  confirmButtonConfig: TcSmartButton = {
    label: 'yes',
    color: MaterialColor.Primary,
    type: MaterialButtonType.Raised,
    name: `prompt.confirm-button`,
  };

  displayCancelButton = true;
  displayConfirmButton = true;
  displayCloseButton = true;

  constructor(
    private dialogRef: MatDialogRef<TcPromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TcPromptDialogPayload
  ) {
    super();

    if (data.displayConfirmButton !== undefined)
      this.displayConfirmButton = data.displayConfirmButton;

    if (data.displayCancelButton !== undefined)
      this.displayCancelButton = data.displayCancelButton;

    if (data.disableClose !== undefined && data.disableClose === true) {
      this.dialogRef.disableClose = true;
      this.displayCloseButton = false;
    }

    if (data.cancel) this.cancelButtonConfig.label = data.cancel;

    if (data.confirm) this.confirmButtonConfig.label = data.confirm;
  }

  onClose(result: boolean) {
    this.dialogRef.close(result);
  }
}
