import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import { closeDialogAndNavigateButtonClicked, TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import { TcComponentLookup, TcTranslateService } from '@tc/core';

import { CustomRoutes } from '../../../../typings/custom-routes.enum';
import { loadReceivedDeliveryNoteHeaderInfo } from '../../store/received-delivery-notes.actions';
import {
  getCodeAssociation,
  getCodeBanqueDetails,
  getEnvoyeLeDetails,
  getNumeroBLDetails,
  getResultatDetails,
} from '../../store/received-delivery-notes.selectors';

@TcComponentLookup('ReceivedDeliveryNoteDetailsComponent')
@Component({
  selector: 'app-received-delivery-note-details',
  templateUrl: './received-delivery-note-details.component.html',
  styleUrls: ['./received-delivery-note-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceivedDeliveryNoteDetailsComponent
  extends TcSmartDetailPopupComponent
  implements OnInit
{
  public getBLNoDetails;
  public getReceivedDetails;
  public getCodeBA;
  public getAssociationCode;
  public getResultatDetails;

  public receivedBLId: number;

  formStoreKey = 'received-delivery-notes-grid';

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);
  }

  async ngOnInit() {
    this.receivedBLId = this.data?.id;

    this.store$.dispatch(
      loadReceivedDeliveryNoteHeaderInfo({ receivedDeliveryNote: this.data })
    );

    this.getBLNoDetails = getNumeroBLDetails;
    this.getReceivedDetails = getEnvoyeLeDetails;
    this.getAssociationCode = getCodeAssociation;
    this.getCodeBA = getCodeBanqueDetails;
    this.getResultatDetails = getResultatDetails;

    super.ngOnInit();
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasHeader = true;
    this.dialogConfig.headerConfig = {
      configType: TcConfigTypes.TcDetailTitle,
      title: 'received-delivery-note-details.title',
      hasCloseButton: true,
    };
    this.dialogConfig.hasFooter = true;
    this.dialogConfig.footerConfig = {
      configType: TcConfigTypes.TcDetailHeader,
      buttonsList: this.getFooterButtons(),
    };
  }

  getFooterButtons(): TcSmartButton[] {
    const headerButtons = [];

    headerButtons.push({
      label: 'close',
      color: MaterialColor.Primary,
      action: closeDialogAndNavigateButtonClicked,
      actionPayload: {
        storeKey: this.formStoreKey,
        route: `/${CustomRoutes.ListBlRecu}`,
        queryParams: {},
      },
    });

    return headerButtons;
  }
}
