import { createAction, props } from '@ngrx/store';

import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { BonLivraison } from '../interfaces/bon-livraison.interface';

export const deactivateSelectedBLs = createAction(
  '[Delivery Notes] Deativate Selected BLs'
);

export const activateSelectedBLs = createAction(
  '[Delivery Notes] Activate Selected BLs'
);

export const loadDeliveryNoteHeaderInfo = createAction(
  '[Delivery Notes] Load BL Header Information',
  props<{ deliveryNote: BonLivraison }>()
);

export const loadBLMainDetailsSuccess = createAction(
  '[Delivery Notes] Load Delivery Notes Main Data Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadBLDateDetailsSuccess = createAction(
  '[Delivery Notes] Load Delivery Notes Traite and Date Info Success',
  props<{ payload: AmsStatsInterface[] }>()
);
