/**
 * Get the nestedvalue
 * @param object Object to work on,  e.g. car = {driver: {name: 'John', age: 35}}
 * @param nestedProperty Selector with dot notation, e.g. driver.name
 * @returns e.g. 'John'
 */
export function getNestedValue(object: any, nestedProperty: string) {
  var arrayOfProperties = nestedProperty.split('.');

  while (
    arrayOfProperties.length &&
    (object = object[arrayOfProperties.shift()])
  );

  return object;
}

/**
 * Sets a nested value of an object
 * @param object Object to work on,  e.g. car = {driver: {firstName: 'John', age: 35}}
 * @param nestedProperty Selector with dot notation, e.g. driver.lastName
 * @param value 'Smith'
 */
export function setNestedValue(
  object: any,
  nestedProperty: string,
  value: any
): void {
  var schema = object;
  var keys = nestedProperty.split('.');
  var len = keys.length;

  for (var i = 0; i < len - 1; i++) {
    var elem = keys[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[keys[len - 1]] = value;
}
