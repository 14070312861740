export const TcComponentLookupRegistry: Map<string, any> = new Map();

export const TcComponentLookup = (key: string): any => {
  return (componentClass) => {
    if (TcComponentLookupRegistry.has(key)) {
      console.log(`TcComponentLookup: ${key} already used`);
    } else {
      TcComponentLookupRegistry.set(key, componentClass);
    }
  };
};
