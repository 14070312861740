import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfflineModeState } from '../reducers/offline-mode.reducers';

export const getOfflineModeState =
  createFeatureSelector<OfflineModeState>('offlineModeInfo');

export const getOfflineModeActivated = createSelector(
  getOfflineModeState,
  (state: OfflineModeState) => state.offlineModeActivated
);
