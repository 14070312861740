import { createReducer, on } from '@ngrx/store';
import {
  AssociationsState,
  initialAssociationState,
} from './associations.state';
import {
  loadAssociationsCategoriesStatsSuccess,
  loadAssociationsSoftwareStatsSuccess,
  resetCancelEditing,
  setCancelEditing,
} from './associations.actions';

export const associationsReducer = createReducer<AssociationsState>(
  initialAssociationState,
  on(loadAssociationsCategoriesStatsSuccess, (state, { payload }) => ({
    ...state,
    associationsCategoriesStats: [...payload],
  })),
  on(loadAssociationsSoftwareStatsSuccess, (state, { payload }) => ({
    ...state,
    associationsSoftwareStats: [...payload],
  })),
  on(setCancelEditing, (state, { payload }) => ({
    ...state,
    cancelEditing: payload,
  })),
  on(resetCancelEditing, (state) => ({
    ...state,
    cancelEditing: false,
  }))
);
