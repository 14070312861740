import { TcFilterDef } from '@tc/abstract';
import { ITcDataResult, ITcDataService } from '@tc/abstract';

/**
 * A basic, generic entity data service
 */
export class TcDefaultDataService<T> implements ITcDataService<T> {
  protected _name: string;
  protected storeKey: string;

  get name() {
    return this._name;
  }

  constructor(storeKey: string) {
    this._name = `${storeKey} TcGridDefaultDataService`;
    this.storeKey = storeKey;
  }

  getData(
    skip: number,
    take: number,
    filter?: TcFilterDef
  ): Promise<ITcDataResult<T>> {
    return new Promise<ITcDataResult<T>>((resolve, reject) => {
      resolve({ data: [], total: 0 });
    });
  }

  getDataInlineCount(filter?: TcFilterDef): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      resolve(0);
    });
  }

  async upsert(item: T): Promise<T> {
    return item;
  }

  async delete(item: T): Promise<T> {
    return;
  }
}
