import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ListOrder, MaterialColor, TcConfigTypes, TcDataProviderType, TcSmartFilterConfig } from '@tc/abstract';
import { editTcGridButtonClicked, TcSmartGridComponent } from '@tc/advanced-components';
import { MaterialButtonType } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcGridCellComponent,
  TcTranslateService,
} from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { take } from 'rxjs/operators';
import { DEFAULT_GRID_HEADER_HEIGHT, DEFAULT_GRID_ROW_HEIGHT } from '../../../../shared/utils/constants';

import { dateCellRenderer } from '../../../../shared/utils/format.utils';
import { getPreviousDay } from '../../../../shared/utils/getPreviousDate';
import moment from 'moment';

@Component({
  selector: 'app-received-delivery-notes-grid',
  templateUrl: './received-delivery-notes-grid.component.html',
  styleUrls: ['./received-delivery-notes-grid.component.scss'],
})
export class ReceivedDeliveryNotesGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  @ViewChild('calendarTrigger', { static: true })
  calendarTrigger: TemplateRef<any>;

  storeKey = 'received-delivery-notes-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translateService: TcTranslateService
  ) {
    super(store$);

    this.setFilterConfig();
  }

  ngOnDestroy(): void {}

  async ngOnInit() {
    // TODO: might be needed on full implementation of permissions and login
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'received-delivery-notes-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: DEFAULT_GRID_ROW_HEIGHT
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().bonsLivraisonRecu,
        fields: '',
      },
      columns: [
        {
          field: 'codeAssociation',
        },
        {
          field: 'codeBA',
        },
        {
          field: 'numBeBl',
          minWidth: 90,
          maxWidth: 90,
          suppressAutoSize: true,
        },
        {
          field: 'reception',
          cellRenderer: (params) => dateCellRenderer(params?.value, true),
          minWidth: 160,
          maxWidth: 160,
          suppressAutoSize: true,
        },
        {
          field: 'resultat',
          minWidth: 150,
          maxWidth: 150,
          suppressAutoSize: true,
        },
        {
          field: 'actions',
          minWidth: 90,
          maxWidth: 90,
          suppressAutoSize: true,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Accent,
                faIcon: 'fa-eye',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'ReceivedDeliveryNoteDetailsComponent',
                },
              },
            ],
          },
        },
      ],
      filterConfig: this.filterConfig,
    };

    super.ngOnInit();
  }

  setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'baCode',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  filterInputMinLength: 2,
                },
                lgColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'asCode',
                type: TcFormlyComponent.FormlyInput,
                lgColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'nrBl',
                type: TcFormlyComponent.FormlyInput,
                colSpan: 1,
              }),
              formlyControl({
                key: 'dateReception',
                type: TcFormlyComponent.TcDateRangePicker,
                defaultValue: {
                  start: moment(getPreviousDay(new Date())),
                  end: moment(new Date())
                },
                templateOptions: {
                  format: 'DD/MM/YYYY HH:mm',
                  showTimePicker: true
                },
                colSpan: 4,
              }),
            ],
          }),
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true,
        },
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }
}
