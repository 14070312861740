import { TcAction } from './tc-action';
import { IVersionState } from '../reducers/version.reducers';

export enum VersionActionTypes {
  SET_VERSION_INFO = '[Version] Set Version Info',
}

export class SetVersionInfo implements TcAction {
  readonly type = VersionActionTypes.SET_VERSION_INFO;
  constructor(public payload: IVersionState) {}
}

export type VersionActions = SetVersionInfo;
