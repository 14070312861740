import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ReceivedDeliveryNotesState } from './received-delivery-notes.state';

const getReceivedDeliveryNotesState =
  createFeatureSelector<ReceivedDeliveryNotesState>('received-delivery-notes');

export const getNumeroBLDetails = createSelector(
  getReceivedDeliveryNotesState,
  (state: ReceivedDeliveryNotesState) => state?.numeroBLDetails
);

export const getEnvoyeLeDetails = createSelector(
  getReceivedDeliveryNotesState,
  (state: ReceivedDeliveryNotesState) => state?.envoyeLeDetails
);

export const getCodeBanqueDetails = createSelector(
  getReceivedDeliveryNotesState,
  (state: ReceivedDeliveryNotesState) => state?.banqueBLDetails
);

export const getCodeAssociation = createSelector(
  getReceivedDeliveryNotesState,
  (state: ReceivedDeliveryNotesState) => state?.associationBLDetails
);

export const getResultatDetails = createSelector(
  getReceivedDeliveryNotesState,
  (state: ReceivedDeliveryNotesState) => state?.resultatBLDetails
);
