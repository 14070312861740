import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'tc-formly-file-uploader',
  templateUrl: './tc-formly-file-uploader.component.html',
  styleUrls: ['./tc-formly-file-uploader.component.scss'],
})
export class TcFormlyFileUploaderComponent extends FieldType {
  uploading: boolean = false;

  public onFilesChanged(files: any[]) {
    this.formControl.setValue(files);
    this.updateModel(files);
    this.formControl.markAsTouched();
  }

  public onUploading(uploading) {
    this.uploading = uploading;
    if (uploading) {
      this.formControl.setErrors({ uploading: true });
    } else {
      this.formControl.setErrors({ uploading: null });
      this.formControl.updateValueAndValidity();
    }
  }

  /**
   * Update the model with items. Formly is not very compatible with arrays of objects.
   * On normal submit without touching the model, he's not sending all the data.
   * @param data Array of items
   */
  private updateModel(files: any[] | null) {
    // Clear one time to avoid reference problems
    if (
      this.field.key &&
      (typeof this.field.key === 'string' || typeof this.field.key === 'number')
    ) {
      // Update nested key
      var schema = this.model;
      var keys = (this.field.key as string).split('.');
      var len = keys.length;

      for (var i = 0; i < len - 1; i++) {
        var elem = keys[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }

      schema[keys[len - 1]] = null;
      // Update the model
      schema[keys[len - 1]] = files;
    }
  }
}
