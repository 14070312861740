import { createAction, props } from '@ngrx/store';
import { TcDataProviderType } from '../../tc-abstract/component-configs';

/**
 * this action is triggered to check if offline mode is activated
 */
export const hasOfflineMode = createAction(
  '[Offline Mode] Check if offline mode is activated',
  props<{ providerType: TcDataProviderType; relaunch: boolean }>()
);

/**
 * this action is triggered to activate offline mode
 */
export const activateOfflineMode = createAction(
  '[Offline Mode] Activate offline mode',
  props<{ providerType: TcDataProviderType; popupComponentName?: string }>()
);
export const activateOfflineModeSuccess = createAction(
  '[Offline Mode] Activate offline mode success'
);

/**
 * this action is triggered to terminate offline mode
 */
export const terminateOfflineMode = createAction(
  '[Offline Mode] Terminate offline mode',
  props<{
    providerType: TcDataProviderType;
    relaunch: boolean;
    popupComponentName?: string;
  }>()
);
export const terminateOfflineModeSuccess = createAction(
  '[Offline Mode] Terminate offline mode success'
);
