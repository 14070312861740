import { Component, ViewEncapsulation } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { TcGridValidator } from '../../services/tc-grid-validator';
import { TcValidationsService } from '../../services/tc-validations.service';

@Component({
  selector: 'tc-grid-multi-select-editor',
  templateUrl: './tc-grid-multi-select-editor.component.html',
  styleUrls: ['./tc-grid-multi-select-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridMultiSelectEditorComponent
  extends TcGridValidator
  implements AgEditorComponent
{
  public values: { label?: string; value: any }[];
  public params;

  constructor(public readonly tcValidationsService: TcValidationsService) {
    super(tcValidationsService);
  }

  public agInit(params) {
    this.data = params?.data ?? null;
    this.params = params;
    this.validators = params?.validators ?? [];

    this.values = params?.value?.map((item) => ({
      value: item?.value ?? item,
      label: item?.label ?? item,
    }));
  }

  public onChange(updated) {
    this.values = updated;
    super.validate(updated);
  }

  public getValue() {
    return this.values?.length ? this.values.map((v) => v.value) : null;
  }

  // all editions will be in popup
  // https://www.ag-grid.com/angular-grid/cell-editing/#popup
  public isPopup() {
    return true;
  }
}
