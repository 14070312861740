import { createReducer, on } from '@ngrx/store';
import { setMenuItems } from './menu.actions';
import { initialMenuState, MenuState } from './menu.state';

export const menuReducer = createReducer<MenuState>(
  initialMenuState,
  on(setMenuItems, (state, { items }) => ({
    ...state,
    items,
  }))
);
