import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterTypesEnum, TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { TcTranslateService } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { take } from 'rxjs/operators';
import { DEFAULT_GRID_HEADER_HEIGHT, DEFAULT_GRID_ROW_HEIGHT } from '../../../../../shared/utils/constants';

import { dateCellRenderer } from '../../../../../shared/utils/format.utils';

@Component({
  selector: 'app-delivery-note-articles-grid',
  templateUrl: './delivery-note-articles-grid.component.html',
  styleUrls: ['./delivery-note-articles-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryNoteArticlesGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  @Input() deliveryNoteId: number;

  storeKey = 'delivery-note-articles-grid';

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'delivery-note-articles-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: DEFAULT_GRID_ROW_HEIGHT
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().articles,
        fields: '',
        filter: {
          filters: [
            {
              key: 'blId',
              value: this.deliveryNoteId.toString(),
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
      },
      columns: [
        {
          field: 'codeArticle',
          cellClass: 'text-align-right',
          minWidth: 150,
          maxWidth: 150,
          suppressAutoSize: true,
        },
        {
          field: 'libelle',
        },
        {
          field: 'nbUnites',
          minWidth: 100,
          maxWidth: 100,
          suppressAutoSize: true,
        },
        {
          field: 'qteUnite',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
        },
        {
          field: 'quantite',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
        },
        {
          field: 'dateLimite',
          width: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          cellRenderer: (params) => dateCellRenderer(params?.value),
        },
        {
          field: 'numeroLot',
          width: 150,
          maxWidth: 150,
        },
        {
          field: 'libelleComplementaire',
        },
      ],
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {}
}
