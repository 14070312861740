import { LoginMfaInterface } from '../interfaces/login-mfa.interface';
import { UserModel } from '../models/user.model';

export interface AuthState {
  isAuthenticated: boolean;
  user: UserModel;
  errorMessage: string | null;
  token: string;
  mfaOptions: LoginMfaInterface;
  loginAs: string | null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  token: null,
  mfaOptions: null,
  loginAs: null
};
