import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TcNavMenuNodes } from '../../../interfaces/tc-nav-menu-nodes';
import { TcNavMenuListStyle } from '../../../interfaces/tc-nav-menu-list-style';
import { TcNavMenuCONSTANT } from '../../../constants/tc-nav-menu-constant';
import { Router } from '@angular/router';
import { TcNavMenuService } from '../../../services/tc-nav-menu.service';
import { TcNavMenuConfiguration } from '../../../interfaces/tc-nav-menu-configuration';

@Component({
  selector: 'tc-list-nav-item',
  templateUrl: './tc-list-nav-item.component.html',
  styleUrls: ['./tc-list-nav-item.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 0.2 }),
        group([
          animate(200, style({ height: 0 })),
          animate('200ms ease-out', style({ opacity: 0 })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        group([
          animate(200, style({ height: '*' })),
          animate('400ms ease-out', style({ opacity: 1 })),
        ]),
      ]),
    ]),
    trigger('isExpandedLTR', [
      state('no', style({ transform: 'rotate(-90deg)' })),
      state('yes', style({ transform: 'rotate(0deg)' })),

      transition('no => yes', animate(200)),
      transition('yes => no', animate(200)),
    ]),
    trigger('isExpandedRTL', [
      state('no', style({ transform: 'rotate(90deg)' })),
      state('yes', style({ transform: 'rotate(0deg)' })),

      transition('no => yes', animate(200)),
      transition('yes => no', animate(200)),
    ]),
  ],
})
export class TcListNavItemComponent implements OnInit, OnChanges {
  @Input() node: TcNavMenuNodes;
  @Input() level = 1;
  @Input() submenuLevel = 0;
  @Input() selectedNode: TcNavMenuNodes;
  @Input() nodeConfiguration: TcNavMenuConfiguration = null;
  @Output() selectedItem = new EventEmitter<TcNavMenuNodes>();
  isSelected = false;
  nodeChildren: TcNavMenuNodes[];
  classes: { [index: string]: boolean };
  selectedListClasses: { [index: string]: boolean };
  expanded = false;
  firstInitializer = false;
  constructor(
    private router: Router,
    public multilevelMenuService: TcNavMenuService
  ) {
    this.selectedListClasses = {
      [TcNavMenuCONSTANT.DEFAULT_LIST_CLASS_NAME]: true,
      [TcNavMenuCONSTANT.SELECTED_LIST_CLASS_NAME]: false,
      [TcNavMenuCONSTANT.ACTIVE_ITEM_CLASS_NAME]: false,
    };
  }
  ngOnChanges() {
    this.nodeChildren =
      this.node && this.node.items
        ? this.node.items.filter((n) => !n.hidden)
        : [];
    if (this.selectedNode !== undefined && this.selectedNode !== null) {
      this.setSelectedClass(
        this.multilevelMenuService.recursiveCheckId(
          this.node,
          this.selectedNode.id
        )
      );
    }
  }
  ngOnInit() {
    this.selectedListClasses[TcNavMenuCONSTANT.DISABLED_ITEM_CLASS_NAME] =
      this.node.disabled;
    this.selectedListClasses[TcNavMenuCONSTANT.SELECTED_LIST_CLASS_NAME] =
      this.node.selected;

    if (
      this.node.faIcon !== null &&
      this.node.faIcon !== undefined &&
      this.node.faIcon.match(/\bfa\w(?!-)/) === null
    ) {
      this.node.faIcon = `fas ${this.node.faIcon}`;
    }

    this.selectedListClasses[
      `level-${this.level}-submenulevel-${this.submenuLevel}`
    ] = true;

    if (typeof this.node.expanded === 'boolean') {
      this.expanded = this.node.expanded;
    }

    this.setClasses();
  }
  setSelectedClass(isFound: boolean): void {
    if (isFound) {
      if (!this.firstInitializer) {
        this.expanded = true;
      }
      this.isSelected =
        this.nodeConfiguration.highlightOnSelect ||
        this.selectedNode.items === undefined
          ? true
          : false;
    } else {
      this.isSelected = false;
      if (this.nodeConfiguration.collapseOnSelect) {
        this.expanded = false;
      }
    }
    this.selectedListClasses = {
      [TcNavMenuCONSTANT.DEFAULT_LIST_CLASS_NAME]: true,
      [TcNavMenuCONSTANT.SELECTED_LIST_CLASS_NAME]: this.isSelected,
      [TcNavMenuCONSTANT.ACTIVE_ITEM_CLASS_NAME]:
        this.selectedNode.id === this.node.id,
      [TcNavMenuCONSTANT.DISABLED_ITEM_CLASS_NAME]: this.node.disabled,
      [`level-${this.level}-submenulevel-${this.submenuLevel}`]: true,
    };
    this.setClasses();
  }
  getPaddingAtStart(): boolean {
    return this.nodeConfiguration.paddingAtStart ? true : false;
  }
  getListStyle(): TcNavMenuListStyle {
    const styles = {
      background: TcNavMenuCONSTANT.DEFAULT_LIST_BACKGROUND_COLOR,
      color: TcNavMenuCONSTANT.DEFAULT_LIST_FONT_COLOR,
    };
    if (this.nodeConfiguration.listBackgroundColor !== null) {
      styles.background = this.nodeConfiguration.listBackgroundColor;
    }
    if (this.isSelected) {
      this.nodeConfiguration.selectedListFontColor !== null
        ? (styles.color = this.nodeConfiguration.selectedListFontColor)
        : (styles.color = TcNavMenuCONSTANT.DEFAULT_SELECTED_FONT_COLOR);
    } else if (this.nodeConfiguration.fontColor !== null) {
      styles.color = this.nodeConfiguration.fontColor;
    }
    return styles;
  }
  getListIcon(node: TcNavMenuNodes): string {
    if (node.icon !== null && node.icon !== undefined && node.icon !== '') {
      return `icon`;
    } else if (
      node.faIcon !== null &&
      node.faIcon !== undefined &&
      node.faIcon !== ''
    ) {
      return `faicon`;
    } else if (
      node.imageIcon !== null &&
      node.imageIcon !== undefined &&
      node.imageIcon !== ''
    ) {
      return `imageicon`;
    } else if (
      node.svgIcon !== null &&
      node.svgIcon !== undefined &&
      node.svgIcon !== ''
    ) {
      return `svgicon`;
    } else {
      return ``;
    }
  }
  getRouterLinkForItem(item: TcNavMenuNodes): string {
    if (item.route) {
      return `/${item.route}`
    }
    return null;
  }
  getSelectedSvgIcon() {
    if (this.isSelected && this.node.activeSvgIcon) {
      return this.node.activeSvgIcon;
    }
    return this.node.svgIcon;
  }
  getSelectedIcon() {
    if (this.isSelected && this.node.activeIcon) {
      return this.node.activeIcon;
    }
    return this.node.icon;
  }
  getSelectedFaIcon() {
    if (this.isSelected && this.node.activeFaIcon) {
      return this.node.activeFaIcon;
    }
    return this.node.faIcon;
  }
  getSelectedImageIcon() {
    if (this.isSelected && this.node.activeImageIcon) {
      return this.node.activeImageIcon;
    }
    return this.node.imageIcon;
  }
  hasItems(): boolean {
    return this.nodeChildren.length > 0 ? true : false;
  }
  isRtlLayout(): boolean {
    return this.nodeConfiguration.rtlLayout;
  }
  setClasses(): void {
    this.classes = {
      [`level-${this.level + 1}`]: true,
      'tc-nav-menu-submenu': this.hasItems() && this.getPaddingAtStart(),
    };
  }
  expand(node: TcNavMenuNodes): void {
    if (node.disabled) {
      return;
    }
    this.expanded = !this.expanded;
    this.firstInitializer = true;
    this.setClasses();
    if (
      this.nodeConfiguration.interfaceWithRoute !== null &&
      this.nodeConfiguration.interfaceWithRoute &&
      node.link !== undefined &&
      node.link
    ) {
      if (node.externalRedirect !== undefined && node.externalRedirect) {
        window.location.href = node.link;
      } else {
        this.router.navigate([node.link], node.navigationExtras);
      }
    } else if (node.onSelected && typeof node.onSelected === 'function') {
      node.onSelected(node);
      this.selectedListItem(node);
    } else if (
      node.items === undefined ||
      this.nodeConfiguration.collapseOnSelect
    ) {
      this.selectedListItem(node);
    }
  }
  selectedListItem(node: TcNavMenuNodes): void {
    this.selectedItem.emit(node);
  }
}
