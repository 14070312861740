import { Injectable } from '@angular/core';
import { TcDataProviderConfig } from '@tc/abstract';
import { ITcDataService } from '@tc/abstract';
import { TcBreezeDataService } from '@tc/breeze';
import { TcDataServiceFactory } from './tc-data-service.factory';
import * as R from 'ramda';

/**
 * Registry data services that make CRUD calls
 *
 */
@Injectable()
export class TcDataService {
  protected services: { [name: string]: ITcDataService<any> } = {};

  constructor(protected tcdataServiceFactory: TcDataServiceFactory) {}

  /**
   * Get (or create) a data service for store key
   * @param storeKey - the store key
   */
  getService<T>(
    storeKey: string,
    dataProvider: TcDataProviderConfig
  ): ITcDataService<T> {
    storeKey = storeKey.trim();
    let service = this.services[storeKey];
    if (!service) {
      service = this.tcdataServiceFactory.create(storeKey, dataProvider);
      this.services[storeKey] = service;
    } else if (
      !R.equals((service as TcBreezeDataService<T>).dataProvider, dataProvider)
    ) {
      (service as TcBreezeDataService<T>).dataProvider = dataProvider;
    }
    return service;
  }

  /**
   * Register a TcDataService for a store key
   * @param storeKey - the store key
   * @param service - data service for that entity type
   *
   * Examples:
   *   registerService('test1', myTest1DataService);
   *   registerService('test2', myTest2DataService);
   */
  registerService<T>(storeKey: string, service: ITcDataService<T>) {
    this.services[storeKey.trim()] = service;
  }

  /**
   * Register a batch of data services.
   * @param services - data services to merge into existing services
   *
   * Examples:
   *   registerServices({
   *     test1: myTest1DataService,
   *     test2: myTest2DataService
   *   });
   */
  registerServices(services: { [name: string]: ITcDataService<any> }) {
    this.services = { ...this.services, ...services };
  }
}
