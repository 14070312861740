import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MenuEffects } from './store/menu.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([MenuEffects])],
})
export class MenuModule {}
