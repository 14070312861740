import { TcAction } from './tc-action';

export enum SpinnerActionTypes {
  ShowSpinner = '[UI] Show loading spinner',
  HideSpinner = '[UI] Hide loading spinner',
  ResetSpinner = '[UI] Reset loading spinner',
}

export class ShowSpinner implements TcAction {
  readonly type = SpinnerActionTypes.ShowSpinner;
  constructor() {}
}

export class HideSpinner implements TcAction {
  readonly type = SpinnerActionTypes.HideSpinner;
  constructor() {}
}

export class ResetSpinner implements TcAction {
  readonly type = SpinnerActionTypes.HideSpinner;
  constructor() {}
}

export type SpinnerActions = ShowSpinner | HideSpinner | ResetSpinner;
