/**
 * Types of html inputs
 * Visit https://www.w3schools.com/html/html_form_input_types.asp when a new type needs to be supported
 */
export enum InputType {
  text = 'text',
  number = 'number',
  checkbox = 'checkbox',
  multipleSelect = 'multipleSelect',
}
