import { TcFilterDef, TcSortDef } from '@tc/abstract';
import { TcDataProviderConfig } from '@tc/abstract';

/**
 * Tc data state key in store
 */
export const DEFAULT_TC_DATA_STATE_KEY = 'tc-data';

/**
 * Tc data state in store (all the components that need data services will be added in store under tc-data state key)
 */
export interface NgRxTcDataState {
  [key: string]: TcDataState;
}

/**
 * tc data state
 */
export interface TcDataState<T extends object = object> {
  storeKey: string;
  dataProvider: TcDataProviderConfig;
  writeDataProvider?: TcDataProviderConfig;
  data: T[];
  total: number;
  skip?: number;
  take?: number;
  filter?: TcFilterDef;
  sort?: TcSortDef;
  isLoading: boolean;
  createdOn?: string;
}
