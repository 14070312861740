import { TcSmartButton } from '../../tc-buttons/interfaces';

export interface TcPageState {
  pageInfo: IPageState;
}

export interface IPageState {
  title: string;
  buttonsList?: TcSmartButton[];
  displayPageTitle?: boolean;
}

export const getPageInfo = (state: TcPageState) => state.pageInfo;
