import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { TcSmartComponent } from '@tc/abstract';
import { Observable } from 'rxjs';
import { TermsOfUse } from '../../../interfaces/terms-of-use.interface';
import { acceptTerms } from '../../../store/terms-conditions.actions';
import { getTermsToValidate } from '../../../store/terms-conditions.selectors';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent
  extends TcSmartComponent
  implements OnInit
{
  public termsToValidate$: Observable<TermsOfUse>;

  constructor(store$: Store<TcAppState>) {
    super(store$);
  }

  ngOnInit() {
    this.getTerms();
  }

  public onAccept(id: number) {
    this.store$.dispatch(acceptTerms({ id }));
  }

  private async getTerms() {
    this.termsToValidate$ = this.store$.select(getTermsToValidate);
  }
}
