export enum TcGridColumnType {
  Numeric = 'numeric',
  Date = 'date',
  Centered = 'centered',
}

export enum TcGridCellComponent {
  HeaderWithSelection = 'header-with-selection',
  HtmlRenderer = 'html-renderer',
  ButtonsRenderer = 'buttons-renderer',
  FaButtonsRenderer = 'fa-buttons-renderer',
  EventButtonRenderer = 'event-button-renderer',
  SmartButtonRenderer = 'smart-button-renderer',
  CheckboxEditor = 'checkbox-editor',
  MatInputEditor = 'mat-input-editor',
  DatePickerEditor = 'datepicker-editor',
  AutoCompleteEditor = 'autocomplete-editor',
  MultiSelectEditor = 'multi-select-editor',
  NoRowsOverlay = 'no-rows-overlay',
  TemplateRenderer = 'template-renderer',
  MassUpdateHeader = 'mass-update-header',
  CheckboxRenderer = 'checkbox-renderer',
  ConcatArrayRenderer = 'concat-array-renderer',
  LinkRenderer = 'link-renderer',
  EmailRenderer = 'email-renderer',
  PhoneRenderer = 'phone-renderer',
}

export enum TcGridDataProviderType {
  BreezeJs = 'breezeJSDataSet',
}
