import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcButtonsModule } from '@tc/buttons';
import { SharedModule } from '../../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { ProductReferentialDetailComponent } from './product-referential-detail/product-referential-detail.component';
import { ProductReferentialService } from './services/product-referential.service';
import { ProductReferentialEffects } from './store/product-referential-detail.effects';

const components = [ProductReferentialDetailComponent];
const modules = [SharedModule];
const services = [ProductReferentialService];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    CommonModule,
    TcButtonsModule,
    TcCoreModule,
    EffectsModule.forFeature([ProductReferentialEffects]),
  ],
  exports: [...components],
  providers: [...services],
})
export class ProductReferentialModule {}
