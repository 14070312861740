import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TcTranslateService extends TranslateService {
  langChanged = new BehaviorSubject<string>('');

  setInitialLang(lang: string) {
    this.langChanged.next(lang);
  }
}
