import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { tcPermissionsReducer } from './store/tc-permissions.reducer';
import { DEFAULT_TC_PERMISSIONS_STATE_KEY } from './store/tc-permissions.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      DEFAULT_TC_PERMISSIONS_STATE_KEY,
      tcPermissionsReducer
    ),
  ],
})
export class TcPermissionsModule {}
