import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ProductReferentialService } from '../services/product-referential.service';
import { downloadProductReferential } from '../store/product-referential-detail.actions';

@Injectable()
export class ProductReferentialEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly service: ProductReferentialService
  ) {}

  downloadProductReferential$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadProductReferential),
        tap(async () => {
          const productReference =
            await this.service.downloadProductReferential();
        })
      ),
    { dispatch: false }
  );
}
