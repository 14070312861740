import { TcReportingQuery } from './../../interfaces/tc-reporting-query';
import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { TcFormComponent } from '../tc-form/tc-form.component';
import { TcTranslateService } from '../../services/tc-translate.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'tc-reporting-form',
  templateUrl: './tc-reporting-form.component.html',
  styleUrls: ['./tc-reporting-form.component.scss'],
})
export class TcReportingFormComponent
  extends TcFormComponent<any>
  implements OnInit, AfterViewInit
{
  @Input() query: TcReportingQuery;
  @Output() formSubmit = new EventEmitter();
  @ViewChild('reportingForm', { static: false }) public reportingForm: NgForm;

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }

  ngOnInit() {
    // Reset form properties
    this.fields = [];
    this.model = {};
    this.fields = this.query.filters;

    super.ngOnInit();
    super.setLabels(this.query.id, this.fields);
  }

  ngAfterViewInit() {
    // Need the timeout to reset the form after view has changed and avoid ExpressionChangedAfterItHasBeenCheckedError.
    setTimeout(() => {
      this.reportingForm.resetForm();
    });
  }

  /**
   * Take care of the submission of the generated form and send data to output event
   */
  public submit() {
    if (this.form.status === 'VALID') {
      this.formSubmit.emit(this.model);
    }
  }
}
