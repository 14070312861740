import { DEFAULT_TC_DATA_STATE_KEY, NgRxTcDataState, TcDataState } from '../state/tc-data-state';
import * as R from 'ramda';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const tcDataState = createFeatureSelector<NgRxTcDataState>(DEFAULT_TC_DATA_STATE_KEY);

/**
 * Get data property from tc-data store slice identified with the given key
 */
export const getTcDataStoreSliceData = createSelector(
  tcDataState,
  (state: NgRxTcDataState, slice: string) => {
    return state?.[slice]?.data;
  }
)

/**
 * Get tc data from store by store key
 */
export const getTcDataByStoreKey = (
  state: NgRxTcDataState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

/**
 * Get tc data
 */
export const getTcData = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr([], 'data')(state);
  }
);

export const getTcNewDataCreatedOn = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(null, 'createdOn')(state);
  }
);

/**
 * Get tc data isLoading property
 */
export const getTcDataIsLoading = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(null, 'isLoading')(state);
  }
);

/**
 * Get tc data total
 */
export const getTcDataTotal = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(undefined, 'total')(state);
  }
);

/**
 * Get tc grid data provider
 */
export const getTcDataProvider = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(null, 'dataProvider')(state);
  }
);

/**
 * Get tc grid write data provider
 */
export const getTcWriteDataProvider = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return (
      R.propOr(null, 'writeDataProvider')(state) ??
      R.propOr(null, 'dataProvider')(state)
    );
  }
);

/**
 *  Get tc data filters
 */
export const getTcDataFilters = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(null, 'filter')(state);
  }
);

/**
 *  Get tc data sort
 */
export const getTcDataSort = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(null, 'sort')(state);
  }
);

/**
 *  Get tc data take
 */
export const getTcDataTake = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(200, 'take')(state);
  }
);

/**
 *  Get tc data skip
 */
export const getTcDataSkip = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(0, 'skip')(state);
  }
);

/**
 *  Get complete data state
 */
export const getTcDataState = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return state;
  }
);

/**
 *  Get tc data separated InlineCount call
 */
export const getTcDataSeparatedInlineCountCall = createSelector(
  getTcDataByStoreKey,
  (state: TcDataState) => {
    return R.propOr(false, 'separatedInlineCountCall')(state.dataProvider);
  }
);
