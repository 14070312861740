import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialColor } from '@tc/abstract';
import { MaterialButtonType } from '@tc/buttons';
import { IPageState } from '@tc/store';

import { exportIndicateursCsv } from '../../modules/indicators/store/indicators.actions';
import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-indicators-page',
  templateUrl: './indicators-page.component.html',
  styleUrls: ['./indicators-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IndicatorsPageComponent extends AmsPage implements OnInit {
  constructor(store$: Store<any>) {
    super(store$);
  }

  exportIndicators(): void {
    this.store.dispatch(exportIndicateursCsv());
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'indicators-page.title',
      buttonsList: [
        {
          label: 'export-csv',
          color: MaterialColor.Accent,
          type: MaterialButtonType.Flat,
          faIcon: 'fa-file-csv',
          action: () => this.exportIndicators(),
        },
      ],
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
