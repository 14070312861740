import { SetPageInfo } from '@tc/store';
import { OnInit, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState } from '@tc/store';
@Directive()
export class TcPage implements OnInit {
  pageInfo: IPageState;

  constructor(protected store?: Store<any>) {}

  ngOnInit(): void {
    if (this.store) {
      this.store.dispatch(new SetPageInfo(this.pageInfo));
    }
  }
}
