import { createReducer, on } from '@ngrx/store';
import { loadVersionDetailsSuccess } from './home.actions';
import { HomeState, initialHomeState } from './home.state';

export const homeReducer = createReducer<HomeState>(
  initialHomeState,
  on(loadVersionDetailsSuccess, (state, { payload }) => ({
    ...state,
    version: payload.version,
    date: payload.date,
    associationsToBeValidated: payload.associationsToBeValidated,
  }))
);
