import { nbOfDecimals, nbOfDecimalsUnit } from './../utils/number-util';
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private readonly decimalPipe: DecimalPipe) {}

  formatNumber(
    value: any,
    changeSign: boolean = false,
    isUnit: boolean = false
  ) {
    const nbOfDecimalDisplayed = !isUnit ? nbOfDecimals : nbOfDecimalsUnit;

    const digitsInfo = `1.${nbOfDecimalDisplayed}-${nbOfDecimalDisplayed}`;
    const lang = 'fr';

    if (!value) {
      if (typeof value === 'number') {
        return this.decimalPipe.transform('0', digitsInfo, lang);
      }
      return value;
    }
    if (changeSign) {
      value = -1 * value;
    }
    return this.decimalPipe.transform(value, digitsInfo, lang);
  }
}
