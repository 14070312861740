import { createReducer, on } from '@ngrx/store';
import * as R from 'ramda';
import * as TcPanelListActions from '../actions/tc-panel-list-actions';
import { InitTcPanelListPayload } from '../actions/tc-panel-list-payload';
import {
  NgRxTcPanelListState,
  TcPanelListState,
} from '../state/tc-panel-list-state';

/**
 * initialNgRxTcPanelListState
 */
export const initialNgRxTcPanelListState: NgRxTcPanelListState = {};

/**
 * the initial state of tc panel list
 */
export const initialTcPanelListState: TcPanelListState = {
  storeKey: null,
  // component: null,
  itemSize: 100,
};

/**
 * this handler will init tc panel list in store
 */
const initTcPanelListHandler = (
  state: TcPanelListState,
  newState: InitTcPanelListPayload
): TcPanelListState => {
  const { storeKey, component, itemSize } = newState;

  return R.mergeRight(state, {
    storeKey,
    component,
    itemSize,
  });
};

/**
 * ngrx tc panel list state reducer
 */
const ngRxTcPanelListStateReducer = createReducer(initialNgRxTcPanelListState);

/**
 * reducer
 */
const reducer = createReducer(
  initialTcPanelListState,
  on(TcPanelListActions.initTcPanelList, initTcPanelListHandler)
);

/**
 *  check if action is a TcPanelListAction (action starts with TC_PANEL_LIST_ACTIONS_PREFIX)
 */
const isTcPanelListAction = R.startsWith(
  TcPanelListActions.TC_PANEL_LIST_ACTIONS_PREFIX
);

/**
 * tc panel list reducer
 */
export function tcPanelListReducer(
  state = initialNgRxTcPanelListState,
  action
) {
  if (!isTcPanelListAction(action.type)) {
    return state;
  }

  const { storeKey } = action;

  const nextState = ngRxTcPanelListStateReducer(state, action);
  const tcPanelListState = nextState[storeKey];

  return R.mergeRight(nextState, {
    [storeKey]: reducer(tcPanelListState, action),
  });
}
