import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { Store, select } from '@ngrx/store';
import { FilterTypesEnum } from '@tc/abstract';
import { getNestedValue } from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  emptyTcData,
  getTcData,
  loadTcData,
  NgRxTcDataState,
} from '@tc/data-store';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { TcFormSmartFieldConfig } from '../../../abstract/tc-form-smart-field-config';
import { TcSmartMultiSelectOptions } from '../../../interfaces/tc-smart-multi-select-options';

@Component({
  selector: 'tc-smart-multi-select',
  templateUrl: './tc-smart-multi-select.component.html',
  styleUrls: ['./tc-smart-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TcSmartMultiSelectComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /**
   * Used for opening the autocomplete options panel
   */
  @ViewChild(MatAutocompleteTrigger) private trigger: MatAutocompleteTrigger;
  /**
   * Used for opening reseting the input
   */
  @ViewChild('itemInput') private itemInput: ElementRef<HTMLInputElement>;

  @ViewChild('tcInput') tcInput: MatInput;

  /**
   * Default multiselect options
   */
  private readonly defaultOptions: TcSmartMultiSelectOptions = {
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
    autocompleteMinLength: 3,
    maxOptionsNumber: 10,
    filterOptionsType: FilterTypesEnum.StartsWith,
    disabled: false,
  };

  /**
   * Multiselect options
   */
  @Input() options: TcSmartMultiSelectOptions;

  /**
   * Smart filed config for conecting to the store
   */
  @Input() smartConfigs: TcFormSmartFieldConfig;

  /**
   * Multiselect selected items
   */
  @Input() selected: any[] = [];

  /**
   * Cumstom display of multiselect items labels
   */
  @Input() display;

  /**
   * Input cleared observable created by formly-clear-input-suffix-wrapper
   */
  @Input() inputClearedObservable: Observable<Symbol>;

  /**
   * The name of the input field. For testing purposes.
   */
  @Input() fieldName: string;

  /**
   * Event emitter on blur
   */
  @Output() blur = new EventEmitter<void>();

  /**
   * Event emitter that emits the current selected items
   */
  @Output() changeSelected = new EventEmitter<any[]>();

  /**
   * Event emitter that emits how the label should placed depending on the current input
   */
  @Output() changeFloat = new EventEmitter<string>();

  /**
   * Whether or not the component was inited.
   */
  private componentInited: boolean;

  /**
   * The autocomplete items from the data store
   */
  public autocompleteItems: any[] = [];

  /**
   * Subscription to the data store for autocomplete items
   */
  private autocompleteItemsSubscription: Subscription;

  /**
   * FormControl of the input in which we write
   */
  public itemsControl = new FormControl();

  /**
   * Subscription to the itemsControl valueChanges
   */
  private itemsControlSubscription: Subscription;

  private inputClearedSubscription: Subscription;

  /**
   * Observable of the data store
   */
  private dataStore$: Observable<NgRxTcDataState>;

  constructor(private readonly store$: Store<any>) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnInit() {
    this.completeMissingOptions();

    this.itemsControlSubscription = this.itemsControl.valueChanges
      .pipe(startWith(''))
      .subscribe((term) => {
        this.onValueChange(term);
      });

    this.autocompleteItemsSubscription = selectByKey(
      getTcData,
      this.dataStore$,
      this.smartConfigs.storeKey
    ).subscribe((items) => {
      this.autocompleteItems = items.filter(
        (item) =>
          !this.selected.some((selectedOption) => {
            // If there's dot notation inside the uniqueFieldName, try to access nested objects
            let valueItem;
            let valueSelected;
            if (this.smartConfigs.uniqueFieldName.includes('.')) {
              valueItem = getNestedValue(
                item,
                this.smartConfigs.uniqueFieldName
              );
              valueSelected = getNestedValue(
                selectedOption,
                this.smartConfigs.uniqueFieldName
              );
            } else {
              valueItem = item[this.smartConfigs.uniqueFieldName];
              valueSelected = selectedOption[this.smartConfigs.uniqueFieldName];
            }

            return valueItem === valueSelected;
          })
      );
      if (this.autocompleteItems?.length > 0) {
        this.openAutocompletePanel();
      }
    });

    // If disabled option is set, force removable to hide cancel icon
    if (this.options.disabled) {
      this.options.removable = false;
    }
  }

  ngAfterViewInit() {
    this.componentInited = true;
    this.inputClearedSubscription = this.inputClearedObservable?.subscribe(
      (_: Symbol) => {
        this.selected = [];
        this.emitSelected();
        this.tcInput?.focus();
      }
    );
  }

  /**
   * Called whent the input changes it's value
   */
  private onValueChange(value) {
    // Make sure the label is where it's supposed to be
    if ((value?.length > 0 || value === '') && this.componentInited) {
      this.changeFloat.emit('always');
    } else {
      this.changeFloat.emit('auto');
    }

    // Prevent loading items if the input value doesn't look like we want it to be
    if (
      typeof value !== 'string' ||
      !this.componentInited ||
      value?.length < this.options.autocompleteMinLength
    ) {
      this.autocompleteItems = [];
      return;
    }
    this.loadItems(value);
  }

  /**
   * Dispaches a load data action tot the store for autocomplete items
   * @param value
   */
  private loadItems(value: string) {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.smartConfigs.storeKey,
        skip: 0,
        take: this.options.maxOptionsNumber,
        filter: {
          filters: [
            {
              key: this.smartConfigs.labelFieldName,
              value,
              filterType: this.options.filterOptionsType,
            },
          ],
        },
      })
    );
  }

  /**
   * Called when remove icon of the mat-chip is clicked
   * @param item
   */
  public remove(item: any) {
    const index = this.indexOfWithoutReference(this.selected, item);

    if (index >= 0) {
      let selectedItems = [...this.selected];
      selectedItems.splice(index, 1);
      if (this.selected.length === 0) {
        selectedItems = null;
      }
      this.selected = selectedItems?.length === 0 ? null : selectedItems;
      this.emitSelected();
    }
  }

  /**
   * Add an item to selection and resets the input
   */
  public selectItem(event: MatAutocompleteSelectedEvent) {
    this.addItemToSelection(event.option.value);
    this.autocompleteItems = [];
  }

  /**
   * Completes the missing options with their default value
   */
  private completeMissingOptions() {
    this.options = {
      ...this.defaultOptions,
      ...this.options,
    };

    this.display = this.display || this.defaultDisplayMethod.bind(this);
  }

  public defaultDisplayMethod(item) {
    let label = '';

    if (item) {
      // If there's dot notation inside the labelFieldName, try to access nested objects
      if (this.smartConfigs.labelFieldName.includes('.')) {
        return getNestedValue(item, this.smartConfigs.labelFieldName);
      }

      // If there's a key matching the labelFieldName, use it
      if (item[this.smartConfigs.labelFieldName]) {
        return item && item[this.smartConfigs.labelFieldName];
      }
    }
    return label;
  }

  /**
   * Open the autocomplete panel for displaying results if the plugin can do it
   */
  private openAutocompletePanel() {
    if (this.trigger) {
      setTimeout(() => {
        if (this.componentInited) {
          this.trigger.openPanel();
        }
      }, 100);
    }
  }

  /**
   * Add an item to selection and resets the input
   */
  private addItemToSelection(item: {}) {
    // We initiate form value as an array if it's not done already
    if (!this.selected) {
      this.selected = [];
    }

    this.selected = [...this.selected, item];

    this.emitSelected();
  }

  private emitSelected() {
    this.changeSelected.emit(this.selected);

    this.resetInput();
  }

  /**
   * Reset the autocomplete term in the text input
   */
  private resetInput() {
    this.itemsControl.setValue('');
    this.itemInput.nativeElement.value = '';
  }

  /**
   * Return index of array without relaying on references. It actually compares properties to define if you already have the object or not.
   * @param array The array to search the index
   * @param object The object you're searching
   * @return number Same return type as indexOf method
   */
  private indexOfWithoutReference(array: any[], object: {}): number {
    const objectJson = JSON.stringify(object);

    return array.findIndex((current) => {
      const currentObjectJson = JSON.stringify(current);

      return currentObjectJson === objectJson;
    });
  }

  /**
   * Called when the input gets blured
   */
  public onBlur() {
    this.blur.emit();
    if (this.itemsControl.value === '') {
      this.changeFloat.emit('auto');
    }
  }

  /**
   * Loads autocomplete options based on the input's value on focus
   */
  public onFocus() {
    if (this.itemsControl.value?.length >= this.options.autocompleteMinLength) {
      this.loadItems(this.itemsControl.value);
    }
  }

  /**
   * Empties the autocomplete options after the user no-longer has the input selected
   * NOTE: we need to wait for a brief moment in case the user selects a value from the
   *       autocomplete options, which also count as a focus out in order for the selected
   *       option to actually get selected.
   */
  public onFocusOut() {
    setTimeout(() => {
      if (this.smartConfigs?.storeKey) {
        this.store$.dispatch(
          emptyTcData({ storeKey: this.smartConfigs.storeKey })
        );
      }
      this.trigger?.closePanel();
    }, 100);
  }

  ngOnDestroy() {
    this.componentInited = false;

    this.itemsControlSubscription?.unsubscribe();
    this.autocompleteItemsSubscription?.unsubscribe();
    this.inputClearedSubscription?.unsubscribe();

    if (this.smartConfigs?.storeKey) {
      this.store$.dispatch(
        emptyTcData({ storeKey: this.smartConfigs.storeKey })
      );
    }
  }
}
