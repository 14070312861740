import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState } from '@tc/store';

import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-statistiques-page',
  templateUrl: './statistiques-page.component.html',
  styleUrls: ['./statistiques-page.component.scss'],
})
export class StatistiquesPageComponent extends AmsPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  async ngOnInit() {
    const pageInfo: IPageState = {
      title: 'statistiques-page.title',
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
