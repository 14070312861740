import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TcFilter } from '../../../interfaces/tc-filter';
import { TcFilterValue } from '../../../interfaces/tc-filter-value';
import { TcFilterTypes } from '@tc/abstract';

@Component({
  selector: 'app-tc-filter',
  templateUrl: './tc-filter.component.html',
  styleUrls: ['./tc-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcFilterComponent implements OnInit {
  @Input() filters: TcFilter[] = [];

  @Output() search = new EventEmitter<TcFilterValue>();

  public tcFilterTypes = TcFilterTypes;

  private selectedFilterValues: TcFilterValue = {};
  private oldMultiSelectValues: Record<string, any[]> = {};

  ngOnInit() {
    const defaultFilters = this.filters.filter(
      (filter) => !!filter.options?.default
    );

    this.manageDefaultValues(defaultFilters);

    if (defaultFilters.length) {
      this.search.emit(this.selectedFilterValues);
    }
  }

  public applyFilter(filter: TcFilter, value: any) {
    this.manageFilterValue(filter, value);
    this.search.emit(this.selectedFilterValues);
  }

  public trackByFunc(_, item) {
    return item?.key;
  }

  private manageFilterValue(filter: TcFilter, value: any) {
    if (
      [
        this.tcFilterTypes.anyFieldContains,
        this.tcFilterTypes.anyFieldStartsWith,
      ].includes(filter.type)
    ) {
      this.selectedFilterValues[filter.type] = value;

      return;
    }

    if (filter.type === this.tcFilterTypes.toggle) {
      if (!this.selectedFilterValues[filter.key]) {
        this.selectedFilterValues[filter.key] = {
          filterType: filter.filterType,
          value: [filter.options.onValue],
        };
        return;
      }

      const newValue = value
        ? [
            ...this.selectedFilterValues[filter.key].value,
            filter.options.onValue,
          ]
        : [...this.selectedFilterValues[filter.key].value];
      if (!value) {
        newValue.splice(
          this.selectedFilterValues[filter.key].value.indexOf(
            filter.options.onValue
          ),
          1
        );
      }

      this.selectedFilterValues[filter.key] = {
        filterType: filter.filterType,
        value: newValue,
      };
      return;
    }

    if (filter.type === this.tcFilterTypes.multiselect) {
      const newValues = (value || []).map((v) => v.value);

      if (!this.selectedFilterValues[filter.key]) {
        this.selectedFilterValues[filter.key] = {
          value: newValues,
          filterType: filter.filterType,
        };
        this.oldMultiSelectValues[filter.key] = newValues;
        return;
      }

      const oldValues = this.oldMultiSelectValues[filter.key] || [];
      const allValues = this.selectedFilterValues[filter.key].value;
      oldValues.forEach((v) => {
        const indexOfValue = (allValues as any[]).indexOf(v);
        if (indexOfValue > -1) {
          allValues.splice(indexOfValue, 1);
        }
      });

      this.selectedFilterValues[filter.key] = {
        filterType: filter.filterType,
        value: [...allValues, ...newValues],
      };
      this.oldMultiSelectValues[filter.key] = newValues;
      return;
    }

    this.selectedFilterValues[filter.key] = {
      value,
      filterType: filter.filterType,
    };
  }

  private manageDefaultValues(filters: TcFilter[]) {
    for (const filter of filters) {
      if (
        [
          this.tcFilterTypes.anyFieldContains,
          this.tcFilterTypes.anyFieldStartsWith,
        ].includes(filter.type)
      ) {
        this.selectedFilterValues[filter.key] = filter.options.default;

        continue;
      }

      if (filter.type === this.tcFilterTypes.toggle) {
        if (!!this.selectedFilterValues[filter.key]) {
          this.selectedFilterValues[filter.key] = {
            ...this.selectedFilterValues[filter.key],
            value: [
              ...this.selectedFilterValues[filter.key].value,
              filter.options.onValue,
            ],
          };
          continue;
        }

        this.selectedFilterValues[filter.key] = {
          filterType: filter.filterType,
          value: [filter.options.onValue],
        };
        continue;
      }

      if (filter.type === this.tcFilterTypes.multiselect) {
        const defaultValues = filter.options.default.map((v) => v.value);

        if (!!this.selectedFilterValues[filter.key]) {
          const value = [
            ...this.selectedFilterValues[filter.key].value,
            ...defaultValues,
          ];
          this.selectedFilterValues[filter.key] = {
            ...this.selectedFilterValues[filter.key],
            value,
          };
          this.oldMultiSelectValues[filter.key] = value;
          continue;
        }

        this.selectedFilterValues[filter.key] = {
          ...this.selectedFilterValues[filter.key],
          value: defaultValues,
        };
        this.oldMultiSelectValues[filter.key] = defaultValues;
        continue;
      }

      if (filter.type === this.tcFilterTypes.daterange) {
        this.selectedFilterValues[filter.key] = {
          ...this.selectedFilterValues[filter.key],
          value: {
            ...filter.options.default,
            format: filter.options.format,
          },
        };
        continue;
      }

      this.selectedFilterValues[filter.key] = {
        filterType: filter.filterType,
        value: filter.options.default,
      };
    }
  }
}
