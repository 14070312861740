import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'tc-select',
  templateUrl: './tc-select.component.html',
  styleUrls: ['./tc-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcSelectComponent implements OnInit {
  @Input() label = '';
  @Input() selected = '';
  @Input() items: { value: any; label: string }[];
  @Input() isFormlyComponent = false;

  @Output() changeSelected = new EventEmitter<any>();

  selectedItem: any;

  ngOnInit(): void {
    if (this.items.length === 1 && !this.selected) {
      this.selectedItem = this.items[0].value;
    } else if (this.selected) {
      const itemExists = this.items.filter(
        (item) => item.value === this.selected
      );

      if (itemExists.length > 0) {
        this.selectedItem = this.selected;
      } else {
        if (this.items.length === 1) {
          this.selectedItem = this.items[0].value;
        }
      }
    }
  }

  public change(value) {
    this.changeSelected.emit(value);
  }
}
