import { Trimestre } from '../typings/trimestre.enum';

export class Periode {
  year: number;
  trimestre: Trimestre;

  public toString() {
    return `${this.trimestre.valueOf().toUpperCase()} ${this.year}`;
  }

  public getStartDate() {
    let month = 1;
    switch (this.trimestre) {
      case Trimestre.T1:
        month = 0;
        break;
      case Trimestre.T2:
        month = 3;
        break;
      case Trimestre.T3:
        month = 6;
        break;
      case Trimestre.T4:
        month = 9;
        break;
    }
    return new Date(this.year, month, 1);
  }
}
