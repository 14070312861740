import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';

export class TcAppDateAdapter extends MomentDateAdapter {
  deserialize(value) {
    // Add support to set date as unix timestamp (string or number)
    const intConversion: number = parseInt(value, 10);
    if (intConversion) {
      const length = intConversion.toString().length;
      // If the number has more digits than 12, we assume it's a timestamp in milliseconds.
      // Graphql can send it and we can only assume that if it too far in the future, then, it's milliseconds, since we don't know the format of the timestamp.
      if (length >= 12) {
        // Put the date as milliseconds
        return moment(intConversion);
      }

      // If not, we assume this is is a timestamp in seconds.
      return moment.unix(intConversion);
    }

    return super.deserialize(value);
  }
}
