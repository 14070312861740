import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcTranslateService } from '@tc/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridApi, IAfterGuiAttachedParams } from 'ag-grid-community';
import {
  setCancelEditing,
  updateAssociationTicadiStartDate,
} from '../../../modules/associations/store/associations.actions';
import { Logiciel } from '../../../modules/associations/types';

@Component({
  selector: 'ams-grid-datepicker-renderer',
  templateUrl: './ams-grid-datepicker-renderer.component.html',
  styleUrls: ['./ams-grid-datepicker-renderer.component.scss']
})
export class AmsGridDatepickerRendererComponent implements AgRendererComponent {
  private params: any;
  public showButton: boolean;
  public value: any;
  public associationId: number;
  public editable = false;
  api: GridApi;

  constructor(
    private readonly translate: TcTranslateService,
    private readonly store$: Store<any>
  ) {}

  refresh(params: any): boolean {
    return false;
  }

  agInit(params): void {
    this.value = params?.value;
    const data = params?.data;
    this.api = params?.api;

    this.editable =
      data?.logiciel?.toLowerCase() !== Logiciel.Ticadi.valueOf().toLowerCase();

    this.associationId = params?.data?.id;
    this.showButton =
      data?.logiciel?.toLowerCase() !==
        Logiciel.Ticadi.valueOf().toLowerCase() && this.value !== null;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  public clearDate() {
    this.store$.dispatch(
      updateAssociationTicadiStartDate({
        payload: {
          associationId: this.associationId,
          dateDebutTicadi: null,
        },
      })
    );

    this.store$.dispatch(setCancelEditing({ payload: true }));
  }
}
