import { Component, Input } from '@angular/core';
import { TcDetailHeaderConfig } from '../../../tc-abstract/component-configs/tc-detail-header-config';

/**
 * Displays a header with a  text and a list of buttons on a detail
 */
@Component({
  selector: 'tc-detail-header',
  templateUrl: './tc-detail-header.component.html',
  styleUrls: ['./tc-detail-header.component.scss'],
})
export class TcDetailHeaderComponent {
  /**
   * Ths config of the component
   */
  @Input() config: TcDetailHeaderConfig;

  constructor() {}
}
