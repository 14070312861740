import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';

@Component({
  selector: 'app-associations-filter',
  templateUrl: './associations-filter.component.html',
  styleUrls: ['./associations-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssociationsFilterComponent
  extends TcFormComponent<any>
  implements OnInit
{
  gridStoreKey = 'associations-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }

  ngOnInit() {}
}
