import { Action } from '@ngrx/store';
import { TcAppConfig } from '../config/tc-app.config';

export enum SettingsActionTypes {
  LOAD_CONFIG = '[Settings] Load config',
  CHANGE_LANGUAGE = '[Settings] Change Language',
  CHANGE_THEME = '[Settings] Change Theme',
}

export class ActionSettingsLoadConfig implements Action {
  readonly type = SettingsActionTypes.LOAD_CONFIG;

  constructor(readonly payload: TcAppConfig) {}
}

export class ActionSettingsChangeLanguage implements Action {
  readonly type = SettingsActionTypes.CHANGE_LANGUAGE;

  constructor(readonly payload: { language: string }) {}
}

export class ActionSettingsChangeTheme implements Action {
  readonly type = SettingsActionTypes.CHANGE_THEME;

  constructor(readonly payload: string) {}
}

export type SettingsActions =
  | ActionSettingsLoadConfig
  | ActionSettingsChangeLanguage
  | ActionSettingsChangeTheme;
