import { MaterialColor } from '@tc/abstract';
import { MaterialButtonType } from '../interfaces';

/**
 * 
 Get generic deactivate button config
 * @param action the action that is triggered when clicking in the button
 * @param deactivationField the field to change value for deactivation 
 * @param deactivateValue the value to set element to deactivate (for example: false)
 * @param options to override config or add options like permissionConfig
 * @returns TcSmartButton for deactivate button
 */
export function getDeactivateButtonConfig(
  action: any,
  deactivationField: string,
  deactivationValue: any,
  options: any
) {
  return {
    color: MaterialColor.Warn,
    matIcon: 'delete',
    action: action,
    type: MaterialButtonType.Icon,
    actionPayload: {
      detailsPopupComponent: 'TcPromptDialogComponent',
      deactivationField: deactivationField,
      deactivationValue: deactivationValue,
      height: '12em',
      width: '28em',
    },
    ...options,
  };
}
