import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TcSmartComponent } from '@tc/abstract';
import {
  TcGridEventButtonParams,
  TcGridSmartButtonParams,
} from '../../interfaces/tc-grid.interface';
import { Params } from '@angular/router';
import { getNestedValue, setNestedValue } from '@tc/core';
import { TcGridSmartButtonsRendererComponent } from '../tc-grid-smart-buttons-renderer/tc-grid-smart-buttons-renderer.component';
import * as R from 'ramda';
import { navigate, openFilteredDataDetail } from '@tc/advanced-components';

/**
 *
 * Renders a list of action buttons to the right of the cell value.
 *
 * This renderer is similiar to the @TcGridSmartButtonsRendererComponent renderer
 * but instead of being just a renderer for the current row action button, this
 * renderer was created to best fit the cells that represent a relation to another
 * collection of the current document.
 *
 * Current usecase is to use a button that first navigates and then opens a pop-up
 * of a foreign grid using the "navigate" and "openFilteredDataDetail" actions.
 */
@Component({
  selector: 'tc-grid-link-renderer',
  templateUrl: './tc-grid-link-renderer.component.html',
  styleUrls: ['./tc-grid-link-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TcGridLinkRendererComponent
  extends TcGridSmartButtonsRendererComponent
  implements AgRendererComponent
{
  /**
   * Value of the cell
   */
  public cellValue: string | string[];

  constructor(store$: Store<any>) {
    super(store$);
  }

  agInit(params) {
    super.agInit(params);

    this.cellValue = this.params.value;

    // change cellValue type to Array
    if (!Array.isArray(this.cellValue)) {
      let array = [];
      array.push(this.cellValue);
      this.cellValue = array;
    }
  }

  /**
   * Handles the click event by triggering a store action
   * @param event => { clickEvent (native click event), buttonIndex }
   */
  public onClickHandler(event, indexValue = null) {
    const { clickEvent, index } = event;

    const { action } = this.buttons[index];
    let { actionPayload } = this.buttons[index];

    // Check the action is a navigate action in order to calculate dynamic values of action payloads
    // i.e. actionPayload.queryParams
    if (action === navigate) {
      // Clone the actionPayload so that we can modify the callbackActionPayload
      // in order to set the dynamic query params.
      actionPayload = R.clone(actionPayload);

      let { queryParams: payloadQueryParams } = actionPayload;

      // If the queryParams is a function we first call it to get an Array
      if (typeof payloadQueryParams === 'function') {
        payloadQueryParams = payloadQueryParams(indexValue);
      }

      // If the queryParams is an Array than we are dealing with dynamic query params
      if (Array.isArray(payloadQueryParams)) {
        const queryParams: Params = {};

        // Convert the dynamic query params into actual Params value
        payloadQueryParams.forEach((paramObject) => {
          const value = getNestedValue(
            this.params.node.data,
            paramObject.dataPropertyName
          );

          setNestedValue(queryParams, paramObject.paramName, value);
        });

        actionPayload.queryParams = queryParams;
      }

      // Check the action is a navigate action openFilteredDataDetail in order to dynamic values of action payloads
      // i.e. actionPayload.callbackActionPayload.filterValue
      if (actionPayload.callbackAction === openFilteredDataDetail) {
        const { filterProperty } = actionPayload.callbackActionPayload;

        actionPayload.callbackActionPayload.filterValue =
          actionPayload.queryParams[filterProperty];
      }
    }

    this.store$.dispatch(
      action({
        storeKey: this.storeKey,
        ...actionPayload,
      })
    );
  }
}
