import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AssociationsState } from './associations.state';

const getAssociationsState =
  createFeatureSelector<AssociationsState>('associations');

export const getAssociationsCategoriesData = createSelector(
  getAssociationsState,
  (state: AssociationsState) => state?.associationsCategoriesStats
);

export const getAssociationsSoftwareData = createSelector(
  getAssociationsState,
  (state: AssociationsState) => state?.associationsSoftwareStats
);

export const getCancelEditingFlag = createSelector(
  getAssociationsState,
  (state: AssociationsState) => state?.cancelEditing
);
