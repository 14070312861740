import * as R from 'ramda';
import { NgRxTcPanelListState, TcPanelListState } from '../state';
import { createSelector } from '@ngrx/store';

/**
 * Get tc panel list from store by store key
 */
export const getTcPanelListByStoreKey = (
  state: NgRxTcPanelListState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

/**
 * Get tc panel list data provider
 */
export const getTcPanelListComponent = createSelector(
  getTcPanelListByStoreKey,
  (state: TcPanelListState) => {
    return R.propOr({}, 'component')(state);
  }
);

/**
 *  Get tc panel list itemSize
 */
export const getTcPanelListItemSize = createSelector(
  getTcPanelListByStoreKey,
  (state: TcPanelListState) => {
    return R.propOr(100, 'itemSize')(state);
  }
)
