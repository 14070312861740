import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable, Subscription, zip } from 'rxjs';
import { TcGenericEntity } from '../../abstract/tc-generic-entity.interface';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tc-slider-list',
  templateUrl: './tc-slider-list.component.html',
  styleUrls: ['./tc-slider-list.component.scss'],
})
export class TcSliderListComponent implements OnInit, OnDestroy {
  items$: Observable<any[]>;

  itemTemplate: TemplateRef<any>;

  selectedItem$: Observable<any>;

  protected subscription: Subscription;

  config: SwiperConfigInterface = {
    observer: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false,
    threshold: 0,
    spaceBetween: 1,
    centeredSlides: true,
  };

  @ViewChild(SwiperComponent, { static: true }) componentRef?: SwiperComponent;

  constructor() {}

  ngOnInit() {
    if (this.items$ && this.selectedItem$) {
      this.subscription = zip(this.items$, this.selectedItem$)
        .pipe(
          map(([items, selectedItem]) => {
            return { items: [...items], selectedItem };
          })
        )
        .subscribe((s) => {
          if (s.selectedItem && s.items.length > 0) {
            const selectedItemIndex = s.items.findIndex(
              (i) => i.id === s.selectedItem.id
            );
            if (selectedItemIndex >= 0) {
              this.config.initialSlide = selectedItemIndex;
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.items$ && this.selectedItem$ && this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onItemClick(item: TcGenericEntity) {}
}
