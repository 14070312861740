import { Type } from '@angular/core';

/**
 * Tc panel lisy state key in store
 */
export const DEFAULT_TC_PANEL_LIST_STATE_KEY = 'tc-panel-list';

/**
 * Tc panel list state in store (all the panel lists will be added in store under tc-panel-list state key)
 */
export interface NgRxTcPanelListState {
  [key: string]: TcPanelListState;
}

/**
 * tc panel list state
 */
export interface TcPanelListState {
  storeKey: string;
  // component: Type<any>;
  itemSize: number;
}
