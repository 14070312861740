import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcFilterDef, TcFilterItem } from '@tc/abstract';
import { getTcFilterStoreSliceFilters, TcTranslateService } from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcDataFilters,
  getTcDataStoreSliceData,
  refreshTcData,
} from '@tc/data-store';
import { selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

import { getAuthenticatedUser } from '../../../../modules/auth/store/auth.selectors';
import { PrintService } from '../../../services/print.service';
import { BanksService } from '../../../shared/services/banks.service';
import { Bank } from '../../../shared/typings/bank';
import { createAssociationsPDF } from '../helpers/associations-pdf-helpers';
import { AssociationFilterParams } from '../interfaces/association-filter-params.interface';
import { AssociationsService } from '../services/associations.service';
import {
  exportAssociationsListCsv,
  exportAssociationsListPdf,
  loadAssociationsCategoriesStatsSuccess,
  loadAssociationsSoftwareStatsSuccess,
  loadAssociationsStats,
  updateAssociationTicadiStartDate,
} from './associations.actions';
import {
  getAssociationsCategoriesData,
  getAssociationsSoftwareData,
} from './associations.selectors';

@Injectable()
export class AssociationsEffects {
  storeKey = 'associations-grid';

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly printService: PrintService,
    private readonly associationsService: AssociationsService,
    private readonly banksService: BanksService,
    private readonly translateService: TcTranslateService
  ) {}

  exportAssociationsListPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportAssociationsListPdf),
        concatLatestFrom(() => [
          this.store$.select(getAssociationsSoftwareData),
          this.store$.select(getAssociationsCategoriesData),
          this.store$.select(getTcFilterStoreSliceFilters, 'associations-grid'),
        ]),
        tap(async ([_action, softwareData, categoriesData, filters]) => {
          const bankFilter = filters.find((f) => f.key === 'banqueId');
          let bank: Bank | null = null;
          if (bankFilter && bankFilter.value) {
            const bankId = bankFilter.value;
            bank = await this.banksService.getBankById(bankId);
          }
          const associationsList =
            await this.associationsService.getAssociations(filters);
          const associationsPdfContent = createAssociationsPDF(
            bank,
            filters,
            softwareData,
            categoriesData,
            associationsList,
            this.translateService
          );
          const filename = this.translateService.instant(
            'associations-page.title'
          );
          this.printService.buildPdf(associationsPdfContent, filename);
        })
      ),
    { dispatch: false }
  );

  loadAssociationsStatistics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAssociationsStats),
        tap(async (curFilter) => {
          const { filters } = curFilter as TcFilterDef;

          const {
            category,
            bankId,
            active,
            equipped,
            softwareVersion,
            startDate,
            endDate,
          } = this.generateAssociationParams(filters);

          const categoryStatistics =
            await this.associationsService.getAssociationsCategoriesData(
              category,
              bankId,
              active,
              equipped,
              softwareVersion,
              startDate,
              endDate
            );
          const softwareStatistics =
            await this.associationsService.getAssociationsSoftwareData(
              category,
              bankId,
              active,
              equipped,
              softwareVersion,
              startDate,
              endDate
            );

          this.store$.dispatch(
            loadAssociationsCategoriesStatsSuccess({
              payload: categoryStatistics,
            })
          );
          this.store$.dispatch(
            loadAssociationsSoftwareStatsSuccess({
              payload: softwareStatistics,
            })
          );
        })
      ),
    { dispatch: false }
  );

  exportAssociationsListCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportAssociationsListCsv),
        tap(async () => {
          const associationFilter: TcFilterDef =
            await this.getAssociationFilter();

          const filters = associationFilter?.filters;

          const {
            category,
            bankId,
            active,
            equipped,
            softwareVersion,
            startDate,
            endDate,
          } = this.generateAssociationParams(filters);

          const curUser = await this.store$
            .select(getAuthenticatedUser)
            .pipe(take(1))
            .toPromise();
          const banqueId = bankId ? bankId : (curUser as any)?.bankId;

          await this.associationsService.exportAssociationsListCsv(
            category,
            banqueId,
            active,
            equipped,
            softwareVersion,
            startDate,
            endDate
          );
        })
      ),
    { dispatch: false }
  );

  updateAssociationTicadiStartDate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAssociationTicadiStartDate),
        tap(async ({ payload }) => {
          const { associationId, dateDebutTicadi } = payload as any;

          this.associationsService
            .updateAssociationTicadiStartDate(associationId, dateDebutTicadi)
            .then(async () => {
              // refresh tc data grid
              const associationFilter = await this.getAssociationFilter();

              this.store$.dispatch(
                refreshTcData({
                  storeKey: this.storeKey,
                  filter: associationFilter,
                })
              );
            });
        })
      ),
    { dispatch: false }
  );

  private generateAssociationParams(
    filters: TcFilterItem[]
  ): AssociationFilterParams {
    const category: string =
      filters?.filter((f) => f.key === 'categorie').length > 0
        ? filters.filter((f) => f.key === 'categorie')[0]?.value
        : null;
    const bankId: number =
      filters?.filter((f) => f.key === 'banqueId').length > 0
        ? Number(filters.filter((f) => f.key === 'banqueId')[0].value)
        : null;
    const activeFilter = filters?.filter((f) => f.key === 'active');
    const active: boolean =
      activeFilter.length > 0 && activeFilter[0].value !== ''
        ? Boolean(activeFilter[0].value)
        : null;
    const equipped: boolean = null;
    const softwareVersion: string =
      filters?.filter((f) => f.key === 'logiciel').length > 0
        ? filters.filter((f) => f.key === 'logiciel')[0].value
        : null;
    const startDate: string =
      filters?.filter((f) => f.key === 'dateDebutTicadi.start').length > 0
        ? filters.filter((f) => f.key === 'dateDebutTicadi.start')[0].value
        : null;
    const endDate: string =
      filters?.filter((f) => f.key === 'dateDebutTicadi.end').length > 0
        ? filters.filter((f) => f.key === 'dateDebutTicadi.end')[0].value
        : null;

    return {
      category,
      bankId,
      active,
      equipped,
      softwareVersion,
      startDate,
      endDate,
    };
  }

  private async getAssociationFilter() {
    const dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    const associationFilter: TcFilterDef = await selectValueByKey(
      getTcDataFilters,
      dataStore$,
      this.storeKey
    );

    return associationFilter;
  }
}
