import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { TcContentService } from '../../services/tc-content.service';

@Component({
  selector: 'tc-content',
  template: '<ng-content></ng-content>',
})
export class TcContentComponent implements OnInit {
  @Input()
  placeholder: string;

  constructor(
    private elementRef: ElementRef,
    private tcContentService: TcContentService
  ) {}

  ngOnInit() {
    this.tcContentService.registerContent({
      placeholder: this.placeholder,
      elementRef: this.elementRef,
    });
  }
}
