import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { CONFIG_SERVICE, IConfigService } from '@tc/config';
import { TcGridPaginationService } from '@tc/core';
// import { CustomRequestOptions } from './crud/crud.interface';

@Injectable({
  providedIn: 'root',
})
export class TcCrudService<
  TResult extends { id: number } = any
> extends TcService {
  protected basePath = 'http://localhost:3333/api';
  protected defaultHeaders = new HttpHeaders();
  public collection: string;

  constructor(
    public httpClient: HttpClient,
    @Inject(CONFIG_SERVICE) public config: IConfigService
  ) {
    super();

    this.basePath = config.get('API_BASE_PATH') || this.basePath;
  }

  async delete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<boolean> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .delete<any>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<boolean>();
  }

  async create(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .post<TResult>(`${url}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  async update(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .put<TResult>(`${url}/${item.id}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  async get(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  async getList(
    queryString: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}/${queryString}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  async getWithQueryParams(
    queryString: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}?${queryString}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  //   public async custom(request: CustomRequestOptions) {
  //     return this.httpClient
  //       .request(request.method, request.url, {
  //         withCredentials: true,
  //         observe: 'body',
  //         ...request.options,
  //       })
  //       .toPromise();
  //   }

  public getEntityServiceEndpoint() {
    return `${this.basePath}/${this.collection}`;
  }
}
