import { TcFilterItem, TcFilterDef, TcConfigTypes } from '@tc/abstract';
import {
  initTcFilter,
  updateTcFilter,
  resetTcFilter,
} from '../../store/actions/tc-filter-actions';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartFilterConfig } from '@tc/abstract';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import moment from 'moment';
import {
  getTcFilterConfig,
  getTcFilters,
} from '../../store/selectors/tc-filter.selectors';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  NgRxTcFilterState,
} from '../../store/state/tc-filter-state';
import { isValidDate } from '../../../shared/utils/date-utils';
import { selectByKey } from '@tc/store';
import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterTypes } from '@tc/abstract';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import { TcFilterService } from '../../services/tc-filter-store.service';
import { TcFormlyComponent } from '../../../enums/tc-formly-component.enum';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormSubmitModel,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import { TcFilterCONSTANTS } from '@tc/core';
import { flattenModel } from '../../utils';

@Component({
  selector: 'tc-filter-store',
  templateUrl: './tc-filter-store.component.html',
  styleUrls: ['./tc-filter-store.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcFilterStoreComponent implements OnInit, OnDestroy {
  @Input() storeKey: string;

  /**
   * Store key used by the smart form
   */
  smartFormStoreKey: string;

  config: TcSmartFilterConfig;
  configSubscription: Subscription;

  /**
   * Filter store
   */
  filterStore$: Observable<NgRxTcFilterState>;

  filterItems = [];
  fieldFilterTypes = [];
  fieldTypes = [];
  anyFieldContainsFields = [];
  anyFieldStartsWithFields = [];
  anyFieldEqualsFields = [];

  public isPersistant = false;

  private filtersSubscription: Subscription;

  /**
   * Smart form store
   */
  formStore$: Observable<NgRxTcSmartFormState>;

  /**
   * For unsubscribing purposes
   */
  formSubmitModelSubscription: Subscription;

  /**
   * The current model of the smart form
   */
  model: any = {};

  /**
   * The fields of the smart form
   */
  fields: any;

  /**
   * @ignore
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly tcFilterService: TcFilterService,
    private readonly route: ActivatedRoute,
    private readonly configService: ConfigService
  ) {
    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Initialize all the data needed from the form config, convert dates to moment values and reload persistent filters inside the model
   */
  async ngOnInit() {
    this.smartFormStoreKey =
      this.storeKey + TcFilterCONSTANTS.SMART_FORM_STORE_KEY_ENDING;

    const config = await this.filterStore$
      .pipe(
        select(getTcFilterConfig, { storeKey: this.storeKey }),
        filter((v) => !!v),
        take(1)
      )
      .toPromise();
    this.initializeFilter(config);
  }

  initializeFilter(config) {
    this.config = R.clone(config);

    const {
      storeKey,
      fields,
      isPersistant,
      anyFieldContainsFields,
      anyFieldStartsWithFields,
      anyFieldEqualsFields,
    } = this.config;

    this.storeKey = storeKey || this.storeKey;
    this.fields = fields || this.fields;
    this.isPersistant = isPersistant || this.isPersistant;

    this.anyFieldContainsFields =
      anyFieldContainsFields || this.anyFieldContainsFields;
    this.anyFieldStartsWithFields =
      anyFieldStartsWithFields || this.anyFieldStartsWithFields;
    this.anyFieldEqualsFields =
      anyFieldEqualsFields || this.anyFieldEqualsFields;

    this.setFieldFilterTypes();
    this.setFieldTypes();

    this.setFormListener();

    const queryParams = this.route.snapshot.queryParams;
  }

  /**
   * TODO Task 67049 : Refactor this when fixing the get filters from url
   * Reload all the persistents filters from the store into the form model
   */
  async reloadFilters() {
    let persistedFilters = [];

    selectByKey(
      getTcFilters,
      this.filterStore$,
      this.config?.storeKey
    ).subscribe((filter) => {
      persistedFilters = filter.filters;

      if (persistedFilters.length > 0) {
        // TODO Task 67049 : This part will fail, as the model can be a tree with inifinite dept. It should be recursive to apply moment on the elements.
        // The key must be splited on dots because if you have field._id, it will be model['field']['id'].
        // This part is not yet working so I let it as it was coded.
        for (const filter in persistedFilters) {
          const { key, value } = persistedFilters[filter];

          if (isValidDate(value)) {
            const momentDate = moment(value);

            this.model[key] = momentDate;
          } else {
            this.model[key] = value;
          }
        }
      }
    });
  }

  /**
   * Compile all filters from the form model and put them into the store
   * @param isInit Determine if the method should create or update the filter in the store
   */
  applyFilters(isInit = false) {
    let filters: TcFilterItem[] = [];
    const flatModel = flattenModel(this.model);

    const filterInputMinLength =
      this.configService.get(ConfigKeys.filterInputMinLength) || 3;

    for (const modelFilter of flatModel) {
      const key = modelFilter.key;
      const value = modelFilter.value;
      const tcFormlyComponentType = this.tcFilterService.getTcFormlyInputType(
        key,
        this.fields
      );
      const formlyConfig = this.getFormlyFieldDefinition(key);

      /**
       * If a filter field is hidden, on the first filter update we remove it.
       * Otherwise we need to implement another way to remove hidden filters.
       * If you have hidden filters that you don't what to get removed you can set them direclty on the dataSource.
       */
      if (!isInit && formlyConfig?.hide === true) {
        continue;
      }

      // If the filterInputMinLength value is set from the form config, use it prioritary to default configuration
      const searchMinLength = formlyConfig?.templateOptions
        ?.filterInputMinLength
        ? formlyConfig?.templateOptions?.filterInputMinLength
        : filterInputMinLength;

      if (
        tcFormlyComponentType === TcFormlyComponent.FormlyInput &&
        typeof value === 'string' &&
        value?.length < searchMinLength
      )
        continue;

      let filter: TcFilterItem;

      const filterType = this.getFieldFilterType(key, value);
      const type = this.getFieldType(key);
      // Get the filterOn optionnal property on the formly configuration
      const filterOn = formlyConfig?.templateOptions?.filterOn;
      const filterMultiWord = formlyConfig?.templateOptions?.filterMultiWord;
      const filterMultiWordOperator =
        formlyConfig?.templateOptions?.filterMultiWordOperator;

      // Compile the filter to be executed
      filter = {
        key,
        value: value,
        filterType: filterType,
        type,
        ...(filterOn ? { filterOn } : {}),
        ...(filterMultiWord ? { filterMultiWord } : {}),
        ...(filterMultiWordOperator ? { filterMultiWordOperator } : {}),
      };

      filters.push(filter);
    }

    const filter: TcFilterDef = {
      anyFieldContainsFields: this.anyFieldContainsFields,
      anyFieldStartsWithFields: this.anyFieldStartsWithFields,
      anyFieldEqualsFields: this.anyFieldEqualsFields,
      filters,
    };

    if (isInit) {
      this.store$.dispatch(
        initTcFilter({
          storeKey: this.storeKey,
          filter,
          isPersistant: this.isPersistant,
        })
      );
    } else {
      this.store$.dispatch(
        updateTcFilter({
          storeKey: this.storeKey,
          filter,
        })
      );
    }
  }

  /**
   * Get a filterType based on his key. Value is needed for checking if data is valid on certain cases, like dates.
   * @param key Key of the form filter
   * @param value Value of the filter
   * @returns FilterTypesEnum
   */
  getFieldFilterType(key: string, value: any) {
    const filterType = this.fieldFilterTypes?.filter((i) => i.key === key)[0]
      ?.filterType;

    if (filterType) {
      return filterType;
    } else {
      // AAU: disabled because forceing equal causes unexpected behavior
      // if (isValidDate(value)) {
      //   return FilterTypesEnum.Equal;
      // } else if (Number(value)) {
      //   return FilterTypesEnum.Equal;
      // }

      return FilterTypesEnum.Contains;
    }
  }

  /**
   * Get the field type (input, select, multiselect...) for the form filter key
   * @param key Key of the form filter
   * @returns TcFilterTypes
   */
  getFieldType(key: string) {
    const fieldType = this.fieldTypes?.filter((i) => i.key === key)[0]?.type;

    if (fieldType) {
      return fieldType;
    } else {
      return TcFilterTypes.text;
    }
  }

  /**
   * Get field definition (formly full configuration) base on the form filter key
   * @param key Key of the form filter
   * @returns FormlyFieldConfig
   */
  getFormlyFieldDefinition(key: string): FormlyFieldConfig {
    const fields = this.tcFilterService.getFormlyFieldDefinitions(this.fields);
    return fields.find((item) => item.key === key);
  }

  /**
   * Build an array with {key, type} to find a form field type (select, input...) more easily based on the form field key
   */
  setFieldTypes() {
    let fieldDefs = this.tcFilterService.getFormlyFieldDefinitions(this.fields);
    const defaultType = TcFilterTypes.text;

    fieldDefs.forEach((fieldDef: FormlyFieldConfig) => {
      const fieldType = fieldDef.templateOptions?.fieldType;

      if (fieldType) {
        this.fieldTypes.push({
          key: fieldDef.key,
          type: fieldType,
        });
      } else {
        this.fieldTypes.push({
          key: fieldDef.key,
          type: defaultType,
        });
      }
    });
  }

  /**
   * Build an array with {key, filterType} to find a form filterType (Contains, StartsWith...) more easily based on the form field key
   */
  setFieldFilterTypes() {
    let fieldDefs = this.tcFilterService.getFormlyFieldDefinitions(this.fields);

    const defaultFilterType = FilterTypesEnum.Contains;

    fieldDefs.forEach((fieldDef: FormlyFieldConfig) => {
      const filterType = fieldDef.templateOptions?.filterType;

      if (filterType) {
        this.fieldFilterTypes.push({
          key: fieldDef.key,
          filterType,
        });
      } else {
        this.fieldFilterTypes.push({
          key: fieldDef.key,
          fieldType: defaultFilterType,
        });
      }
    });
  }
  /**
   * Add a subscription to smart form submit model changes
   */
  setFormListener() {
    // Listen to the on submit event of the form
    this.formSubmitModelSubscription = selectByKey(
      getTcSmartFormSubmitModel,
      this.formStore$,
      this.smartFormStoreKey
    )
      .pipe(debounceTime(500))
      .subscribe((model) => {
        // handle form values changes and dispatch filter action
        this.model = model;
        this.applyFilters();
      });
  }

  /**
   * Destroyer method of the component : if the filter are not persistent, store will be reset before the component is destroyed.
   */
  ngOnDestroy(): void {
    // Reset filters if filters are not persistant
    if (!this.isPersistant) {
      this.reset();
    }

    this.configSubscription?.unsubscribe();
    this.filtersSubscription?.unsubscribe();
    this.formSubmitModelSubscription?.unsubscribe();
  }

  /**
   * Trigger the action to reset the filters inside the store
   */
  reset() {
    this.store$.dispatch(resetTcFilter({ storeKey: this.storeKey }));
  }
}
