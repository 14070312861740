import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { TcUploadFileService } from '../../services/tc-upload-file.service';

@Component({
  selector: 'tc-camera-upload',
  templateUrl: './tc-camera-upload.component.html',
  styleUrls: ['./tc-camera-upload.component.scss'],
})
export class TcCameraUploadComponent implements OnInit, OnDestroy {
  url = '';

  image: File;

  overlayVisible = false;
  progress = 0;
  uploadStatus: any;

  progressSubscription: Subscription;
  forkJoinProgressSubscription: Subscription;
  bodyProgressSubscription: Subscription;

  @ViewChild('imageInput', { static: false }) imageInput;

  constructor(public uploadService: TcUploadFileService) {}

  ngOnInit() {}

  uploadImage(url: string) {
    this.url = url;
    this.imageInput.nativeElement.value = '';
    this.imageInput.nativeElement.click();
  }

  onImageAdded() {
    this.overlayVisible = true;

    this.image = this.imageInput.nativeElement.files[0];

    this.uploadStatus = this.uploadService.upload(this.image, this.url);

    this.progressSubscription = this.uploadStatus.progress.subscribe((p) => {
      this.progress = p;
    });

    this.forkJoinProgressSubscription = forkJoin(
      this.uploadStatus.progress
    ).subscribe((end) => {
      this.overlayVisible = false;
    });

    this.bodyProgressSubscription = forkJoin(this.uploadStatus.body).subscribe(
      (body) => {
        if (body) {
          this.onImageUploaded(body[0]);
        }
        this.overlayVisible = false;
      }
    );
  }

  onImageUploaded(file: any) {}

  ngOnDestroy() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }

    if (this.forkJoinProgressSubscription) {
      this.forkJoinProgressSubscription.unsubscribe();
    }

    if (this.bodyProgressSubscription) {
      this.bodyProgressSubscription.unsubscribe();
    }
  }
}
