import {
  Component,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { EventTargetInterruptSource } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { TcAppComponent, TcConfigService, TcMediaService } from '@tc/core';
import { SetVersionInfo } from '@tc/store';
import { TcVersionService } from '@tc/version';
import moment from 'moment';
import { SessionTimeoutService } from './services/session-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends TcAppComponent implements OnInit {
  constructor(
    public readonly appConfigService: TcConfigService,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly mediaService: TcMediaService,
    private readonly store$: Store<any>,
    private readonly tcVersionService: TcVersionService
  ) {
    super(appConfigService);

    // Display version info on console
    this.tcVersionService.displayVersionInConsole();

    this.store$.dispatch(
      new SetVersionInfo({
        version: this.tcVersionService.getVersionAsString(),
        currentDate: moment().toISOString(),
      })
    );

    this.sessionTimeoutService.start([
      new EventTargetInterruptSource(
        this.element.nativeElement,
        'mousedown keydown'
      ),
    ]);

    this.mediaService.init(this.changeDetectorRef);
  }

  async ngOnInit() {
    super.ngOnInit();
  }
}
