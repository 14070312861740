import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TcDumbComponent } from '@tc/abstract';

/**
 * Renders a phone link
 */
@Component({
  selector: 'tc-grid-mail-renderer',
  templateUrl: './tc-grid-phone-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TcGridPhoneRendererComponent
  extends TcDumbComponent
  implements AgRendererComponent
{
  /**
   * Value of the cell
   */
  public cellValue: string;

  agInit(params: ICellRendererParams) {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
