import { TcPanelListItemComponent } from './components/tc-panel-list-item/tc-panel-list-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcPanelListComponent } from './components/tc-panel-list/tc-panel-list.component';
import { TcPanelListEffects } from './store/effects/tc-panel-list-effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { DEFAULT_TC_PANEL_LIST_STATE_KEY } from './store/state/tc-panel-list-state';
import { tcPanelListReducer } from './store/reducers/tc-panel-list.reducer';
import { TcDataServiceFactory } from '@tc/data-store';
import { TcDataService } from '@tc/data-store';
import { TcPanelItemDirective } from './directives/tc-panel-item.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';

const components = [TcPanelListComponent, TcPanelListItemComponent];

const directives = [TcPanelItemDirective];

@NgModule({
  declarations: [...components, ...directives],
  providers: [TcDataServiceFactory, TcDataService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    TranslateModule,
    ScrollingModule,

    EffectsModule.forFeature([TcPanelListEffects]),
    StoreModule.forFeature(DEFAULT_TC_PANEL_LIST_STATE_KEY, tcPanelListReducer),
  ],
  exports: [...components],
})
export class TcPanelListModule {}
