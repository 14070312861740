import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TermsOfUseValidation } from '../../modules/terms-conditions/interfaces/terms-of-use-validation.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class TermsService extends CrudService {
  constructor(public config: ConfigService, public httpClient: HttpClient) {
    super(CustomEntitiesEnum.Terms, httpClient, config);
  }

  async accept(termsId: number): Promise<TermsOfUseValidation> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<TermsOfUseValidation>(`${url}/${termsId}/validation`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }

  async checkLast() {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get(`${url}/last`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }
}
