import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialColor } from '@tc/abstract';
import { MaterialButtonType } from '@tc/buttons';
import { IPageState } from '@tc/store';

import { downloadProductReferential } from '../../modules/product-referential/store/product-referential-detail.actions';
import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-product-referential-page',
  templateUrl: './product-referential-page.component.html',
  styleUrls: ['./product-referential-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductReferentialPageComponent extends AmsPage implements OnInit {
  constructor(store$: Store<any>) {
    super(store$);
  }

  downloadProductReferentiel(): void {
    this.store.dispatch(downloadProductReferential());
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'product-referential-page.title',
      buttonsList: [
        {
          label: 'download',
          color: MaterialColor.Accent,
          type: MaterialButtonType.Flat,
          action: () => this.downloadProductReferentiel(),
        },
      ],
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
