import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '../../modules/auth/models/user.model';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends CrudService {
  constructor(public httpClient: HttpClient, public config: ConfigService) {
    super(CustomEntitiesEnum.Users, httpClient, config);
  }

  public async getMe(): Promise<UserModel> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<UserModel>(`${url}/me`, {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }

  public async getPermissions(): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<any>(`${url}/permissions`, {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }
}
