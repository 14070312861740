import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { can } from '@tc/permissions';
import { NgRxTcSmartFormState } from '@tc/smart-form';
import { selectByKey, TcAppState } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { TcSmartButton } from '../../interfaces/tc-smart-button.interface';

/**
 * This component is used for every button in the app and it is usually part of
 * a bigger component named TcButtonsList
 */
@Component({
  selector: 'tc-smart-button',
  templateUrl: './tc-smart-button.component.html',
  styleUrls: ['./tc-smart-button.component.scss'],
})
export class TcSmartButtonComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  /**
   * Button configurations
   */
  @Input()
  button: TcSmartButton;

  /**
   * Disabled propery like usual buttons have.
   * If we specify a formStoreKey in the TcSmartButton config this will be
   * changed depending on the validity of the form.
   */
  @Input()
  disabled: boolean = false;

  /**
   * In order to know if this is used inside a tc-grid component
   */
  @Input()
  tcGridColumn: boolean = false;

  /**
   * Node of the TcGrid row if used inside a tc-grid component
   */
  @Input()
  tcGridNode: any = null;

  /**
   * Click event emmiter
   */
  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  /**
   * Observable to the form store
   */
  formStore$: Observable<NgRxTcSmartFormState>;

  /**
   * Subscription for the form inalidity selector.
   * Used for disabling the button in case it used as a submit button
   */
  formInvaliditySubscription: Subscription;

  /**
   * this is used to know if a button will be visible or not based on permissionAction and permissionSubject
   */
  isVisible = true;

  /**
   * subscription for is visible
   */
  isVisibleSubscription: Subscription;

  /**
   * Simple constructor that initializes the store.
   * @param store$
   */
  constructor(store$: Store<TcAppState>) {
    super(store$);
  }

  /**
   * Get the form store and subscribe to it's invalidity if it's tha case.
   */
  async ngOnInit() {
    // Handle permission from user defined boolean
    if (this.button?.permissionCustom === false) {
      // Boolean : direct change
      this.isVisible = false;
    } else if (
      typeof this.button?.permissionCustom === 'function' &&
      this.tcGridColumn === true
    ) {
      // If permissionCustom if a user defined method, execute it with the row data
      const hasPermission = await this.button?.permissionCustom(
        this.tcGridNode?.data
      );
      this.isVisible = hasPermission;
    } else {
      // Handle permission from subject and key
      if (this.button?.permissionAction && this.button?.permissionSubject) {
        this.isVisibleSubscription = this.store$
          .select(
            can(this.button?.permissionAction, this.button?.permissionSubject)
          )
          .subscribe((isVisible) => {
            this.isVisible = isVisible;
          });
      }
    }

    // If we are inside a grid column we let the cell rendered handle the button disable state
    // If not, we subscribe to the form invalidity, in case we have one.
    if (
      !this.tcGridColumn &&
      this.button.disableStoreKey &&
      this.button.disableSelector &&
      this.button.smartStateKey
    ) {
      // Initialize this only once
      if (!this.formStore$) {
        this.formStore$ = this.store$.pipe(
          select(this.button.smartStateKey),
          filter(hasValue),
          distinctUntilChanged()
        );
      }

      this.formInvaliditySubscription = selectByKey(
        this.button.disableSelector,
        this.formStore$,
        this.button.disableStoreKey
      ).subscribe((invalid) => {
        this.disabled = invalid as boolean;
      });
    }
  }

  /**
   * Emits the click event to the parent component
   * @param clickEvent Native click event
   */
  onClickHandler(clickEvent) {
    // If we are inside a grid column we let the cell rendered handle the button action
    if (!this.tcGridColumn) {
      if (this.button.actionPayload) {
        // Smart Button Usecase
        this.store$.dispatch(this.button.action(this.button.actionPayload));
      } else if (this.button.action) {
        // Dumb Butoon Usecase
        this.button.action(clickEvent);
      }
    }

    this.onClick.emit(clickEvent);
  }

  /**
   * Unsubscribe to subscriptions
   */
  ngOnDestroy() {
    this.formInvaliditySubscription?.unsubscribe();
    this.isVisibleSubscription?.unsubscribe();
  }
}
