import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { TcGridValidator } from '../../services/tc-grid-validator';
import { TcValidationsService } from '../../services/tc-validations.service';

@Component({
  selector: 'tc-grid-mat-input-editor',
  templateUrl: './tc-grid-mat-input-editor.component.html',
  styleUrls: ['./tc-grid-mat-input-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridMatInputEditorComponent
  extends TcGridValidator
  implements AgEditorComponent, AfterViewInit
{
  @ViewChild('input') input: ElementRef;

  public label: string;
  public value: string;
  public type: string;
  public mask: string;
  public decimalMarker: string;
  public allowNegativeNumbers: boolean;
  public replacePeriodWithComma: boolean;

  constructor(public readonly tcValidationsService: TcValidationsService) {
    super(tcValidationsService);
  }

  ngAfterViewInit() {
    setTimeout(() => this.input?.nativeElement?.focus());
  }

  public agInit(params) {
    this.data = params?.data ?? null;
    this.value = params?.value;
    this.label = params?.label;
    this.type = params?.type ?? 'text';
    this.validators = params?.validators;

    this.mask = params?.mask;
    this.decimalMarker = params?.decimalMarker;
    this.allowNegativeNumbers = params?.allowNegativeNumbers;
    this.replacePeriodWithComma = params?.replacePeriodWithComma ?? false;
  }

  public onChange(updated) {
    super.validate(updated);
  }

  public getValue() {
    return this.value;
  }

  keyUp(event) {
    if (event?.key === '.' && this.replacePeriodWithComma) {
      if (event?.target?.value === '') {
        event.target.value = ',';
      } else {
        if (!event?.target?.value.includes(',')) {
          event.target.value = event.target.value + ',';
        }
      }
    }
  }

  // all editions will be in popup
  // https://www.ag-grid.com/angular-grid/cell-editing/#popup
  public isPopup() {
    return true;
  }
}
