import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const getAuthenticationState = createFeatureSelector<AuthState>('auth');

export const getAuthenticatedUser = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.user
);

export const getAuthenticationToken = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.token
);

export const getAuthenticationMfaOptions = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.mfaOptions
);
