import { Inject, Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { IConfigService } from '@tc/config';
import { CONFIG_SERVICE } from '@tc/config';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import localForage from 'localforage';

@Injectable({
  providedIn: 'root',
})
export class TcLocalStorageService extends TcService {
  private localForage = localForage;

  /**
   * @ignore
   */
  constructor(@Inject(CONFIG_SERVICE) public config: IConfigService) {
    super();

    // Get offline mode name from config if defined, if not, use TCP by default
    const offlineModeDBName: string = this.config.get(
      ConfigKeys.offlineModeDBName
    )
      ? this.sanitizeDbName(this.config.get(ConfigKeys.offlineModeDBName))
      : 'TCP';

    // LocalForage configuration : must be set before anything else
    this.localForage.config({
      driver: localForage.INDEXEDDB,
      name: offlineModeDBName,
      version: 1.0,
      storeName: offlineModeDBName,
      description: `Offline data for ${offlineModeDBName} usage.`,
    });
  }

  /**
   * Write the data into the local storage
   * @param key Identifier of the data
   * @param value Value of the data
   * @returns boolean
   */
  public async set(key: string, value: any): Promise<boolean> {
    const result = await this.localForage.setItem(key, value);
    return result ? true : false;
  }

  /**
   * Get the data from the local storage
   * @param key Identifier of the data
   * @returns any
   */
  public async get(key: string): Promise<any> {
    const result = await this.localForage.getItem(key);
    return result;
  }

  /**
   * Remove the data associated to the key in the local storage
   * @param key Identifier of the data
   */
  public async remove(key: string) {
    await this.localForage.removeItem(key);
  }

  /**
   * Clear ALL local storage keys. Use with caution.
   */
  public async clear() {
    await this.localForage.clear();
  }

  /**
   * Database names should be alphanumeric, with underscores.
   * @param name Database Name
   * @returns Cleaned DB name
   */
  private sanitizeDbName(name: string): string {
    const cleanedName = name.replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/gi, '');
    return cleanedName;
  }
}
