import { HostListener, Directive } from '@angular/core';
import {
  TcValidatorFn,
  TcValidatorError,
} from '../../interfaces/tc-validations.interfaces';
import { TcValidationsService } from './tc-validations.service';

@Directive()
export class TcGridValidator {
  public validators: TcValidatorFn[];
  public errors: TcValidatorError[];
  public data: any;

  constructor(protected readonly tcValidationsService: TcValidationsService) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (['Enter', 'Tab'].indexOf(event.code) !== -1 && this.errors?.length) {
      event.stopPropagation();
      return false;
    }
  }

  public validate(value) {
    if (!this.validators?.length) {
      return;
    }
    this.errors = this.tcValidationsService.validate(
      this.validators,
      value,
      this.data
    );
  }
}
