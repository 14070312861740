import { TcAction } from './tc-action';

export enum HttpErrorsActionTypes {
  AddHttpError = '[Error] Add HTTP error',
}

export class AddHttpError implements TcAction {
  readonly type = HttpErrorsActionTypes.AddHttpError;
  constructor(public payload: any) {}
}

export type ErrorsActions = AddHttpError;
