import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TcComponent } from '@tc/abstract';
import { TcConfirmDialogConfig } from '../../interfaces/tc-confirm-dialog-config';

@Component({
  selector: 'tc-confirm-dialog',
  templateUrl: './tc-confirm-dialog.component.html',
  styleUrls: ['./tc-confirm-dialog.component.css'],
})
export class TcConfirmDialogComponent
  extends TcComponent
  implements OnInit, TcConfirmDialogConfig
{
  title: string;
  message: string;
  messageClass = '';
  beforeButtonsText = '';
  yesFocused = true;

  hasCloseButton = true;

  yesText = 'OUI';
  noText = 'NON';

  constructor(
    public dialogRef: MatDialogRef<TcConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: TcConfirmDialogConfig
  ) {
    super();

    if (data.messageClass) {
      this.messageClass = data.messageClass;
    }

    if (data.yesFocused !== undefined) {
      this.yesFocused = data.yesFocused;
    }

    if (data.message) {
      this.message = data.message;
    }

    if (data.beforeButtonsText) {
      this.beforeButtonsText = data.beforeButtonsText;
    }

    if (data.noText) {
      this.noText = data.noText;
    }

    if (data.yesText) {
      this.yesText = data.yesText;
    }

    if (data.hasCloseButton !== undefined) {
      this.hasCloseButton = data.hasCloseButton;
    }

    if (data.title) {
      this.title = data.title;
    }
  }

  public onNoClick(): void {
    this.dialogRef.close('no');
  }

  public onYesClick(): void {
    this.dialogRef.close('yes');
  }

  ngOnInit() {}

  onClose() {
    this.dialogRef.close('no');
  }
}
