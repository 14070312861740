import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TermsConditionsState } from './terms-conditions.state';

export const getTermsConditionsState =
  createFeatureSelector<TermsConditionsState>('termsConditions');

export const getTermsAccepted = createSelector(
  getTermsConditionsState,
  (state: TermsConditionsState) => state.termsAccepted
);

export const getTermsToValidate = createSelector(
  getTermsConditionsState,
  (state: TermsConditionsState) => state.termsToValidate
);
