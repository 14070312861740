import {
  AfterViewInit,
  Component,
  ElementRef,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AgEditorComponent, ICellRendererAngularComp } from 'ag-grid-angular';
import moment from 'moment';
import { TcGridValidator, TcValidationsService } from '@tc/core';
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
  GridApi,
} from 'ag-grid-community';
import { Store } from '@ngrx/store';
import {
  resetCancelEditing,
  updateAssociationTicadiStartDate,
} from '../../../modules/associations/store/associations.actions';
import { DATE_FORMAT } from '../../utils/constants';
import { getCancelEditingFlag } from '../../../modules/associations/store/associations.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ams-grid-datepicker-editor',
  templateUrl: './ams-grid-datepicker-editor.component.html',
  styleUrls: ['./ams-grid-datepicker-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmsGridDatepickerEditorComponent
  implements ICellRendererAngularComp, AfterViewInit
{
  @ViewChild('input') input: ElementRef;

  public label: string;
  public value;
  public inputValue: string;
  public triggerTemplate: TemplateRef<any>;
  public data: any;
  public api: GridApi;

  onChange: any;
  clearDate: any;

  public minDate = moment().add(1, 'd').toDate();
  public associationId: number;

  constructor(private readonly store$: Store<any>) {}

  ngAfterViewInit() {
    setTimeout(() => this.input?.nativeElement?.focus());
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  public async agInit(params) {
    this.value = params?.value ? moment(params?.value) : null;

    this.data = params?.data ?? null;

    this.value = params?.value ? moment(params?.value) : null;
    this.label = params?.label ?? '';
    this.inputValue = this.value?.format(params?.format ?? DATE_FORMAT);

    this.triggerTemplate = params?.triggerTemplate;
    this.associationId = params?.data?.id;
    this.api = params?.api;

    const cancelEditing = await this.store$
      .select(getCancelEditingFlag)
      .pipe(take(1))
      .toPromise();

    if (cancelEditing) {
      this.api.stopEditing(true);

      this.store$.dispatch(resetCancelEditing());
    }
  }

  public handleInputChange(source: string, $event) {
    let updated = source === 'input' ? $event?.target?.value : $event;

    if (typeof $event === 'object' && !$event?.target?.value) {
      updated = $event;
    }

    let validDate = false;

    if (typeof updated === 'string') {
      if (updated?.length < 10) {
        validDate = false;
      } else {
        this.inputValue = updated;

        validDate = moment(this.inputValue, 'DD/MM/YYYY', true).isValid();
      }
    } else {
      validDate = true;
      this.inputValue = updated?.format('YYYY-MM-DD HH:mm:ss');
    }

    if (validDate) {
      this.store$.dispatch(
        updateAssociationTicadiStartDate({
          payload: {
            associationId: this.associationId,
            dateDebutTicadi: this.inputValue,
          },
        })
      );

      this.api.stopEditing(false);
    }
  }

  public getValue() {
    return this.value;
  }

  public handleClearDate() {
    this.inputValue = null;
    this.value = null;

    this.store$.dispatch(
      updateAssociationTicadiStartDate({
        payload: { associationId: this.associationId, dateDebutTicadi: null },
      })
    );

    this.api.stopEditing(false);
  }

  // all editions will be in popup
  // https://www.ag-grid.com/angular-grid/cell-editing/#popup
  public isPopup() {
    return true;
  }
}
