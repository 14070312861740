import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { PermissionsEffects } from './store/permissions.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([PermissionsEffects])],
})
export class PermissionsModule {}
