import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { TcStoreInitEffects } from './effects/tc-store-init-effects';

@NgModule({
  declarations: [],
  providers: [],
  imports: [CommonModule, EffectsModule.forFeature([TcStoreInitEffects])],
})
export class TcStoreInitModule {}
