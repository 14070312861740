import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  ListOrder,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { MaterialButtonType } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcGridCellComponent,
  TcTranslateService,
} from '@tc/core';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { Subscription } from 'rxjs';

import { UserService } from '../../../../shared/services/user.service';
import {
  DEFAULT_GRID_HEADER_HEIGHT,
  DEFAULT_GRID_ROW_HEIGHT,
} from '../../../../shared/utils/constants';
import { AssociationCategorie } from '../../../associations/types';
import { indicatorsGridActionButtonClicked } from '../../store/indicators.actions';
import { StatusValidation } from '../../typings/status-validation.enum';

@Component({
  selector: 'app-indicators-grid',
  templateUrl: './indicators-grid.component.html',
  styleUrls: ['./indicators-grid.component.scss'],
})
export class IndicatorsGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  private subscription = new Subscription();
  storeKey = 'indicators-grid';
  private filterConfig: TcSmartFilterConfig;
  private fields: FormlyFieldConfig[];

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translateService: TcTranslateService,
    private readonly userService: UserService
  ) {
    super(store$);
    this.setFilterConfig();
  }

  private periodeRenderer(params: any): HTMLElement {
    const div = document.createElement('div');
    div.className = 'periode-string-value';
    div.innerHTML = params.value;
    return div;
  }

  async ngOnInit(): Promise<void> {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'indicators-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: DEFAULT_GRID_ROW_HEIGHT,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().indicatorsGrid,
        fields: '',
      },
      columns: [
        {
          field: 'associationCode',
          cellRenderer: (params: any) => {
            if (params.data.children) {
              return params.data.associationCode;
            }
            return '';
          },
          minWidth: 80,
          maxWidth: 80,
          suppressAutoSize: true,
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) =>
            isDescending ? -1 : 1,
        },
        {
          field: 'associationNom',
          cellRenderer: (params: any) => {
            if (params.data.children) {
              return params.data.associationNom;
            }
            return '';
          },
        },
        {
          field: 'equipee',
          cellRenderer: (params: any) =>
            this.indicatorsCellRenderer('equipee', params.value),
          minWidth: 95,
          maxWidth: 95,
          suppressAutoSize: true,
        },
        {
          field: 'categorieInt',
          minWidth: 100,
          maxWidth: 100,
          suppressAutoSize: true,
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) =>
            isDescending ? -1 : 1,
        },
        {
          field: 'periodeString',
          cellRenderer: this.periodeRenderer,
          minWidth: 90,
          maxWidth: 90,
          suppressAutoSize: true,
        },
        {
          field: 'dateRemontee',
          cellRenderer: (params: any) => {
            if (params.value) {
              const date = new Date(params.value);
              return date.toLocaleDateString();
            }
            return '';
          },
          minWidth: 145,
          maxWidth: 145,
          suppressAutoSize: true,
        },
        {
          field: 'modificationManuelle',
          cellRenderer: (params: any) =>
            this.indicatorsCellRenderer('modificationManuelle', params.value),
          minWidth: 160,
          maxWidth: 160,
          suppressAutoSize: true,
        },
        {
          field: 'statutValidation',
          cellRenderer: this.statusValidationCellRenderer.bind(this),
          minWidth: 150,
          maxWidth: 150,
          suppressAutoSize: true,
        },
        {
          field: 'numeroVersion',
          cellRenderer: (params: any) => {
            // Do not display version number for children
            if (params.data.children) {
              return params.data.numeroVersion;
            }
            return '';
          },
          minWidth: 95,
          maxWidth: 95,
          suppressAutoSize: true,
        },
        {
          field: 'action',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                matIcon: 'remove_red_eye',
                type: MaterialButtonType.Icon,
                action: indicatorsGridActionButtonClicked,
              },
            ],
          },
          minWidth: 80,
          maxWidth: 80,
          suppressAutoSize: true,
        },
      ],
      filterConfig: this.filterConfig,
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private indicatorsCellRenderer(
    field: string,
    value: string | null | undefined
  ): string {
    // Value could be "false" -> wouldn't work with checking "if(value)"
    if (value === null || value === undefined) {
      return '';
    }
    return this.translateService.instant(
      `${this.storeKey}.table.values.${field}.${value}`
    );
  }

  private statusValidationCellRenderer(params: any): string {
    const translation = this.translateService.instant(
      `${this.storeKey}.table.values.statutValidation.${params.value}`
    );
    // Green background
    if (params.value === StatusValidation.Valide) {
      return `<div class=status-validation-container><div class="status-validation status-validation-received">${translation}</div><div>`;
    }
    // Red Background
    if (params.value === StatusValidation.ARevoir) {
      return `<div class=status-validation-container><div class="status-validation status-validation-to-review">${translation}</div></div>`;
    }
    // Blue background
    if (params.value === StatusValidation.AValider) {
      return `<div class=status-validation-container><div class="status-validation status-validation-to-validate">${translation}</div></div>`;
    }
    // Yellow background
    return `<div class=status-validation-container><div class="status-validation status-validation-not-received">${translation}</div></div>`;
  }

  setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'categorie',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: AssociationCategorie.TousCategories,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: AssociationCategorie.TousCategories,
                      label: this.translateService.instant(
                        `associationCategorie.tousCategories`
                      ),
                    },
                    {
                      value: 0,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie1}`
                      ),
                    },
                    {
                      value: 1,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie2}`
                      ),
                    },
                  ],
                },
                colSpan: 2,
              }),
              formlyControl({
                key: 'equipeeDeLogiciel',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: '',
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(`globalLabels.tous`),
                    },
                    {
                      value: true,
                      label: this.translateService.instant(`globalLabels.oui`),
                    },
                    {
                      value: false,
                      label: this.translateService.instant(`globalLabels.non`),
                    },
                  ],
                },
                colSpan: 2,
              }),
              formlyControl({
                key: 'active',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: '',
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(
                        `globalLabels.toutes`
                      ),
                    },
                    {
                      value: true,
                      label: this.translateService.instant(
                        `globalLabels.oui`
                      ),
                    },
                    {
                      value: false,
                      label: this.translateService.instant(
                        `globalLabels.non`
                      ),
                    },
                  ],
                },
                colSpan: 1,
              }),
            ],
          }),
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true,
        },
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }
}
