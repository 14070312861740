import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { getVersionDetails } from '../../home/store/home.selectors';
import { take } from 'rxjs/operators';
import { downloadFile } from '../../../shared/utils/export.utils';
import { ConfigService } from '../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../shared/interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductReferentialService {
  private basePath = 'http://localhost:3333/api';

  constructor(
    public httpClient: HttpClient,
    private readonly store$: Store<any>,
    private readonly config: ConfigService
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async downloadProductReferential(): Promise<void> {
    const url = `${this.basePath}/referentiel-produits/download-product-repository`;

    const response = await this.httpClient
      .get(url, { responseType: 'text' })
      .toPromise();

    const versionDetails = this.store$
      .select(getVersionDetails)
      .pipe(take(1))
      .toPromise();

    const fileName = `Référentiel v${(await versionDetails).version}.xml`;

    downloadFile(fileName, response, null, 'text/csv;charset=UTF-8', false);
  }
}
