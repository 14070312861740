import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigKeys } from '../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../shared/services/config.service';
import { AmsRouteProvider } from '../../services/ams-route.provider';
import { Bank } from '../typings/bank';

@Injectable({
  providedIn: 'root',
})
export class BanksService {
  private basePath = 'http://localhost:3333/api';
  constructor(
    public httpClient: HttpClient,
    private readonly config: ConfigService,
    private readonly routerService: AmsRouteProvider
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async getBankById(id: string): Promise<Bank> {
    const response = await this.httpClient.get<Bank>(`${this.basePath}/${this.routerService.getRoutes().banques}/${id}`).toPromise();
    return response;
  }
}
