/**
 * Types of TcFilter
 */
export enum TcFilterTypes {
  text = 'text',
  anyFieldContains = 'anyFieldContains',
  anyFieldStartsWith = 'anyFieldStartsWith',
  anyFieldEquals = 'anyFieldEquals',
  toggle = 'toggle',
  daterange = 'daterange',
  multiselect = 'multiselect',
  select = 'select',
}
