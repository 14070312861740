import { HomePageComponent } from './pages/home-page/home-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TcApiConfig, TcCoreModule } from '@tc/core';
import { TcStoreInitModule } from '@tc/store-init';
import { TcAppReducers, TcMetaReducers } from '@tc/store';
import { CONFIG_SERVICE } from '@tc/config';
import { TcLayoutModule } from '@tc/layout';
import {
  DefaultDataServiceConfig,
  EntityDispatcherDefaultOptions,
  NgrxDataModule,
} from 'ngrx-data';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { entityConfig } from './entity-metadata';
import { AuthModule } from './modules/auth/auth.module';
import { ExamplesModule } from './modules/examples/examples.module';
import { TermsConditionsModule } from './modules/terms-conditions/terms-conditions.module';
import { LoginMfaPageComponent } from './pages/login-mfa-page/login-mfa-page.component';
import { LoginOauthFailedPageComponent } from './pages/login-oauth-failed-page/login-oauth-failed-page.component';
import { LoginOauthPageComponent } from './pages/login-oauth-page/login-oauth-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { TermsConditionsPageComponent } from './pages/terms-conditions-page/terms-conditions-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { AppInitService } from './services/app-init.service';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { NgxInputFormlyComponent } from './shared/components/ngx-input/ngx-input.component';
import { RouteResolver } from './shared/resolvers/route.resolvers';
import { LayoutModule } from './modules/layout/layout.module';
import { CustomModule } from './custom/custom.module';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import moment from 'moment';
import localeFr from '@angular/common/locales/fr';
import { SharedModule } from './shared/shared.module';
import { IdleExpiry, SimpleExpiry } from '@ng-idle/core';
import { FormsModule } from '@angular/forms';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';
import { ConfigService } from './shared/services/config.service';
import { TcSmartListDetailsModule } from '@tc/advanced-components';
import { PermissionsModule } from './modules/permissions/permissions.module';
import { MenuModule } from './modules/menu/menu.module';
import { TcDialogModule } from '@tc/dialog';

const modules = [
  AuthModule,
  TermsConditionsModule,
  LayoutModule,
  CustomModule,
  SharedModule,
  AbilityModule,
  PermissionsModule,
  MenuModule,
  TcDialogModule,
];

const pages = [
  HomePageComponent,
  LoginPageComponent,
  LoginMfaPageComponent,
  LoginOauthPageComponent,
  LoginOauthFailedPageComponent,
  TermsConditionsPageComponent,
  UsersPageComponent,
];

const resolvers = [RouteResolver];

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: 'http://localhost:11508', // change this with your backend url
  timeout: 3000,
  delete404OK: false,
};

const entityDispatcherDefaultOptions: EntityDispatcherDefaultOptions = {
  optimisticAdd: false,
  optimisticDelete: false,
  optimisticUpdate: false,
  optimisticUpsert: false,
  optimisticSaveEntities: false,
};

const apiServiceConfig: TcApiConfig = {
  errorStatusExceptions: [401],
  spinnerExceptions: [
    { url: '/api/auth/refresh', method: 'GET' },
    {
      url: '/api/file',
      method: 'POST',
    },
  ],
};

export const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<void> => {
    return appInitService.init();
  };
}

registerLocaleData(localeFr, 'fr');
moment.locale('fr');

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth'],
    rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [
  ...TcMetaReducers,
  localStorageSyncReducer,
];

@NgModule({
  declarations: [...pages, AppComponent, NgxInputFormlyComponent],
  imports: [
    ...modules,
    HttpClientModule,
    FormsModule,
    ExamplesModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TcCoreModule,
    TcStoreInitModule,
    TcLayoutModule,
    MatButtonModule,
    NgrxDataModule.forRoot(entityConfig),
    StoreModule.forRoot(TcAppReducers, { metaReducers }),    
    NgxMaskModule.forRoot(ngxMaskOptions),
    NgIdleKeepaliveModule.forRoot(),
    FormlyModule.forRoot({
      types: [
        {
          name: 'ngx-input',
          component: NgxInputFormlyComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'SkeletonSeed',
        }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TcSmartListDetailsModule,
  ],
  providers: [
    ...resolvers,
    DecimalPipe,
    { provide: TcApiConfig, useValue: apiServiceConfig },
    { provide: IdleExpiry, useClass: SimpleExpiry },
    // { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    // { provide: EntityDispatcherDefaultOptions, useValue: entityDispatcherDefaultOptions }
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    // { provide: DefaultDataServiceFactory, useClass: DataSourceFactory }, // Should be used when backend will provide REST API,
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    { provide: CONFIG_SERVICE, useClass: ConfigService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
