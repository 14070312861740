import { TcLocalStorageService } from '@tc/local-storage';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { TcService } from '@tc/abstract';
import {
  breeze,
  DataService,
  EntityManager,
  EntityQuery,
  Entity,
  EntityKey,
  EntityState,
  MergeStrategy,
} from 'breeze-client';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderODataAdapter } from 'breeze-client/adapter-uri-builder-odata';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { HttpClient } from '@angular/common/http';
import ObjectID from 'bson-objectid';
import { IConfigService } from '@tc/config';
import { CONFIG_SERVICE } from '@tc/config';
import { map } from 'rxjs/operators';
import { merge, Observable, Observer } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { Subscription } from 'rxjs/internal/Subscription';
import { TcSpinnerService } from '@tc/store';
import { OfflineModeSyncResponse } from './interfaces/offline-mode-sync-response.interface';

@Injectable({
  providedIn: 'root',
})
export class TcBreezeService extends TcService {
  protected masterManager: EntityManager;
  protected networkStatus: boolean;
  protected subscription: Subscription = new Subscription();
  protected metaDataStorageKey: string = 'metadata';
  protected offlineModeStorageKey: string = 'offlineMode';
  protected offlineModeEntityManagerLoaded = false;

  /**
   * @ignore
   */
  constructor(
    public http: HttpClient,
    @Inject(CONFIG_SERVICE) public config: IConfigService,
    protected localStorageService: TcLocalStorageService,
    protected tcSpinnerService: TcSpinnerService
  ) {
    super();
    // Configure Breeze adapters
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderODataAdapter.register();
    AjaxHttpClientAdapter.register(http);

    // Mongo does not have yet a data adapter in breeze-client. This file is a javascript adapter from breezejs repository, purged from the non working elements.
    // Repo : https://github.com/Breeze/breeze.js/blob/master/src/breeze.dataService.mongo.js
    require('./breeze.dataService.mongo.js');

    // Configure breeze to initialize Mongo data adapter
    breeze.config.initializeAdapterInstance('dataService', 'mongo', true);
    const dataService = new DataService({
      serviceName: config.get('API_BASE_PATH') + '/breeze/',
      hasServerMetadata: true,
    });
    this.masterManager = new EntityManager({ dataService });

    // Create observable from window to know if the browser is connected or not
    const observable = merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );

    // Subscribe to the observable to set the networkStatus boolean on change
    const subscription = observable.subscribe(async (networkStatus) => {
      this.networkStatus = networkStatus;
    });
    this.subscription.add(subscription);
  }

  /**
   * Configure local storage for offline mode
   */
  public async initOfflineMode() {
    // Start checking if the key exists in the local storage and define it to false if she isn't
    const offlineMode = await this.localStorageService.get(
      this.offlineModeStorageKey
    );
    // If the value doesn't exist, create it
    if (offlineMode === null) {
      await this.localStorageService.set(this.offlineModeStorageKey, false);
    }
    // If online mode is set, restore data from local storage in memory
    if (offlineMode === true && this.offlineModeEntityManagerLoaded === false) {
      this.tcSpinnerService.showSpinner('localStorageToEntityManager');
      await this.localStorageToEntityManager();
      this.tcSpinnerService.hideSpinner('localStorageToEntityManager');
    }
  }

  /**
   * Set the offline mode : load entire data from DB into the localstorage
   */
  public async activateOfflineMode(): Promise<OfflineModeSyncResponse> {
    const startingDate: Date = new Date();
    try {
      const offline = await this.localStorageService.get(
        this.offlineModeStorageKey
      );
      if (offline === true) {
        throw Error('Offline mode is already activated.');
      }
      if ((await this.hasNetwork()) === false) {
        throw Error(
          'Connexion is needed to sync the backend data into the local storage.'
        );
      }
      const collections = await this.DBtoLocalCache();

      const endingDate: Date = new Date();
      return {
        success: true,
        startingDate,
        endingDate,
        collections,
      };
    } catch (e) {
      const endingDate: Date = new Date();
      await this.clearOfflineData();

      // Throw back the exception to have the initial error that was triggered and not lose why it has failed.
      console.error(e);

      return {
        success: false,
        startingDate,
        endingDate,
        errors: [
          {
            message: e.message,
          },
        ],
      };
    }
  }

  /**
   * End the offline mode : sync the data and clear the localstorage
   */
  public async terminateOfflineMode() {
    const startingDate: Date = new Date();
    try {
      const offline = await this.localStorageService.get(
        this.offlineModeStorageKey
      );
      if (offline === false) {
        throw Error('Offline mode is already terminated.');
      }
      if ((await this.hasNetwork()) === false) {
        throw Error(
          'Connexion is needed to sync the data from the local storage to the backend.'
        );
      }
      // Force the manager to sync the data with the DB and stop offline mode
      const manager = await this.getEntityManager();
      await manager.saveChanges();
      await this.clearOfflineData();

      const endingDate: Date = new Date();
      return {
        success: true,
        startingDate,
        endingDate,
      };
    } catch (e) {
      const endingDate: Date = new Date();
      return {
        success: false,
        startingDate,
        endingDate,
        errors: [
          {
            message: e.message,
          },
        ],
      };
    }
  }

  /**
   * Check if the application is in offline mode or not : true mode is activated
   */
  public async hasOfflineMode(): Promise<boolean> {
    return await this.localStorageService.get(this.offlineModeStorageKey);
  }

  /**
   * Sync DB to entityManager local cache
   */
  private async DBtoLocalCache(): Promise<
    {
      name: string;
      number: number;
    }[]
  > {
    const collections = await this.getCollectionsFromMetadata();
    const resultCollections: {
      name: string;
      number: number;
    }[] = [];
    // Load all the data in the entity manager memory
    for (const collection of collections) {
      const manager = await this.getEntityManager();
      let query = EntityQuery.from(collection);
      const queryResults = await manager.executeQuery(query);
      await this.updateCacheAndGetEntities(collection, queryResults.results);
      resultCollections.push({
        name: collection,
        number: queryResults.results.length,
      });
    }
    // Once the data is here, put it in local storage
    await this.backupOfflineData();

    // If everything is done, put the offline mode flag to true
    await this.localStorageService.set(this.offlineModeStorageKey, true);
    return resultCollections;
  }

  /**
   * Put the data into the cache and return the entities from entityManager
   * @param collection Collection name
   * @param results Result of the query
   * @returns Entity[]
   */
  public async updateCacheAndGetEntities(
    collection: string,
    results: any[]
  ): Promise<Entity[]> {
    // Add the loaded items into the internal cache of Breeze if they are in the metadata
    const hasMetadata = await this.hasMetadata(collection);
    const entities: Entity[] = [];
    if (hasMetadata) {
      for (let result of results) {
        const entity = await this.getEntityById(collection, result._id);
        entities.push(entity);
        // Will create the Breeze entity in the cache the first time a query is loading the object
        if (!entity) {
          // If the element don't have a id, set it (case of view without id but breeze need one to garantee unicity)
          if (!result._id) {
            result = { ...result, _id: this.getObjectId() };
          }
          const newEntity = await this.createEntity(
            collection,
            { ...result },
            EntityState.Unchanged
          );
          entities.push(newEntity);
        }
      }
    } else {
      // Warn to say that no metadata was present for an object. (Can happen in case of views).
      console.warn('No metadata for ' + collection);
    }

    return entities;
  }

  /**
   * Get the collections declared in the metadata
   */
  public async getCollectionsFromMetadata(): Promise<string[]> {
    const manager = await this.getEntityManager();
    const collections = [];
    Object.keys(manager.metadataStore._resourceEntityTypeMap).map(function (
      index
    ) {
      const collection = manager.metadataStore._resourceEntityTypeMap[index];
      collections.push(collection);
    });
    return collections;
  }

  /**
   * Restore the data from the local storage into the entityManager
   */
  private async localStorageToEntityManager() {
    const metadata = await this.localStorageService.get(
      this.metaDataStorageKey
    );
    this.masterManager.metadataStore.importMetadata(metadata);
    const collections = await this.getCollectionsFromMetadata();
    for (const collection of collections) {
      const collectionData = await this.localStorageService.get(collection);
      this.masterManager.importEntities(collectionData);
    }

    // Tells the service the data has been pushed into entityManager (to avoid unserialize at each request)
    this.offlineModeEntityManagerLoaded = true;
  }

  /**
   * Backup the data from the entityManager into the local storage
   */
  private async backupOfflineData(collections?: string[]) {
    const metaData = await this.masterManager.metadataStore.exportMetadata();
    this.localStorageService.set(this.metaDataStorageKey, metaData);
    if (!collections) {
      collections = await this.getCollectionsFromMetadata();
    }
    for (const collection of collections) {
      const entityType =
        this.masterManager.metadataStore.getAsEntityType(collection);
      const entityTypes = [entityType];
      const data = this.masterManager.exportEntities(entityTypes, {
        asString: true,
        includeMetadata: false,
      });
      if (typeof data === 'string') {
        this.localStorageService.set(collection, data);
      } else {
        throw Error('Sync : unable to save data for local storage.');
      }
    }
  }

  /**
   * Cleanup offline data from local storage (only keys that are used actively by Breeze)
   * @param collections Array of collection names
   */
  private async clearOfflineData(collections?: string[]) {
    // Iterate on all wanted collections
    if (!collections) {
      collections = await this.getCollectionsFromMetadata();
    }
    if (collections.length > 0) {
      for (const collection of collections) {
        // Remove the data
        await this.localStorageService.remove(collection);
      }
    }

    // Cleanup metadata key
    await this.localStorageService.remove(this.metaDataStorageKey);

    // Set the flag to use offline mode to false
    await this.localStorageService.set(this.offlineModeStorageKey, false);
  }

  /**
   * Return the network status : true => online, false => offline
   * @returns boolean
   */
  public hasNetwork(): boolean {
    return this.networkStatus;
  }

  /**
   * Return an valid id for mongo based on system time
   * @returns An BSON ObjectId usable in mongo.
   */
  public getObjectId() {
    return new ObjectID();
  }

  /**
   * Get a new manager from breeze
   */
  public async getEntityManager(): Promise<EntityManager> {
    if (this.masterManager.metadataStore.isEmpty()) {
      await this.masterManager.fetchMetadata();
    }
    return this.masterManager;
  }

  /**
   * Check if the metadata is set for one collection
   * @param collection Collection name
   * @returns boolean
   */
  public async hasMetadata(collection: string): Promise<boolean> {
    if (this.masterManager.metadataStore.isEmpty()) {
      await this.masterManager.fetchMetadata();
    }
    // We try to generate the entity from the metadatastore. If she's here, then, metadata is set for the collection
    const entity = this.masterManager.metadataStore.getAsEntityType(
      collection,
      true
    );
    return entity ? true : false;
  }

  /**
   * Check if the collection as an _id field declared in the metadata
   * @param collection Collection name
   * @returns boolean
   */
  public async hasIdColumn(collection: string): Promise<boolean> {
    if (this.masterManager.metadataStore.isEmpty()) {
      await this.masterManager.fetchMetadata();
    }
    const entity = this.masterManager.metadataStore.getAsEntityType(
      collection,
      true
    );

    if (
      entity &&
      entity['dataProperties'].find((item) => item.name === '_id')
    ) {
      return true;
    }
    return false;
  }

  /**
   * Create an entity inside the entity manager. By default, it will consider that the data must be added to database at next sync.
   * @param collection The collection name
   * @param data The default values of the data
   * @param state The default state of the entity
   * @returns
   */
  public async createEntity(
    collection: string,
    data,
    state: EntityState = EntityState.Added,
    mergeStategy?: MergeStrategy
  ): Promise<Entity> {
    const manager = await this.getEntityManager();
    return manager.createEntity(collection, data, state, mergeStategy);
  }

  /**
   * Return an entity from the entityManager based on his primary id
   * @param collection Name of the collection
   * @param id Identifier of the collection
   */
  public async getEntityById(collection: string, id: string): Promise<Entity> {
    const manager = await this.getEntityManager();
    const type = manager.metadataStore.getAsEntityType(collection);
    const key = new EntityKey(type, id);
    return manager.getEntityByKey(key);
  }

  /**
   * Send to the database the objects modified in EntityManager
   */
  public async sync(collection: string) {
    if (await this.hasOfflineMode()) {
      // In case of changes and offline mode is activated, sync the data inside the local storage
      const collections = [collection];
      this.backupOfflineData(collections);
    } else {
      const manager = await this.getEntityManager();
      try {
        await manager.saveChanges();
      } catch (error) {
        this.saveChangesErrorHandler(error);
      }
    }
  }

  /**
   * Special error handler for saveChanges process
   * @param error Exception
   */
  private saveChangesErrorHandler(error) {
    // Log to console the entities error because the entityErrors
    // is lost before reaching the tc-error-handler somewhere.
    if (isDevMode() && error.entityErrors) {
      console.error('Full entityErrors object ', error.entityErrors);
      error.entityErrors.forEach((error) => {
        console.error(error?.errorMessage, error);
      });
    }

    // Preserve the initial behaviour
    throw error;
  }
}
