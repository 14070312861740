import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { Subscription } from 'rxjs';

import { StatistiquesVersion } from '../../interfaces/statistiques-version.interface';
import { getStatistiquesVersionData } from '../../store/statistiques.selectors';

@Component({
  selector: 'app-statistiques-version',
  templateUrl: './statistiques-version.component.html',
  styleUrls: ['./statistiques-version.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatistiquesVersionComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription = new Subscription();
  versionData: StatistiquesVersion[] = [];
  selectedYear: number = new Date().getFullYear();

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {
    const versionData$ = this.store$
      .select(getStatistiquesVersionData)
      .subscribe((data) => {
        this.versionData = data;
      });

    this.subscription?.add(versionData$);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
