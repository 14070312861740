import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { timer } from 'rxjs';
import { TcDateRangeValue } from '../../../interfaces/tc-daterange-value';
import { TcFilter } from '../../../interfaces/tc-filter';
import { TcFilterOptions } from '../../../interfaces/tc-filter-options';

@Component({
  selector: 'tc-daterange',
  templateUrl: './tc-daterange.component.html',
  styleUrls: ['./tc-daterange.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TcDateRangeComponent implements OnInit, AfterViewInit {
  @Input()
  item: TcFilter;

  @Output()
  changeRange = new EventEmitter<TcDateRangeValue & { format: string }>();

  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;

  public format = 'DD/MM/YYYY';
  public value: TcDateRangeValue;
  public options: TcFilterOptions = {
    showDropdowns: true,
  };

  private componentInited = false;

  async ngOnInit() {
    this.options = {
      ...this.options,
      ...(this.item.options || {}),
    };
    this.format = this.options?.format || this.format;

    if (this.options?.default?.start && this.options?.default?.end) {
      this.value = {
        start: this.options.default.start,
        end: this.options.default.end,
      };
    }
  }

  async ngAfterViewInit() {
    await timer(500).toPromise();

    this.componentInited = true;

    this.preventOpenFromClick();
  }

  public onChangeValue() {
    this.emitValue(this.value);
  }

  public openDatepicker() {
    this.pickerDirective.open(true);
  }

  public applyFilterSubject(value) {
    if (!value) {
      this.changeRange.emit(null);
      return;
    }

    const [startValue, endValue] = value.split(' - ');

    const start = moment(startValue, this.format);
    const end = moment(endValue, this.format);

    if (start.isValid() && end.isValid()) {
      this.emitValue({ start, end });
    }
  }

  public reset() {
    this.value = null;
    // this.changeRange.emit(null);
  }

  private emitValue(value: TcDateRangeValue) {
    if (!this.componentInited) {
      return;
    }

    this.changeRange.emit({ ...value, format: this.format });
  }

  /*
    Overwrite picker open method (https://github.com/fetrarij/ngx-daterangepicker-material/blob/dc55667870baa0c8968fed78a255ce0dcbeab48d/src/daterangepicker/daterangepicker.directive.ts#L240)
    to prevent opening when a flag is not sent
  */
  private preventOpenFromClick() {
    const oldOpen = this.pickerDirective.open;
    this.pickerDirective.open = (shouldOpen) => {
      if (!shouldOpen) {
        return;
      }
      oldOpen.call(this.pickerDirective);
    };
  }
}
