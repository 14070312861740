import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../services/permissions.service';
import { initMenu } from '../../menu/store/menu.actions';
import { loadTcPermissions, loadTcPermissionsSuccess } from '@tc/permissions';

@Injectable()
export class PermissionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly permissionsService: PermissionsService
  ) {}

  loadPermissions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissions),
        tap(async ({ userRoles }) => {
          const rightsConfig = await this.permissionsService.getPermissions();

          const permissionsForRoles = [];
          for (const configuration of rightsConfig.configuration) {
            // Add the permissions associated with the roles to the total permissions of the logged user.
            if (
              userRoles.some((userRole) =>
                configuration.roles.includes(userRole)
              )
            ) {
              permissionsForRoles.push(...configuration.permissions);
            }
          }

          this.store$.dispatch(
            loadTcPermissionsSuccess({
              rights: rightsConfig,
              authenticatedUserPermissions: permissionsForRoles,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * after the permissions are set into the store, call the action to initialize the menu
   */
  loadTcPermissionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissionsSuccess),
        tap(() => {
          this.store$.dispatch(initMenu());
        })
      ),
    { dispatch: false }
  );
}
