import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { mergeMap, tap } from 'rxjs/operators';
import { TermsService } from '../../../services/business-services/terms.service';
import { GenericRoutes } from '../../../shared/typings/generic-routes';
import { loginFailure, loginSuccess } from '../../auth/store/auth.actions';
import { TermsOfUse } from '../interfaces/terms-of-use.interface';
import {
  acceptTerms,
  lastTermsAccepted,
  openTermsOfUsePage,
  saveTermsToValidate,
  verifyTerms,
} from './terms-conditions.actions';

@Injectable()
export class TermsConditionsEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly store$: Store<TcAppState>,
    private readonly termsService: TermsService
  ) {}

  verifyTerms = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyTerms),
      mergeMap(() =>
        this.termsService
          .checkLast()
          .then((options: TermsOfUse) => {
            if (options?.id) {
              this.store$.dispatch(saveTermsToValidate({ options }));
              return openTermsOfUsePage();
            }

            this.store$.dispatch(lastTermsAccepted());

            return loginSuccess();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  openTermsOfUsePage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openTermsOfUsePage),
        tap(() => {
          this.router.navigateByUrl(`/${GenericRoutes.TermsOfUse}`);
        })
      ),
    { dispatch: false }
  );

  acceptTerms = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptTerms),
      mergeMap(({ id }) =>
        this.termsService
          .accept(id)
          .then(() => {
            this.store$.dispatch(lastTermsAccepted());

            return loginSuccess();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );
}
