import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { LoginMethod } from '../../../enums/login-method.enum';
import { UserLoginFormOptions } from '../../../interfaces/user-login.interface';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends TcFormComponent<any> implements OnInit {
  @Input() method: LoginMethod = LoginMethod.Password;

  @Output() login: EventEmitter<UserLoginFormOptions> = new EventEmitter();

  public readonly loginMethod = LoginMethod;
  public form: FormGroup;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService
  ) {
    super(translate, elem);

    this.initFormFields();
  }

  ngOnInit() {
    this.model = { username: null, password: null };
  }

  public verify() {
    if (this.method !== LoginMethod.Password) {
      this.login.emit({ mode: this.method });

      return;
    }

    if (this.form.valid) {
      this.login.emit({
        username: this.form.value.username,
        password: this.form.value.password,
        mode: LoginMethod.Password,
      });
    }
  }

  private initFormFields() {
    this.fields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'text',
          label: this.translate.instant('username')
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
          label: this.translate.instant('password')
        },
      },
    ];
  }
}
