import { TcComponent } from './tc-component';

/**
 * Base class for all tc dumb components
 */
export class TcDumbComponent extends TcComponent {
  constructor() {
    super();
  }
}
