import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'tc-grid-html-renderer',
  templateUrl: './tc-grid-html-renderer.component.html',
  styleUrls: ['./tc-grid-html-renderer.component.scss'],
})
export class TcGridHtmlRendererComponent implements AgRendererComponent {
  public html: string;

  agInit(params) {
    this.html = params?.value;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
