import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tc-long-text',
  templateUrl: './tc-long-text.component.html',
  styleUrls: ['./tc-long-text.component.css'],
})
export class TcLongTextComponent implements OnInit {
  @Input() text: string;
  @Input() width: string;

  constructor() {}

  ngOnInit() {}
}
