import { saveAs } from 'file-saver';

export function downloadFile(
  name: string,
  content: string,
  options?: any,
  type: string = 'text/csv;charset=UTF-8',
  bom = true
) {
  const params = options ?? { type };
  const blob = new Blob([ bom ? '\uFEFF' + content : content], params);
  saveAs(blob, name);
}
