import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { getVersionDetails } from '../../home/store/home.selectors';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-referential-detail',
  templateUrl: './product-referential-detail.component.html',
  styleUrls: ['./product-referential-detail.component.scss'],
})
export class ProductReferentialDetailComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  public version: string;
  public versionDate: string;
  versionTitle: string;
  versionDateTitle: string;
  subscription: Subscription;

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {
    this.versionTitle = this.translate.instant(
      'product-referential-detail.version-title'
    );
    this.versionDateTitle = this.translate.instant(
      'product-referential-detail.version-date-title'
    );

    this.subscription = this.store$
      .select(getVersionDetails)
      .subscribe((versionDetails) => {
        const { version, date } = versionDetails;

        this.version = 'v' + version;
        this.versionDate = moment(date).format('DD/MM/YYYY');
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
