import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { openTermsOfUsePage } from '../terms-conditions/store/terms-conditions.actions';
import { getTermsAccepted } from '../terms-conditions/store/terms-conditions.selectors';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard implements CanActivate {
  constructor(private readonly store$: Store<TcAppState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.store$.pipe(
      select(getTermsAccepted),
      map((termsAccepted) => {
        if (!termsAccepted) {
          this.store$.dispatch(openTermsOfUsePage());
          return false;
        }

        return true;
      })
    );
  }
}
