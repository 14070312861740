import { createReducer, on } from '@ngrx/store';

import {
  getIndicateursEtatDetailsSuccess,
  loadIndicatorAssociationDetailsSuccess,
  setIndicatorToUpdate,
  setTicadiIndicatorsToModify,
  clearTicadiIndicatorsToModify,
  setTicadiIndicatorInitialValuesToUpdate,
  setTicadiIndicatorsValuesToUpdate,
  setInitialValuesVisible,
} from './indicators.actions';
import { IndicatorsState, initialIndicatorsState } from './indicators.state';

export const indicatorsReducer = createReducer<IndicatorsState>(
  initialIndicatorsState,
  on(getIndicateursEtatDetailsSuccess, (state, { payload }) => ({
    ...state,
    associationId: payload.associationId,
    currentYear: payload.year
  })),
  on(loadIndicatorAssociationDetailsSuccess, (state, { payload }) => ({
    ...state,
    associationCodeDetails: payload.associationCodeDetails,
    associationNameDetails: payload.associationNameDetails,
  })),
  on(setIndicatorToUpdate, (state, { indicateur }) => ({
    ...state,
    indicatorToUpdate: indicateur,
  })),
  on(setTicadiIndicatorInitialValuesToUpdate, (state, { indicateur }) => ({
    ...state,
    ticadiIndicatorToUpdate: indicateur
  })),
  on(setTicadiIndicatorsValuesToUpdate, (state, { anneeIndicateur, trimestreIndicateur }) => ({
    ...state,
    ticadiIndicatorsToUpdate: {
      anneeIndicateur,
      trimestreIndicateur
    }
  })),
  on(setTicadiIndicatorsToModify, (state, { indicators, trimestre }) => ({
    ...state,
    stateIndicators: {
      indicators,
      trimestre
    }
  })),
  on(clearTicadiIndicatorsToModify, (state) => ({
    ...state,
    stateIndicators: {
      indicators: [],
      trimestre: undefined
    }
  })),
  on(setInitialValuesVisible, (state, { initialValuesVisible }) => ({
    ...state,
    initialValues: initialValuesVisible
  }))
);
