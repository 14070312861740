import { StatistiquesVersion } from '../interfaces/statistiques-version.interface';
import { Statistiques } from '../interfaces/statistiques.interface';

export interface StatistiquesState {
  selectedYear: number;
  statistiquesData: Statistiques[];
  versionData: StatistiquesVersion[];
}

export const initialStatistiquesState: StatistiquesState = {
  selectedYear: new Date().getFullYear(),
  statistiquesData: [],
  versionData: [],
};
