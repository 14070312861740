import { IVersionState, SetVersionInfo } from '@tc/store';
import { OnInit, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import moment from 'moment';

@Directive()
export class AmsPage extends TcPage implements OnInit {
  versionInfo: IVersionState;

  constructor(protected store?: Store<any>) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.versionInfo = {
      currentDate: moment().toISOString(),
    };

    if (this.store) {
      this.store.dispatch(new SetVersionInfo(this.versionInfo));
    }
  }
}
