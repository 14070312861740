import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TcFilter } from '../../../interfaces/tc-filter';

@Component({
  selector: 'tc-simple-filter',
  templateUrl: './tc-simple-filter.component.html',
  styleUrls: ['./tc-simple-filter.component.css'],
})
export class TcSimpleFilterComponent implements OnInit, OnDestroy {
  searchTextChanged = new Subject<string>();
  searchSubscription: Subscription;

  @Input()
  item: TcFilter;

  @Output()
  search = new EventEmitter<any>();

  value: string;

  private minLength = 1;
  private debounceTime = 500;

  ngOnInit() {
    this.minLength = this.item?.options?.minLength || this.minLength;
    this.debounceTime = this.item?.options?.debounceTime || this.debounceTime;

    this.searchSubscription = this.searchTextChanged
      .pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        if (value?.length >= this.minLength || value === '') {
          this.search.emit(value);
        }
      });
  }

  applyFilterSubject(value) {
    this.searchTextChanged.next(value);
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}
