/**
 * Types of TcConfig
 */
export enum TcConfigTypes {
  TcList = 'TcList',
  TcPanelList = 'TcPanelList',
  TcGrid = 'TcGrid',
  TcForm = 'TcForm',
  TcFormField = 'TcFormField',
  TcFilter = 'TcFilter',
  TcDataProvider = 'TcDataProvider',
  TcDetailHeader = 'TcDetailHeader',
  TcDetailTitle = 'TcDetailTitle',
  TcDialogContent = 'TcDialogContent',
  TcDialogHeader = 'TcDialogHeader',
  TcDialogFooter = 'TcDialogFooter',
}
