import { Permission, UserRights } from '../typings';

export const DEFAULT_TC_PERMISSIONS_STATE_KEY = 'tc-permissions';

export interface TcPermissionsState {
  rights: UserRights;
  authenticatedUserPermissions: Permission[];
}

export const initialTcPermissionsState: TcPermissionsState = {
  rights: null,
  authenticatedUserPermissions: []
};
