import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';

export interface AssociationsState {
  associationsCategoriesStats: AmsStatsInterface[];
  associationsSoftwareStats: AmsStatsInterface[];
  cancelEditing?: boolean;
}

export const initialAssociationState: AssociationsState = {
  associationsCategoriesStats: [],
  associationsSoftwareStats: [],
  cancelEditing: false,
};
