import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, distinctUntilChanged, startWith } from 'rxjs/operators';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  emptyTcData,
  getTcData,
  loadTcData,
  NgRxTcDataState,
} from '@tc/data-store';
import { Store, select } from '@ngrx/store';
import { hasValue } from '@tc/utils';
import { FilterTypesEnum } from '@tc/abstract';
import { selectByKey } from '@tc/store';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { TcSmartAutocompleteOptions } from '../../../interfaces/tc-smart-autocomplete-options';
import { TcFormSmartFieldConfig } from '../../../abstract/tc-form-smart-field-config';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';

@Component({
  selector: 'tc-smart-autocomplete',
  templateUrl: './tc-smart-autocomplete.component.html',
  styleUrls: ['./tc-smart-autocomplete.component.scss'],
})
export class TcSmartAutocompleteComponent implements OnInit, OnDestroy {
  /**
   * Default options
   */
  private readonly defaultOptions: TcSmartAutocompleteOptions = {
    autocompleteMinLength: 3,
    maxOptionsNumber: 10,
    filterOptionsType: FilterTypesEnum.StartsWith,
    allowNewItem: false,
    disabled: false,
  };

  /**
   * Options
   */
  @Input() options: TcSmartAutocompleteOptions;

  /**
   * Smart filed config for conecting to the store
   */
  @Input() smartConfigs: TcFormSmartFieldConfig;

  /**
   * Selected item
   */
  @Input() selected: any;

  /**
   * Input cleared observable created by formly-clear-input-suffix-wrapper
   */
  @Input() inputClearedObservable: Observable<Symbol>;

  /**
   * The name of the input field. For testing purposes.
   */
  @Input() fieldName: string;

  /**
   * Event emitter on blur
   */
  @Output() blur = new EventEmitter<void>();

  /**
   * Event emitter that emits the current selected items
   */
  @Output() changeSelected = new EventEmitter<any>();

  /**
   * Event emitter that emits how the label should placed depending on the current input
   */
  @Output() changeFloat = new EventEmitter<string>();

  /**
   * The items from the data store
   */
  public items: any[];

  /**
   * Subscription to the data store for items
   */
  private itemsSubscription: Subscription;

  /**
   * Observable of the data store
   */
  private dataStore$: Observable<NgRxTcDataState>;

  /**
   * FormControl of the input in which we write
   */
  public itemControl = new FormControl();

  /**
   * Subscription to the itemsControl valueChanges
   */
  private itemControlSubscription: Subscription;

  private inputClearedSubscription: Subscription;

  @ViewChild('tcInput') tcInput: MatInput;

  @ViewChild(MatAutocompleteTrigger) private trigger: MatAutocompleteTrigger;

  constructor(
    private readonly store$: Store<any>,
    private readonly dialog: MatDialog
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnInit() {
    this.itemControl.setValue(this.selected);

    this.completeMissingOptions();

    this.itemControlSubscription = this.itemControl.valueChanges
      .pipe(startWith(''))
      .subscribe((value) => {
        this.onValueChange(value);
      });

    this.itemsSubscription = selectByKey(
      getTcData,
      this.dataStore$,
      this.smartConfigs.storeKey
    ).subscribe((options) => {
      this.items = options;
      if (this.items?.length > 0) {
        this.trigger?.openPanel();
      }
    });

    if (this.options.disabled) {
      this.itemControl.disable();
    }
  }

  ngAfterViewInit(): void {
    this.inputClearedSubscription = this.inputClearedObservable?.subscribe(
      (_: Symbol) => {
        this.itemControl.reset();
        this.tcInput?.focus();
      }
    );
  }

  /**
   * Completes the missing options with their default value
   */
  private completeMissingOptions() {
    this.options = {
      ...this.defaultOptions,
      ...this.options,
    };
  }

  /**
   * Called whent the input changes it's value
   */
  onValueChange(value) {
    if (typeof value === 'string' && value.length > 0) {
      this.changeSelected.emit(null);
      this.changeFloat.emit('always');
    } else {
      this.changeFloat.emit('auto');
    }

    if (value?.length >= this.options.autocompleteMinLength) {
      this.loadData(value);
    } else {
      this.items = [];
    }

    if (value?.length > 0 && this.options.allowNewItem) {
      this.changeSelected.emit(value);
    }
  }

  /**
   * Dispaches a load data action tot the store for items
   * @param value
   */
  loadData(value) {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.smartConfigs.storeKey,
        skip: 0,
        take: this.options.maxOptionsNumber,
        filter: {
          filters: [
            {
              key: this.smartConfigs.labelFieldName,
              value,
              filterType: this.options.filterOptionsType,
            },
          ],
        },
      })
    );
  }

  /**
   * Display function of the item
   */
  public displayFn(item): string | undefined {
    return (item && item[this.smartConfigs.labelFieldName]) || undefined;
  }

  /**
   * Called when an item is selected
   * @param event
   */
  public selectItem(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) this.changeSelected.emit(event.option.value);
    this.items = [];
  }

  /**
   * Called when the input gets blured
   */
  public onBlur() {
    this.blur.emit();

    if (this.options.showPromptIfNotSelected) {
      setTimeout(() => {
        if (!this.selected && this.itemControl.value) {
          // Open a prompt dialog
          this.dialog.open(TcPromptDialogComponent, {
            width: '33rem',
            data: {
              text: this.options.showPromptMessage,
              displayConfirmButton: false,
              displayCancelButton: false,
            },
          });
        }
      }, 200);
    }
  }

  /**
   * Loads autocomplete options based on the input's value on focus
   */
  public onFocus() {
    if (this.itemControl.value?.length >= this.options.autocompleteMinLength) {
      this.loadData(this.itemControl.value);
    }
  }

  /**
   * Empties the autocomplete options after the user no-longer has the input selected
   * NOTE: we need to wait for a brief moment in case the user selects a value from the
   *       autocomplete options, which also count as a focus out in order for the selected
   *       option to actually get selected.
   */
  public onFocusOut() {
    setTimeout(() => {
      if (this.smartConfigs?.storeKey) {
        this.store$.dispatch(
          emptyTcData({ storeKey: this.smartConfigs.storeKey })
        );
      }
      this.trigger?.closePanel();
    }, 100);
  }

  ngOnDestroy() {
    this.itemsSubscription?.unsubscribe();
    this.itemControlSubscription?.unsubscribe();
    this.inputClearedSubscription?.unsubscribe();

    if (this.smartConfigs?.storeKey) {
      this.store$.dispatch(
        emptyTcData({ storeKey: this.smartConfigs.storeKey })
      );
    }
  }
}
