import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IndicatorsState } from './indicators.state';

const getIndicatorsState = createFeatureSelector<IndicatorsState>('indicators');

export const getStateIndicators = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.stateIndicators
);

export const getCurrentYear = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.currentYear
);

export const getIndicatorsAssociation = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.associationId
);

export const getCurrentTrimestre = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.currentTrimestre
);

export const isInitialValues = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.initialValues
);

export const getAssociationNameDetails = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.associationNameDetails
);

export const getAssociationCodeDetails = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.associationCodeDetails
);

export const getIndicatorToUpdate = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.indicatorToUpdate
);

export const getTicadiIndicatorToUpdate = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.ticadiIndicatorToUpdate
)

export const getTicadiIndicatorsToModify = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.stateIndicators
);

export const getTicadiIndicatorDetailsToUpdate = createSelector(
  getIndicatorsState,
  (state: IndicatorsState) => state?.ticadiIndicatorsToUpdate
);