import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TcSmartComponent } from '@tc/abstract';
import { Subscription } from 'rxjs';
import { Store, createSelector, select } from '@ngrx/store';
import { createEntityCacheSelector, EntityCache } from 'ngrx-data';
import { TcAppState } from '@tc/store';

@Component({
  selector: 'tc-entity-reference',
  templateUrl: './tc-entity-reference.component.html',
  styleUrls: ['./tc-entity-reference.component.css'],
})
export class TcEntityReferenceComponent extends TcSmartComponent
  implements OnInit, OnDestroy {
  @Input()
  entityName: string;

  @Input()
  entityId: number;

  @Input()
  displayProperties: string;

  displayValue = '';

  itemsSubscription: Subscription;

  constructor(store: Store<TcAppState>) {
    super(store);
  }

  ngOnInit() {
    const getItems = createSelector(
      createEntityCacheSelector(),
      (state: EntityCache) => state[this.entityName]
    );

    this.itemsSubscription = this.store$
      .pipe(select(getItems))
      .subscribe(items => {
        if (items && items.entities) {
          const item = items.entities[this.entityId];
          if (item) {
            this.displayValue = this.setDisplayValue(
              item,
              this.displayProperties
            );
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

  setDisplayValue = (item: any, valueName?: string): string => {
    if (valueName) {
      const result = [];
      const values = valueName.split(',');
      values.forEach(value => {
        result.push(item[value]);
      });
      return result.join(' ');
    } else {
      return item.name ? item.name : '';
    }
  };
}
