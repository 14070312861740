import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TcMenuItem } from '../../interfaces/tc-menu-item';
import { TcGenericEntity } from '../../abstract/tc-generic-entity.interface';

@Component({
  selector: 'tc-menu',
  templateUrl: './tc-menu.component.html',
  styleUrls: ['./tc-menu.component.css'],
})
export class TcMenuComponent implements OnInit {
  @Input() items: TcMenuItem[] = [];
  @Input() class?: string;
  @ViewChild('tcMenu', { static: true }) public tcMenu;

  menuName: string;

  constructor(elem: ElementRef) {
    this.menuName = elem.nativeElement.id;
  }

  /**
   * Classes who will be added to the mat-menu tag in HTML. Usefull when you want to style a specific menu without touching all others menus.
   */
  public classes = 'tc-menu';

  ngOnInit() {
    // If input custom class has been provided, we add it to the classes property with a leading space
    if (this.class) {
      this.classes += ' ' + this.class;
    }

    if (this.menuName) {
      this.items.map((m) => {
        if (!m.displayName) {
          m.displayName = this.menuName + '.' + m.name;
        }
      });
    }
  }

  onMenuItemClick(item: TcMenuItem, data: TcGenericEntity) {}
}
