export interface HomeState {
  version: string;
  date: string;
  associationsToBeValidated: number;
}

export const initialHomeState: HomeState = {
  version: '',
  date: '',
  associationsToBeValidated: 0,
};
