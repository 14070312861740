import {
  Component,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-generic-panel',
  templateUrl: './formly-generic-panel.component.html',
  styleUrls: ['./formly-generic-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyGenericPanelComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
}
