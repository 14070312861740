import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import {
  selectSettingsLanguage,
  ActionSettingsChangeLanguage,
} from '../../settings';

@Component({
  selector: 'tc-language',
  templateUrl: './tc-language.component.html',
  styleUrls: ['./tc-language.component.scss'],
})
export class TcLanguageComponent implements OnInit {
  @Input() languages = [];

  language$: Observable<string>;

  constructor(private store: Store<TcAppState>) {}

  ngOnInit() {
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }
}
