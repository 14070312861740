import moment from 'moment';

/**
 * The purpose of this method it to flatten the whole model to have the comptued key with the corresponding value.
 * The model in formly will contain objects if the key has a dot inside. Example : myField._id will appear as {myField: {_id: 1}} in the model.
 * This recursive method will flatten everything to have only one key / value for each filter : [{key: "myField._id", value: "1"}]
 * @param model The current form model to iterate on
 * @param values The result array for each recursive call
 * @param previousKey The last used key on the iteration
 */
export function flattenModel(
  model,
  values = [],
  previousKey = null
): { key: string; value: any }[] {
  for (const key in model) {
    const valueKey = previousKey ? previousKey + '.' + key : key;
    if (typeof model[key] === 'object' && !Array.isArray(model[key])) {
      // If this is a moment object, convert it into a date
      if (moment.isMoment(model[key])) {
        values.push({
          key: valueKey,
          value: moment(model[key]).toISOString(),
        });
      } else {
        // If it's another object, iterate on it with recursive call
        values = flattenModel(model[key], values, valueKey);
      }
    } else {
      // If it's a value, push it directly if she's not already present
      if (!values.find((i) => i.key === valueKey)) {
        values.push({
          key: valueKey,
          value: model[key],
        });
      }
    }
  }

  return values;
}
