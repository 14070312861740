import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HideSpinner, ShowSpinner } from '../actions';
import { TcService } from '@tc/abstract';
import { TcAppState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class TcSpinnerService extends TcService {
  constructor(protected tcAppStore: Store<TcAppState>) {
    super();
  }

  /**
   * This method is used to show the loading spinner for an action.
   * @param action Required. The action name.
   */
  showSpinner(action: string) {
    this.tcAppStore.dispatch(new ShowSpinner());
  }

  /**
   * This method is used to hide the loading spinner for an action.
   * @param action Required. The action name.
   */
  hideSpinner(action: string) {
    this.tcAppStore.dispatch(new HideSpinner());
  }

  /**
   * This method is used to reset the loading spinner to it's initial state.
   * Used when there's been an http error that doesn't allow the code to reach the hideSpinner function call
   */
  resetSpinner() {
    this.tcAppStore.dispatch(new HideSpinner());
  }
}
