import { Injectable } from '@angular/core';
import { RestApiRoutes } from './types/rest-api-route';

@Injectable({
  providedIn: 'root',
})
export abstract class ITcRestApiRoutesProvider {
  /**
   * Returns an object that defines API routes for each specified type, in the form:
   * {
   *    'my-defined-type': /my-defined-type/{id},
   *    'my-defined-types': /my-defined-type/get-all-data
   * }
   * 
   * The class needs to be provided with an actual implementation at runtime
   */
  abstract getRoutes(): RestApiRoutes;
}
