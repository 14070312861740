import { TcMenuItem } from './../interfaces/tc-menu-item';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class TcCurrentUserMenuService {
  items: TcMenuItem[] = [];

  initMenu(items: TcMenuItem[]) {
    this.items = items;
  }

  getMenu() {
    return this.items;
  }

  addItem(item: TcMenuItem) {
    this.items.push(item);
  }

  addItems(items: TcMenuItem[]) {
    this.items.push(...items);
  }
}
