import { createAction, props } from '@ngrx/store';
import { InitTcPanelListPayload } from './tc-panel-list-payload';

/**
 * the prefix for all the tc panel list actions
 */
export const TC_PANEL_LIST_ACTIONS_PREFIX: string = '[tc-panel-list]';

/**
 * the action to init tc panel list
 */
export const initTcPanelList = createAction(
  `${TC_PANEL_LIST_ACTIONS_PREFIX}/Init`,
  props<InitTcPanelListPayload>()
);
