import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-statistiques-filter',
  templateUrl: './statistiques-filter.component.html',
  styleUrls: ['./statistiques-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatistiquesFilterComponent extends TcFormComponent<any> {
  gridStoreKey = 'statistiques-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }
}
