import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { TcSmartListDetailEffects } from './store';

const components = [];
@NgModule({
  declarations: [...components],
  imports: [CommonModule, EffectsModule.forFeature([TcSmartListDetailEffects])],
  exports: [...components],
})
export class TcSmartListDetailsModule {}
