import { createAction, props } from '@ngrx/store';

import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { BonLivraisonLog } from '../interfaces/bon-livraison-log.interface';

export const loadReceivedDeliveryNoteHeaderInfo = createAction(
  '[Received Delivery Notes] Load BLRecu Header Information',
  props<{ receivedDeliveryNote: BonLivraisonLog }>()
);

export const loadNumeroBLDetsSuccess = createAction(
  '[Received Delivery Notes] Load Received Delivery Note BL Number Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadEnvoyeLeDetsSuccess = createAction(
  '[Received Delivery Notes] Load Received Delivery Note Sent On Details Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadBanqueBLDetsSuccess = createAction(
  '[Received Delivery Notes] Load Received Delivery Note Banquee Details Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadAssociationBLDetsSuccess = createAction(
  '[Received Delivery Notes] Load Received Delivery Note Association Details Success',
  props<{ payload: AmsStatsInterface[] }>()
);

export const loadResultantBLDetsSuccess = createAction(
  '[Received Delivery Notes] Load Received Delivery Note Resultant Details Success',
  props<{ payload: AmsStatsInterface[] }>()
);
