import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcFilterDef, TcFilterItem } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { DEFAULT_TC_DATA_STATE_KEY, getTcDataFilters } from '@tc/data-store';
import { selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

import { Trimestre } from '../../indicators/typings/trimestre.enum';
import { StatistiquesFilterParams } from '../interfaces/statistiques-filter-params.interface';
import { Statistiques } from '../interfaces/statistiques.interface';
import { StatistiquesIndicatorType } from '../types/statistiques-indicator-type.enum';
import { StatistiquesService } from './../services/statistiques.service';
import {
  loadStatistiquesData,
  loadStatistiquesDataSuccess,
  loadStatistiquesSelectedYear,
  loadStatistiquesVersions,
  loadStatistiquesVersionsSuccess,
} from './statistiques.actions';
import { getSelectedYear } from './statistiques.selectors';

@Injectable()
export class StatistiquesEffects {
  storeKey = 'statistiques-grid';

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly translateService: TcTranslateService,
    private readonly statistiquesService: StatistiquesService
  ) {}

  loadStatistiquesData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadStatistiquesData),
        tap(async ({ statistiquesFilter }) => {
          const filters = statistiquesFilter?.filters;

          const selectedYear = await this.store$
            .select(getSelectedYear)
            .pipe(take(1))
            .toPromise();

          const { annee, banqueId, categorie, equipeeDeLogiciel } =
            await this.generateStatistiquesParams(filters);

          if (annee !== selectedYear) {
            this.store$.dispatch(
              loadStatistiquesSelectedYear({ selectedYear: annee })
            );
          }

          const statistiquesData: Statistiques[] = [];

          statistiquesData.push(
            await this.updateIndicatorValue(
              StatistiquesIndicatorType.NbAssocViaOutil,
              annee,
              banqueId,
              categorie,
              equipeeDeLogiciel
            )
          );
          statistiquesData.push(
            await this.updateIndicatorValue(
              StatistiquesIndicatorType.NbAssocAyant,
              annee,
              banqueId,
              categorie,
              equipeeDeLogiciel
            )
          );
          statistiquesData.push(
            await this.updateIndicatorValue(
              StatistiquesIndicatorType.NbAssocIndValides,
              annee,
              banqueId,
              categorie,
              equipeeDeLogiciel
            )
          );
          statistiquesData.push(
            await this.updateIndicatorValue(
              StatistiquesIndicatorType.NbAssociationsIndNonRecus,
              annee,
              banqueId,
              categorie,
              equipeeDeLogiciel
            )
          );

          this.store$.dispatch(
            loadStatistiquesDataSuccess({ statistiquesData })
          );

          this.store$.dispatch(
            loadStatistiquesVersions({ banqueId: banqueId || -1 })
          );
        })
      ),
    { dispatch: false }
  );

  loadStatistiquesVersions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadStatistiquesVersions),
        tap(async ({ banqueId }) => {
          const versionData =
            await this.statistiquesService.getStatistiquesVersions(banqueId);

          this.store$.dispatch(
            loadStatistiquesVersionsSuccess({ versionData })
          );
        })
      ),
    { dispatch: false }
  );

  private async updateIndicatorValue(
    indicatorType: StatistiquesIndicatorType,
    annee: number = null,
    banqueId: number = null,
    categorie: string = null,
    equipeeDeLogiciel: boolean = null
  ): Promise<Statistiques> {
    const statistic: Statistiques = { indicatorType };
    const numOfPeriods = 5; // Period annee included in loop

    for (let i = 1; i <= numOfPeriods; i++) {
      const periode = this.getPeriode(i);

      const result = await this.getIndicateurValeur(
        indicatorType,
        periode,
        annee,
        banqueId,
        categorie,
        equipeeDeLogiciel
      );

      const value = (result as any)?.count;

      switch (periode) {
        case Trimestre.T1: {
          statistic.t1 = value;
          break;
        }
        case Trimestre.T2: {
          statistic.t2 = value;
          break;
        }
        case Trimestre.T3: {
          statistic.t3 = value;
          break;
        }
        case Trimestre.T4: {
          statistic.t4 = value;
          break;
        }
        case Trimestre.Annee: {
          statistic.annee = value;
          break;
        }
      }
    }

    return statistic;
  }

  private async getIndicateurValeur(
    indicatorType: StatistiquesIndicatorType,
    periode: Trimestre,
    annee: number,
    banqueId: number,
    categorie: string,
    equipeeDeLogiciel: boolean
  ): Promise<number> {
    switch (indicatorType) {
      case StatistiquesIndicatorType.NbAssocViaOutil: {
        if (periode !== Trimestre.Annee) {
          return await this.statistiquesService.getNbAssocViaOutilTrimestre(
            periode.valueOf(),
            annee,
            banqueId,
            equipeeDeLogiciel,
            categorie
          );
        } else {
          return await this.statistiquesService.getNbAssocViaOutil(
            annee,
            banqueId,
            equipeeDeLogiciel,
            categorie
          );
        }
      }
      case StatistiquesIndicatorType.NbAssocAyant: {
        return await this.statistiquesService.getNbAssocAyant(
          periode === Trimestre.Annee
            ? Trimestre.T4.valueOf()
            : periode.valueOf(),
          annee,
          banqueId,
          equipeeDeLogiciel,
          categorie
        );
      }
      // TIC-957 Ecran statistique : Nombre d’associations avec indicateurs Etat validés
      // Pour asso sur Ticadi,
      //    Pour T4: comptabiliser uniquement les assoco ayant validé les IE T4
      //    Pour année: comptabiliser uniquement les assoco ayant validé les IE Année
      //   send Annee period instead of T4 for backecnd to decide the result
      case StatistiquesIndicatorType.NbAssocIndValides: {
        return await this.statistiquesService.getNbAssocIndValides(
          periode.valueOf(),
          annee,
          banqueId,
          equipeeDeLogiciel,
          categorie
        );
      }
      case StatistiquesIndicatorType.NbAssociationsIndNonRecus: {
        return await this.statistiquesService.getNbAssociationsIndNonRecus(
          periode === Trimestre.Annee
            ? Trimestre.T4.valueOf()
            : periode.valueOf(),
          annee,
          banqueId,
          equipeeDeLogiciel,
          categorie
        );
      }
    }
  }

  private getPeriode(num: number): Trimestre {
    switch (num) {
      case 1: {
        return Trimestre.T1;
      }
      case 2: {
        return Trimestre.T2;
      }
      case 3: {
        return Trimestre.T3;
      }
      case 4: {
        return Trimestre.T4;
      }
      case 5: {
        return Trimestre.Annee;
      }
    }
  }

  private async generateStatistiquesParams(
    filters: TcFilterItem[]
  ): Promise<StatistiquesFilterParams> {
    const selectedYear = await this.store$
      .select(getSelectedYear)
      .pipe(take(1))
      .toPromise();

    const banqueId: number =
      filters?.filter((f) => f.key === 'banqueId').length > 0
        ? Number(filters.filter((f) => f.key === 'banqueId')[0].value)
        : null;
    const annee: number =
      filters?.filter((f) => f.key === 'annee').length > 0
        ? Number(filters.filter((f) => f.key === 'annee')[0].value)
        : selectedYear;
    const categorie: string =
      filters?.filter((f) => f.key === 'categorie').length > 0
        ? filters.filter((f) => f.key === 'categorie')[0].value
        : null;

    const equipeeDeLogicielFilter = filters?.filter(
      (f) => f.key === 'equipeeDeLogiciel'
    );
    const equipeeDeLogiciel: boolean =
      equipeeDeLogicielFilter?.length > 0 &&
      equipeeDeLogicielFilter[0].value !== ''
        ? Boolean(equipeeDeLogicielFilter[0].value)
        : null;

    return {
      banqueId,
      annee,
      equipeeDeLogiciel,
      categorie,
    };
  }

  private async getStatistiquesFilter() {
    const dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    const statistiquesFilter: TcFilterDef = await selectValueByKey(
      getTcDataFilters,
      dataStore$,
      this.storeKey
    );

    return statistiquesFilter;
  }
}
