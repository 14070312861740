import { createReducer, on } from '@ngrx/store';

import { loadBLDateDetailsSuccess, loadBLMainDetailsSuccess } from './delivery-notes.actions';
import { DeliveryNotesState, initialDeliveryNotesState } from './delivery-notes.state';

export const deliveryNotesReducer = createReducer<DeliveryNotesState>(
  initialDeliveryNotesState,
  on(loadBLMainDetailsSuccess, (state, { payload }) => ({
    ...state,
    blMainDetails: [...payload],
  })),
  on(loadBLDateDetailsSuccess, (state, { payload }) => ({
    ...state,
    blDateDetails: [...payload],
  }))
);
