import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';

import { SharedModule } from '../../shared/shared.module';
import {
  ReceivedDeliveryNoteArticlesGridComponent,
} from './components/received-delivery-note-details/received-delivery-note-details-articles-grid/received-delivery-note-articles-grid.component';
import {
  ReceivedDeliveryNoteDetailsComponent,
} from './components/received-delivery-note-details/received-delivery-note-details.component';
import {
  ReceivedDeliveryNotesFilterComponent,
} from './components/received-delivery-notes-filter/received-delivery-notes-filter.component';
import {
  ReceivedDeliveryNotesGridComponent,
} from './components/received-delivery-notes-grid/received-delivery-notes-grid.component';
import { ReceivedDeliveryNotesService } from './services/received-delivery-notes.service';
import { ReceivedDeliveryNotesEffects } from './store/received-delivery-notes.effects';
import { receivedDeliveryNotesReducer } from './store/received-delivery-notes.reducer';

const components = [
  ReceivedDeliveryNotesFilterComponent,
  ReceivedDeliveryNotesGridComponent,
  ReceivedDeliveryNoteDetailsComponent,
  ReceivedDeliveryNoteArticlesGridComponent,
];

const services = [ReceivedDeliveryNotesService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    TcDialogModule,
    EffectsModule.forFeature([ReceivedDeliveryNotesEffects]),
    StoreModule.forFeature(
      'received-delivery-notes',
      receivedDeliveryNotesReducer
    ),
    SharedModule,
    AgGridModule.withComponents([]),
  ],
  providers: [...services],
  exports: [...components],
})
export class ReceivedDeliveryNotesModule {}
