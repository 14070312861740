import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TcContentDescriptor } from '../interfaces/tc-content-descriptor';

@Injectable({
  providedIn: 'root',
})
export class TcContentService {
  private contentInit$ = new Subject<TcContentDescriptor>();

  contentInit(): Observable<TcContentDescriptor> {
    return this.contentInit$.asObservable();
  }

  registerContent(content: TcContentDescriptor) {
    this.contentInit$.next(content);
  }
}
