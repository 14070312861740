import {
  InsertTcGridEmptyRowPayload,
  LoadTcGridDataPayloadSuccess,
  UpdateTcGridRowPayloadSuccess,
  InitTcGridPayload,
  RefreshTcGridDataSuccessPayload,
  SetTcGridDetailsDialogIdPayload,
  SetTcGridSelectionPayload,
} from './../actions/tc-grid-payload';
import * as R from 'ramda';
import * as TcGridActions from '../actions/tc-grid-actions';
import { createReducer, on } from '@ngrx/store';
import { NgRxTcGridState, TcGridState } from '../state/tc-grid-state';
import { SetTcGridColumnsPayload } from '../actions/tc-grid-payload';
import { BaseTcStorePayload } from '@tc/abstract';

/**
 * initialNgRxTcGridState
 */
export const initialNgRxTcGridState: NgRxTcGridState = {};

/**
 * the initial state of tc grid
 */
export const initialTcGridState: TcGridState = {
  storeKey: null,
  columns: [],
  sort: null,
  gridOptions: {},
  selectedRows: []
};

/**
 * this handler will init tc grid in store
 */
const initTcGridHandler = (
  state: TcGridState,
  newState: InitTcGridPayload
): TcGridState => {
  const {
    storeKey,
    columns,
    columnNumberPerDevice,
    gridOptions,
    infiniteScrollPercent,
    addButton,
    cssClass,
  } = newState;
  return R.mergeRight(state, {
    columns,
    columnNumberPerDevice,
    storeKey,
    gridOptions,
    infiniteScrollPercent,
    addButton,
    cssClass,
  });
};

/**
 * this handler will set columns for tc grid in store
 */
const setTcGridColumnsHandler = (
  state: TcGridState,
  newState: SetTcGridColumnsPayload
): TcGridState => {
  const { storeKey, columns } = newState;
  return R.mergeRight(state, {
    columns,
    storeKey,
  });
};

/**
 * this handler will set data for tc grid in store
 */
const loadTcGridDataSuccessHandler = (
  state: TcGridState,
  newState: LoadTcGridDataPayloadSuccess
): TcGridState => {
  const { data, total, take, skip } = newState;
  return {
    ...state,
  };
};

/**
 * this handler will add more data for tc grid in store
 */
const loadTcGridMoreDataSuccessHandler = (
  state: TcGridState,
  newState: LoadTcGridDataPayloadSuccess
): TcGridState => {
  const { data, total, take, skip } = newState;
  return {
    ...state,
  };
};

/**
 * this handler will update row in tc grid store
 */
const updateTcGridRowSuccessHandler = (
  state: TcGridState,
  newState: UpdateTcGridRowPayloadSuccess
): TcGridState => {
  const id = newState.rowData.id;
  // const data = R.clone(state.data);
  // const index = R.findIndex(R.propEq('id', id))(data);

  // data[index] = newState.rowData;

  return {
    ...state,
  };
};

/**
 * this handler will insert an empty row in the grid, after add row button was clicked and a component was not provided
 */
const insertTcGridEmptyRowHandler = (
  state: TcGridState,
  newState: InsertTcGridEmptyRowPayload
): TcGridState => {
  return {
    ...state,
    // data: R.concat([{ id: null }], state.data),
  };
};

/**
 * this handler will set data for tc grid in store
 */
const refreshTcGridDataSuccessHandler = (
  state: TcGridState,
  newState: RefreshTcGridDataSuccessPayload
): TcGridState => {
  const { filter } = newState;
  return {
    ...state,
    // filter
  };
};

const setTcGridDetailsDialogIdHandler = (
  state: TcGridState,
  newState: SetTcGridDetailsDialogIdPayload
): TcGridState => {
  const { dialogId } = newState;
  return {
    ...state,
    detailsDialogId: dialogId,
  };
};

const closeTcGridDetailsDialogSuccessHandler = (
  state: TcGridState,
  newState: BaseTcStorePayload
): TcGridState => {
  return {
    ...state,
    detailsDialogId: null,
  };
};

const setTcGridSelectionHandler = (
  state: TcGridState,
  newState: SetTcGridSelectionPayload
): TcGridState => {
  const { selectedRows, storeKey } = newState;

  return R.mergeRight(state, {
    selectedRows,
    storeKey,
  });
}

/**
 * ngrx tc grid state reducer
 */
const ngRxTcGridStateReducer = createReducer(initialNgRxTcGridState);

/**
 * reducer
 */
const reducer = createReducer(
  initialTcGridState,
  on(TcGridActions.initTcGridStore, initTcGridHandler),
  on(TcGridActions.setTcGridColumns, setTcGridColumnsHandler),
  on(TcGridActions.loadTcGridDataSuccess, loadTcGridDataSuccessHandler),
  on(TcGridActions.loadTcGridMoreDataSuccess, loadTcGridMoreDataSuccessHandler),
  on(TcGridActions.updateTcGridRowSuccess, updateTcGridRowSuccessHandler),
  on(TcGridActions.insertTcGridEmptyRow, insertTcGridEmptyRowHandler),
  on(TcGridActions.refreshTcGridDataSuccess, refreshTcGridDataSuccessHandler),
  on(TcGridActions.setTcGridDetailsDialogId, setTcGridDetailsDialogIdHandler),
  on(
    TcGridActions.closeTcGridDetailsDialogSuccess,
    closeTcGridDetailsDialogSuccessHandler
  ),
  on(TcGridActions.setTcGridSelection, setTcGridSelectionHandler),
);

/**
 *  check if action is a TcGridAction (action starts with TC_GRID_ACTIONS_PREFIX)
 */
const isTcGridAction = R.startsWith(TcGridActions.TC_GRID_ACTIONS_PREFIX);

/**
 * tc grid reducer
 */
export function tcGridReducer(state = initialNgRxTcGridState, action) {
  if (!isTcGridAction(action.type)) {
    return state;
  }

  const { storeKey } = action;

  const nextState = ngRxTcGridStateReducer(state, action);
  const tcGridState = nextState[storeKey];

  return R.mergeRight(nextState, {
    [storeKey]: reducer(tcGridState, action),
  });
}
