import { Injectable, NgZone, Inject, Injector } from '@angular/core';
import { TcNotificationConfig } from '../notifications/tc-notification.config';
import {
  ToastrService,
  ToastToken,
  TOAST_CONFIG,
  Overlay,
  ActiveToast,
} from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { TcAppConfig } from '../config/tc-app.config';

@Injectable({
  providedIn: 'root',
})
export class TcNotificationService {
  constructor(public toastrService: ToastrService) {}

  info(
    message: string,
    title?: string,
    config?: Partial<TcNotificationConfig>
  ): ActiveToast<any> {
    return this.toastrService.info(message, title, config);
  }

  success(
    message: string,
    title?: string,
    config?: Partial<TcNotificationConfig>
  ): ActiveToast<any> {
    return this.toastrService.success(message, title, config);
  }

  warning(
    message: string,
    title?: string,
    config?: Partial<TcNotificationConfig>
  ): ActiveToast<any> {
    return this.toastrService.warning(message, title, config);
  }

  error(
    message: string,
    title?: string,
    config?: Partial<TcNotificationConfig>
  ): ActiveToast<any> {
    return this.toastrService.error(message, title, config);
  }

  show(
    message: string,
    title?: string,
    config?: Partial<TcNotificationConfig>,
    type?: string
  ): ActiveToast<any> {
    return this.toastrService.show(message, title, config);
  }
}
