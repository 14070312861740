import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { tcGridReducer } from './store/reducers/tc-grid.reducer';
import { TcGridComponent } from './components/tc-grid/tc-grid.component';
import { TcGridHeaderSelectionComponent } from './components/tc-grid-header-selection/tc-grid-header-selection.component';
import { TcGridHtmlRendererComponent } from './components/tc-grid-html-renderer/tc-grid-html-renderer.component';
import { TcGridButtonsRendererComponent } from './components/tc-grid-buttons-renderer/tc-grid-buttons-renderer.component';
import { TcGridFaButtonsRendererComponent } from './components/tc-grid-fa-buttons-renderer/tc-grid-fa-buttons-renderer.component';
import { TcGridEventButtonsRendererComponent } from './components/tc-grid-event-buttons-renderer/tc-grid-event-buttons-renderer.component';
import { TcGridSmartButtonsRendererComponent } from './components/tc-grid-smart-buttons-renderer/tc-grid-smart-buttons-renderer.component';
import { TcGridMatInputEditorComponent } from './components/tc-grid-mat-input-editor/tc-grid-mat-input-editor.component';
import { TcGridCheckboxEditorComponent } from './components/tc-grid-checkbox-editor/tc-grid-checkbox-editor.component';
import { TcGridDatePickerEditorComponent } from './components/tc-grid-datepicker-editor/tc-grid-datepicker-editor.component';
import { TcGridAutocompleteEditorComponent } from './components/tc-grid-autocomplete-editor/tc-grid-autocomplete-editor.component';
import { TcGridMultiSelectEditorComponent } from './components/tc-grid-multi-select-editor/tc-grid-multi-select-editor.component';
import { TcGridNoRowsOverlayComponent } from './components/tc-grid-no-rows-overlay/tc-grid-no-rows-overlay.component';
import { TcGridMassUpdateHeaderComponent } from './components/tc-grid-mass-update-header/tc-grid-mass-update-header.component';
import { TcGridTemplateRendererComponent } from './components/tc-grid-template-renderer/tc-grid-template-renderer.component';
import { TcGridCheckboxRendererComponent } from './components/tc-grid-checkbox-renderer/tc-grid-checkbox-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { TcGridEffects } from './store/effects/tc-grid-effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TcDataService, TcDataServiceFactory } from '@tc/data-store';
import { DEFAULT_TC_GRID_STATE_KEY } from './store/state/tc-grid-state';
import { TcButtonsModule } from '@tc/buttons';
import { TcGridConcatArrayRendererComponent } from './components/tc-grid-concat-array-renderer/tc-grid-concat-array-renderer.component';
import { TcGridLinkRendererComponent } from './components/tc-grid-link-renderer/tc-grid-link-renderer.component';
import { TcGridEmailRendererComponent } from './components/tc-grid-mail-renderer/tc-grid-email-renderer.component';
import { TcGridPhoneRendererComponent } from './components/tc-grid-phone-renderer/tc-grid-phone-renderer.component';
import { TcGridColumnConfigProviderService } from './services/tc-grid-column-config-provider.service';

/**
 * Tc grid module components
 */
const components = [
  TcGridComponent,
  TcGridHeaderSelectionComponent,
  TcGridHtmlRendererComponent,
  TcGridButtonsRendererComponent,
  TcGridFaButtonsRendererComponent,
  TcGridEventButtonsRendererComponent,
  TcGridSmartButtonsRendererComponent,
  TcGridMatInputEditorComponent,
  TcGridCheckboxEditorComponent,
  TcGridDatePickerEditorComponent,
  TcGridAutocompleteEditorComponent,
  TcGridMultiSelectEditorComponent,
  TcGridNoRowsOverlayComponent,
  TcGridMassUpdateHeaderComponent,
  TcGridTemplateRendererComponent,
  TcGridCheckboxRendererComponent,
  TcGridConcatArrayRendererComponent,
  TcGridLinkRendererComponent,
  TcGridEmailRendererComponent,
  TcGridPhoneRendererComponent,
];
@NgModule({
  declarations: [...components],
  providers: [
    TcDataServiceFactory,
    TcDataService,
    TcGridColumnConfigProviderService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    MaterialModule,

    TranslateModule,

    AgGridModule.withComponents([]),

    EffectsModule.forFeature([TcGridEffects]),
    StoreModule.forFeature(DEFAULT_TC_GRID_STATE_KEY, tcGridReducer),

    TcButtonsModule,
  ],
  exports: [...components],
})
export class TcGridModule {}
