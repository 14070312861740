import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';

export interface DeliveryNotesState {
  blMainDetails: AmsStatsInterface[];
  blDateDetails: AmsStatsInterface[];
}

export const initialDeliveryNotesState: DeliveryNotesState = {
  blMainDetails: [],
  blDateDetails: [],
};
