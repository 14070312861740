import { Ability } from '@casl/ability';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { unpackRules } from '@casl/ability/extra';
import {
  DEFAULT_TC_PERMISSIONS_STATE_KEY,
  TcPermissionsState,
} from './tc-permissions.state';
import { Permission } from '../typings';

export const getTcPermissionsState = createFeatureSelector<TcPermissionsState>(
  DEFAULT_TC_PERMISSIONS_STATE_KEY
);

const getAbilitiesFromPermissions = (permissions: Permission[]) => {
  const ability = new Ability();
  const mappedPermissions = permissions.map(p => [p.action, p.subject] as [string, string]);
  if (mappedPermissions.length) {
    ability.update(unpackRules(mappedPermissions));
  }
  return ability;
};
export const getAbilities = createSelector(
  getTcPermissionsState,
  (state: TcPermissionsState) => getAbilitiesFromPermissions(state.authenticatedUserPermissions)
);

export const can = (action, subject) =>
  createSelector(getTcPermissionsState, (state: TcPermissionsState) => {
    return getAbilitiesFromPermissions(state.authenticatedUserPermissions).can(action, subject);
  });

export const cannot = (action, subject) =>
  createSelector(getTcPermissionsState, (state: TcPermissionsState) => {
    return getAbilitiesFromPermissions(state.authenticatedUserPermissions).cannot(
      action,
      subject
    );
  });
