import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { UsersService } from './business-services/users.service';
import { UserRights } from '@tc/permissions';
import { TcDataServiceFactory } from '@tc/data-store';
import { ConfigService } from '../shared/services/config.service';
import { TcOfflineModeService } from '@tc/breeze';
import { TcLocalStorageService } from '@tc/local-storage';
import { ConfigKeys } from '../shared/interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends TcService {
  private offlineModeService: TcOfflineModeService;
  private permissionsStorageKey = 'permissions';

  constructor(
    private readonly usersService: UsersService,
    private readonly tcDataServiceFactory: TcDataServiceFactory,
    private readonly config: ConfigService,
    protected localStorageService: TcLocalStorageService
  ) {
    super();
    if (this.config.get(ConfigKeys.supportsOfflineMode)) {
      this.offlineModeService = this.tcDataServiceFactory.getOfflineModeService(
        this.config.get(ConfigKeys.offlineModeDataProviderType)
      );
    }
  }

  public async getPermissions(): Promise<UserRights> {
    if (this.config.get(ConfigKeys.supportsOfflineMode)) {
      const isOfflineMode = await this.offlineModeService.hasOfflineMode();

      if (isOfflineMode) {
        const localPermissions = await this.localStorageService.get(
          this.permissionsStorageKey
        );

        return localPermissions
          ? localPermissions
          : Promise.reject('Local permissions are not set');
      } else {
        const permissions = await this.usersService.getPermissions();

        await this.localStorageService.set(
          this.permissionsStorageKey,
          permissions
        );

        return permissions;
      }
    } else {
      return await this.usersService.getPermissions();
    }
  }
}
