import { Component, TemplateRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'tc-grid-template-renderer',
  templateUrl: './tc-grid-template-renderer.component.html',
  styleUrls: ['./tc-grid-template-renderer.component.css'],
})
export class TcGridTemplateRendererComponent implements AgRendererComponent {
  template: TemplateRef<any>;
  templateContext: { $implicit: any; params: any };

  agInit(params: ICellRendererParams): void {
    const key = 'ngTemplate';

    this.template = params[key];
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.templateContext = {
      $implicit: params.data,
      params,
    };
    return true;
  }
}
