import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialColor } from '@tc/abstract';
import { getAbilities } from '@tc/permissions';
import { IPageState } from '@tc/store';
import { take } from 'rxjs/operators';

import { activateSelectedBLs, deactivateSelectedBLs } from '../../modules/delivery-notes/store/delivery-notes.actions';
import { AmsPage } from '../../shared/directives/ams-page';
import { PermissionAction } from '../../typings/permission-action.enum';
import { PermissionSubject } from '../../typings/permission-subject.enum';

@Component({
  selector: 'app-delivery-note-page',
  templateUrl: './delivery-note-page.component.html',
  styleUrls: ['./delivery-note-page.component.scss'],
})
export class DeliveryNotePageComponent extends AmsPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  async ngOnInit() {
    const abilities = await this.store
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    const buttonsList = [];

    if (
      abilities.can(PermissionAction.ListBonLivraison, PermissionSubject.All)
    ) {
      buttonsList.push({
        label: 'activate',
        color: MaterialColor.Accent,
        action: () => this.store.dispatch(activateSelectedBLs()),
      });
    }
    if (
      abilities.can(PermissionAction.ListBonLivraison, PermissionSubject.All)
    ) {
      buttonsList.push({
        label: 'deactivate',
        color: MaterialColor.Primary,
        action: () => this.store.dispatch(deactivateSelectedBLs()),
      });
    }

    const pageInfo: IPageState = {
      title: 'delivery-note-page.title',
      buttonsList: buttonsList,
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
