import { NgRxTcDataState, TcDataState } from '../state/tc-data-state';
import * as R from 'ramda';
import {
  FiltersTcDataPayload,
  InitTcListDataPayload,
  LoadTcDataPayloadSuccess,
  LoadTcDataTotalSuccessPayload,
  LoadTcMoreDataPayloadSuccess,
  UpdateItemSuccessPayload,
} from '../actions/tc-data-payload';
import { createReducer, on } from '@ngrx/store';
import * as TcDataActions from '../actions/tc-data-actions';
import { BaseTcStorePayload, TcConfigTypes } from '@tc/abstract';
import { isDevMode } from '@angular/core';

/**
 * initialNgRxTcDataState
 */
export const initialNgRxTcDataState: NgRxTcDataState = {};

// ServiceLocator can't be used here in order to inject ConfigService because these lines of code are being compiled before TcCoreModule constructor
/**
 * Default page size based on the default page size that was set in config.json. If it was not set default to 200.
 */
const {
  defaultPageSize = 200,
} = require('../../../../../../../apps/frontend/src/app/custom/assets/config.json');

/**
 * the initial state of tc data
 */
export const initialTcDataState: TcDataState = {
  storeKey: null,
  dataProvider: null,
  writeDataProvider: null,
  data: [],
  total: undefined,
  take: defaultPageSize,
  skip: 0,
  isLoading: null,
};

/**
 * this handler will init tc data in store
 */
const initTcListDataHandler = (
  state: TcDataState,
  newState: InitTcListDataPayload
): TcDataState => {
  const { storeKey, listConfig } = newState;
  const { dataProvider, writeDataProvider, pageSize, configType } = listConfig;

  let sort;
  // If this is a tc-grid config then there might me a default sorted column that we need
  // to set so that whoever calls the loadTcData action does have the sort.
  // The grid will later catch this sort column and also display a small arrow next to the name.
  if (configType === TcConfigTypes.TcGrid) {
    const columns = (listConfig as any).columns;
    let foundOneSortedColumn = false;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort) {
        if (foundOneSortedColumn) {
          // At the moment we do not suport multiple columns sorting so in case the developer
          // sets sorting multiple columns we take only the first one into consideration
          if (isDevMode()) {
            alert(
              'At the moment we do not suport multiple columns sorting. Please set sort property on only one column'
            );
          }
          break;
        }

        sort = { key: columns[i].field, order: columns[i].sort?.toUpperCase() };

        foundOneSortedColumn = true;
      }
    }
  }

  return R.mergeRight(state, {
    dataProvider,
    writeDataProvider,
    storeKey,
    ...(pageSize ? { take: pageSize } : {}),
    skip: 0,
    isLoading: null,
    filter: null,
    ...(sort ? { sort } : { sort: null }),
  });
};

/**
 * this handler update to loading status of the data to it's proper value when data starts loading
 */
const loadTcDataHandler = (state: TcDataState, _): TcDataState => {
  return {
    ...state,
    isLoading: true,
  };
};

/**
 * this handler will set data for tc data in store
 */
const loadTcDataSuccessHandler = (
  state: TcDataState,
  newState: LoadTcDataPayloadSuccess
): TcDataState => {
  const { data, total, take, skip, filter, sort, createdOn } = newState;

  return {
    ...state,
    data,
    total,
    take,
    skip,
    filter,
    sort,
    isLoading: false,
    createdOn,
  };
};

/**
 * this handler will set filters for tc data in store
 */
const setTcDataFiltersHandler = (
  state: TcDataState,
  newState: FiltersTcDataPayload
): TcDataState => {
  const { filter } = newState;
  return {
    ...state,
    filter,
  };
};

/**
 * this handler update to loading status of the data to it's proper value when more data starts loading
 */
const loadTcMoreDataHandler = (state: TcDataState, _): TcDataState => {
  return {
    ...state,
    isLoading: true,
  };
};

/**
 * this handler will add more data for tc data in store
 */
const loadTcMoreDataSuccessHandler = (
  state: TcDataState,
  newState: LoadTcMoreDataPayloadSuccess
): TcDataState => {
  const { data, total, take, skip } = newState;
  return {
    ...state,
    data: R.concat(state.data, data),
    ...(total ? { total } : {}),
    take,
    skip,
    isLoading: false,
  };
};

/**
 * Creates a new row in data store
 */
const createItemSuccessHandler = (
  state: TcDataState,
  newState: UpdateItemSuccessPayload
): TcDataState => {
  const { data } = state;
  const { item } = newState;

  const newData = [item, ...R.clone(data)];

  return {
    ...state,
    data: newData,
  };
};

/**
 * Update the row that was edited in data store
 */
const updateItemSuccessHandler = (
  state: TcDataState,
  newState: UpdateItemSuccessPayload
): TcDataState => {
  const { data } = state;
  const { item } = newState;
  const newData = R.clone(data);

  for (let i = 0; i < newData.length; i++) {
    if ((newData[i] as any)._id === item._id) {
      newData[i] = item;
      break;
    }
  }

  return {
    ...state,
    data: newData,
  };
};

/**
 * Remove the row that was deleted in data store
 */
const deleteItemSuccessHandler = (
  state: TcDataState,
  newState: UpdateItemSuccessPayload
): TcDataState => {
  const { data } = state;
  const { item } = newState;
  const newData = R.clone(data);

  for (let i = 0; i < newData.length; i++) {
    if ((newData[i] as any)._id === item._id) {
      newData.splice(i, 1);
      break;
    }
  }

  return {
    ...state,
    data: newData,
  };
};

/**
 * this handler will set data for tc data in store to empty
 */
const emptyTcDataHandler = (
  state: TcDataState,
  newState: BaseTcStorePayload
): TcDataState => {
  return {
    ...state,
    data: [],
    total: 0,
    skip: 0,
  };
};

/**
 * this handler will set data total in store
 */
const loadTcDataTotalSuccessHandler = (
  state: TcDataState,
  newState: LoadTcDataTotalSuccessPayload
): TcDataState => {
  const { total } = newState;
  return {
    ...state,
    total,
  };
};

/**
 * ngrx tc data state reducer
 */
const ngRxTcDataStateReducer = createReducer(initialNgRxTcDataState);

/**
 * reducer
 */
const reducer = createReducer(
  initialTcDataState,
  on(TcDataActions.initTcListDataStore, initTcListDataHandler),
  on(TcDataActions.updateTcListDataStore, initTcListDataHandler),
  on(TcDataActions.loadTcData, loadTcDataHandler),
  on(TcDataActions.loadTcDataSuccess, loadTcDataSuccessHandler),
  on(TcDataActions.setTcDataFilters, setTcDataFiltersHandler),
  on(TcDataActions.loadTcMoreData, loadTcMoreDataHandler),
  on(TcDataActions.loadTcMoreDataSuccess, loadTcMoreDataSuccessHandler),
  on(TcDataActions.createItemSuccess, createItemSuccessHandler),
  on(TcDataActions.updateItemSuccess, updateItemSuccessHandler),
  on(TcDataActions.deleteItemSuccess, deleteItemSuccessHandler),
  on(TcDataActions.emptyTcData, emptyTcDataHandler),
  on(TcDataActions.loadTcDataTotalSuccess, loadTcDataTotalSuccessHandler)
);

/**
 *  check if action is a TcDataAction (action starts with TC_DATA_ACTIONS_PREFIX)
 */
const isTcDataAction = R.startsWith(TcDataActions.TC_DATA_ACTIONS_PREFIX);

/**
 * tc data reducer
 */
export function tcDataReducer(state = initialNgRxTcDataState, action) {
  if (!isTcDataAction(action.type)) {
    return state;
  }

  const { storeKey } = action;

  const nextState = ngRxTcDataStateReducer(state, action);
  const tcDataState = nextState[storeKey];

  return R.mergeRight(nextState, {
    [storeKey]: reducer(tcDataState, action),
  });
}
