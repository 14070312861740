import { RouterModule } from '@angular/router';
import { ExamplesModule } from '../examples/examples.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { LayoutComponent } from './components/smart/layout/layout.component';
import { TcLayoutModule } from '@tc/layout';
import { AuthModule } from '../auth/auth.module';
import { TcVersionModule } from '@tc/version';
import { TcButtonsModule } from '@tc/buttons';
import { VerticalLayoutComponent } from './components/smart/vertical-layout/vertical-layout.component';

const smartComponents = [LayoutComponent, VerticalLayoutComponent];

@NgModule({
  declarations: [smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    AuthModule,
    ExamplesModule,
    TcLayoutModule,
    RouterModule,
    TcVersionModule,
    TcButtonsModule,
  ],
  exports: [...smartComponents],
})
export class LayoutModule {}
