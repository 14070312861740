import { createReducer, on } from '@ngrx/store';

import {
  loadStatistiquesDataSuccess,
  loadStatistiquesSelectedYear,
  loadStatistiquesVersionsSuccess,
} from './statistiques.actions';
import { initialStatistiquesState, StatistiquesState } from './statistiques.state';

export const statistiquesReducer = createReducer<StatistiquesState>(
  initialStatistiquesState,
  on(loadStatistiquesDataSuccess, (state, { statistiquesData }) => ({
    ...state,
    statistiquesData,
  })),
  on(loadStatistiquesVersionsSuccess, (state, { versionData }) => ({
    ...state,
    versionData,
  })),
  on(loadStatistiquesSelectedYear, (state, { selectedYear }) => ({
    ...state,
    selectedYear,
  }))
);
