import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';

import { SharedModule } from '../../shared/shared.module';
import { StatistiquesFilterComponent } from './components/statistiques-filter/statistiques-filter.component';
import { StatistiquesGridComponent } from './components/statistiques-grid/statistiques-grid.component';
import { StatistiquesVersionComponent } from './components/statistiques-version/statistiques-version.component';
import { StatistiquesComponent } from './components/statistiques/statistiques.component';
import { StatistiquesService } from './services/statistiques.service';
import { StatistiquesEffects } from './store/statistiques.effects';
import { statistiquesReducer } from './store/statistiques.reducer';

const components = [
  StatistiquesFilterComponent,
  StatistiquesComponent,
  StatistiquesVersionComponent,
  StatistiquesGridComponent,
];

const services = [StatistiquesService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    TcDialogModule,
    SharedModule,
    EffectsModule.forFeature([StatistiquesEffects]),
    StoreModule.forFeature('statistiques', statistiquesReducer),
    AgGridModule.withComponents([]),
  ],
  providers: [...services],
  exports: [...components],
})
export class StatistiquesModule {}
