/**
 * Angular material knows only these 6 tooltip position
 */
export enum MaterialTooltipPosition {
  Above = 'above',
  Below = 'below',
  Left = 'left',
  Right = 'right',
  Before = 'before',
  After = 'after',
}
