import { NgModule } from '@angular/core';
import { TcVersionComponent } from './components/tc-version/tc-version.component';

const components = [TcVersionComponent];

@NgModule({
  declarations: [...components],
  imports: [],
  exports: [...components],
})
export class TcVersionModule {}
