import {
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
  } from '@angular/core';
  import { TcTranslateService, TcFormComponent } from '@tc/core';
  
  @Component({
    selector: 'app-indicators-filter',
    templateUrl: './indicators-filter.component.html',
    styleUrls: ['./indicators-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
  })
  export class IndicatorsFilterComponent
    extends TcFormComponent<any>
    implements OnInit
  {
    gridStoreKey = 'indicators-grid';
  
    constructor(translate: TcTranslateService, elem: ElementRef) {
      super(translate, elem);
    }
  
    ngOnInit() {}
  }
  