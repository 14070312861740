import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[tcPreventDoubleClick]',
})
export class TcPreventDoubleClickDirective {
  @Input() timeout: number;
  @Input() opacity: string;

  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    let timeoutValue = 1000;
    let opacityValue = '0.6';

    if (
      this.timeout !== undefined &&
      this.timeout !== null &&
      this.timeout > 0
    ) {
      timeoutValue = this.timeout;
    }

    if (
      this.opacity !== undefined &&
      this.opacity !== null &&
      this.opacity !== ''
    ) {
      opacityValue = this.opacity;
    }

    const button =
      event.srcElement.disabled === undefined
        ? event.srcElement.parentElement
        : event.srcElement;

    button.style.pointerEvents = 'none';
    button.style.opacity = opacityValue;
    setTimeout(() => {
      button.style.pointerEvents = '';
      button.style.opacity = '';
    }, timeoutValue);
  }
}
