import { TermsOfUse } from '../interfaces/terms-of-use.interface';

export interface TermsConditionsState {
  termsAccepted: boolean;
  termsToValidate: TermsOfUse;
}

export const initialAuthState: TermsConditionsState = {
  termsAccepted: false,
  termsToValidate: null,
};
