import { Injectable } from '@angular/core';
import { TcNavMenuNodes } from '../interfaces/tc-nav-menu-nodes';

@Injectable({
  providedIn: 'root',
})
export class TcNavMenuService {
  foundLinkObject: TcNavMenuNodes;
  generateId(): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  addRandomId(nodes: TcNavMenuNodes[]): void {
    nodes.forEach((node: TcNavMenuNodes) => {
      node.id = this.generateId();
      if (node.items !== undefined) {
        this.addRandomId(node.items);
      }
    });
  }
  recursiveCheckId(node: TcNavMenuNodes, nodeId: string): boolean {
    if (node.id === nodeId) {
      return true;
    } else {
      if (node.items !== undefined) {
        return node.items.some((nestedNode: TcNavMenuNodes) => {
          return this.recursiveCheckId(nestedNode, nodeId);
        });
      }
    }
  }
  recursiveCheckLink(nodes: TcNavMenuNodes[], link: string): void {
    for (const node of nodes) {
      // const node = nodes[nodeIndex];
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          if (encodeURI(node.link) === link) {
            this.foundLinkObject = node;
          } else {
            if (node.items !== undefined) {
              this.recursiveCheckLink(node.items, link);
            }
          }
        }
      }
    }
  }
  getMatchedObjectByUrl(node: TcNavMenuNodes[], link: string): TcNavMenuNodes {
    this.recursiveCheckLink(node, link);
    return this.foundLinkObject;
  }
  // overrides key-value pipe's default reordering (by key) by implementing dummy comprarer function
  // https://angular.io/api/common/KeyValuePipe#description
  kvDummyComparerFn() {
    return 0;
  }
}
