import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TcFilterItem } from '@tc/abstract';
import * as R from 'ramda';
import { DEFAULT_TC_FILTER_STATE_KEY, NgRxTcFilterState, TcFilterState } from '../state/tc-filter-state';

const tcFilterState = createFeatureSelector<NgRxTcFilterState>(DEFAULT_TC_FILTER_STATE_KEY);

export const getTcFilterStoreSliceFilters = createSelector(
  tcFilterState,
  (state: NgRxTcFilterState, slice: string): TcFilterItem[] => {
    return state?.[slice]?.filter?.filters;
  }
)

/**
 * Get tc filter from store by store key
 */
export const getTcFiltersByStoreKey = (
  state: NgRxTcFilterState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

/**
 * Get tc filters
 */
export const getTcFilters = createSelector(
  getTcFiltersByStoreKey,
  (state: TcFilterState) => {
    return R.propOr([], 'filter')(state);
  }
);

/**
 *  Get Tc config
 */
export const getTcFilterConfig = createSelector(
  getTcFiltersByStoreKey,
  (state: TcFilterState) => {
    return R.propOr(null, 'config')(state);
  }
);
