import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';

export interface ReceivedDeliveryNotesState {
  numeroBLDetails: AmsStatsInterface[];
  envoyeLeDetails: AmsStatsInterface[];
  banqueBLDetails: AmsStatsInterface[];
  associationBLDetails: AmsStatsInterface[];
  resultatBLDetails: AmsStatsInterface[];
}

export const initialReceivedDeliveryNotesState: ReceivedDeliveryNotesState = {
  numeroBLDetails: [],
  envoyeLeDetails: [],
  banqueBLDetails: [],
  associationBLDetails: [],
  resultatBLDetails: [],
};
