import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { TcGenericEntity } from '../../../abstract/tc-generic-entity.interface';
import { TcList } from '../../../base/tc-list';
import { TcListFilterType } from '../../../enums/tc-list-filter-type.enum';
import { TcFilterValue } from '../../../interfaces/tc-filter-value';
import { TcFilterTypes } from '@tc/abstract';

@Component({
  selector: 'tc-list',
  templateUrl: './tc-list.component.html',
  styleUrls: ['./tc-list.component.scss'],
})
export class TcListComponent extends TcList {
  constructor(elem: ElementRef) {
    super(elem);
  }

  @ViewChild('table', { static: false })
  public table: MatTable<TcGenericEntity>;

  onDragAndDrop(event: CdkDragDrop<TcGenericEntity[]>) {
    const prevIndex = this.dataSource.data.findIndex(
      (d) => d === event.item.data
    );
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);

    this.table.renderRows();
    this.afterDragAndDrop(prevIndex, event.currentIndex);
  }

  public search(filterValue: TcFilterValue) {
    this.selectedFilterValues = filterValue;

    if (this.filterType === TcListFilterType.Client) {
      this.applyClientSideFilter();
      return;
    }

    this.applyServerSideFilter();
  }

  public searchDefaultFilter(value: any) {
    const filterValue: TcFilterValue = {};
    filterValue[TcFilterTypes.anyFieldContains] = value;

    this.selectedFilterValues = filterValue;

    if (this.filterType === TcListFilterType.Client) {
      this.applyClientSideFilter();
      return;
    }

    this.applyServerSideFilter();
  }
}
