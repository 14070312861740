import { EntityMetadataMap } from 'ngrx-data';

const entityMetadata: EntityMetadataMap = {
  StarShip: {},
};

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames,
};
