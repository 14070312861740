import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { Subscription } from 'rxjs';
import { AmsStatsInterface } from '../../interfaces/ams-stats.interface';

@Component({
  selector: 'app-ams-stats-table',
  templateUrl: './ams-stats-table.component.html',
  styleUrls: ['./ams-stats-table.component.scss'],
})
export class AmsStatsTableComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  @Input() public getData: any;
  @Input() public useTranslation = false;

  private subscription = new Subscription();
  tableData: AmsStatsInterface[];

  constructor(store$: Store<any>) {
    super(store$);
  }

  async ngOnInit() {
    const storeSubscription = this.store$
      .select(this.getData)
      .subscribe((data) => {
        this.tableData = data;
      });

    this.subscription.add(storeSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
