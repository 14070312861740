import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { IVersionState, getVersionInfo } from '@tc/store';

/**
 * This component sole purpose is to get the current software version and current date from the store and display in inside a span
 * If the version text needs to be customized use the .current-version css class
 */
@Component({
  selector: 'tc-version-header',
  templateUrl: './tc-version-header.component.html',
  styleUrls: ['./tc-version-header.component.scss'],
})
export class TCVersionHeaderComponent
  extends TcSmartComponent
  implements OnInit
{
  /**
   * Observable of the version info from the store
   */
  versionInfo$: Observable<IVersionState>;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.versionInfo$ = this.store$.pipe(select(getVersionInfo));
  }
}
