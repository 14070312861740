import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TcDumbComponent } from '@tc/abstract';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ams-table-input-editor',
  templateUrl: './ams-table-input-editor.component.html',
  styleUrls: ['./ams-table-input-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmsTableInputEditorComponent
  extends TcDumbComponent
  implements OnInit, OnDestroy
{
  @Input() public label: string;
  @Input() public disabled = false;
  @Input() public type: string;
  @Input() public field: any;
  @Input() public value: any;

  @Output() public handleChange = new EventEmitter<{
    field: any;
    value: any;
  }>();
  @Output() public handleKeyUp = new EventEmitter();

  private subscription = new Subscription();

  constructor() {
    super();
  }

  async ngOnInit() {
    this.type = this.type ? this.type : 'text';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChange(value) {
    this.handleChange.emit({ field: this.field, value });
  }

  keyUp($event) {
    this.handleKeyUp.emit($event);
  }
}
