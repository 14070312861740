import { Injectable } from '@angular/core';
import { TcLoggerService } from '../services/tc-logger.service';
import { TcNotificationService } from '../services/tc-notification.service';
import { TcAppConfig } from '../config/tc-app.config';
import { TcLoggerConfig } from '../logger/tc-logger.config';
import { TcLoggerLevel } from '../logger/tc-logger.level';
import {
  ActionSettingsChangeTheme,
  ActionSettingsLoadConfig,
} from '../settings';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { GetConfigService } from '../config/get-config.service';
import { TcService } from '@tc/abstract';

@Injectable({
  providedIn: 'root',
})
export class TcConfigService extends TcService {
  config: TcAppConfig;

  constructor(
    private getConfigService: GetConfigService,
    private logger: TcLoggerService,
    private notification: TcNotificationService,
    private store: Store<TcAppState>
  ) {
    super();
  }

  public loadAppConfig() {
    if (this.getConfigService.appConfig) {
      this.config = this.getConfigService.appConfig;

      if (this.config.notifications) {
        Object.keys(this.config.notifications).forEach((k) => {
          this.notification.toastrService.toastrConfig[k] =
            this.config.notifications[k];
        });
      }

      if (this.config.logger) {
        const loggerConfig = new TcLoggerConfig();
        Object.keys(this.config.logger).forEach((k) => {
          if (k === 'level') {
            const level: string = this.config.logger[k];
            loggerConfig[k] = TcLoggerLevel[level];
          } else if (k === 'serverLogLevel') {
            const serverLogLevel: string = this.config.logger[k];
            loggerConfig[k] = TcLoggerLevel[serverLogLevel];
          } else {
            loggerConfig[k] = this.config.logger[k];
          }
        });
        this.logger.updateConfig(loggerConfig);
      }

      this.store.dispatch(new ActionSettingsLoadConfig(this.config));

      if (this.config?.theme) {
        this.store.dispatch(new ActionSettingsChangeTheme(this.config.theme));
      }
    }
  }
}
