import { OnInit, Directive } from '@angular/core';
import { TcConfigService } from '../services/tc-config.service';

@Directive()
export class TcAppComponent implements OnInit {
  constructor(public configService: TcConfigService) {}

  ngOnInit(): void {
    this.configService.loadAppConfig();
  }
}
