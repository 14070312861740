import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { TcSmartFormComponent } from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TranslateModule } from '@ngx-translate/core';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  TcSmartFormEffects,
  tcSmartFormReducer,
} from './store';
import { TcDetailHeaderComponent } from './components/tc-detail-header/tc-detail-header.component';
import { TcDetailTitleComponent } from './components/tc-detail-title/tc-detail-title.component';
import { TcButtonsModule } from '@tc/buttons';
import { EffectsModule } from '@ngrx/effects';
import { PortalModule } from '@angular/cdk/portal';

const components = [
  TcSmartFormComponent,
  TcDetailHeaderComponent,
  TcDetailTitleComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    FormlyModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,

    TranslateModule,
    TcButtonsModule,
    PortalModule,

    EffectsModule.forFeature([TcSmartFormEffects]),
    StoreModule.forFeature(DEFAULT_TC_SMART_FORM_STATE_KEY, tcSmartFormReducer),
  ],
  exports: [...components],
})
export class TcSmartFormModule {}
