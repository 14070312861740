import { TcPdfViewerActionsPosition } from './../../../enums/tc-pdf-viewer-actions-position.enum';
import { Component, Input, OnInit } from '@angular/core';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import { TcPdfViewerConfig } from './../../../interfaces/tc-pdf-viewer-config';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'tc-pdf-viewer',
  templateUrl: './tc-pdf-viewer.component.html',
  styleUrls: ['./tc-pdf-viewer.component.scss'],
})
export class TcPdfViewerComponent implements OnInit {
  @Input() config: TcPdfViewerConfig;

  private content: Blob;
  public currentPage = 1;
  public totalPages: number;
  public isLoaded = false;
  public zoom = 1;
  public actionsPosition = TcPdfViewerActionsPosition;

  constructor() {}

  ngOnInit() {
    // If we got a string, we assume this is a base64 encoded PDF. We decode it.
    if (typeof this.config.src === 'string') {
      this.config.src = this.base64ToArrayBuffer(this.config.src);
    }

    // Set page if provided in config
    if (this.config.page) {
      this.currentPage = this.config.page;
    }
  }

  private base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public afterPdfLoadComplete(pdfData: PDFDocumentProxy) {
    // Get the content of the document from ng2-pdf-viewer and convert it to blob to use it in print and download features.
    pdfData.getData().then((data) => {
      this.content = new Blob([data], { type: 'octet/stream' });
      this.totalPages = pdfData.numPages;
      this.isLoaded = true;
    });
  }

  public prevPage() {
    this.currentPage--;
  }

  public firstPage() {
    this.currentPage = 1;
  }

  public nextPage() {
    this.currentPage++;
  }

  public lastPage() {
    this.currentPage = this.totalPages;
  }

  public zoomIn() {
    this.zoom += 0.5;
  }

  public zoomOut() {
    this.zoom -= 0.5;
  }

  public print() {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const result = fileReader.result as string;
      // This fileReader readAsDataURL function is returning a string in base64 that is handling unicode chars like : data:application/pdf;base64,{{base64}}
      // Split on ',' and get base64 content on array [1]
      // Don't use btoa, as it don't support unicode.
      printJS({
        printable: result.split(',')[1],
        type: 'pdf',
        base64: true,
      });
    };
    fileReader.readAsDataURL(this.content);
  }

  public download() {
    saveAs(this.content, this.config.filename);
  }
}
