import { createReducer, on } from '@ngrx/store';

import {
  loadAssociationBLDetsSuccess,
  loadBanqueBLDetsSuccess,
  loadEnvoyeLeDetsSuccess,
  loadNumeroBLDetsSuccess,
  loadResultantBLDetsSuccess,
} from './received-delivery-notes.actions';
import { initialReceivedDeliveryNotesState, ReceivedDeliveryNotesState } from './received-delivery-notes.state';

export const receivedDeliveryNotesReducer =
  createReducer<ReceivedDeliveryNotesState>(
    initialReceivedDeliveryNotesState,
    on(loadNumeroBLDetsSuccess, (state, { payload }) => ({
      ...state,
      numeroBLDetails: [...payload],
    })),
    on(loadEnvoyeLeDetsSuccess, (state, { payload }) => ({
      ...state,
      envoyeLeDetails: [...payload],
    })),
    on(loadBanqueBLDetsSuccess, (state, { payload }) => ({
      ...state,
      banqueBLDetails: [...payload],
    })),
    on(loadAssociationBLDetsSuccess, (state, { payload }) => ({
      ...state,
      associationBLDetails: [...payload],
    })),
    on(loadResultantBLDetsSuccess, (state, { payload }) => ({
      ...state,
      resultatBLDetails: [...payload],
    }))
  );
