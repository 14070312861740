import { createAction, props } from '@ngrx/store';
import { SetVersionDetailsPayload } from './home.payloads';

export const loadVersionDetails = createAction(
  '[Home] Load Latest Product Version Details'
);

export const loadVersionDetailsSuccess = createAction(
  '[Home] Load Latest Product Version Details Success',
  props<{ payload: SetVersionDetailsPayload }>()
);
