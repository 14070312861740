import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Subject } from '@casl/ability';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

/**
 * This service helps us with observables for building responsive layouts.
 * Make sure that the `init` function is called in app.component in order for it to work.
 */
@Injectable({
  providedIn: 'root',
})
export class TcMediaService {
  /**
   * Private BehaviorSubject that the service uses in order to transmit if we are on mobile or not
   */
  private _isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Public observable form determining if the app is in mobile mode based on the breakpoint
   * written in config.json
   */
  public readonly isMobile: Observable<boolean> = this._isMobile.asObservable();

  /**
   * MediaQueryList that is used in order to know when the resolution reaches the mobile breakpoint
   */
  private mobileQuery: MediaQueryList;

  /**
   * Listener that is used by mobileQuery for when the resolution reaches the mobile breakpoint.
   * Only declared so that it can be removed in the ngOnDestroy method
   */
  private _mobileQueryListener: () => void;

  /**
   * Private BehaviorSubject that the service uses in order to transmit if we are on mobile or not
   */
  private _isExtraLargeDevice: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /**
   * Public observable form determining if the app is in extra large mode based on the breakpoint
   * written in config.json
   */
  public readonly isExtraLargeDevice: Observable<boolean> =
    this._isExtraLargeDevice.asObservable();

  /**
   * MediaQueryList that is used in order to know when the resolution reaches the extra large breakpoint
   */
  private extraLargeQuery: MediaQueryList;

  /**
   * Listener that is used by extraLargeQuery for when the resolution reaches the extra large breakpoint.
   * Only declared so that it can be removed in the ngOnDestroy method
   */
  private _extraLargeQueryListener: () => void;

  /**
   * Responsive options form the config.json file
   */
  private responsiveOptions;

  /**
   * Required for detecting window width changes changes.
   */
  private changeDetectorRef: ChangeDetectorRef;

  constructor(
    private readonly config: ConfigService,
    private media: MediaMatcher
  ) {}

  /**
   * Called once when the app starts from the app component, it requiers `@param changeDetectorRef`
   * since it can only be accesed form components.
   */
  public init(changeDetectorRef: ChangeDetectorRef) {
    // If the service has already been inited there is no point doing it again.
    if (this.changeDetectorRef) return;

    this.changeDetectorRef = changeDetectorRef;

    this.responsiveOptions = this.config.get(
      ConfigKeys.layoutConfig.responsiveOptions
    );

    this.setMobileMediaListener();
    this.setExtraLargeMediaListener();
  }

  private setMobileMediaListener() {
    if (this.responsiveOptions?.responsive) {
      const setMobileResolution = () => {
        this._isMobile.next(this.mobileQuery.matches);
      };

      this.mobileQuery = this.media.matchMedia(
        `(max-width: ${this.responsiveOptions.breakPoint})`
      );

      this._mobileQueryListener = () => {
        setMobileResolution();

        this.changeDetectorRef.detectChanges();
      };
      setMobileResolution();

      this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }
  }

  private setExtraLargeMediaListener() {
    if (this.responsiveOptions?.responsive) {
      const setExtraLargeResolution = () => {
        this._isExtraLargeDevice.next(this.extraLargeQuery.matches);
      };

      this.extraLargeQuery = this.media.matchMedia(
        `(min-width: ${this.responsiveOptions.deviceBreakPoints?.extraLargeDevice?.min})`
      );

      this._extraLargeQueryListener = () => {
        setExtraLargeResolution();

        this.changeDetectorRef.detectChanges();
      };
      setExtraLargeResolution();

      this.extraLargeQuery.addEventListener(
        'change',
        this._extraLargeQueryListener
      );
    }
  }

  ngOnDestroy() {
    if (this.responsiveOptions?.responsive) {
      this.mobileQuery?.removeEventListener(
        'change',
        this._mobileQueryListener
      );

      this.extraLargeQuery?.removeEventListener(
        'change',
        this._extraLargeQueryListener
      );
    }

    this._isMobile.complete();
    this._isExtraLargeDevice.complete();
  }
}
