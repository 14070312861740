import { Component, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TcContentDescriptor } from '../../interfaces/tc-content-descriptor';
import { TcContentService } from '../../services/tc-content.service';

@Component({
  selector: 'tc-placeholder',
  template: '<ng-content></ng-content>',
})
export class TcPlaceholderComponent implements OnDestroy {
  @Input() name: string;

  private subscription: Subscription;

  constructor(
    private containerRef: ViewContainerRef,
    private tcContentService: TcContentService
  ) {
    this.subscription = this.tcContentService
      .contentInit()
      .subscribe((content: TcContentDescriptor) => {
        if (content.placeholder === this.name) {
          this.containerRef.clear();
          this.containerRef.element.nativeElement.appendChild(
            content.elementRef.nativeElement
          );
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
