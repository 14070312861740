import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcFilterDef } from '@tc/abstract';
import { DEFAULT_TC_GRID_STATE_KEY, getTcGridSelection, TcTranslateService } from '@tc/core';
import { DEFAULT_TC_DATA_STATE_KEY, getTcDataFilters, refreshTcData } from '@tc/data-store';
import { selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { DeliveryNotesService } from '../services/delivery-notes.service';
import {
  activateSelectedBLs,
  deactivateSelectedBLs,
  loadBLDateDetailsSuccess,
  loadBLMainDetailsSuccess,
  loadDeliveryNoteHeaderInfo,
} from '../store/delivery-notes.actions';

@Injectable()
export class DeliveryNotesEffects {
  storeKey = 'delivery-notes-grid';

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly translate: TcTranslateService,
    private readonly deliveryNotesService: DeliveryNotesService
  ) {}

  deactivateSelectedBLs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deactivateSelectedBLs),
        tap(async () => {
          const selectedBLs = await this.getSelectedBLs();

          for (const bl of selectedBLs) {
            const { id } = bl;

            this.deliveryNotesService.setBLTraite(id, true);

            const deliveryNotesFilter = await this.getDeliveryNotesFilter();

            this.store$.dispatch(
              refreshTcData({
                storeKey: this.storeKey,
                filter: deliveryNotesFilter,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  activateSelectedBLs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateSelectedBLs),
        tap(async () => {
          const selectedBLs = await this.getSelectedBLs();

          for (const bl of selectedBLs) {
            const { id } = bl;

            this.deliveryNotesService.setBLTraite(id, false);

            const deliveryNotesFilter = await this.getDeliveryNotesFilter();

            this.store$.dispatch(
              refreshTcData({
                storeKey: this.storeKey,
                filter: deliveryNotesFilter,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  loadDeliveryNoteHeaderInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadDeliveryNoteHeaderInfo),
        tap(async ({ deliveryNote }) => {
          const blNo: AmsStatsInterface = {
            label: 'delivery-note-details.label.numeroBL',
            value: deliveryNote.numeroBL,
          };
          const associationDets: AmsStatsInterface = {
            label: 'delivery-note-details.label.association',
            value: deliveryNote.associationNom,
          };
          const blMainDetails = [blNo, associationDets];

          this.store$.dispatch(
            loadBLMainDetailsSuccess({ payload: blMainDetails })
          );

          const traiteData: AmsStatsInterface = {
            label: 'delivery-note-details.label.traite',
            value: this.translate.instant(!!deliveryNote.traite ? 'oui' : 'non')
          };
          const blDate: AmsStatsInterface = {
            label: 'delivery-note-details.label.dateLivraison',
            value: deliveryNote.dateLivraisonShort,
          };
          const blDateDetails = [traiteData, blDate];

          this.store$.dispatch(
            loadBLDateDetailsSuccess({ payload: blDateDetails })
          );
        })
      ),
    { dispatch: false }
  );

  private async getSelectedBLs() {
    const gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    const selectedBLs = await selectValueByKey(
      getTcGridSelection,
      gridStore$,
      this.storeKey
    );

    return selectedBLs;
  }

  private async getDeliveryNotesFilter() {
    const dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    const deliveryNotesFilter: TcFilterDef = await selectValueByKey(
      getTcDataFilters,
      dataStore$,
      this.storeKey
    );

    return deliveryNotesFilter;
  }
}
