import { Injectable } from '@angular/core';
import { TcFilterSerializerService } from '../../services/tc-filter-serializer/tc-filter-serializer.service';
import { TcGridQueryOptions } from '../interfaces/tc-grid-pagination.interface';

@Injectable({ providedIn: 'root' })
export class TcGridPaginationService {
  constructor(
    private readonly tcFilterSerializerService: TcFilterSerializerService
  ) {}

  public buildQueryString(options: TcGridQueryOptions): string {
    const serializedFilter = this.tcFilterSerializerService.serialize(
      options.filter
    );

    const query = {
      skip: this.calculateSkip(options.page, options.limit)?.toString(),
      take: options.limit?.toString(),
      filter: serializedFilter ? JSON.stringify(serializedFilter) : null,
      orderBy: options?.sort ? JSON.stringify(options.sort) : null,
    };

    const qs = new URLSearchParams(query);
    return qs.toString();
  }

  private calculateSkip(page: number, limit: number): number {
    return (page - 1) * limit;
  }
}
