import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState, IVersionState, SetVersionInfo } from '@tc/store';
import { TcPage } from '@tc/core';
import { loadVersionDetails } from '../../modules/home/store/home.actions';
import moment from 'moment';
import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent extends AmsPage implements OnInit {
  versionInfo: IVersionState;

  constructor(private readonly store$: Store<any>) {
    super(store$);
  }
  ngOnInit() {
    this.store$.dispatch(new SetVersionInfo(this.versionInfo));

    const pageInfo: IPageState = {
      title: 'home-page.title',
      displayPageTitle: true
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    this.store$.dispatch(loadVersionDetails());
  }
}
