import { createReducer, on } from '@ngrx/store';
import {
  lastTermsAccepted,
  saveTermsToValidate,
} from './terms-conditions.actions';
import {
  initialAuthState,
  TermsConditionsState,
} from './terms-conditions.state';

export const termsConditionsReducer = createReducer<TermsConditionsState>(
  initialAuthState,
  on(lastTermsAccepted, (state) => ({
    ...state,
    termsAccepted: true,
  })),
  on(saveTermsToValidate, (state, { options }) => ({
    ...state,
    termsToValidate: options,
  }))
);
