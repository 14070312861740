import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssociationsAwaitingValidationResponse } from '../interfaces/associations-awaiting-validation-response.interface';
import { ProductReferenceResponse } from '../interfaces/product-reference-response.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../shared/interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    public httpClient: HttpClient,
    private readonly config: ConfigService
  ) {}

  public async getAssociationsAwaitingValidation(
    baId: number
  ): Promise<AssociationsAwaitingValidationResponse> {
    const url =
      baId !== 0
        ? `${this.config.get(
            ConfigKeys.API_BASE_PATH
          )}/indicateurs/get-valider-revoir-indicateurs-etat?baId=${baId}`
        : `${this.config.get(
            ConfigKeys.API_BASE_PATH
          )}/indicateurs/get-valider-revoir-indicateurs-etat`;

    const response = await this.httpClient
      .get<AssociationsAwaitingValidationResponse>(url)
      .toPromise();
    return response;
  }

  public async getProductReference(): Promise<ProductReferenceResponse> {
    const url = `${this.config.get(
      ConfigKeys.API_BASE_PATH
    )}/referentiel-produits/latest`;
    const response = await this.httpClient
      .get<ProductReferenceResponse>(url)
      .toPromise();
    return response;
  }
}
