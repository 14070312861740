import { Trimestre } from '../typings/trimestre.enum';

export const getLatestEndedTrimestreAndYear = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const month = now.getMonth();
  if (month >= 0 && month <= 2) {
    return {
      trimestre: Trimestre.T4,
      year: currentYear - 1
    };
  }
  if (month > 2 && month <= 5) {
    return {
      trimestre: Trimestre.T1,
      year: currentYear
    };
  }
  if (month > 5 && month <= 8) {
    return {
      trimestre: Trimestre.T2,
      year: currentYear
    };
  }
  return {
    trimestre: Trimestre.T3,
    year: currentYear
  };
};
