import { TcFilterTypes } from './tc-filter-types';
import { FilterTypesEnum } from './filter-types.enum';

export enum TcFilterMultiWordOperator {
  And = 'and',
  Or = 'or',
}

export interface TcFilterItem {
  key: string;
  value: string;
  filterType?: FilterTypesEnum;
  type?: TcFilterTypes;

  /**
   * An array of keys on which the filter should be applied
   */
  filterOn?: string[];

  /**
   * NOTE: Works only with filterOn
   * If set to true the value of the filter is broken down into words
   * and a filter is applied for every word on every key specified in filterOn array
   * @Example: `filterOn = ['nom', 'prenom']; `
   * @Example: `filterValue = 'Jean Claude ';`
   * @Example: `filterMultiWordOperator = TcFilterMultiWordOperator.AND;`
   * @Example: filterValue Gets broken into: `words = ['Jean', 'Claude']`
   * @Example: Result filter: ` (nom === 'Jean' || prenom === 'Jean') && (nom === 'Claude' || prenom === 'Claude)`
   */
  filterMultiWord?: boolean;

  /**
   * Determins the way in which the we bid the multi word filters
   * If not specified `TcFilterMultiWordOperator.AND` is used.
   */
  filterMultiWordOperator?: TcFilterMultiWordOperator;
}

export interface TcFilterDef {
  anyFieldContainsFields?: string[];
  anyFieldStartsWithFields?: string[];
  anyFieldEqualsFields?: string[];
  filters?: TcFilterItem[];
}
