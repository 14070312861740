import { SettingsState } from './settings.model';
import { SettingsActions, SettingsActionTypes } from './settings.actions';

export const initialState: SettingsState = {
  theme: null,
  translate: {
    language: 'fr',
  },
  notifications: null,
  logger: null,
};

export function settingsReducer(
  state: SettingsState = initialState,
  action: SettingsActions
): SettingsState {
  switch (action.type) {
    case SettingsActionTypes.LOAD_CONFIG:
      return { ...state, ...action.payload };
    case SettingsActionTypes.CHANGE_THEME:
      return { ...state, theme: action.payload };
    case SettingsActionTypes.CHANGE_LANGUAGE:
      return { ...state, translate: { ...action.payload } };
    default:
      return state;
  }
}
