import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';

import { SharedModule } from '../../shared/shared.module';
import { AmsIndicatorTableComponent } from './components/ams-indicator-table/ams-indicator-table.component';
import { AmsInitialValuesTableComponent } from './components/ams-initial-values-table/ams-initial-values-table.component';
import { IndicatorsDetailComponent } from './components/indicators-details/indicators-detail.component';
import { IndicatorsFilterComponent } from './components/indicators-filter/indicators-filter.component';
import { IndicatorsGridComponent } from './components/indicators-grid/indicators-grid.component';
import { IndicatorsEffects } from './store/indicators.effects';
import { indicatorsReducer } from './store/indicators.reducers';

const components = [
  AmsIndicatorTableComponent,
  AmsInitialValuesTableComponent,
  IndicatorsGridComponent,
  IndicatorsFilterComponent,
  IndicatorsDetailComponent,
];
const modules = [SharedModule];
const services = [];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    TcDialogModule,
    EffectsModule.forFeature([IndicatorsEffects]),
    StoreModule.forFeature('indicators', indicatorsReducer),
    AgGridModule.withComponents([]),
  ],
  exports: [...components],
  providers: [...services],
})
export class IndicatorsModule {}
