export abstract class TcApiConfig {
  spinnerExceptions?: Array<{ url: string; method: string }>;
  toastErrorExceptions?: Array<{ url: string; method: string; status: number }>;
  customToastErrors?: Array<{
    url: string;
    method: string;
    status: number;
    toastType: string;
    translateKey: string;
  }>;
  errorStatusExceptions?: number[];
}
