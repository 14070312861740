import { Months } from '../../../shared/typings/months';
import { Periode } from '../interfaces/periode.interface';
import { Trimestre } from '../typings/trimestre.enum';

export function getPeriodFromDate(date: Date): Periode {
  const periode: Periode = new Periode();

  periode.year = date.getFullYear();
  periode.trimestre =
    date.getMonth() <= Months.MARCH
      ? Trimestre.T1
      : date.getMonth() <= Months.JUNE
      ? Trimestre.T2
      : date.getMonth() <= Months.SEPTEMBER
      ? Trimestre.T3
      : Trimestre.T4;
  return periode;
}

export function getTrimestreNumber(trimestre: Trimestre) {
  switch (trimestre) {
    case Trimestre.T1: {
      return 1;
    }
    case Trimestre.T2: {
      return 2;
    }
    case Trimestre.T3: {
      return 3;
    }
    case Trimestre.T4:
    case Trimestre.Annee: {
      return 4;
    }
  }
}

export function toPeriodInt(year: number, t: Trimestre): number {
  return year * 10 + getTrimestreNumber(t);
}

export function previousPeriod(periode: Periode): Periode {
  periode.trimestre =
    periode.trimestre === Trimestre.T1
      ? Trimestre.T4
      : periode.trimestre === Trimestre.T2
      ? Trimestre.T1
      : periode.trimestre === Trimestre.T3
      ? Trimestre.T2
      : Trimestre.T3;
  if (periode.trimestre === Trimestre.T4) periode.year--;

  return periode;
}

export function nextPeriod(periode: Periode): Periode {
  periode.trimestre =
    periode.trimestre === Trimestre.T1
      ? Trimestre.T2
      : periode.trimestre === Trimestre.T2
      ? Trimestre.T3
      : periode.trimestre === Trimestre.T3
      ? Trimestre.T4
      : Trimestre.T1;
  if (periode.trimestre === Trimestre.T1) periode.year++;

  return periode;
}

export function getPeriodeAValider(date: Date): Periode {
  const periode: Periode = new Periode();
  (periode.trimestre =
    date.getMonth() <= Months.MARCH
      ? Trimestre.T4
      : date.getMonth() <= Months.JUNE
      ? Trimestre.T1
      : date.getMonth() <= Months.SEPTEMBER
      ? Trimestre.T2
      : Trimestre.T3),
    (periode.year = date.getFullYear());

  if (periode.trimestre === Trimestre.T4) {
    periode.year--;
  }

  return periode;
}

export function getTrimesterAndYearFromPeriodString(periodeString: string): {
  trimestre?: string;
  annee?: number;
} | null {
  const periodeArr = periodeString?.split(' ');

  if (periodeArr?.length > 0) {
    return {
      trimestre: periodeArr[0].trim(),
      annee: Number(periodeArr[1].trim()),
    };
  }

  return null;
}
