import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcTranslateService } from '@tc/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrls: ['./statistiques.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatistiquesComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription;

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService
  ) {
    super(store$);
  }

  async ngOnInit() {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
