export interface TcVersionState {
  versionInfo: IVersionState;
}

export interface IVersionState {
  currentDate?: string;
  version?: string;
}

export const getVersionInfo = (state: TcVersionState) => state.versionInfo;
