import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TcCoreModule } from '@tc/core';
import { NgxMaskModule } from 'ngx-mask';

const components = [];

@NgModule({
  imports: [CommonModule, TcCoreModule, NgxMaskModule.forRoot({})],
  providers: [DecimalPipe],
  declarations: [...components],
  exports: [...components, CommonModule, FormsModule, TcCoreModule],
})
export class SharedModule {}
