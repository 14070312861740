import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-tc-formly-select',
  templateUrl: './tc-formly-select.component.html',
  styleUrls: ['./tc-formly-select.component.scss'],
})
export class TcFormlySelectComponent extends FieldType implements OnInit {
  label = '';
  selected = '';
  items: { value: any; label: string }[] = [];

  selectedItem: any;

  ngOnInit(): void {
    this.label = this.to.label || this.label;
    this.selected = this.to.default || this.selected;
    this.items = this.to.items || this.items;
    this.selected = this.formControl.value || this.selected;

    if (this.items.length === 1 && !this.selected) {
      this.selectedItem = this.items[0].value;
    } else if (this.selected) {
      const itemExists = this.items.filter(
        item => item.value === this.selected
      );

      if (itemExists.length > 0) {
        this.selectedItem = this.selected;
      } else {
        if (this.items.length === 1) {
          this.selectedItem = this.items[0].value;
        }
      }
    }
  }

  public onChange($event) {
    this.formControl.setValue($event.value);

    if (this.to.onChange) {
      this.to.onChange($event);
    }
  }
}
