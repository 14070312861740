import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcFilter } from '../../../interfaces/tc-filter';
import { TcFilterOptions } from '../../../interfaces/tc-filter-options';

@Component({
  selector: 'tc-toggle',
  templateUrl: './tc-toggle.component.html',
  styleUrls: ['./tc-toggle.component.css'],
})
export class TcToggleComponent implements OnInit {
  @Input()
  item: TcFilter;

  @Output()
  changeValue = new EventEmitter<boolean>();

  value: boolean;
  options: TcFilterOptions = {};

  ngOnInit() {
    this.options = this.item.options || this.options;

    this.value = Boolean(this.options?.default);
  }

  public onChangeValue() {
    this.changeValue.emit(this.value);
  }
}
