import { createAction, props } from '@ngrx/store';
import { BaseTcStorePayload } from '@tc/abstract';
import {
  AddTcGridButtonClickedPayload,
  CloaseAndNavigateButtonClickedPlayload,
  CloseAndOpenAnotherDetailButtonClickedPlayload,
  DeactivateTcGridButtonClickedPayload,
  DeleteTcGridButtonClickedPayload,
  EditTcGridButtonClickedPayload,
  NavigatePayload,
  OpenEmailAppPayload,
  OpenFilteredDataDetailPayload,
  OpenPhoneAppPayload,
} from '../payloads';

/**
 * The prefix for all the tc grid actions
 */
export const TC_SMART_LIST_DETAIL_ACTIONS_PREFIX: string =
  '[tc-smart-list-detail]';

/**
 * this action is triggered when add button was clicked
 */
export const addTcGridButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Add Button Clicked`,
  props<AddTcGridButtonClickedPayload>()
);

/**
 * This action is triggered when edit button was clicked on a row
 */
export const editTcGridButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Edit Button Clicked`,
  props<EditTcGridButtonClickedPayload>()
);

/**
 * This action is triggered when delete button was clicked on a row
 */
export const deleteTcGridButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Delete Button Clicked`,
  props<DeleteTcGridButtonClickedPayload>()
);

/**
 * This action is triggered when deactivate button was clicked in a row
 */
export const deactivateTcGridButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Deactivate Button Clicked`,
  props<DeactivateTcGridButtonClickedPayload>()
);

/**
 * Used for submiting and closing the details dialog at the same time
 */
export const submitAndCloseDialogButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Submit and close Dialog Button Clicked`,
  props<BaseTcStorePayload>()
);

/**
 * Used for closing and navigating the details dialog at the same time
 */
export const closeDialogAndNavigateButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Close Dialog and Navigate Button Clicked`,
  props<CloaseAndNavigateButtonClickedPlayload>()
);

/**
 * Used for closing and opening another details dialog at the same time
 */
export const closeAndOpenAnotherDialogButtonClicked = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Close and Open Another Dialog Button Clicked`,
  props<CloseAndOpenAnotherDetailButtonClickedPlayload>()
);

/**
 * Used for navigating to other routes.Can be chained with an aditional action as a callback.
 */
export const navigate = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Navigate`,
  props<NavigatePayload>()
);

/**
 * Used for opening a detail of a filtered data. Can be nicely chained to the navigate action.
 */
export const openFilteredDataDetail = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Open Filtered Data Detail Payload`,
  props<OpenFilteredDataDetailPayload>()
);

/**
 * Used for opening the email app of the user's device
 */
export const openEmailApp = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Open Email App`,
  props<OpenEmailAppPayload>()
);

/**
 * Used for opening the phone app of the user's device
 */
export const openPhoneApp = createAction(
  `${TC_SMART_LIST_DETAIL_ACTIONS_PREFIX}/Open Phone App`,
  props<OpenPhoneAppPayload>()
);
