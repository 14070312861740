import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class TcMissingTranslationHandler implements MissingTranslationHandler {
  // Global sections of the translation file to iterate to check if the key exists in one of theses sections.
  // To create a new section, just add the key to the array.
  // Mind the order. First key in the array will be checked first.
  private readonly globalSections = ['globalLabels', 'globalMessages'];

  public handle(params: MissingTranslationHandlerParams) {
    // Iterate on each section to check if the key is here and return it if found.
    for (const globalSection of this.globalSections) {
      // Check if you find the full flatten key inside the sections. Exemple : test.delete-element.
      if (this.hasTranslation(params, globalSection, params.key)) {
        const keyFound = globalSection + '.' + params.key;
        return params.translateService.instant(keyFound);
      }
      // Split key on dot and return the last part to find out if this is a "global" key. Exemple : test.delete-element will return delete-element.
      const globalKey = this.getGlobalKey(params.key);
      if (this.hasTranslation(params, globalSection, globalKey)) {
        const keyFound = globalSection + '.' + globalKey;
        return params.translateService.instant(keyFound);
      }
    }

    // By default, if not found in global sections, return the key without translation.
    return params.key;
  }

  private getGlobalKey(key: string) {
    return key.split('.').pop();
  }

  private hasTranslation(
    params: MissingTranslationHandlerParams,
    section: string,
    key: string
  ): boolean {
    // Check withing the translation service if the translation exists for the provided language
    const translation =
      params.translateService.translations?.[
        params.translateService.currentLang
      ]?.[section]?.[key];
    return translation !== undefined;
  }
}
