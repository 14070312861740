import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigKeys } from '../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../shared/services/config.service';
import { AmsRouteProvider } from '../../services/ams-route.provider';
import { User } from '../typings/User';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private basePath = 'http://localhost:3333/api';

    constructor(
        private httpClient: HttpClient,
        private readonly config: ConfigService,
        private readonly routerService: AmsRouteProvider
    )
    {
        this.basePath = config.get(ConfigKeys.API_BASE_PATH);
    }

    public async getUserProfile(): Promise<User> {
        const response = await this.httpClient.get<User>(`${this.basePath}/${this.routerService.getRoutes().userProfile}`).toPromise();
        return response;
    }
}