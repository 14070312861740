import { Injectable } from '@angular/core';
import moment from 'moment';
import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterTypes } from '@tc/abstract';

@Injectable({ providedIn: 'root' })
export class TcFilterSerializerService {
  private readonly specialKeys: string[] = [
    TcFilterTypes.anyFieldContains,
    TcFilterTypes.anyFieldStartsWith,
  ];

  public serialize(filters) {
    if (!filters) {
      return null;
    }

    const response = {};

    for (const key of Object.keys(filters)) {
      const value = filters[key];

      const filter = this.getFilterByType(key, value);
      if (!filter) {
        continue;
      }

      response[key] = filter;
    }

    return Object.keys(response).length ? response : null;
  }

  private getFilterByType(key: string, value: any) {
    if (this.specialKeys.indexOf(key) !== -1) {
      return value;
    }

    if (!value?.filterType) {
      return null;
    }
    const type = value.filterType;

    switch (type) {
      case FilterTypesEnum.DateRange:
        const start = this.formatDate(
          value?.value?.start,
          value?.value?.format
        );
        const end = this.formatDate(value?.value?.end, value?.value?.format);

        if (!start || !end) {
          return null;
        }

        return {
          filterType: FilterTypesEnum.DateRange,
          value: `${start}|${end}`,
        };
      default:
        return this.hasValue(value?.value) ? value : null;
    }
  }

  private hasValue(value) {
    return !(
      typeof value === 'undefined' ||
      (typeof value === 'string' && !value)
    );
  }

  private formatDate(value, format: string) {
    if (!value || !format) {
      return null;
    }
    return moment(value).format(format);
  }
}
