import {
  getTcPanelListComponent,
  getTcPanelListItemSize,
} from './../../store/selectors/tc-panel-list-selectors';
import { getTcData, getTcDataTotal, loadTcMoreData } from '@tc/data-store';
import { DEFAULT_TC_DATA_STATE_KEY } from '@tc/data-store';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import {
  DEFAULT_TC_PANEL_LIST_STATE_KEY,
  NgRxTcPanelListState,
} from '../../store/state/tc-panel-list-state';
import { hasValue } from '@tc/utils';
import { NgRxTcDataState } from '@tc/data-store';
import { selectByKey, selectValueByKey } from '@tc/store';
import { loadTcData } from '@tc/data-store';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'tc-panel-list',
  templateUrl: './tc-panel-list.component.html',
  styleUrls: ['./tc-panel-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcPanelListComponent implements OnInit, OnDestroy {
  @Input() storeKey: string;
  component: string;

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  public asyncData: any[];
  private dataSubscription: Subscription;
  /**
   * Panel list store
   */
  panelListStore$: Observable<NgRxTcPanelListState>;

  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  /**
   *  Item size
   */
  itemSize: number = 100;

  /**
   * Infinite scroll percent
   */
  infiniteScrollPercent = 75;

  /**
   *  Is at the end
   */
  isEnd = false;

  /**
   *
   *  Current page
   */
  currentPage = 1;

  constructor(private readonly store$: Store<any>) {
    this.panelListStore$ = this.store$.pipe(
      select(DEFAULT_TC_PANEL_LIST_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  async ngOnInit() {
    this.component = await selectValueByKey(
      getTcPanelListComponent,
      this.panelListStore$,
      this.storeKey
    );

    this.itemSize = await selectValueByKey(
      getTcPanelListItemSize,
      this.panelListStore$,
      this.storeKey
    );

    setTimeout(() => {
      // load panel list data
      this.loadData();
    });

    setTimeout(() => {
      // Get data
      this.dataSubscription = selectByKey(
        getTcData,
        this.dataStore$,
        this.storeKey
      ).subscribe((data) => {
        // This means that a filter event has been triggered and in order to show to the user a refresh
        // kind of effect where the scroll goes back up we need to set the data to null for a brief moment
        if (data?.length < this.asyncData?.length) {
          this.asyncData = null;
        }

        setTimeout(() => {
          this.asyncData = data;
        });
      });
    });
  }

  /**
   * Method to load panel list data
   */
  private loadData() {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.storeKey,
        skip: 0,
      })
    );
  }

  async onPanelScroll($event) {
    if (this.isEnd) {
      return;
    }

    const viewportTotal = this.viewport.getDataLength();
    const scrollPercent = ($event / this.viewport.getDataLength()) * 100;

    if (scrollPercent >= this.infiniteScrollPercent) {
      const total = await selectByKey(
        getTcDataTotal,
        this.dataStore$,
        this.storeKey
      )
        .pipe(take(1))
        .toPromise();
      const data = await selectByKey(getTcData, this.dataStore$, this.storeKey)
        .pipe(take(1))
        .toPromise();

      const dataLength = data.length;

      if (dataLength < total) {
        this.store$.dispatch(
          loadTcMoreData({
            storeKey: this.storeKey,
            skip: dataLength,
          })
        );
        this.currentPage++;
      }

      if (viewportTotal >= total) {
        this.isEnd = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }
}
