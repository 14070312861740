import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcFilterStoreComponent } from './components/tc-filter-store/tc-filter-store.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { tcFilterReducer } from './store';
import { DEFAULT_TC_FILTER_STATE_KEY } from './store/state';
import { TcFilterEffects } from './store/effects/tc-filter-effects';
import { TcSmartFormModule } from '@tc/smart-form';

const components = [TcFilterStoreComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    FormlyModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,

    TranslateModule,
    TcSmartFormModule,

    EffectsModule.forFeature([TcFilterEffects]),
    StoreModule.forFeature(DEFAULT_TC_FILTER_STATE_KEY, tcFilterReducer),
  ],
  exports: [...components],
})
export class TcFilterStoreModule {}
