import { AmsStatsInterface } from '../../../shared/interfaces/ams-stats.interface';
import { IndicateursEtat } from '../interfaces/indicateurs-etat.interface';
import { Trimestre } from '../typings/trimestre.enum';

export interface IndicatorsState {
  currentYear?: number;
  associationId?: number;
  stateIndicators?: { indicators: IndicateursEtat[], trimestre?: Trimestre  };
  initialValues?: boolean;
  currentTrimestre?: string;
  associationNameDetails: AmsStatsInterface[];
  associationCodeDetails: AmsStatsInterface[];
  indicatorToUpdate?: IndicateursEtat;
  ticadiIndicatorToUpdate?: IndicateursEtat;
  ticadiIndicatorsToUpdate?: {
    anneeIndicateur: IndicateursEtat,
    trimestreIndicateur: IndicateursEtat
  }
}

export const initialIndicatorsState: IndicatorsState = {
  currentYear: new Date().getFullYear(),
  stateIndicators: { indicators: [], trimestre: undefined },
  initialValues: false,
  currentTrimestre: '',
  associationId: undefined,
  associationCodeDetails: [],
  associationNameDetails: [],
  indicatorToUpdate: undefined,
  ticadiIndicatorToUpdate: undefined
};
