import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeliveryNotesState } from './delivery-notes.state';

const getDeliveryNotesState =
  createFeatureSelector<DeliveryNotesState>('delivery-notes');

export const getBLMainData = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state?.blMainDetails
);

export const getBLDateData = createSelector(
  getDeliveryNotesState,
  (state: DeliveryNotesState) => state?.blDateDetails
);
