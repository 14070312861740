import { TcTranslateService } from '@tc/core';
import { AssociationCategorie } from '../../associations/types/association-categorie';
import { IndicateursEtat } from '../interfaces/indicateurs-etat.interface';
import { Trimestre } from '../typings/trimestre.enum';
import { getLatestEndedTrimestreAndYear } from './periode-helpers';
import moment from 'moment';
import { IndicatorType } from '../typings/indicator-type.enum';
import { StatusValidation } from '../typings/status-validation.enum';

const MARGIN_BETWEEN_SECTIONS = 20;
const TABLE_FONT_SIZE = 10;
const CELL_FONT_SIZE = 8;
const TABLE_BACKGROUND_COLOR = '#9bb2d4';
const TABLE_BORDER_COLOR = '#5981b8';
const TRIMESTRE_VALID_BACKGROUND = '#92d050';
const TRIMESTRE_NON_RECU_BACKGROUND = '#d9d9d9';
const TABLE_FILL_OPACITY = 0.4;

export const createIndicatorsCategorie2Pdf = (
  associationName: string,
  associationCode: string,
  yearIndicators: IndicateursEtat[],
  translateService: TcTranslateService
) => {
  const yearIndicator = yearIndicators.find(i => i.trimestreIndicateurs === Trimestre.T4);
  return [
    { ...getTitleContent(translateService) },
    { ...getFiltersContent(
        translateService,
        associationName,
        associationCode
      ) 
    },
    ...getCategorie2IndicatorsContent(yearIndicators, yearIndicator, translateService),
    ...getCommentsData(yearIndicator, translateService)
  ]
}

const getCategorie2IndicatorsContent = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  const yearVolume = yearIndicator?.indVolumeAnnee;
  const t1Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T1);
  const t2Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T2);
  const t3Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T3);
  const t4Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T4);
  return [
    {
      text: '\n\n'
    },
    {
      text: '\n'
    },
    {
      fontSize: CELL_FONT_SIZE,
      alignment: 'center',
      table: {
        widths: ['auto', 60, 60, 60, 60, 60],
        headerRows: 1,
        body: [
          [
            // Header
            {
              ...getTableHeaderCell('', true, 2)
            },
            {
              ...getTrimestreHeaderCell(t1Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t2Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t3Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t4Indicator, translateService)
            },
            {
              ...getYearHeaderCell(yearIndicator, translateService) 
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.netWeightReceivedFromBa'),
              fillColor: TABLE_BACKGROUND_COLOR,
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(yearVolume?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.registeredHouseholdsWithCurrentRights'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyersInscrits),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.numberOfPeopleRegistered'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesInscrites),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.numberOfBeneficiairesPassages'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesAidees),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.numberOFOpeningDaysOverPeriod'),
              fillColor: TABLE_BACKGROUND_COLOR,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: Number(yearIndicator?.indFonctionnementAnnee?.nbJoursOuverture || 0).toFixed(1),
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ]
        ]
      }
    }
  ]
}

export const createIndicatorsCategorie1Pdf = (
  associationName: string,
  associationCode: string,
  yearIndicators: IndicateursEtat[],
  translateService: TcTranslateService,
) => {
  const yearIndicator = yearIndicators.find(i => i.trimestreIndicateurs === Trimestre.T4);
  return [
    { ...getTitleContent(translateService) },
    {
      ...getFiltersContent(
        translateService,
        associationName,
        associationCode
      ),
    },
    ...getDistributedVolumesData(yearIndicators, yearIndicator, translateService),
    ...getBeneficiairesData(yearIndicators, yearIndicator, translateService),
    ...getFunctionementData(yearIndicators, yearIndicator, translateService),
    ...getCommentsData(yearIndicator, translateService)
  ];
};

const getTitleContent = (translateService: TcTranslateService) => {
  return {
    text: translateService.instant('indicators-page.pdfExport.title'),
    bold: true,
    style: 'header',
    alignment: 'center',
    margin: [0, 0, 0, MARGIN_BETWEEN_SECTIONS],
  };
};

const getFiltersContent = (
  translateService: TcTranslateService,
  associationName: string,
  associationCode: string
) => {
  return {
    alignment: 'justify',
    columns: [
      {
        type: 'none',
        ul: [
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.association'
              )} `,
              { text: associationName, bold: true },
            ],
          },
          { text: ' ' },
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.periodeAValide'
              )} `,
              { text: `${translateService.instant(`indicators-etat.pdfExport.trimestre.${getLatestEndedTrimestreAndYear().trimestre}`)} ${new Date().getFullYear()}`, bold: true },
            ],
          },
        ],
      },
      {
        type: 'none',
        ul: [
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.associationCode'
              )} `,
              { text: associationCode, bold: true },
            ],
          },
          { text: ' ' },
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.exportDate'
              )} `,
              { text: moment(new Date()).format('DD/MM/YYYY'), bold: true },
            ],
          },
        ],
      },
    ],
  };
};

const getDistributedVolumesData = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  const yearVolume = yearIndicator?.indVolumeAnnee;
  const t1Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T1);
  const t2Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T2);
  const t3Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T3);
  const t4Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T4);

  return [
    {
      text: '\n\n'
    },
    {
      text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.table.name'),
      bold: true
    },
    {
      text: '\n'
    },
    {
      fontSize: CELL_FONT_SIZE,
      alignment: 'center',
      table: {
        widths: ['auto', 40, 60, 60, 60, 60, 60],
        headerRows: 1,
        body: [
          [
            // Header
            {
              ...getTableHeaderCell('', true, 2)
            },
            {
              ...getTableHeaderCell(
                translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.unit'),
                true
              )
            },
            {
              ...getTrimestreHeaderCell(t1Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t2Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t3Indicator, translateService)
            },
            {
              ...getTrimestreHeaderCell(t4Indicator, translateService)
            },
            {
              ...getYearHeaderCell(yearIndicator, translateService) 
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.distributedNetWeight'),
              fillColor: TABLE_BACKGROUND_COLOR,
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              fillColor: TABLE_BACKGROUND_COLOR,
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: roundNumber(yearVolume?.poidsNetKg),
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.productBreakdown'),
              fillColor: TABLE_BACKGROUND_COLOR,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.fruits'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsFruits),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsFruits),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsFruits),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsFruits),
            },
            {
              text: roundNumber(yearVolume?.poidsFruits),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.viande'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsViande),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsViande),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsViande),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsViande),
            },
            {
              text: roundNumber(yearVolume?.poidsViande),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.feculents'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsFeculents),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsFeculents),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsFeculents),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsFeculents),
            },
            {
              text: roundNumber(yearVolume?.poidsFeculents),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.produitsLaitiers'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsProdLaitiers),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsProdLaitiers),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsProdLaitiers),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsProdLaitiers),
            },
            {
              text: roundNumber(yearVolume?.poidsProdLaitiers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.matieresGrasses'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsMatieresGrasses),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsMatieresGrasses),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsMatieresGrasses),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsMatieresGrasses),
            },
            {
              text: roundNumber(yearVolume?.poidsMatieresGrasses),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.sucresSales'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre?.poidsSucresSales),
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre?.poidsSucresSales),
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre?.poidsSucresSales),
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre?.poidsSucresSales),
            },
            {
              text: roundNumber(yearVolume?.poidsSucresSales),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.breakdownByDistributedWeight'),
              fillColor: TABLE_BACKGROUND_COLOR,
              alignment: 'left',
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceTotal?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceTotal?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceTotal?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceTotal?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.breakdownByProductClass'),
              fillColor: TABLE_BACKGROUND_COLOR,
              alignment: 'left',
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.fruits'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFruits?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFruits?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFruits?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFruits?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.viande'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceViande?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceViande?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceViande?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceViande?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.feculents'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFeculents?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFeculents?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFeculents?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceFeculents?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.produitsLaitiers'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceProduitsLaitiers?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceProduitsLaitiers?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceProduitsLaitiers?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceProduitsLaitiers?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.matieresGrasses'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceMatieresGrasses?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceMatieresGrasses?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceMatieresGrasses?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceMatieresGrasses?.poidsAchats),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.sucresSales'),
              margin: [20, 0, 0, 0],
              alignment: 'left',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.banqueAlimentaire'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceSucresSales?.poidsBA),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.corporateAndDonations'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceSucresSales?.poidsDonsEntreprises),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.donsParticuliers'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceSucresSales?.poidsDonsParticuliers),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.distributedVolumes.achats'),
              margin: [40, 0, 0, 0],
              alignment: 'left',
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.kg'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indVolumeAnnee?.poidsParSourceSucresSales?.poidsAchats),
            }
          ]
        ]
      }
    }
  ];
};

const getTableHeaderCell = (label: string, isHeader = false, colspan = 1) => ({
  text: label,
  alignment: 'center',
  colspan,
  bold: isHeader,
  fontSize: isHeader ? TABLE_FONT_SIZE : CELL_FONT_SIZE,
  borderColor: getTableBorderColor(),
});

const getTrimestreHeaderCell = (indicator: IndicateursEtat | undefined, translateService: TcTranslateService) => ({
  text: !!indicator ? `${translateService.instant(`indicators-etat.pdfExport.trimestre.${indicator?.trimestreIndicateurs}`)} ${indicator?.annee} \n ${translateService.instant(`indicators-etat.pdfExport.statusValidation.${indicator?.statutValidation}`)}` : '',
  alignment: 'center',
  fillColor: (indicator?.isNonRecu || indicator?.statutValidation === StatusValidation.ARevoir) ? TRIMESTRE_NON_RECU_BACKGROUND : TRIMESTRE_VALID_BACKGROUND,
  fontSize: TABLE_FONT_SIZE,
  borderColor: getTableBorderColor()
});

const getYearHeaderCell = (indicator: IndicateursEtat | undefined, translateService: TcTranslateService) => ({
  text: !!indicator ? `${translateService.instant('indicators-etat.pdfExport.trimestre.annee')} ${indicator.annee} \n ${translateService.instant(`indicators-etat.pdfExport.statusValidation.${indicator.statutValidation}`)}` : '',
  alignment: 'center',
  fillColor: indicator?.isNonRecu ? TRIMESTRE_NON_RECU_BACKGROUND : TRIMESTRE_VALID_BACKGROUND,
  fontSize: TABLE_FONT_SIZE,
  borderColor: getTableBorderColor()
});

const getTableBorderColor = () => [
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
  TABLE_BORDER_COLOR,
];

const getBeneficiairesData = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  const t1Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T1);
  const t2Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T2);
  const t3Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T3);
  const t4Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T4);

  return [
    {
      text: '\n\n'
    },
    {
      text: translateService.instant('indicators-etat.pdfExport.beneficiaires.tableHeader'),
      bold: true
    },
    {
      text: '\n'
    },
    {
      fontSize: CELL_FONT_SIZE,
      alignment: 'center',
      table: {
        widths: ['auto', 60, 60, 60, 60, 60],
        headerRows: 1,
        body: [
          [
            // Header
            {
              ...getTableHeaderCell('', true, 2)
            },
            {
              ...getSimpleTrimestreHeader(t1Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t2Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t3Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t4Indicator, translateService)
            },
            {
              ...getTableHeaderCell(`${translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.year')} ${yearIndicator?.annee}`, true)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.registeredHouseholdsWithCurrentRights'),
              bold: true,
              alignment: 'left'
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre.nbFoyersInscrits),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre.nbFoyersInscrits),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre.nbFoyersInscrits),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre.nbFoyersInscrits),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyersInscrits),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.numberOfPeopleRegistered'),
              bold: true,
              alignment: 'left'
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre.nbPersonnesInscrites),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre.nbPersonnesInscrites),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesInscrites),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.numberOfBeneficiairesPassages'),
              bold: true,
              alignment: 'left'
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre.nbPersonnesAidees),
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre.nbPersonnesAidees),
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre.nbPersonnesAidees),
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre.nbPersonnesAidees),
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesAidees),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.breakdownByAge'),
              bold: true,
              fillColor: TABLE_BACKGROUND_COLOR,
              alignment: 'left'
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: getAgeRangeLabel(0, 3, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnes0a3ans),
            }
          ],
          [
            {
              text: getAgeRangeLabel(4, 14, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnes4a14ans),
            }
          ],
          [
            {
              text: getAgeRangeLabel(15, 25, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnes15a25ans),
            }
          ],
          [
            {
              text: getAgeRangeLabel(26, 64, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnes26a64ans),
            }
          ],
          [
            {
              text: getAgeRangeLabel(65, null, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesPlus65ans),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.breakdownByGender'),
              bold: true,
              fillColor: TABLE_BACKGROUND_COLOR,
              alignment: 'left'
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.masculin'),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbHommes),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.feminin'),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFemmes),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.breakdownByIncome'),
              bold: true,
              fillColor: TABLE_BACKGROUND_COLOR,
              alignment: 'left'
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR
            }
          ],
          [
            {
              text: getIncomeRangeLabel(0, 2.99, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyers0a3Euro),
            }
          ],
          [
            {
              text: getIncomeRangeLabel(3, 4.99, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyers3a5Euro),
            }
          ],
          [
            {
              text: getIncomeRangeLabel(5, 7.99, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyers5a7Euro),
            }
          ],
          [
            {
              text: getIncomeRangeLabel(8, 9.99, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyers7a10Euro),
            }
          ],
          [
            {
              text: getIncomeRangeLabel(10, null, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyersPlus10Euro),
            }
          ],
        ]
      }
    }
  ]
};

const getSimpleTrimestreHeader = (indicator: IndicateursEtat | undefined, translateService) => ({
  text: !!indicator ? `${translateService.instant(`indicators-etat.pdfExport.trimestre.${indicator.trimestreIndicateurs}`)} ${indicator.annee}` : '',
  alignment: 'center',
  fontSize: TABLE_FONT_SIZE,
  bold: true
});

const getAgeRangeLabel = (ageStart: number, ageEnd: number | null, translateService: TcTranslateService) => {
  if (!ageEnd) {
    return `${ageStart} ${translateService.instant('indicators-etat.pdfExport.beneficiaires.yearsPlus')}`;
  }
  return `${ageStart} - ${ageEnd} ${translateService.instant('indicators-etat.pdfExport.beneficiaires.years')}`;
}

const getIncomeRangeLabel = (incomeStart: number, incomeEnd: number | null, translateService: TcTranslateService) => {
  if (!incomeEnd) {
    return `${incomeStart} ${translateService.instant('indicators-etat.pdfExport.beneficiaires.euroPlus')}`;
  }
  return `${incomeStart} - ${incomeEnd} ${translateService.instant('indicators-etat.pdfExport.beneficiaires.euro')}`;
}

const getFunctionementData = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  return [
    {
      text: '\n\n'
    },
    {
      text: translateService.instant('indicators-etat.pdfExport.functionement.fonctionnement'),
      bold: true
    },
    {
      text: '\n'
    },
    {
      table: {
        headerRows: 1,
        body: [
          [
            // Header
            {
              ...getTableHeaderCell('', true, 2)
            },
            {
              ...getTableHeaderCell(translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.unit'), true)
            },
            {
              ...getTableHeaderCell(translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.year'), true)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.functionement.numberOfDays'),
              bold: true
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indFonctionnementAnnee?.nbJoursOuverture)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.functionement.averageEmployeeCount'),
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.etp')
            },
            {
              text: roundNumber(yearIndicator?.indFonctionnementAnnee?.nbSalaries)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.functionement.averageVolunteersCount'),
              bold: true
            },
            {
              text: translateService.instant('indicators-etat.pdfExport.unit.etp')
            },
            {
              text: roundNumber(yearIndicator?.indFonctionnementAnnee?.nbBenevoles)
            }
          ]
        ]
      }
    }
  ];
};

const getCommentsData = (indicator: IndicateursEtat | undefined, trasnlateService: TcTranslateService) => {
  const comments = indicator?.associationCommentaire?.[new Date().getFullYear()];
  return [
    {
      text: '\n\n'
    },
    {
      text: trasnlateService.instant('indicators-etat.pdfExport.comments'),
      bold: true
    },
    {
      text: '\n'
    },
    {
      table: {
        widths: ['*'],
        heights: function (row) {
					if (!comments) {
            return 50;
          }
				},
        body: [
          [
            {
              text: comments
            }
          ]
        ]
      }
    }
  ]
};

export const getIndicatorsTicadiPDF = (
  associationName: string,
  associationCode: string,
  periode: string,
  indicators: IndicateursEtat[],
  translateService: TcTranslateService
) => {
  const yearIndicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.Annee);

  const categorie = indicators[0].categorieAssociation;
  let pdfDataContent: null | any = null;
  // Ticadi PDF export is different based on categories
  if (categorie === AssociationCategorie.Categorie1) {
    pdfDataContent = getTableContentForIndicatorsWithoutVersionCategory1(indicators, yearIndicator, translateService)
  } else {
    pdfDataContent = getTableContentForIndicatorsWithoutVersionCategory2(indicators, yearIndicator, translateService)
  }

  return [
    { ...getTitleContent(translateService) },
    { ...getFiltersContentForIndicatorsWithoutVersion(
      translateService,
      associationName,
      associationCode,
      periode
    ) },
    ...pdfDataContent
  ];
}

const getFiltersContentForIndicatorsWithoutVersion = (
  translateService: TcTranslateService,
  associationName: string,
  associationCode: string,
  periode: string
) => {
  return {
    alignment: 'justify',
    columns: [
      {
        type: 'none',
        ul: [
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.association'
              )} `,
              { text: `${associationCode} - ${associationName}`, bold: true },
            ],
          },
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.exportDate'
              )} `,
              { text: moment(new Date()).format('DD/MM/YYYY'), bold: true },
            ],
          },
          { text: ' ' },
          {
            text: [
              `${translateService.instant(
                'indicators-etat.pdfExport.header.periodeAValide'
              )} `,
              { text: periode, bold: true }
            ],
          },
        ],
      }
    ],
  };
}

const getTableContentForIndicatorsWithoutVersionCategory2 = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  const t1Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T1);
  const t2Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T2);
  const t3Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T3);
  const t4Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T4);

  return [
    {
      text: '\n'
    },
    {
      fontSize: CELL_FONT_SIZE,
      alignment: 'center',
      table: {
        widths: ['auto', 60, 60, 60, 60, 60],
        headerRows: 1,
        body: [
          [
            // Header
            {
              text: '',
              border: [false, false, false, false]
            },
            {
              ...getSimpleTrimestreHeader(t1Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t2Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t3Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t4Indicator, translateService)
            },
            {
              text: `${translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.year')} ${yearIndicator?.annee}`,
              alignment: 'center',
              bold: true,
              fontSize: TABLE_FONT_SIZE
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.statusDesIndicateurs')
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t1Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t2Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t3Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t4Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${yearIndicator?.statutValidation}`)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.volumesDistributes'),
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.poidsNetDistributeEnKg')
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(yearIndicator?.indVolumeTrimestre.poidsNetKg, 2)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.podsNetDistributesEnTonnes')
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(yearIndicator?.indVolumeTrimestre.poidsNetT, 2)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.beneficiaires'),
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombreFoyersInscrits'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyersInscrits, 0)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombrePersonnesInscrites'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesInscrites, 0)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombrePasagesBeneficiaires'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesAidees, 0)
            }
          ]
        ]
      }
    }
  ];
}

const getTableContentForIndicatorsWithoutVersionCategory1 = (indicators: IndicateursEtat[], yearIndicator: IndicateursEtat | undefined, translateService: TcTranslateService) => {
  const t1Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T1 && (i.type.toUpperCase() === IndicatorType.TICADI_BA || i.type === IndicatorType.BA));
  const t2Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T2 && (i.type.toUpperCase() === IndicatorType.TICADI_BA || i.type === IndicatorType.BA));
  const t3Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T3 && (i.type.toUpperCase() === IndicatorType.TICADI_BA || i.type === IndicatorType.BA));
  const t4Indicator = indicators.find(i => i.trimestreIndicateurs === Trimestre.T4 && (i.type.toUpperCase() === IndicatorType.TICADI_BA || i.type === IndicatorType.BA));

  return [
    {
      text: '\n'
    },
    {
      fontSize: CELL_FONT_SIZE,
      alignment: 'center',
      table: {
        widths: ['auto', 60, 60, 60, 60, 60],
        headerRows: 1,
        body: [
          [
            // Header
            {
              text: '',
              border: [false, false, false, false]
            },
            {
              ...getSimpleTrimestreHeader(t1Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t2Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t3Indicator, translateService)
            },
            {
              ...getSimpleTrimestreHeader(t4Indicator, translateService)
            },
            {
              text: `${translateService.instant('indicators-etat.pdfExport.distributedVolumes.header.year')} ${yearIndicator?.annee}`,
              alignment: 'center',
              bold: true,
              fontSize: TABLE_FONT_SIZE
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.statusDesIndicateurs')
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t1Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t2Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t3Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${t4Indicator?.statutValidation}`)
            },
            {
              text: translateService.instant(`indicators-etat.pdfExport.statusValidation.${yearIndicator?.statutValidation}`)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.volumesDistributes'),
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.poidsNetDistributeEnKg')
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre.poidsNetKg, 2)
            },
            {
              text: roundNumber(yearIndicator?.indVolumeTrimestre.poidsNetKg, 2)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.podsNetDistributesEnTonnes')
            },
            {
              text: roundNumber(t1Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t2Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t3Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(t4Indicator?.indVolumeTrimestre.poidsNetT, 2)
            },
            {
              text: roundNumber(yearIndicator?.indVolumeTrimestre.poidsNetT, 2)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.beneficiaires'),
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              bold: true
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombreFoyersInscrits'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbFoyersInscrits, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbFoyersInscrits, 0)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombrePersonnesInscrites'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesInscrites, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesInscrites, 0)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.nombrePasagesBeneficiaires'),
            },
            {
              text: roundNumber(t1Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t2Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t3Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(t4Indicator?.indBeneficiairesTrimestre?.nbPersonnesAidees, 0)
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee?.nbPersonnesAidees, 0)
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.noVersion.ventialtionDesBeneficiairesParPage'),
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: getAgeRangeLabel(0, 3, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbPersonnes0a3ans, 0),
            }
          ],
          [
            {
              text: getAgeRangeLabel(4, 14, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbPersonnes4a14ans, 0),
            }
          ],
          [
            {
              text: getAgeRangeLabel(15, 25, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbPersonnes15a25ans, 0),
            }
          ],
          [
            {
              text: getAgeRangeLabel(26, 64, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbPersonnes26a59ans, 0),
            }
          ],
          [
            {
              text: getAgeRangeLabel(65, null, translateService),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbPersonnesPlus65ans, 0),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.breakdownByGender'),
              bold: true,
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY,
              alignment: 'left'
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            },
            {
              text: '',
              fillColor: TABLE_BACKGROUND_COLOR,
              fillOpacity: TABLE_FILL_OPACITY
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.masculin'),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbHommes, 0),
            }
          ],
          [
            {
              text: translateService.instant('indicators-etat.pdfExport.beneficiaires.feminin'),
              margin: [20, 0, 0, 0],
              bold: true,
              alignment: 'left'
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: ''
            },
            {
              text: roundNumber(yearIndicator?.indBeneficiairesAnnee.nbFemmes, 0),
            }
          ]
        ]
      }
    }
  ];
}

const roundNumber = (number?: number, decimals?: number) => {
  if (decimals !== null && decimals !== undefined) {
    return Number(number).toFixed(decimals);
  }
  if (number) {
    return Math.round(number);
  }
  return '';
}