import { TcDumbComponent } from './tc-dumb-component';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TcConfigTypes, TcDialogContentConfig } from './component-configs';

/**
 * Base class for all tc detail components
 */
export class TcAbstractDetailComponent extends TcDumbComponent {
  /**
   * The detail id from the data
   */
  public detailId: string;

  /**
   * The detail data
   */
  public data: any;

  /**
   * Default dialog content config
   */
  public dialogConfig: TcDialogContentConfig = {
    dialogStoreKey: undefined, // to be set by developer when creating a new detail component
    configType: TcConfigTypes.TcDialogContent,
    hasHeader: true,
    headerConfig: {
      configType: TcConfigTypes.TcDialogHeader,
      enableTitleSlot: true,
      enableActionsSlot: true,
      hasCloseButton: true,
    },
    hasFooter: true,
    footerConfig: {
      configType: TcConfigTypes.TcDialogFooter,
      enableActionsSlot: false,
    },
  };

  /**
   * We expect that when a dialog is opened it will be have a data property
   * this.dialog.open(component, {data: payload.data})
   * @param data
   */
  constructor(@Inject(MAT_DIALOG_DATA) data) {
    super();
    this.detailId = data?._id;
    this.data = data;
  }
}
