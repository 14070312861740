import {
  AfterViewInit,
  Component,
  ElementRef,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import moment from 'moment';
import { TcGridValidator } from '../../services/tc-grid-validator';
import { TcValidationsService } from '../../services/tc-validations.service';

@Component({
  selector: 'tc-grid-datepicker-editor',
  templateUrl: './tc-grid-datepicker-editor.component.html',
  styleUrls: ['./tc-grid-datepicker-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridDatePickerEditorComponent
  extends TcGridValidator
  implements AgEditorComponent, AfterViewInit
{
  @ViewChild('input') input: ElementRef;

  public label: string;
  public value;
  public inputValue: string;
  public triggerTemplate: TemplateRef<any>;

  constructor(public readonly tcValidationsService: TcValidationsService) {
    super(tcValidationsService);
  }

  ngAfterViewInit() {
    setTimeout(() => this.input?.nativeElement?.focus());
  }

  public agInit(params) {
    this.data = params?.data ?? null;
    this.value = params?.value ? moment(params?.value) : null;
    this.label = params?.label ?? '';
    this.validators = params?.validators;
    this.inputValue = this.value?.format(params?.format ?? 'DD/MM/YYYY');

    this.triggerTemplate = params?.triggerTemplate;
  }

  public onChange(updated) {
    this.inputValue = updated;
    super.validate(updated);
  }

  public getValue() {
    return this.errors?.length ? this.inputValue : this.value;
  }

  // all editions will be in popup
  // https://www.ag-grid.com/angular-grid/cell-editing/#popup
  public isPopup() {
    return true;
  }
}
