import {
  DEFAULT_TC_DATA_STATE_KEY,
  emptyTcData,
  getTcDataFilters,
  getTcNewDataCreatedOn,
} from '@tc/data-store';
import {
  ColDefExtended,
  ColumnNumberPerDevice,
  ListOrder,
  TcSortDef,
} from '@tc/abstract';
import * as R from 'ramda';
import { setTcGridSelection, updateTcGridRow } from './../../store/actions/tc-grid-actions';
import {
  getTcGridColumns,
  getTcGridOptions,
  getTcGridTake,
  getTcInfiniteScrollPercent,
  getTcGridAddButton,
  getTcGridCssClass,
  getTcGridColumnNumberPerDevice,
} from './../../store/selectors/tc-grid-selectors';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcGridState,
} from '../../store/state/tc-grid-state';
import { Observable, Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {
  BodyScrollEvent,
  CellClickedEvent,
  ColumnApi,
  DisplayedColumnsChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDataUpdatedEvent,
  SortChangedEvent,
  ValueSetterParams
} from 'ag-grid-community';
import {
  TcGridCellComponent,
  TcGridColumnType,
} from '../../enums/tc-grid.enum';
import { TcTranslateService } from '../../../services/tc-translate.service';
import { TcGridAutocompleteEditorComponent } from '../tc-grid-autocomplete-editor/tc-grid-autocomplete-editor.component';
import { TcGridButtonsRendererComponent } from '../tc-grid-buttons-renderer/tc-grid-buttons-renderer.component';
import { TcGridCheckboxEditorComponent } from '../tc-grid-checkbox-editor/tc-grid-checkbox-editor.component';
import { TcGridDatePickerEditorComponent } from '../tc-grid-datepicker-editor/tc-grid-datepicker-editor.component';
import { TcGridFaButtonsRendererComponent } from '../tc-grid-fa-buttons-renderer/tc-grid-fa-buttons-renderer.component';
import { TcGridHeaderSelectionComponent } from '../tc-grid-header-selection/tc-grid-header-selection.component';
import { TcGridHtmlRendererComponent } from '../tc-grid-html-renderer/tc-grid-html-renderer.component';
import { TcGridMatInputEditorComponent } from '../tc-grid-mat-input-editor/tc-grid-mat-input-editor.component';
import { TcGridMultiSelectEditorComponent } from '../tc-grid-multi-select-editor/tc-grid-multi-select-editor.component';
import { TcGridNoRowsOverlayComponent } from '../tc-grid-no-rows-overlay/tc-grid-no-rows-overlay.component';
import { TcGridMassUpdateHeaderComponent } from '../tc-grid-mass-update-header/tc-grid-mass-update-header.component';
import { TcGridCheckboxRendererComponent } from '../tc-grid-checkbox-renderer/tc-grid-checkbox-renderer.component';
import { TcGridTemplateRendererComponent } from '../tc-grid-template-renderer/tc-grid-template-renderer.component';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { selectByKey, selectValueByKey } from '@tc/store';
import { TcGridAddButtonConfig } from '@tc/abstract';
import { loadTcData, loadTcMoreData } from '@tc/data-store';
import { getTcData, getTcDataTotal } from '@tc/data-store';
import { NgRxTcDataState } from '@tc/data-store';
import { TcGridEventButtonsRendererComponent } from '../tc-grid-event-buttons-renderer/tc-grid-event-buttons-renderer.component';
import { TcGridSmartButtonsRendererComponent } from '../tc-grid-smart-buttons-renderer/tc-grid-smart-buttons-renderer.component';
import { TcGridConcatArrayRendererComponent } from '../tc-grid-concat-array-renderer/tc-grid-concat-array-renderer.component';
import { TcGridLinkRendererComponent } from '../tc-grid-link-renderer/tc-grid-link-renderer.component';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import { TcGridEmailRendererComponent } from '../tc-grid-mail-renderer/tc-grid-email-renderer.component';
import { TcGridPhoneRendererComponent } from '../tc-grid-phone-renderer/tc-grid-phone-renderer.component';
import { TcGridColumnConfigProviderService } from '../../services/tc-grid-column-config-provider.service';

/**
 * TcGrid component
 */
@Component({
  selector: 'tc-grid',
  templateUrl: './tc-grid.component.html',
  styleUrls: ['./tc-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridComponent implements OnInit, OnDestroy {
  /**
   * Grid config
   */
  @Input() storeKey: string;

  /**
   * Whether the grid should support expandable rows
   */
  @Input() expandableRows: boolean;

  /**
   * Grid store
   */
  gridStore$: Observable<NgRxTcGridState>;

  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  /**
   * Grid rows.
   */
  rowData: any[];

  /**
   * Total number of rows.
   */
  totalNumberOfRows: Number | string;

  /**
   * Grid columns definitions
   */
  public columnDefs;

  /**
   * Columns Subscription to the store selector
   */
  private columnsSubscription: Subscription;

  /**
   *  Add button config
   */
  addButton: TcGridAddButtonConfig;

  /**
   * css class
   */
  cssClass: string;

  /**
   * Grid api
   */
  private gridApi: GridApi;

  /**
   * Grid column api
   */
  private columnApi: ColumnApi;

  /**
   * Grid options
   */
  public gridOptions: GridOptions;

  /**
   * Options Subscription to the store selector
   */
  private optionsSubscription: Subscription;

  /**
   * Add button Subscription to the store selector
   */
  private addButtonSubscription: Subscription;

  /**
   * CSS class Subscription to the store selector
   */
  private cssClassSubscription: Subscription;

  /**
   * Total entries number Subscription to the store selector
   */
  private totalSubscription: Subscription;

  /**
   * Currently loaded entries Subscription to the store selector
   */
  private rowDataSubscription: Subscription;

  /**
   * TcNewDataCreatedOn Subscription to the store selector
   */
  private getTcNewDataCreatedOnSubscription: Subscription;

  /**
   * Grid ready event
   */
  @Output() ready = new EventEmitter<GridReadyEvent>();

  /**
   * This property is used to know if rowData was updated into grid
   * The client has updated data for the grid by changing the rowData bound property with immutableData=true.
   * This property will be changed in rowDataUpdated event
   */
  private rowDataUpdated = false;

  /**
   * Array that stores the grid column priorities in order
   */
  gridColumnPriorities: Array<{ field: string; priority: number }> = [];

  /**
   * @ignore used for better performance when hiding/showing columns depending on screen size
   */
  screenResolution: string;

  /**
   * Containing the amount of columns to display on each device based on priority
   * Pulled the tc-grid store.
   */
  columnNumberPerDevice: ColumnNumberPerDevice;

  /**
   * @ignore columnNumberPerDevice Subscription to the store selector
   */
  columnNumberPerDeviceSubscription: Subscription;

  /**
   * Used for creating a loading effect for total number of rows
   */
  totalInterval: any;

  private expandedRows: any = {};

  /**
   * Constructor
   */
  constructor(
    private readonly translate: TcTranslateService,
    private readonly store$: Store<any>,
    private readonly config: ConfigService,
    private readonly tcGridColsDefService: TcGridColumnConfigProviderService
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.columnsSubscription?.unsubscribe();
    this.columnNumberPerDeviceSubscription?.unsubscribe;
    this.optionsSubscription?.unsubscribe();
    this.getTcNewDataCreatedOnSubscription?.unsubscribe();
    this.addButtonSubscription?.unsubscribe();
    this.cssClassSubscription?.unsubscribe();
    this.rowDataSubscription?.unsubscribe();
    this.totalSubscription?.unsubscribe();
    // Cleanup selection on destroy to clear up memory in redux
    this.sendSelectionUpdate([]);
  }

  /**
   * TcGrid on init funtion
   */
  ngOnInit() {
    this.rowDataSubscription = selectByKey(
      getTcData,
      this.dataStore$,
      this.storeKey
    ).subscribe((data) => {
      this.rowData = data;
      // Also reset expanded rows when resetting row data no matter what
      this.expandedRows = {};

      setTimeout(() => {
        this.gridApi?.resetRowHeights();
      });
    });

    this.columnsSubscription = selectByKey(
      getTcGridColumns,
      this.gridStore$,
      this.storeKey
    ).subscribe((columnDefs) => {
      /**
       * Adding the storeKey to the cellRendererParams
       */

      this.columnDefs = [];
      this.gridColumnPriorities = [];

      // columnDef has cellRendererParams as a read-only property and we cannot assign to it so we
      // have to make a copy of each culumnDef
      columnDefs.forEach((columnDef: ColDefExtended) => {
        let newColumnDef = { ...columnDef };

        //hack to disable grid sorting on client
        if (!columnDef.comparator) {
          newColumnDef.comparator = (
            valueA,
            valueB,
            nodeA,
            nodeB,
            isInverted
          ) => valueA;
        }

        if (
          columnDef.cellRenderer === TcGridCellComponent.SmartButtonRenderer
        ) {
          // cellRendererParams has preventExtensions so instead of extending we have to reassign
          newColumnDef.cellRendererParams = {
            ...newColumnDef.cellRendererParams,
            storeKey: this.storeKey,
          };

          // Action buttons should always be visible thus if the developer doesn't set any
          // priority we set the highest priority by default
          if (newColumnDef.priority === undefined) {
            newColumnDef.priority = 0;
          }
        }

        /**
         * These types of column should not be sortable by default. If the dev decides that it needs
         * to be sortable we trust that he knows better.
         */
        switch (columnDef.cellRenderer) {
          case TcGridCellComponent.SmartButtonRenderer:
          case TcGridCellComponent.ConcatArrayRenderer:
            if (newColumnDef.sortable === undefined) {
              newColumnDef.sortable = false;
            }
            break;
        }

        /**
         * Auto-set column name(to a key) and translate it
         * If the dev does specify a headerValueGetter we trust that he knows better
         */
        if (!newColumnDef.headerValueGetter) {
          newColumnDef.headerValueGetter = () => {
            if (newColumnDef.field)
              return this.translate.instant(
                `${this.storeKey}.header.${newColumnDef.field}`
              );
            return '';
          };
        }

        this.columnDefs.push(newColumnDef);

        // Store the column in gridColumnPriorities
        this.gridColumnPriorities.push({
          field: newColumnDef.field,
          priority: newColumnDef.priority,
        });
      });

      // If grid supports expandable rows, add another column at the start containig the expand/collapse icon
      if (this.expandableRows) {
        const expandableColumnDef = {
          resizable: false,
          width: 10,
          maxWidth: 40,
          cellStyle: { 'text-overflow': 'unset' },
          cellRenderer: (params: any) => {
            if (!params.data.children) {
              return '';
            }
            if (params.data.expanded) {
              return `<i class="toggle-row-expand-icon fas fa-minus"></i>`;
            }
            return `<i class="toggle-row-expand-icon fas fa-plus"></i>`;
          }
        }

        this.columnDefs = [expandableColumnDef, ...this.columnDefs];
      }

      //Sort the gridColumnPriorities
      this.gridColumnPriorities.sort((a, b) => {
        if (a.priority === undefined && b.priority === undefined) return 0;

        if (a.priority === undefined) return 1;

        if (b.priority === undefined) return -1;

        return a.priority - b.priority;
      });
    });

    this.columnNumberPerDeviceSubscription = selectByKey(
      getTcGridColumnNumberPerDevice,
      this.gridStore$,
      this.storeKey
    ).subscribe((columnNumberPerDevice) => {
      this.columnNumberPerDevice = {
        //Default Values
        extraSmallDevice: 2,
        smallDevice: 4,
        mediumDevice: 4,
        largeDevice: 5,
        extraLargeDevice: 6,
        extraExtraLargeDevice: 20,

        ...(columnNumberPerDevice ? columnNumberPerDevice : {}),
      };
    });

    let gridOptions = {};

    this.optionsSubscription = selectByKey(
      getTcGridOptions,
      this.gridStore$,
      this.storeKey
    ).subscribe((options) => {
      gridOptions = options;
    });

    this.getTcNewDataCreatedOnSubscription = selectByKey(
      getTcNewDataCreatedOn,
      this.dataStore$,
      this.storeKey
    ).subscribe((createdOn?: string) => this.onCompletelyNewData());

    this.setGridOptions(gridOptions);

    this.addButtonSubscription = selectByKey(
      getTcGridAddButton,
      this.gridStore$,
      this.storeKey
    ).subscribe((addButtonConfig) => {
      this.addButton = addButtonConfig;
    });

    this.cssClassSubscription = selectByKey(
      getTcGridCssClass,
      this.gridStore$,
      this.storeKey
    ).subscribe((cssClass) => {
      this.cssClass = cssClass;
    });

    this.totalSubscription = selectByKey(
      getTcDataTotal,
      this.dataStore$,
      this.storeKey
    ).subscribe((total) => {
      if (total === undefined) {
        this.totalNumberOfRows = '';

        this.totalInterval = setInterval(() => {
          if ((this.totalNumberOfRows as string).length > 3)
            this.totalNumberOfRows = '';
          else this.totalNumberOfRows += '.';
        }, 100);
      } else {
        if (this.totalInterval) clearInterval(this.totalInterval);
        this.totalNumberOfRows = total;
      }
    });
  }

  private handleToggleExpand(event: CellClickedEvent) {
    // Verify that the user clicked the first cell
    const field = event.colDef.field;
    const colIndex = event.columnApi
      .getAllColumns()
      ?.findIndex((col) => col.getColDef().field === field);

    if (colIndex !== 0) {
      return;
    }

    const { rowIndex } = event;
    const { id: rowId } = event.data;
    let clickedRow = this.rowData.find(row => row.id === rowId);
    const children = clickedRow?.children;
    if (!children) {
      return;
    }
    if (this.expandedRows[rowId]) {
      // Remove the node children from the grid row data
      clickedRow = {
        ...clickedRow,
        expanded: false
      }
      const updatedRows = [...this.rowData.slice(0, rowIndex), clickedRow, ...this.rowData.slice(rowIndex + 1 + children.length)]
      this.expandedRows[rowId] = false;
      this.rowData = updatedRows;
    } else {
      // Insert the children of the expanded row right after the expanded row in the grid
      clickedRow = {
        ...clickedRow,
        expanded: true
      }
      const updatedRows = [...this.rowData.slice(0, rowIndex), clickedRow, ...children, ...this.rowData.slice(rowIndex + 1, this.rowData.length)];
      this.rowData = updatedRows;
      this.expandedRows[rowId] = true;
    }
  }

  /**
   * Set grid options
   * @param options GridOptions interface
   */
  private setGridOptions(options: GridOptions) {
    this.gridOptions = {
      // https://www.ag-grid.com/angular-data-grid/immutable-data/
      immutableData: true,
      // https://www.ag-grid.com/angular-data-grid/viewport/#selection
      getRowNodeId: (data) => data.id || data._id,
      rowClass: 'center-vertically',
      rowClassRules: {
        // If expandable rows are enabled, apply an expanded row class to expanded rows
        'tc-grid-row-expanded': function (params) { return this.expandableRows && (params.data.expanded || !params.data.children) }.bind(this)
      },
      // grid ready
      onGridReady: (event) => this.onGridReady(event),
      // grid scroll
      onBodyScroll: (event) => this.onBodyScroll(event),
      // on rowDataUpdated
      onRowDataUpdated: (event) => this.onRowDataUpdated(event),
      // The list of displayed columns changed.
      onDisplayedColumnsChanged: (event) =>
        this.onDisplayedColumnsChanged(event),
      // Grid sort was changed
      onSortChanged: (event) => this.onSortChanged(event),

      singleClickEdit: options?.singleClickEdit ?? true,
      suppressMovableColumns: options?.suppressMovableColumns ?? true,
      suppressCellSelection: options.suppressCellSelection ?? true,
      suppressRowClickSelection: options.suppressRowClickSelection ?? true,

      noRowsOverlayComponent: TcGridCellComponent.NoRowsOverlay,

      ...options,
      frameworkComponents: this.tcGridColsDefService.getConfig(options),
    };

    if (this.expandableRows) {
      this.gridOptions = {
        ...this.gridOptions,
        onCellClicked: this.handleToggleExpand.bind(this)
      }
    }

    const defaultColDef: ColDefExtended = {
      valueSetter: (params: ValueSetterParams) => {
        // TODO MMN: check if this code is needed
        // const validators = params?.colDef?.cellEditorParams?.validators ?? [];
        // const errors = this.tcValidationsService.validate(validators, params?.newValue);

        // const isValid = !errors?.length;
        // params.data[params?.column?.getColId()] = isValid ? params?.newValue : params?.oldValue;

        // if (isValid) {

        let oldValue = params.oldValue;
        let newValue = params.newValue;

        const isNaN = +params.newValue !== +params.newValue;

        if (typeof +params.newValue === 'number' && !isNaN) {
          newValue = +params.newValue;
        }

        const colId = params?.column?.getColId();
        const nodeId = params.node.id;

        const rowData = R.clone(params.data);
        rowData[colId] = newValue; // TODO MMN: validation : remove if not needed

        this.store$.dispatch(
          updateTcGridRow({
            storeKey: this.storeKey,
            nodeId,
            colId,
            oldValue,
            newValue,
            rowData,
          })
        );

        // }

        return false;
      },
      sortingOrder: [ListOrder.Asc, ListOrder.Desc],
      sortable: true,
    };

    const defaultColumnTypes: {
      [key: string]: ColDefExtended;
    } = {
      [TcGridColumnType.Numeric]: {
        headerClass: 'ag-numeric-header',
        cellClass: 'ag-numeric-cell',
      },
      [TcGridColumnType.Date]: {
        headerClass: 'ag-date-header',
        cellClass: 'ag-date-cell',
      },
      [TcGridColumnType.Centered]: {
        headerClass: 'ag-centered-header',
        cellClass: 'ag-centered-cell',
      },
    };

    if (this.gridOptions.columnTypes) {
      this.gridOptions.columnTypes = {
        ...this.gridOptions.columnTypes,
        ...defaultColumnTypes,
      };
    } else {
      this.gridOptions.columnTypes = defaultColumnTypes;
    }

    if (this.gridOptions.defaultColDef) {
      this.gridOptions.defaultColDef = {
        ...this.gridOptions.defaultColDef,
        ...defaultColDef,
      };
    } else {
      this.gridOptions.defaultColDef = defaultColDef;
    }
  }

  /**
   * Method to return the page size. By default 100
   */
  private async getPageSize() {
    const pageSize = await selectValueByKey(
      getTcGridTake,
      this.gridStore$,
      this.storeKey
    );

    return pageSize ?? 100;
  }

  /**
   * Method to return grid sort model
   */
  private getGridSort(): TcSortDef {
    const columns = this.columnApi?.getColumnState();
    const sortableColumns = columns?.filter((c) => c.sort !== null);
    const [sortModel] = sortableColumns;

    return sortModel
      ? ({ key: sortModel.colId, order: sortModel.sort } as TcSortDef)
      : null;
  }

  /**
   * Method to load grid data
   * this method is called on grid ready or when sort was changed
   */
  private loadGridData() {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.storeKey,
        skip: 0,
        sort: this.getGridSort(),
      })
    );
  }

  /**
   * Method to empty grid data when sorting so that the grid doesn't sort the current data
   * because with what we have now there is no way to prevent it.
   */
  private emptyGridData() {
    this.store$.dispatch(
      emptyTcData({
        storeKey: this.storeKey,
      })
    );
  }

  /**
   * Grid ready
   * @param event GridReadyEvent
   */
  async onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;

    this.columnApi = event.columnApi;

    this.gridApi?.sizeColumnsToFit();

    this.gridApi.addEventListener(
      'gridSizeChanged',
      this.updateGridAfterResize.bind(this)
    );

    this.translate.onLangChange.subscribe(() => this.gridApi.refreshHeader());

    setTimeout(async () => {
      const storeFilter = await selectValueByKey(
        getTcDataFilters,
        this.dataStore$,
        this.storeKey
      );
      // If default filters exists in store, then, the grid loading will be triggered from the filters.
      if (!(storeFilter?.filters?.length > 0)) {
        this.loadGridData();
      }
    });
  }

  /**
   * Updated the grid columns widh on Grid Size Changed and hide / show columns depending on priorities
   * @returns
   */
  updateGridAfterResize() {
    // Resize the grid
    this.gridApi.sizeColumnsToFit();

    // Determine window Witdh
    const windowWidth = window.innerWidth;

    // Get responsive options
    const responsiveOptions = this.config.get(
      ConfigKeys.layoutConfig.responsiveOptions
    );

    // Used for better performance
    let currentScreenResolution;

    // Determine the type of device we are on
    for (const [key, value] of Object.entries(
      (responsiveOptions as any).deviceBreakPoints
    )) {
      let { min: minResolution, max: maxResolution } = value as any;

      minResolution = minResolution && Number(minResolution.split('px')[0]);
      maxResolution = maxResolution && Number(maxResolution.split('px')[0]);

      if (minResolution) {
        if (maxResolution) {
          if (windowWidth >= minResolution && windowWidth <= maxResolution) {
            currentScreenResolution = key;
            break;
          }
        } else {
          if (windowWidth >= minResolution) {
            currentScreenResolution = key;
            break;
          }
        }
      } else if (maxResolution) {
        if (windowWidth <= maxResolution) {
          currentScreenResolution = key;
          break;
        }
      }
    }

    // If we were on the same resolution at the last grid resize do nothing
    if (this.screenResolution === currentScreenResolution) return;

    // Store the current resolution
    this.screenResolution = currentScreenResolution;

    // Array with all the column names in the grid
    const columnNamesArray: Array<string> = this.gridColumnPriorities.map(
      (elem) => elem.field as string
    );

    // Separate the fileds that should be shown from those that should be hidden
    const shownColumnNamesArray = columnNamesArray.splice(
      0,
      this.columnNumberPerDevice[currentScreenResolution]
    );

    // Show columns
    this.gridOptions?.columnApi?.setColumnsVisible(shownColumnNamesArray, true);

    // Hide columns
    this.gridOptions?.columnApi?.setColumnsVisible(columnNamesArray, false);
  }

  /**
   * Grid row data updated
   * @param event RowDataUpdatedEvent
   */
  async onRowDataUpdated(event: RowDataUpdatedEvent) {
    this.rowDataUpdated = true;
    // TODO MMN: ?
    setTimeout(async () => {
      this.gridApi?.sizeColumnsToFit();
      // const data = await this.select(getTcGridData).pipe(take(1)).toPromise();
      // const newRow = data.filter(d => d.id === 0);
      // if (newRow.length > 0) {
      //   this.gridApi?.startEditingCell({ rowIndex: 0, colKey: 'columnA' });
      // }
    }, 500);
  }

  /**
   * Grid columns changed
   * @param event DisplayedColumnsChangedEvent
   */
  onDisplayedColumnsChanged(event: DisplayedColumnsChangedEvent) {
    this.columnApi = event.columnApi;
    this.gridApi?.sizeColumnsToFit();
  }

  /**
   * Grid columns changed
   * @param event SortChangedEvent {key: 'nom', order: 'ASC"}
   */
  onSortChanged(event: SortChangedEvent) {
    setTimeout(() => {
      // Empty the grid
      this.emptyGridData();

      // Load the new data sorted
      this.loadGridData();
    });
  }

  onSelectionChanged() {
    const selection = this.gridApi.getSelectedRows();
    this.sendSelectionUpdate(selection);
  }

  private sendSelectionUpdate(selectedRows: any[]) {
    this.store$.dispatch(
      setTcGridSelection({
        storeKey: this.storeKey,
        selectedRows: selectedRows
      })
    );
  }

  /**
   * Grid scroll
   * @param event BodyScrollEvent
   */
  async onBodyScroll(event: BodyScrollEvent) {
    const displayedRowCount = event.api.getDisplayedRowCount();
    const rowHeight = event.api.getSizesForCurrentTheme().rowHeight;

    var bottomPx = event.api.getVerticalPixelRange().bottom;
    var gridHeight = displayedRowCount * rowHeight;
    const infiniteScrollPercent = await selectValueByKey(
      getTcInfiniteScrollPercent,
      this.gridStore$,
      this.storeKey
    );

    if (bottomPx > ((infiniteScrollPercent ?? 75) / 100) * gridHeight) {
      const total = await selectByKey(
        getTcDataTotal,
        this.dataStore$,
        this.storeKey
      )
        .pipe(take(1))
        .toPromise();
      const data = await selectByKey(getTcData, this.dataStore$, this.storeKey)
        .pipe(take(1))
        .toPromise();

      const gridDataLength = data.length;

      if (gridDataLength < total && this.rowDataUpdated) {
        this.rowDataUpdated = false;

        this.store$.dispatch(
          loadTcMoreData({
            storeKey: this.storeKey,
            skip: gridDataLength,
          })
        );
      }
    }
  }

  async addGridRow() {
    const { detailsPopupComponent, defaultModel, action } = this.addButton;

    this.store$.dispatch(
      action({
        storeKey: this.storeKey,
        detailsPopupComponent: detailsPopupComponent,
        defaultModel: defaultModel,
      })
    );
  }

  onCompletelyNewData(): void {
    if (this.gridApi?.getDisplayedRowCount() > 0) {
      this.gridApi?.ensureIndexVisible(0);
    }
  }
}
