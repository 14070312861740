import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * Displays an HTML text into an expandable panel
 */
@Component({
  selector: 'tc-display-html',
  templateUrl: './tc-display-html.component.html',
  styleUrls: ['./tc-display-html.component.scss'],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '18rem' })),
      transition('false => true', animate('500ms ease-out')),
      transition('true => false', animate('500ms ease-in')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TcDisplayHtmlComponent {
  /**
   * HTML text to display
   */
  @Input()
  text: string;

  /**
   * number of text characters after which expanding is displayed
   */
  @Input()
  length = 200;

  /**
   * text/translation key to display when panel is expanded
   */
  @Input()
  expandedText = 'collapse';

  /**
   * text/translation key to display when panel is collapsed
   */
  @Input()
  collapsedText = 'expand';

  /**
   * if true, the expand/collapse icons are displayed
   */
  @Input()
  showExpandIcon = true;

  /**
   * if true, the expand/collapse texts are displayed
   */
  @Input()
  showExpandText = false;

  /**
   * if true, instead of expanding the panel we will lunch an action
   */
  @Input()
  emitEventInsteadOfExpansion = false;

  /**
   * Emits the expand button click when emitEventInsteadOfExpansion
   * is set to true
   */
  @Output()
  expandClicked = new EventEmitter<void>();

  @Input()
  isExpanded: boolean = false;

  constructor() {}

  expandColapse($event) {
    $event.stopPropagation();

    if (this.emitEventInsteadOfExpansion) {
      this.expandClicked.emit();
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }
}
