import { TcTree } from './../../interfaces/tc-tree';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'tc-tree',
  templateUrl: './tc-tree.component.html',
  styleUrls: ['./tc-tree.component.scss'],
})
export class TcTreeComponent implements OnInit {
  @Input() treeData: TcTree[];

  @Output() treeNodeClicked = new EventEmitter();
  @Output() treeLeafClicked = new EventEmitter();

  public treeControl = new NestedTreeControl<TcTree>((node) => node.children);
  public dataSource = new MatTreeNestedDataSource<TcTree>();

  constructor() {}

  ngOnInit() {
    this.dataSource.data = this.treeData;
  }

  /**
   * Check if nodes have children. Handles node expansion in HTML template.
   */
  public hasChild = (_: number, node: TcTree) =>
    !!node.children && node.children.length > 0;

  /**
   * Method that fire an event "treeNodeClicked" when the node is clicked. The entire node is sent in the event.
   * @param node TcTree Node clicked on.
   */
  public tcTreeClick(node: TcTree) {
    this.treeNodeClicked.emit(node);
  }

  /**
   * Method that fire an event "treeLeafClicked" when a leaf node is clicked. The entire node is sent in the event.
   * @param node TcTree Node clicked on.
   */
  public leafClick(node: TcTree) {
    this.treeLeafClicked.emit(node);
    this.tcTreeClick(node);
  }
}
