import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tc-grid-no-rows-overlay',
  templateUrl: './tc-grid-no-rows-overlay.component.html',
  styleUrls: ['./tc-grid-no-rows-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcGridNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  agInit() {}
}
