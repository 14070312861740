import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';

import { SharedModule } from '../../shared/shared.module';
import {
  DeliveryNoteArticlesGridComponent,
} from './components/delivery-note-details/delivery-note-articles-grid/delivery-note-articles-grid.component';
import { DeliveryNoteDetailsComponent } from './components/delivery-note-details/delivery-note-details.component';
import { DeliveryNotesFilterComponent } from './components/delivery-notes-filter/delivery-notes-filter.component';
import { DeliveryNotesGridComponent } from './components/delivery-notes-grid/delivery-notes-grid.component';
import { DeliveryNotesService } from './services/delivery-notes.service';
import { DeliveryNotesEffects } from './store/delivery-notes.effects';
import { deliveryNotesReducer } from './store/delivery-notes.reducer';

const components = [
  DeliveryNotesFilterComponent,
  DeliveryNotesGridComponent,
  DeliveryNoteDetailsComponent,
  DeliveryNoteArticlesGridComponent,
];

const services = [DeliveryNotesService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    TcDialogModule,
    EffectsModule.forFeature([DeliveryNotesEffects]),
    StoreModule.forFeature('delivery-notes', deliveryNotesReducer),
    SharedModule,
    AgGridModule.withComponents([]),
  ],
  providers: [...services],
  exports: [...components],
})
export class DeliveryNotesModule {}
