import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TcUploadFileService {
  constructor(private http: HttpClient) {}

  public upload(
    file: File,
    url: string
  ): { progress: Observable<number>; body: Observable<any> } {
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append(file.name, file, file.name);

    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    // create a new progress-subject for every file
    const progress = new Subject<number>();

    const body = new Subject<any>();
    progress.next(0);

    // send the http-request and subscribe for progress-updates
    this.http.request(req).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          const percentDone = Math.round((100 * event.loaded) / event.total);

          console.log(file.name + ' - ' + percentDone);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();

          body.next(event.body);
          body.complete();
        } else if (event instanceof HttpErrorResponse) {
          progress.complete();
          body.complete();
        }
      },
      (error) => {
        progress.complete();
        body.complete();
      },
      () => {
        progress.complete();
        body.complete();
      }
    );

    // return the map of progress.observables
    return {
      progress: progress.asObservable(),
      body: body.asObservable(),
    };
  }
}
