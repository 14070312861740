import { Workbox, messageSW } from 'workbox-window';

const TIME_IN_MS = 5 * 1000;

export class ServiceWorkerService {
  static registrationStarted = false;

  static init = async (restartRequired = false) => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    let refreshing = false;

    if (ServiceWorkerService.registrationStarted) {
      return;
    }

    ServiceWorkerService.registrationStarted = true;

    const wb = new Workbox('/sw.js');

    const registration = await wb.register();

    if (registration && registration.waiting) {
      onSWWaiting();
    }
    wb.addEventListener('activated', onSWActivated);
    wb.addEventListener('waiting', onSWWaiting);
    (wb as any).addEventListener('externalactivated', onSWUpdated);
    (wb as any).addEventListener('externalwaiting', onSWWaiting);

    setTimeout(checkForUpdates, TIME_IN_MS);

    function onSWActivated(ev: any /* WorkboxLifecycleEvent*/) {
      if (ev.isUpdate) {
        return onSWUpdated();
      }

      if (restartRequired) {
        messageSW(ev.sw, {
          type: 'CLIENTS_CLAIM',
        });
      }
    }

    function onSWUpdated() {
      if (refreshing) {
        return;
      }

      // New Service Worker has been activated.
      // You will need to refresh the page.

      refreshing = true;
      return window.location.reload();
    }

    function onSWWaiting() {
      upgradeServiceWorker();
    }

    async function upgradeServiceWorker() {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({
          type: 'SKIP_WAITING',
        });
      }
    }

    async function checkForUpdates() {
      if (!wb) {
        return;
      }
      try {
        await wb.update();
      } finally {
        setTimeout(checkForUpdates, TIME_IN_MS);
      }
    }
  };
}
