import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TcNavMenuNodes } from '../../../interfaces/tc-nav-menu-nodes';
import { TcNavMenuConfiguration } from '../../../interfaces/tc-nav-menu-configuration';
import { TcNavMenuService } from '../../../services/tc-nav-menu.service';
import { TcNavMenuCONSTANT } from '../../../constants/tc-nav-menu-constant';
import { TcNavMenuBackgroundStyle } from '../../../interfaces/tc-nav-menu-background-style';

@Component({
  selector: 'tc-nav-menu',
  templateUrl: './tc-nav-menu.component.html',
  styleUrls: ['./tc-nav-menu.component.css'],
})
export class TcNavMenuComponent implements OnInit, OnChanges {
  @Input() items: TcNavMenuNodes[];
  @Input() configuration: TcNavMenuConfiguration = null;
  @Output() selectedItem = new EventEmitter<TcNavMenuNodes>();
  @Output() selectedLabel = new EventEmitter<TcNavMenuNodes>();
  currentNode: TcNavMenuNodes;
  nodeConfig: TcNavMenuConfiguration = {
    paddingAtStart: true,
    listBackgroundColor: null,
    fontColor: null,
    selectedListFontColor: null,
    interfaceWithRoute: null,
    collapseOnSelect: null,
    highlightOnSelect: false,
    rtlLayout: false,
  };
  isInvalidConfig = true;
  constructor(
    private router: Router,
    public multilevelMenuService: TcNavMenuService
  ) {}
  ngOnChanges() {
    this.detectInvalidConfig();
  }
  ngOnInit() {
    if (
      this.configuration !== null &&
      this.configuration !== undefined &&
      this.configuration !== '' &&
      this.configuration.interfaceWithRoute !== null &&
      this.configuration.interfaceWithRoute
    ) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateNodeByURL(event.url);
        }
      });
      this.updateNodeByURL(this.router.url);
    }
  }
  updateNodeByURL(url: string): void {
    const foundNode = this.multilevelMenuService.getMatchedObjectByUrl(
      this.items,
      url
    );
    if (
      foundNode !== undefined &&
      foundNode.link !== undefined &&
      foundNode.link !== null &&
      foundNode.link !== ''
      // && !foundNode.disabled // Prevent route redirection for disabled menu
    ) {
      this.currentNode = foundNode;
      this.selectedListItem(foundNode);
    }
  }
  checkValidData(): void {
    if (this.items.length === 0) {
      console.warn(TcNavMenuCONSTANT.ERROR_MESSAGE);
    } else {
      this.items = this.items.filter((n) => !n.hidden);
      this.multilevelMenuService.addRandomId(this.items);
    }
  }
  detectInvalidConfig(): void {
    if (
      this.configuration === null ||
      this.configuration === undefined ||
      this.configuration === ''
    ) {
      this.isInvalidConfig = true;
    } else {
      this.isInvalidConfig = false;
      const config = this.configuration;
      if (
        config.paddingAtStart !== undefined &&
        config.paddingAtStart !== null &&
        typeof config.paddingAtStart === 'boolean'
      ) {
        this.nodeConfig.paddingAtStart = config.paddingAtStart;
      }
      if (
        config.listBackgroundColor !== '' &&
        config.listBackgroundColor !== null &&
        config.listBackgroundColor !== undefined
      ) {
        this.nodeConfig.listBackgroundColor = config.listBackgroundColor;
      }
      if (
        config.fontColor !== '' &&
        config.fontColor !== null &&
        config.fontColor !== undefined
      ) {
        this.nodeConfig.fontColor = config.fontColor;
      }
      if (
        config.selectedListFontColor !== '' &&
        config.selectedListFontColor !== null &&
        config.selectedListFontColor !== undefined
      ) {
        this.nodeConfig.selectedListFontColor = config.selectedListFontColor;
      }
      if (
        config.interfaceWithRoute !== null &&
        config.interfaceWithRoute !== undefined &&
        typeof config.interfaceWithRoute === 'boolean'
      ) {
        this.nodeConfig.interfaceWithRoute = config.interfaceWithRoute;
      }
      if (
        config.collapseOnSelect !== null &&
        config.collapseOnSelect !== undefined &&
        typeof config.collapseOnSelect === 'boolean'
      ) {
        this.nodeConfig.collapseOnSelect = config.collapseOnSelect;
      }
      if (
        config.highlightOnSelect !== null &&
        config.highlightOnSelect !== undefined &&
        typeof config.highlightOnSelect === 'boolean'
      ) {
        this.nodeConfig.highlightOnSelect = config.highlightOnSelect;
      }
      if (
        config.rtlLayout !== null &&
        config.rtlLayout !== undefined &&
        typeof config.rtlLayout === 'boolean'
      ) {
        this.nodeConfig.rtlLayout = config.rtlLayout;
      }
      this.checkValidData();
    }
  }
  getClassName(): string {
    if (this.isInvalidConfig) {
      return TcNavMenuCONSTANT.DEFAULT_CLASS_NAME;
    } else {
      if (
        this.configuration.classname !== '' &&
        this.configuration.classname !== null &&
        this.configuration.classname !== undefined
      ) {
        return `${TcNavMenuCONSTANT.DEFAULT_CLASS_NAME} ${this.configuration.classname}`;
      } else {
        return TcNavMenuCONSTANT.DEFAULT_CLASS_NAME;
      }
    }
  }
  getGlobalStyle(): TcNavMenuBackgroundStyle {
    if (!this.isInvalidConfig) {
      const styles = {
        background: null,
      };
      if (
        this.configuration.backgroundColor !== '' &&
        this.configuration.backgroundColor !== null &&
        this.configuration.backgroundColor !== undefined
      ) {
        styles.background = this.configuration.backgroundColor;
      }
      return styles;
    }
  }
  isRtlLayout(): boolean {
    return this.nodeConfig.rtlLayout;
  }
  selectedListItem(event: TcNavMenuNodes): void {
    this.currentNode = event;
    if (
      event.items === undefined &&
      (!event.onSelected || typeof event.onSelected !== 'function')
    ) {
      this.selectedItem.emit(event);
    } else {
      this.selectedLabel.emit(event);
    }
  }
}
