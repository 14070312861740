export const TcNavMenuCONSTANT = {
  PADDING_AT_START: true,
  DEFAULT_CLASS_NAME: `tc-nav-menu-container`,
  DEFAULT_LIST_CLASS_NAME: `tc-nav-menu-item`,
  SELECTED_LIST_CLASS_NAME: `selected-tc-nav-menu-item`,
  ACTIVE_ITEM_CLASS_NAME: `active-tc-nav-menu-item`,
  DISABLED_ITEM_CLASS_NAME: `disabled-tc-nav-menu-item`,
  DEFAULT_SELECTED_FONT_COLOR: `white`,
  DEFAULT_LIST_BACKGROUND_COLOR: `transparent`,
  DEFAULT_LIST_FONT_COLOR: `white`,
  ERROR_MESSAGE: `Invalid data for material Multilevel List Component`,
};
