import { MaterialColor } from '@tc/abstract';
import { MaterialButtonType } from '../interfaces';

/**
 * 
 Get generic delete button config
 * @param action the action that is triggered when clicking in the button
 * @param options to override config or add options like permissionConfig
 * @returns TcSmartButton for delete button
 */
export function getDeleteButtonConfig(action: any, options: any) {
  return {
    color: MaterialColor.Warn,
    matIcon: 'delete',
    action: action,
    type: MaterialButtonType.Icon,
    actionPayload: {
      detailsPopupComponent: 'TcPromptDialogComponent',
      height: '12em',
      width: '28em',
    },
    ...options,
  };
}
