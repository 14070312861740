import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcComponent } from './tc-component';

/**
 * Base class for all tc smart components
 * I found myself adding the component decorator due to this issue when extending this class in InteractionsPanelItemComponent
 * https://stackoverflow.com/questions/60149052/this-constructor-is-not-compatible-with-angular-dependency-injection-because-its
 */
@Directive({})
export class TcSmartComponent extends TcComponent {
  protected store$: Store<any>;

  constructor(store$: Store<any>) {
    super();
    this.store$ = store$;
  }
}
