import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcCurrencyFormat',
})
export class TcCurrencyFormat implements PipeTransform {
  transform(value: any, symbol?: string): string {
    return this.transformText(value, symbol);
  }

  transformText = (value: number, symbol?: string): string => {
    let result: string;
    const numberStr = value + '';

    const integerPart = numberStr.split('.')[0];
    const decimalPart = numberStr.split('.')[1];

    if (
      decimalPart === '' ||
      decimalPart === undefined ||
      decimalPart === null
    ) {
      result = this.chunk(integerPart).join(' ');
      if (result && result !== '') {
        result = result + ',00';
      }
    } else {
      if (decimalPart.length === 1) {
        result = this.chunk(integerPart).join(' ') + ',' + decimalPart + '0';
      } else {
        result =
          this.chunk(integerPart).join(' ') + ',' + decimalPart.substring(0, 2);
      }
    }
    if (symbol) {
      result = result + ' ' + symbol;
    }
    return result;
  };

  chunk(text: string) {
    return text
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map((x) => {
        return x.split('').reverse().join('');
      })
      .reverse();
  }
}
