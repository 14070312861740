import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
  selector: 'tc-grid-checkbox-editor',
  templateUrl: './tc-grid-checkbox-editor.component.html',
  styleUrls: ['./tc-grid-checkbox-editor.component.scss'],
})
export class TcGridCheckboxEditorComponent implements AgEditorComponent {
  public label: string;
  public value: boolean;

  public agInit(params) {
    this.value = params?.value;
    this.label = params?.label ?? '';
  }

  public getValue() {
    return this.value;
  }
}
