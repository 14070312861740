import { Injectable } from '@angular/core';
import { IConfigService } from '@tc/config';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements IConfigService {
  private readonly jsonConfig = require('../../custom/assets/config.json');

  get<T>(key: string): T {
    return get(this.jsonConfig, key, undefined);
  }
}
