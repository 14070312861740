import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryNotesService {
  private basePath: string;

  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigService
  ) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }

  public async setBLTraite(id: number, traite: boolean) {
    const url = `${this.basePath}/bons-livraison/${id}/set-traite`;

    await this.httpClient.post(url, { traite }).toPromise();
  }
}
