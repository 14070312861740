export enum FilterTypesEnum {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  In = 'In',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  IsNotEmpty = 'IsNotEmpty',
  DateRange = 'DateRange',
  Contains = 'Contains',
  StartsWith = 'StartsWith',
  IsNotNullOrEmptyString = 'IsNotNullOrEmptyString',

  /**
   * This filter is used as minimun of an interval
   * and use 'TC_FILTER_INTERNAL' to works with
   * 'DateLessThanOrEqual' filter type.
   */
  DateGreaterThanOrEqual = 'DateGreaterThanOrEqual',

  /**
   * This filter is used as maximum of an interval
   * and use 'TC_FILTER_INTERNAL' to works with
   * 'DateGreaterThanOrEqual' filter type.
   */
  DateLessThanOrEqual = 'DateLessThanOrEqual',
}
