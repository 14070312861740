import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcConfigTypes, TcSmartFormConfig } from '@tc/abstract';
import { TcFormlyComponent } from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  initTcListDataStore,
  NgRxTcDataState,
} from '@tc/data-store';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { initTcSmartForm, initTcSmartFormSuccess } from '../actions';
import { InitTcSmartFormPayload } from '../payloads';
import * as R from 'ramda';
import { can } from '@tc/permissions';

@Injectable()
export class TcSmartFormEffects {
  dataStore$: Observable<NgRxTcDataState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   *
   */
  initTcSmartForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initTcSmartForm),
        tap(async (payload: InitTcSmartFormPayload) => {
          const { storeKey, config } = payload;

          const newConfig: TcSmartFormConfig = R.clone(config);

          // if permissions are set for the form, apply them
          let readonly = false;
          // Handle permission from subject and key
          if (newConfig?.permissionAction && newConfig?.permissionSubject) {
            const hasPermission = await this.store$
              .select(
                can(newConfig?.permissionAction, newConfig?.permissionSubject)
              )
              .pipe(take(1))
              .toPromise();
            readonly = !hasPermission;
          }
          // Handle permission from user defined boolean
          if (newConfig?.permissionCustom === false) {
            readonly = true;
          }

          const recursiveSearchForFields = (fieldGroup) => {
            fieldGroup.fieldGroup?.forEach((fieldGroup) => {
              if (fieldGroup.key) {
                if (readonly) {
                  fieldGroup.templateOptions = {
                    ...fieldGroup.templateOptions,
                    disabled: readonly,
                  };
                }
                switch (fieldGroup.type) {
                  case TcFormlyComponent.TcAutoComplete:
                  case TcFormlyComponent.TcMultiSelect:
                  case TcFormlyComponent.TcSmartSelect:
                    if (fieldGroup.templateOptions.dataProvider) {
                      const fieldStoreKey = `${storeKey}-${fieldGroup.key}`;

                      fieldGroup.templateOptions = {
                        ...fieldGroup.templateOptions,
                        storeKey: fieldStoreKey,
                      };

                      this.store$.dispatch(
                        initTcListDataStore({
                          storeKey: fieldStoreKey,
                          listConfig: {
                            storeKey: fieldStoreKey,
                            dataProvider:
                              fieldGroup.templateOptions.dataProvider,
                            configType: TcConfigTypes.TcFormField,
                          },
                        })
                      );
                    }
                    break;

                  default:
                    break;
                }

                // Set field name for testing purposes
                fieldGroup.name = `${storeKey}-${fieldGroup.key}`;
              }
              recursiveSearchForFields(fieldGroup);
            });
          };

          newConfig.fieldConfigs.forEach((fieldGroup) => {
            recursiveSearchForFields(fieldGroup);
          });

          this.store$.dispatch(
            initTcSmartFormSuccess({
              ...payload,
              config: newConfig,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
