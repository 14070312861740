import { createAction, props } from '@ngrx/store';
import { LoginMfaInterface } from '../interfaces/login-mfa.interface';
import { UserLoginCredentials } from '../interfaces/user-login.interface';
import { UserModel } from '../models/user.model';

export const login = createAction(
  '[Authentication] Login',
  props<UserLoginCredentials>()
);

export const loginWindows = createAction('[Authentication] Login windows');

export const refresh = createAction('[Authentication] Refresh JWT token');

export const verifyMfa = createAction('[Authentication] Verify Mfa');

export const loginMfa = createAction(
  '[Authentication] Login Mfa',
  props<{ code: string }>()
);

export const clearMfaOptions = createAction(
  '[Authentication] Clear Mfa Options'
);

export const clearUserData = createAction('[Authentication] Clear User Data');

export const openMfaLoginPage = createAction(
  '[Authentication] Open Login Mfa Page'
);

export const googleLogin = createAction('[Authentication] Login Google');

export const facebookLogin = createAction('[Authentication] Login Facebook');

export const saveToken = createAction(
  '[Authentication] Save authorization token',
  props<{ token: string }>()
);

export const saveMfaOptions = createAction(
  '[Authentication] Save MFA options',
  props<LoginMfaInterface>()
);

export const loginSuccess = createAction('[Authentication] Login Success');

export const loginFailure = createAction(
  '[Authentication] Login Failure',
  props<{ error: Error }>()
);

export const getCurrentUser = createAction('[Authentication] Get Current User');

export const getCurrentUserSuccess = createAction(
  '[Authentication] Get Current User Success',
  props<{ user: UserModel }>()
);

export const logout = createAction('[Authentication] Logout');

export const impersonate = createAction('[Authentication] Impersonate');

export const loginAs = createAction('[Authentication] LoginAs', props<{ login: string }>());
