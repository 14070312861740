import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { TcSmartButton } from '@tc/buttons';
import { selectPropertyByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TcDetailTitleConfig } from '@tc/abstract';
import { getTcSmartFormCurrentModel } from '../../store/selectors/tc-smart-form.selectors';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
} from '../../store/state/tc-smart-form-state';

/**
 * Displays a title with prefix and text and a list of buttons on a detail
 */
@Component({
  selector: 'tc-detail-title',
  templateUrl: './tc-detail-title.component.html',
  styleUrls: ['./tc-detail-title.component.scss'],
})
export class TcDetailTitleComponent extends TcSmartComponent {
  /**
   * Ths config of the component
   */
  @Input()
  set config(config: TcDetailTitleConfig) {
    this.titlePrefix = config?.titlePrefix;
    this.buttonsList = config?.buttonsList;
    if (config?.titleProperty) {
      this.titleText = selectPropertyByKey(
        getTcSmartFormCurrentModel,
        this.formStore$,
        config.storeKey,
        config.titleProperty
      );
    }
  }

  titlePrefix?: string;
  titleText: Observable<string>;
  buttonsList: TcSmartButton[];
  formStore$: Observable<NgRxTcSmartFormState>;

  constructor(public readonly store$: Store<any>) {
    super(store$);
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }
}
