import { loadTcPermissions, loadTcPermissionsSuccess } from '@tc/permissions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getLastError, TcAppState } from '@tc/store';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, tap, take, withLatestFrom } from 'rxjs/operators';
import { AppInitService } from '../../../services/app-init.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AuthService } from '../../../services/business-services/auth.service';
import { UsersService } from '../../../services/business-services/users.service';
import { verifyTerms } from '../../terms-conditions/store/terms-conditions.actions';
import { LoginMfaInterface } from '../interfaces/login-mfa.interface';
import { LoginResponseInterface } from '../interfaces/login-response.interface';
import {
  clearMfaOptions,
  facebookLogin,
  getCurrentUser,
  getCurrentUserSuccess,
  googleLogin,
  login,
  loginFailure,
  loginMfa,
  loginSuccess,
  logout,
  openMfaLoginPage,
  saveMfaOptions,
  saveToken,
  verifyMfa,
  refresh,
  loginWindows,
  impersonate,
  loginAs,
} from './auth.actions';
import { Idle } from '@ng-idle/core';
import { GenericRoutes } from '../../../shared/typings/generic-routes';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly store$: Store<TcAppState>,
    private readonly usersService: UsersService,
    private readonly translateService: TcTranslateService,
    private readonly notificationService: TcNotificationService,
    private readonly authenticationService: AuthenticationService,
    private readonly appInitService: AppInitService,
    private readonly idle: Idle
  ) {}

  login = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      mergeMap(({ username, password }) =>
        this.authenticationService
          .login(username, password)
          .then((options: LoginResponseInterface) => {
            this.store$.dispatch(saveToken({ token: options.accessToken }));

            return verifyMfa();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  loginWindows = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWindows),
      mergeMap(() =>
        this.authenticationService
          .loginWindows()
          .then((options: LoginResponseInterface) => {
            this.store$.dispatch(saveToken({ token: options.accessToken }));
            return verifyMfa();
          })
          .catch(async (error) => {
            const lastError = await this.store$
              .select(getLastError)
              .pipe(take(1))
              .toPromise();
            if(lastError?.status === 401){
              window.location.reload();
            }
            return loginFailure({ error })
          })
      )
    )
  );

  verifyMfa = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyMfa),
      mergeMap(() =>
        this.authService
          .mfaStatus()
          .then((options: LoginMfaInterface) => {
            if (options?.mfa) {
              this.store$.dispatch(saveMfaOptions(options));
              return openMfaLoginPage();
            }

            return verifyTerms();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  loginMfa = createEffect(() =>
    this.actions$.pipe(
      ofType(loginMfa),
      mergeMap(({ code }) =>
        this.authenticationService
          .mfa(code)
          .then(({ accessToken }) => {
            this.store$.dispatch(saveToken({ token: accessToken }));
            this.store$.dispatch(clearMfaOptions());

            return verifyTerms();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  openMfaLoginPage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openMfaLoginPage),
        tap(() => {
          this.router.navigateByUrl(`/${GenericRoutes.LoginMfa}`);
        })
      ),
    { dispatch: false }
  );

  googleLogin = createEffect(
    () =>
      this.actions$.pipe(
        ofType(googleLogin),
        tap(() => {
          this.authService.googleLogin();
        })
      ),
    { dispatch: false }
  );

  facebookLogin = createEffect(
    () =>
      this.actions$.pipe(
        ofType(facebookLogin),
        tap(() => {
          this.authService.facebookLogin();
        })
      ),
    { dispatch: false }
  );

  getCurrentUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUser),
      mergeMap(() =>
        this.usersService
          .getMe()
          .then((user) => {
            if (user) {
              return getCurrentUserSuccess({ user });
            } else {
              return logout();
            }
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  getCurrentUserSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getCurrentUserSuccess),
        tap(({ user }) => {
          const roles = user?.roles?.map((r) => r.role);
          this.store$.dispatch(loadTcPermissions({ userRoles: roles || [] }));
        })
      ),
    { dispatch: false }
  );

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(() => {
          this.store$.dispatch(getCurrentUser());
        })
      ),
    { dispatch: false }
  );

  loadTcPermissionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissionsSuccess),
        tap(() => {
          // The user is logged by getCurrentUser() action and permissions are loaded, we start to watch his session and redirect it to the endpoint
          this.idle.watch();
          this.router.navigateByUrl(
            this.appInitService.getLoginEndPointRoute()
          );
        })
      ),
    { dispatch: false }
  );

  loginFailed = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailure),
        tap(({ error }) => {
          this.notificationService.error(
            this.translateService.instant(error.message)
          );
        })
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          // The user is logged out, we remove the watch on his session
          this.idle.stop();
          this.router.navigate([`/${GenericRoutes.Login}`]);
        })
      ),
    { dispatch: false }
  );

  loginAs = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginAs),
        tap(() => {
          this.router.navigate([`/${GenericRoutes.Login}`]);
        })
      ),
    { dispatch: false }
  );

  refresh = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refresh),
        tap(() => {
          this.authenticationService
            .refresh()
            .then((options: { accessToken: string }) => {
              if (options && options.accessToken) {
                this.store$.dispatch(saveToken({ token: options.accessToken }));
              } else {
                // No access token, we consider that session has already expired
                this.store$.dispatch(logout());
              }
            });
        })
      ),
    { dispatch: false }
  );
}
