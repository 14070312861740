import { Inject, OnInit, Directive, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAbstractDetailComponent, TcSmartFormConfig } from '@tc/abstract';
import { createItem, updateItem } from '@tc/data-store';
import { select } from '@ngrx/store';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
  getTcSmartFormSubmitModel,
  initTcSmartForm,
  setTcSmartFormModel,
  clearTcSmartFormSubmitModel,
} from '@tc/smart-form';
import { Observable, Subscription } from 'rxjs';
import { selectByKey } from '@tc/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Base class for an advanced detail component which contains a form
 */

@Directive()
export class TcSmartDetailPopupComponent
  extends TcAbstractDetailComponent
  implements OnInit, OnDestroy
{
  protected formConfig: TcSmartFormConfig;

  // hook to for setting the form's config from child components
  async setFormConfig() {}

  formStore$: Observable<NgRxTcSmartFormState>;

  formSubmitModelSubscription: Subscription;

  /**
   * We expect that when a dialog is opened it will be have a data property
   * this.dialog.open(component, {data: {entityData: payload.data, ...})
   * @param data
   */
  constructor(protected store$: Store<any>, @Inject(MAT_DIALOG_DATA) data) {
    super(data.entityData);
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  async ngOnInit() {
    await this.setFormConfig();

    // Init tc smart form
    if (this.formConfig) {
      this.store$.dispatch(
        initTcSmartForm({
          storeKey: this.formConfig.storeKey,
          config: this.formConfig,
        })
      );

      // Listen to the on submit event of the form
      this.formSubmitModelSubscription = selectByKey(
        getTcSmartFormSubmitModel,
        this.formStore$,
        this.formConfig.storeKey
      )
        // Filter out null or undefined
        .pipe(filter((v) => !!v))
        .subscribe((model) => {
          if (model._id) {
            this.store$.dispatch(
              updateItem({
                storeKey: this.formConfig.storeKey,
                item: model,
              })
            );
          } else {
            this.store$.dispatch(
              createItem({
                storeKey: this.formConfig.storeKey,
                item: model,
              })
            );
          }
          // We must clean the submit model so that nextime time the pop-up is opened
          // it doesn't see a submit model in the store and doesn't re-update/re-create
          this.store$.dispatch(
            clearTcSmartFormSubmitModel({
              storeKey: this.formConfig.storeKey,
            })
          );
        });

      // Populate form
      this.store$.dispatch(
        setTcSmartFormModel({
          storeKey: this.formConfig.storeKey,
          model: this.data,
        })
      );
    }
  }

  ngOnDestroy() {
    this.formSubmitModelSubscription?.unsubscribe();
  }
}
