import { TcSmartFormConfig } from '@tc/abstract';

export const DEFAULT_TC_SMART_FORM_STATE_KEY = 'tc-smart-form';

export interface NgRxTcSmartFormState {
  [key: string]: TcSmartFormState;
}

export interface TcSmartFormState {
  storeKey: string;
  config: TcSmartFormConfig;
  invalid: boolean;
  isChanged: boolean;

  /**
   * The initial value of the form module, used to set a default/initial state.
   */
  model: any;

  /**
   * The current value of the form module, updated whenever the user changes something.
   */
  currentModel: any;

  /**
   * A snapshot of the currentModel of whenever the user saves the form. Used together with a selector
   * in order to figure out when to lunch an update/create request
   */
  submitModel: any;
}
