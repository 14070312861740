import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tc-dialog',
  templateUrl: './tc-dialog.component.html',
  styleUrls: ['./tc-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcDialogComponent implements OnInit {
  @Input()
  hasCloseButton = true;

  @Output()
  close: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClose() {
    this.close.emit();
  }
}
