import { createAction, props } from '@ngrx/store';
import {
  InitTcFilterPayload,
  InitTcFilterSuccessPayload,
  ResetTcFilterPayload,
  UpdateTcFilterPayload,
  UpdateTcFilterSuccessPayload,
} from '@tc/data-store';

/**
 * the prefix for all the tc grid actions
 */
export const TC_FILTER_ACTIONS_PREFIX: string = '[tc-filter]';

/**
 * the action to initialize tc filter
 */
export const initTcFilter = createAction(
  `${TC_FILTER_ACTIONS_PREFIX}/Init`,
  props<InitTcFilterPayload>()
);

/**
 *  the action to update tc filter on initialization success
 */
export const initTcFilterSuccess = createAction(
  `${TC_FILTER_ACTIONS_PREFIX}/Init Success`,
  props<InitTcFilterSuccessPayload>()
);

/**
 *  the action to update the tc filter store
 */
export const updateTcFilter = createAction(
  `${TC_FILTER_ACTIONS_PREFIX}/Update Filter`,
  props<UpdateTcFilterPayload>()
);

/**
 *  the action to update tc filter store success
 */
export const updateTcFilterSuccess = createAction(
  `${TC_FILTER_ACTIONS_PREFIX}/Update Filter Success`,
  props<UpdateTcFilterSuccessPayload>()
);

/**
 * the to reset the filters
 */
export const resetTcFilter = createAction(
  `${TC_FILTER_ACTIONS_PREFIX}/Reset Filters`,
  props<ResetTcFilterPayload>()
);
