import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { TermsConditionsComponent } from './components/smart/terms-conditions/terms-conditions.component';
import { TermsSubmitFormComponent } from './components/smart/terms-submit-form/terms-submit-form.component';
import { TermsConditionsEffects } from './store/terms-conditions.effects';
import { termsConditionsReducer } from './store/terms-conditions.reducer';

const smartComponents = [TermsConditionsComponent, TermsSubmitFormComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    FormsModule,
    EffectsModule.forFeature([TermsConditionsEffects]),
    StoreModule.forFeature('termsConditions', termsConditionsReducer),
  ],
  exports: [...smartComponents],
})
export class TermsConditionsModule {}
