import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DEFAULT_TC_DATA_STATE_KEY } from './store/state/tc-data-state';
import { StoreModule } from '@ngrx/store';
import { TcDataEffects } from './store/effects/tc-data-effects';
import { tcDataReducer } from './store/reducers/tc-data-reducers';
import { TcDataServiceFactory } from './data-services/services/tc-data-service.factory';
import { TcDataService } from './data-services/services/tc-data.service';

@NgModule({
  declarations: [],
  providers: [TcDataServiceFactory, TcDataService],
  imports: [
    CommonModule,

    EffectsModule.forFeature([TcDataEffects]),
    StoreModule.forFeature(DEFAULT_TC_DATA_STATE_KEY, tcDataReducer),
  ],
})
export class TcDataStoreModule {}
