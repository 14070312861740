import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'tc-grid-checkbox-renderer',
  templateUrl: './tc-grid-checkbox-renderer.component.html',
  styleUrls: ['./tc-grid-checkbox-renderer.component.css'],
})
export class TcGridCheckboxRendererComponent implements AgRendererComponent {
  private params: any;
  public label: string;
  public value: boolean;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params): void {
    this.value = params?.value;
    this.label = params?.label ?? '';
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
