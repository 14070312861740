import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcTranslateService, TcComponentLookup, closeTcGridDetailsDialog } from '@tc/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AssociationsService } from '../../../associations/services/associations.service';
import { Association, AssociationCategorie } from '../../../associations/types';
import { AmsInitialValuesTableItem } from '../../interfaces/ams-initial-values-table-item.interface';
import { AmsInitialValuesTableModel } from '../../interfaces/ams-initial-values-table-model.interface';
import { IndicateursEtat } from '../../interfaces/indicateurs-etat.interface';
import { IndicatorsService } from '../../services/indicators.service';
import { getAuthenticatedUser } from '../../../../../modules/auth/store/auth.selectors';
import { UserModel } from '../../../../../modules/auth/models/user.model';
import { dateCellRenderer } from '../../../../shared/utils/format.utils';
import * as R from 'ramda';
import {
  getCurrentTrimestre,
  getCurrentYear,
} from '../../store/indicators.selectors';
import { IndicatorKeys } from '../../typings/indicator-keys.enum';
import { IndicatorType } from '../../typings/indicator-type.enum';
import { Trimestre } from '../../typings/trimestre.enum';
import { getPeriodFromDate, previousPeriod } from '../../utils/helpers';
import { canEditTicadiIndicator } from '../../helpers/indicators-validation-helpers';
import { setInitialValuesVisible, setTicadiIndicatorInitialValuesToUpdate } from '../../store/indicators.actions';
import { ConfigService } from '../../../../../shared/services/config.service';
import { INTERVAL_MODIFICATION_TICADI } from '../../utils/constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import { TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import {
  saveTicadiIndicatorDetails
} from '../../store/indicators.actions';

@TcComponentLookup('AmsInitialValuesTable')
@Component({
  selector: 'app-ams-initial-values-table',
  templateUrl: './ams-initial-values-table.component.html',
  styleUrls: ['./ams-initial-values-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmsInitialValuesTableComponent
  extends TcSmartDetailPopupComponent
  implements OnInit, OnDestroy
{
  selectedIndicator: IndicateursEtat;
  selectedTrimestre: Trimestre;
  isEditingMode: boolean;

  private intervalModificationTicadi = 0;
  private subscription = new Subscription();
  private user: UserModel;
  model: AmsInitialValuesTableModel;
  initialModel: AmsInitialValuesTableModel;

  // Map to keep track of incorrect values (Ex: beneficiaires values require full integers)
  incorrectValues = {};

  currentYear: number;
  associationId: number;getIndicateursEtatDetails
  trimestre: string;
  indicateurs: IndicateursEtat[];
  validationPeriod: string;

  reasonForModificationAsso = '';
  reasonForModificationBA = '';

  reasonModificationBaIsEmpty = false;

  isBeneficiaryBySexVisible = true;
  isBeneficiaryByAgeVisible = true;
  isMotifEcartAssoCellVisible = true;
  isMotifEcartBACellVisible = true;
  isEditInfoEcartVisible = true;
  isEditInfoUserVisible = true;

  enableBeneficiaresNoFoyersAsso: boolean;
  enableBeneficiaresNoPersonnesInscritesAsso: boolean;
  enableBeneficiaresNoPersonnesAideesAsso: boolean;
  enableBeneficiaresAge0_3Asso: boolean;
  enableBeneficiaresAge4_14Asso: boolean;
  enableBeneficiaresAge15_25Asso: boolean;
  enableBeneficiaresAge26_64Asso: boolean;
  enableBeneficiaresAgePlus65Asso: boolean;
  enableBeneficiaresMasculinAsso: boolean;
  enableBeneficiaresFemininAsso: boolean;
  enableVolDisPoidsNetAsso: boolean;
  enableMotifEcartAsso: boolean;
  enableMotifEcartAssoIV: boolean;

  enableVolDisPoidsNetCalculated: boolean;
  enableBeneficiaresNoFoyersCalculated: boolean;
  enableBeneficiaresNoPersonnesInscritesCalculated: boolean;
  enableBeneficiaresNoPersonnesAideesCalculated: boolean;
  enableBeneficiaresAge0_3Calculated: boolean;
  enableBeneficiaresAge4_14Calculated: boolean;
  enableBeneficiaresAge15_25Calculated: boolean;
  enableBeneficiaresAge26_64Calculated: boolean;
  enableBeneficiaresAgePlus65Calculated: boolean;
  enableBeneficiaresMasculinCalculated: boolean;
  enableBeneficiaresFemininCalculated: boolean;

  enableVolDisPoidsNetBA: boolean;
  enableBeneficiaresNoFoyersBA: boolean;
  enableBeneficiaresNoPersonnesInscritesBA: boolean;
  enableBeneficiaresNoPersonnesAideesBA: boolean;
  enableBeneficiaresAge0_3BA: boolean;
  enableBeneficiaresAge4_14BA: boolean;
  enableBeneficiaresAge15_25BA: boolean;
  enableBeneficiaresAge26_64BA: boolean;
  enableBeneficiaresAgePlus65BA: boolean;
  enableBeneficiaresMasculinBA: boolean;
  enableBeneficiaresFemininBA: boolean;
  enableMotifEcartBA: boolean;
  enableMotifEcartBAIV: boolean;

  private dialogStoreKey = 'indicators-grid-initial-values';

  constructor(
    store$: Store<any>,
    private readonly config: ConfigService,
    private indicatorsService: IndicatorsService,
    private readonly translate: TcTranslateService,
    private readonly associationService: AssociationsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);

    this.intervalModificationTicadi = config.get(INTERVAL_MODIFICATION_TICADI) || 0;

    const { selectedIndicator, selectedTrimestre, isEditingMode } = data.entityData;
    this.selectedIndicator = selectedIndicator;
    this.selectedTrimestre = selectedTrimestre;
    this.isEditingMode = isEditingMode;
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.dialogStoreKey;
    this.dialogConfig.hasHeader = true;
    this.dialogConfig.headerConfig = {
      configType: TcConfigTypes.TcDetailTitle,
      title: 'indicator-detail.initial-values.title',
      hasCloseButton: true,
    };
    this.dialogConfig.hasFooter = true;
    this.dialogConfig.footerConfig = {
      configType: TcConfigTypes.TcDetailHeader,
      buttonsList: this.getFooterButtons(),
    };
  }

  private getFooterButtons(): TcSmartButton[] {
    if (this.isEditingMode) {
      return [
        {
          label: 'cancel',
          color: MaterialColor.Primary,
          faIcon: 'fa-ban',
          action: this.closeDialog.bind(this)
        },
        {
          label: 'save',
          color: MaterialColor.Accent,
          faIcon: 'fa-save',
          action: this.saveIndicators.bind(this)
        },
      ]
    }
    return [
      {
        label: 'close',
        color: MaterialColor.Primary,
        action: this.closeDialog.bind(this)
      }
    ];
  }

  private saveIndicators() {
    // Check if any invalid values
    for (const key in this.incorrectValues) {
      if (this.incorrectValues[key]) {
        // Return when finding the first incorrect value
        return;
      }
    }

    // Check if model has changed but there are no comments
    const clonedModel = R.clone(this.model);
    delete clonedModel.editInfoUser;
    if (!R.equals(clonedModel, this.initialModel) && R.isEmpty(this.reasonForModificationBA)) {
      this.reasonModificationBaIsEmpty = true;
      return;
    } 
    
    this.store$.dispatch(saveTicadiIndicatorDetails());
  }

  private closeDialog() {
    this.store$.dispatch(setInitialValuesVisible({ initialValuesVisible: false }));
    this.store$.dispatch(
      closeTcGridDetailsDialog({
        storeKey: this.dialogStoreKey,
      })
    );
  }

  async ngOnInit() {
    super.ngOnInit();
    this.user = await this.store$
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();
    this.currentYear = await this.store$
      .select(getCurrentYear)
      .pipe(take(1))
      .toPromise();
    this.trimestre = await this.store$
      .select(getCurrentTrimestre)
      .pipe(take(1))
      .toPromise();

    this.associationId = this.selectedIndicator.associationId;
    const association = await this.associationService.getAssociationById(
      this.associationId
    );

    this.initializeModel();

    if (this.isEditingMode) {
      this.setIndicateursForModify(association);
    } else {
      this.setIndicateursForVisualize(association);
    }
  }

  ngOnDestroy() {
    this.store$.dispatch(setInitialValuesVisible({ initialValuesVisible: false }));
    this.subscription?.unsubscribe();
  }

  private initializeModel() {
    this.reasonForModificationAsso = '';
    this.model = {
      volumesDistributed: this.initializeItem(IndicatorKeys.VolumeDistributed),
      beneficiariesHouseholds: this.initializeItem(IndicatorKeys.BeneficiariesRegisteredHouseholds),
      beneficiariesRights: this.initializeItem(IndicatorKeys.BeneficiariesRegisteredWithRights),
      beneficiariesPassages: this.initializeItem(IndicatorKeys.BeneficiariesPassages),
      beneficiariesAge0to3: this.initializeItem(IndicatorKeys.BeneficiariesAge0to3),
      beneficiariesAge4to14: this.initializeItem(IndicatorKeys.BeneficiariesAge4to14),
      beneficiariesAge15to25: this.initializeItem(IndicatorKeys.BeneficiariesAge15to25),
      beneficiariesAge26to64: this.initializeItem(IndicatorKeys.BeneficiariesAge26to64),
      beneficiariesAgeOver65: this.initializeItem(IndicatorKeys.BeneficiariesAgeOver65),
      beneficiariesSexMale: this.initializeItem(IndicatorKeys.BeneficiariesMale),
      beneficiariesSexFemale: this.initializeItem(IndicatorKeys.BeneficiariesFemale),
      lastReasonForModification: this.initializeItem(IndicatorKeys.LastReasonOfDeviation),
      reasonForModificationAsso: this.reasonForModificationAsso,
      reasonForModificationBA: this.reasonForModificationBA,
      editInfoForModification: this.initializeItem(IndicatorKeys.InfoForModification),
      editInfoUser: '',
    };
    this.initialModel = R.clone(this.model);

    // Delete the edit info user since we don't care about it when comparing model data when saving the model
    // @ts-ignore - "editInfoUser" is a required property on the interface but we don't care about it for now
    delete this.initialModel.editInfoUser;
  }

  private async setIndicateursForModify(association: Association) {
    const trimestre = this.selectedTrimestre;
    const year = this.selectedIndicator.annee;
    let period = getPeriodFromDate(new Date());
    period = previousPeriod(period);
    
    let trimestrePeriod = period.trimestre;
    if (trimestre === Trimestre.Annee) {
      trimestrePeriod = trimestre;
    }
    const ticadiIndicators = await this.indicatorsService.getYearIndicateursByIdForTicadi(association.id, year, trimestre);
    const indicators = ticadiIndicators.data || [];
    if (indicators.length > 0) {
      const indicator = indicators.find(i => i.trimestreIndicateurs === trimestrePeriod && i.type.toUpperCase() === IndicatorType.TICADI_BA.toUpperCase());
      if (!indicator || !canEditTicadiIndicator(indicator, this.intervalModificationTicadi)) {
        this.closeDialog();
      }

      this.validationPeriod = 
        this.translate
          .instant(`ams-indicator-table.header.${indicator?.trimestreIndicateurs}`)
          .replace('{{year}}', indicator?.annee);
    }

    this.setTicadiIndicators(
      indicators.filter(i => i.trimestreIndicateurs === trimestrePeriod),
      association
    )
    this.setFormValuesForSave();
  }

  private async setIndicateursForVisualize(association: Association) {
    const trimestre = this.selectedTrimestre;
    const year = this.selectedIndicator.annee;

    const ticadiIndicators = await this.indicatorsService.getYearIndicateursByIdForTicadi(association.id, year, trimestre);
    const listIndicateurs = ticadiIndicators.data || [];
    if (listIndicateurs.length > 0) {
      // Check if we have a BA T4 indicator (old passerelle app value for the year)
      let referenceIndicator: IndicateursEtat | null | undefined = null;
      let list: IndicateursEtat[] = [];

      if (trimestre === Trimestre.Annee) {
        const indicatorBa = listIndicateurs.find(i => i.trimestreIndicateurs === Trimestre.T4 && i.type.toUpperCase() === IndicatorType.BA.toUpperCase());
        if (indicatorBa) {
          list.push(indicatorBa);
          referenceIndicator = indicatorBa;
        }
      }

      // If we don't have a BA, just get the data based on the trimester
      if (list.length === 0) {
        list = listIndicateurs.filter(i => i.trimestreIndicateurs === trimestre);
        referenceIndicator = listIndicateurs.find(
          i => i.trimestreIndicateurs === trimestre &&
          (
            i.type.toUpperCase() === IndicatorType.TICADI_BA.toUpperCase() ||
            i.type.toUpperCase() === IndicatorType.BA.toUpperCase()
          )
        );
      }
      this.setTicadiIndicators(list, association, true);

      this.validationPeriod = 
        this.translate
          .instant(`ams-indicator-table.header.${referenceIndicator?.trimestreIndicateurs}`)
          .replace('{{year}}', referenceIndicator?.annee);
    }
  }

  private setTicadiIndicators(listIndicateurs: IndicateursEtat[], association: Association, initialValues: boolean = false) {
    this.indicateurs = listIndicateurs;
    const hasYear = listIndicateurs.filter(i => 
        i.trimestreIndicateurs === Trimestre.Annee ||
        i.trimestreIndicateurs === Trimestre.T4 &&
        i.type.toUpperCase() === IndicatorType.BA.toUpperCase()
      );

    if (hasYear.length === 0 || association.categorie !== AssociationCategorie.Categorie1) {
      // If your visualizing a trimester, or a association with category != Categorie1 : theses rows in the form should not be displayed
      this.isBeneficiaryByAgeVisible = false;
      this.isBeneficiaryBySexVisible = false;
    }

    // View for inital values. Special block for theses rows at the end on the inital value page
    let BAEnabled = true;
    this.isEditInfoEcartVisible = false;
    this.isEditInfoUserVisible = false;
    if (initialValues) {
      this.isMotifEcartAssoCellVisible = false;
        this.isMotifEcartBACellVisible = false;
        this.isEditInfoEcartVisible = true;
        this.isEditInfoUserVisible = true;
        BAEnabled = false;
    }

    for (const indicator of listIndicateurs) {
      switch (indicator.type.toUpperCase()) {
        case IndicatorType.TICADI_CALCULATED.toUpperCase(): {
          this.setIndicateursCalculated(indicator);
          break;
        }
        case IndicatorType.TICADI_ASSOCIATION.toUpperCase(): {
          this.setIndicateursAssociation(indicator);
          break;
        }
        case IndicatorType.TICADI_BA.toUpperCase():
        case IndicatorType.BA.toUpperCase(): {
          this.setIndicateursBA(indicator, BAEnabled);
          if (indicator.dateModification && indicator.utilisateur_Id != null && indicator.utilisateur_Id !== undefined) {
            const date = indicator.dateModification;
            let name = this.user?.additionalDetails?.['displayName'];
            if (!name || name === ' ') {
              name = this.user.login
            }


            this.model.editInfoUser = this.translate.instant(
              'ams-initial-values-table.edit-info-user',
              { date: dateCellRenderer(date, false), name }
            );
          }
          
          // In case of BA display, we don't have TICADI_CALCULATED and TICADI_ASSOCIATION objects. Just disable the form to prevent inputs
          if (indicator.type.toUpperCase() === IndicatorType.BA) {
            this.enableCalculatedFields(false);
            this.enableAssociationFields(false);
          }
          break;
        }
      }
    }
  }

  private initializeItem(key: string): AmsInitialValuesTableItem {
    if (key === IndicatorKeys.InfoForModification) {
      return { key, calculated: undefined, retainedAsso: '', retainedBA: '' };
    }

    return {
      key,
      calculated: 0,
      retainedAsso: 0,
      retainedBA: 0,
    };
  }

  private setIndicateursCalculated(indicateur: IndicateursEtat) {
    const defaultValue = 0;

    if (
      indicateur.trimestreIndicateurs === Trimestre.Annee ||
      (indicateur.trimestreIndicateurs === Trimestre.T4 &&
        indicateur.type.toUpperCase() === IndicatorType.BA)
    ) {
      // Values from year
      this.model.volumesDistributed.calculated =
        indicateur.indVolumeAnnee !== null
          ? Math.round(indicateur.indVolumeAnnee.poidsNetT * 1000) / 1000
          : defaultValue;
      this.model.beneficiariesHouseholds.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFoyersInscrits
          : defaultValue;
      this.model.beneficiariesRights.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesInscrites
          : defaultValue;
      this.model.beneficiariesPassages.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesAidees
          : defaultValue;

      this.model.beneficiariesAge0to3.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes0a3ans
          : 0;
      this.model.beneficiariesAge4to14.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes4a14ans
          : 0;
      this.model.beneficiariesAge15to25.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes15a25ans
          : 0;
      this.model.beneficiariesAge26to64.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes26a64ans
          : 0;
      this.model.beneficiariesAgeOver65.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ans
          : 0;
      this.model.beneficiariesSexMale.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbHommes
          : 0;
      this.model.beneficiariesSexFemale.calculated =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFemmes
          : 0;
    }

    if (indicateur.trimestreIndicateurs !== Trimestre.Annee) {
      // Values from trimesters
      this.model.volumesDistributed.calculated =
        indicateur.indVolumeTrimestre !== null
          ? Math.round(indicateur.indVolumeTrimestre.poidsNetT * 1000) / 1000
          : defaultValue;
      this.model.beneficiariesHouseholds.calculated =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbFoyersInscrits
          : 0;
      this.model.beneficiariesRights.calculated =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesInscrites
          : 0;
      this.model.beneficiariesPassages.calculated =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesAidees
          : 0;
    }
  }

  private setIndicateursAssociation(
    indicateur: IndicateursEtat,
    enabled = false
  ) {
    const defaultValue = 0;

    if (
      indicateur.trimestreIndicateurs === Trimestre.Annee ||
      (indicateur.trimestreIndicateurs === Trimestre.T4 &&
        indicateur.type.toUpperCase() === IndicatorType.BA)
    ) {
      // Values from year
      this.model.volumesDistributed.retainedAsso =
        indicateur.indVolumeAnnee !== null
          ? Math.round(indicateur.indVolumeAnnee.poidsNetT * 1000) / 1000
          : defaultValue;

      this.model.beneficiariesHouseholds.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFoyersInscrits
          : 0;
      this.model.beneficiariesRights.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesInscrites
          : 0;
      this.model.beneficiariesPassages.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesAidees
          : 0;

      this.model.beneficiariesAge0to3.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes0a3ans
          : 0;
      this.model.beneficiariesAge4to14.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes4a14ans
          : 0;
      this.model.beneficiariesAge15to25.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes15a25ans
          : 0;
      this.model.beneficiariesAge26to64.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes26a64ans
          : 0;
      this.model.beneficiariesAgeOver65.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ans
          : 0;
      this.model.beneficiariesSexMale.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbHommes
          : 0;
      this.model.beneficiariesSexFemale.retainedAsso =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFemmes
          : 0;
    }

    if (indicateur.trimestreIndicateurs !== Trimestre.Annee) {
      // Values from trimesters
      this.model.volumesDistributed.retainedAsso =
        indicateur.indVolumeTrimestre !== null
          ? Math.round(indicateur.indVolumeTrimestre.poidsNetT * 1000) / 1000
          : defaultValue;
      this.model.beneficiariesHouseholds.retainedAsso =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbFoyersInscrits
          : 0;
      this.model.beneficiariesRights.retainedAsso =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesInscrites
          : 0;
      this.model.beneficiariesPassages.retainedAsso =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesAidees
          : 0;
    }

    this.model.reasonForModificationAsso =
      indicateur.motifEcart !== null ? indicateur.motifEcart : '';
    this.reasonForModificationAsso =
      indicateur.motifEcart !== null ? indicateur.motifEcart : '';
    this.model.editInfoForModification.retainedAsso =
      indicateur.motifEcart !== null ? indicateur.motifEcart : '';

    // Enable the fields based on config
    this.enableAssociationFields(enabled);
  }

  private enableAssociationFields(enabled: boolean) {
    this.enableVolDisPoidsNetAsso = enabled;
    this.enableBeneficiaresNoFoyersAsso = enabled;
    this.enableBeneficiaresNoPersonnesInscritesAsso = enabled;
    this.enableBeneficiaresNoPersonnesAideesAsso = enabled;
    this.enableBeneficiaresAge0_3Asso = enabled;
    this.enableBeneficiaresAge4_14Asso = enabled;
    this.enableBeneficiaresAge15_25Asso = enabled;
    this.enableBeneficiaresAge26_64Asso = enabled;
    this.enableBeneficiaresAgePlus65Asso = enabled;
    this.enableBeneficiaresMasculinAsso = enabled;
    this.enableBeneficiaresFemininAsso = enabled;
    this.enableVolDisPoidsNetAsso = enabled;
    this.enableBeneficiaresNoFoyersAsso = enabled;
    this.enableBeneficiaresNoPersonnesInscritesAsso = enabled;
    this.enableMotifEcartAsso = enabled;
    this.enableMotifEcartAssoIV = enabled;
  }

  private enableCalculatedFields(enabled: boolean) {
    this.enableVolDisPoidsNetCalculated = enabled;
    this.enableBeneficiaresNoFoyersCalculated = enabled;
    this.enableBeneficiaresNoPersonnesInscritesCalculated = enabled;
    this.enableBeneficiaresNoPersonnesAideesCalculated = enabled;
    this.enableBeneficiaresAge0_3Calculated = enabled;
    this.enableBeneficiaresAge4_14Calculated = enabled;
    this.enableBeneficiaresAge15_25Calculated = enabled;
    this.enableBeneficiaresAge26_64Calculated = enabled;
    this.enableBeneficiaresAgePlus65Calculated = enabled;
    this.enableBeneficiaresMasculinCalculated = enabled;
    this.enableBeneficiaresFemininCalculated = enabled;
    this.enableVolDisPoidsNetCalculated = enabled;
    this.enableBeneficiaresNoFoyersCalculated = enabled;
    this.enableBeneficiaresNoPersonnesInscritesCalculated = enabled;
  }

  public setIndicateursBA(indicateur: IndicateursEtat, enabled = false) {
    const defaultValue = 0;

    if (
      indicateur.trimestreIndicateurs === Trimestre.Annee ||
      (indicateur.trimestreIndicateurs === Trimestre.T4 &&
        indicateur.type.toUpperCase() === IndicatorType.BA)
    ) {
      // Values from year
      this.model.volumesDistributed.retainedBA =
        indicateur.indVolumeAnnee !== null
          ? Math.round(indicateur.indVolumeAnnee.poidsNetT * 1000) / 1000
          : defaultValue;

      this.model.beneficiariesHouseholds.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFoyersInscrits
          : 0;
      this.model.beneficiariesRights.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesInscrites
          : 0;
      this.model.beneficiariesPassages.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesAidees
          : 0;

      this.model.beneficiariesAge0to3.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes0a3ans
          : 0;
      this.model.beneficiariesAge4to14.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes4a14ans
          : 0;
      this.model.beneficiariesAge15to25.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes15a25ans
          : 0;
      this.model.beneficiariesAge26to64.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnes26a64ans
          : 0;
      this.model.beneficiariesAgeOver65.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ans
          : 0;
      this.model.beneficiariesSexMale.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbHommes
          : 0;
      this.model.beneficiariesSexFemale.retainedBA =
        indicateur.indBeneficiairesAnnee !== null
          ? indicateur.indBeneficiairesAnnee.nbFemmes
          : 0;
    }
    if (indicateur.trimestreIndicateurs !== Trimestre.Annee) {
      // Values from trimesters
      this.model.volumesDistributed.retainedBA =
        indicateur.indVolumeTrimestre !== null
          ? Math.round(indicateur.indVolumeTrimestre.poidsNetT * 1000) / 1000
          : defaultValue;
      this.model.beneficiariesHouseholds.retainedBA =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbFoyersInscrits
          : 0;
      this.model.beneficiariesRights.retainedBA =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesInscrites
          : 0;
      this.model.beneficiariesPassages.retainedBA =
        indicateur.indBeneficiairesTrimestre !== null
          ? indicateur.indBeneficiairesTrimestre.nbPersonnesAidees
          : 0;
    }

    this.model.reasonForModificationBA = indicateur.motifEcart !== null ? indicateur.motifEcart : '';
    this.reasonForModificationBA = indicateur.motifEcart !== null ? indicateur.motifEcart : '';
    this.model.editInfoForModification.retainedBA = indicateur.motifEcart !== null ? indicateur.motifEcart : '';

    // Enable the fields based on config
    this.enableBAFields(enabled);
  }

  private enableBAFields(enabled: boolean) {
    this.enableVolDisPoidsNetBA = enabled;
    this.enableBeneficiaresNoFoyersBA = enabled;
    this.enableBeneficiaresNoPersonnesInscritesBA = enabled;
    this.enableBeneficiaresNoPersonnesAideesBA = enabled;
    this.enableBeneficiaresAge0_3BA = enabled;
    this.enableBeneficiaresAge4_14BA = enabled;
    this.enableBeneficiaresAge15_25BA = enabled;
    this.enableBeneficiaresAge26_64BA = enabled;
    this.enableBeneficiaresAgePlus65BA = enabled;
    this.enableBeneficiaresMasculinBA = enabled;
    this.enableBeneficiaresFemininBA = enabled;
    this.enableVolDisPoidsNetBA = enabled;
    this.enableBeneficiaresNoFoyersBA = enabled;
    this.enableBeneficiaresNoPersonnesAideesBA = enabled;
    this.enableMotifEcartBA = enabled;
    this.enableMotifEcartBAIV = enabled;
  }

  public hasChanges(): boolean {
    if (
      this.model?.volumesDistributed?.retainedBA !== this.model?.volumesDistributed?.retainedAsso ||
      this.model?.beneficiariesHouseholds?.retainedBA !== this.model?.beneficiariesHouseholds?.retainedAsso ||
      this.model?.beneficiariesRights?.retainedBA !== this.model?.beneficiariesRights?.retainedAsso ||
      this.model?.beneficiariesPassages?.retainedBA !== this.model?.beneficiariesPassages?.retainedAsso ||
      this.model?.beneficiariesAge0to3?.retainedBA !== this.model?.beneficiariesAge0to3?.retainedAsso ||
      this.model?.beneficiariesAge4to14?.retainedBA !== this.model?.beneficiariesAge4to14?.retainedAsso ||
      this.model?.beneficiariesAge15to25?.retainedBA !== this.model?.beneficiariesAge15to25?.retainedAsso ||
      this.model?.beneficiariesAge26to64?.retainedBA !== this.model?.beneficiariesAge26to64?.retainedAsso ||
      this.model?.beneficiariesAgeOver65?.retainedBA !== this.model?.beneficiariesAgeOver65?.retainedAsso ||
      this.model?.beneficiariesSexMale?.retainedBA !== this.model?.beneficiariesSexMale?.retainedAsso ||
      this.model?.beneficiariesSexFemale?.retainedBA !== this.model?.beneficiariesSexFemale?.retainedAsso
    ) {
      return true;
    }
    return false;
  }

  onChange() {
    this.setFormValuesForSave();
  }

  private setFormValuesForSave() {
    const curIndicateur = {
      ...this.indicateurs.filter((i) => i.type.toUpperCase() === IndicatorType.TICADI_BA)[0],
    };

    const indicateur = R.clone(curIndicateur);
    if (
      indicateur.trimestreIndicateurs === Trimestre.Annee ||
      (indicateur.trimestreIndicateurs === Trimestre.T4 &&
        indicateur.type.toUpperCase() === IndicatorType.BA)
    ) {
      indicateur.indVolumeAnnee.poidsNetT = this.model?.volumesDistributed?.retainedBA;

      this.incorrectValues['beneficiariesHouseholds'] = !Number.isInteger(this.model?.beneficiariesHouseholds?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbFoyersInscrits = this.model?.beneficiariesHouseholds?.retainedBA;

      this.incorrectValues['beneficiariesRights'] = !Number.isInteger(this.model?.beneficiariesRights?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnesInscrites = this.model?.beneficiariesRights?.retainedBA;

      this.incorrectValues['beneficiariesPassages'] = !Number.isInteger(this.model?.beneficiariesPassages?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnesAidees = this.model?.beneficiariesPassages?.retainedBA;

      this.incorrectValues['beneficiariesAge0to3'] = !Number.isInteger(this.model?.beneficiariesAge0to3?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnes0a3ans = this.model?.beneficiariesAge0to3?.retainedBA;

      this.incorrectValues['beneficiariesAge4to14'] = !Number.isInteger(this.model?.beneficiariesAge4to14?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnes4a14ans = this.model?.beneficiariesAge4to14?.retainedBA;

      this.incorrectValues['beneficiariesAge15to25'] = !Number.isInteger(this.model?.beneficiariesAge15to25?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnes15a25ans = this.model?.beneficiariesAge15to25?.retainedBA;

      this.incorrectValues['beneficiariesAge26to64'] = !Number.isInteger(this.model?.beneficiariesAge26to64?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnes26a64ans = this.model?.beneficiariesAge26to64?.retainedBA;

      this.incorrectValues['beneficiariesAgeOver65'] = !Number.isInteger(this.model?.beneficiariesAgeOver65?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbPersonnesPlus65ans = this.model?.beneficiariesAgeOver65?.retainedBA;

      this.incorrectValues['beneficiariesSexMale'] = !Number.isInteger(this.model?.beneficiariesSexMale?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbHommes = this.model?.beneficiariesSexMale?.retainedBA;

      this.incorrectValues['beneficiariesSexFemale'] = !Number.isInteger(this.model?.beneficiariesSexFemale?.retainedBA);
      indicateur.indBeneficiairesAnnee.nbFemmes = this.model?.beneficiariesSexFemale?.retainedBA;
    }

    if (indicateur.trimestreIndicateurs !== Trimestre.Annee) {
      indicateur.indVolumeTrimestre.poidsNetT = this.model?.volumesDistributed?.retainedBA;

      this.incorrectValues['beneficiariesHouseholds'] = !Number.isInteger(this.model?.beneficiariesHouseholds?.retainedBA);
      indicateur.indBeneficiairesTrimestre.nbFoyersInscrits = this.model?.beneficiariesHouseholds?.retainedBA;

      this.incorrectValues['beneficiariesRights'] = !Number.isInteger(this.model?.beneficiariesRights?.retainedBA);
      indicateur.indBeneficiairesTrimestre.nbPersonnesInscrites = this.model?.beneficiariesRights?.retainedBA;

      this.incorrectValues['beneficiariesPassages'] = !Number.isInteger(this.model?.beneficiariesPassages?.retainedBA);
      indicateur.indBeneficiairesTrimestre.nbPersonnesAidees = this.model?.beneficiariesPassages?.retainedBA;
    }

    const motifEcart = this.reasonForModificationBA;
    indicateur.motifEcart = motifEcart !== null && motifEcart.trim() !== '' ? motifEcart : '';
    indicateur.modificationManuelle = this.hasChanges();

    this.store$.dispatch(setTicadiIndicatorInitialValuesToUpdate({ indicateur }));
  }
}
