import { Component, OnInit } from '@angular/core';
import { TcReportingQueryService } from '../../services/tc-reporting-query.service';
import { TcReportingQuery } from '../../interfaces/tc-reporting-query';

@Component({
  selector: 'tc-reporting-list',
  templateUrl: './tc-reporting-list.component.html',
  styleUrls: ['./tc-reporting-list.component.scss'],
})
export class TcReportingListComponent implements OnInit {
  constructor(private reportingQueries: TcReportingQueryService) {}

  /**
   * Current selected query
   */
  private selectedQuery: TcReportingQuery;

  ngOnInit() {}

  /**
   * Get the TcTree array needed to build the tree menu
   * @returns TcTree[]
   */
  public getTreeData() {
    return this.reportingQueries.getTcTree();
  }

  /**
   * Method to handle clicks on the tree menu
   */
  public getTreeNode(event) {
    const query = this.reportingQueries.get(event.id);
    this.selectedQuery = undefined;
    // We set a timeout here because we need to clear the DOM before setting a new query.
    // This is a hack because we encountered problems where formly forms where not working correctly if DOM was not cleared.
    setTimeout(() => (this.selectedQuery = query), 100);
  }

  /**
   * Method to return the selected query on the tree menu
   */
  public getSelectedQuery(): TcReportingQuery {
    return this.selectedQuery;
  }
}
