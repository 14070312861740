import { createAction, props } from '@ngrx/store';
import { BaseTcStorePayload } from '@tc/abstract';
import {
  InitTcSmartFormPayload,
  SetTcSmartFormModelPayload,
  UpdateTcSmartFormCurrentModelPayload,
} from '../payloads';

/**
 * The prefix for all the tc grid actions
 */
export const TC_SMART_FORM_ACTIONS_PREFIX: string = '[tc-smart-form]';

/**
 * The action to initialize tc smart form
 */
export const initTcSmartForm = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Init Smart Form`,
  props<InitTcSmartFormPayload>()
);

/**
 * The action to initialize tc smart form. Called after initTcSmartForm effect.
 */
export const initTcSmartFormSuccess = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Init Smart Form Success`,
  props<InitTcSmartFormPayload>()
);

/**
 * The action to update the tc smart form current model
 * Used every time the form changes
 */
export const updateTcSmartFormCurrentModel = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Update Smart Form Current Model`,
  props<UpdateTcSmartFormCurrentModelPayload>()
);

/**
 * The action to mark the tc smart form state as changed
 * Used to detect when actual model change is happens
 */
export const markTcSmartFormStateAsChanged = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Mark Smart Form state as changed`,
  props<BaseTcStorePayload>()
);

/**
 * The action to update the tc smart form model
 * Used when we want to submit the curretModel to the model
 */
export const submitTcSmartFormCurrentModel = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Submit current model to Smart Form Model`,
  props<BaseTcStorePayload>()
);

/**
 * The action to update the tc smart form model
 * Used when we want to submit the currentModel to the model
 */
export const resetTcSmartFormCurrentModel = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Reset currentModel to model`,
  props<BaseTcStorePayload>()
);

/**
 * The action to sets the tc smart form model
 * Used by the tc list detail to set the model on opening pop-up
 */
export const setTcSmartFormModel = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Set smart form model`,
  props<SetTcSmartFormModelPayload>()
);

/**
 * The action to clear the tc smart form submit model
 */
export const clearTcSmartFormSubmitModel = createAction(
  `${TC_SMART_FORM_ACTIONS_PREFIX}/Clear smart form submit model`,
  props<BaseTcStorePayload>()
);
