import { TcReportingQuery } from './../interfaces/tc-reporting-query';
import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';

@Injectable({
  providedIn: 'root',
})
export class TcReportingQueryService extends TcService {
  constructor() {
    super();
  }

  /**
   * Declared queries
   */
  private reportingQueries: TcReportingQuery[] = [];

  /**
   * Domain of backlog
   */
  private domain = '';

  /**
   * Register multiples reproting queries to the reporting module
   */
  public registerQueries(queries: TcReportingQuery[]) {
    queries.forEach((query) => this.registerQuery(query));
  }

  /**
   * Register a reporting query to the reporting module
   * @param reportingQuery TcReportingQuery interface object with data
   */
  public registerQuery(reportingQuery: TcReportingQuery): void {
    // Check that the id of the query is unique
    const result = this.reportingQueries.filter(
      (item) => item.id === reportingQuery.id
    );
    if (result.length !== 0) {
      throw Error(
        'Reporting query ' +
          reportingQuery.id +
          ' is already registered. Check your configuration.'
      );
    }
    // Check that the category property is set if parent category is set
    if (
      reportingQuery.parentCategory &&
      reportingQuery.parentCategory !== '' &&
      (!reportingQuery.category || reportingQuery.category === '')
    ) {
      throw Error(
        'Reporting query ' +
          reportingQuery.id +
          ' has a parent category but no category. Check your configuration.'
      );
    }

    this.reportingQueries.push(reportingQuery);
    this.reportingQueries.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  /**
   * Register the domain of the backend to generate dynamically the urls to get the queries data.
   * @param domain Domain of the backend with http(s):// and port if need. Exemple : http://localhost:3001
   */
  public registerBackEndDomain(domain: string): void {
    const pattern = /^((http|https):\/\/)/;
    if (!domain || domain === '' || !pattern.test(domain)) {
      throw Error(
        'Reporting query domain ' +
          domain +
          ' is not valid. Check your configuration.'
      );
    }
    this.domain = domain;
  }

  /**
   * Get the TcReportingQuery object by his id property.
   * @param id string Id of the reporting query
   * @return TcReportingQuery
   */
  public get(id: string): TcReportingQuery {
    const result = this.reportingQueries.filter((item) => item.id === id);
    if (result.length !== 1) {
      throw Error('Reporting query ' + id + ' does not exists.');
    }
    return result[0];
  }

  /**
   * Get the list of the declared reporting queries.
   * @return TcReportingQuery[]
   */
  public list(): TcReportingQuery[] {
    return this.reportingQueries;
  }

  /**
   * Get the backend url needed to get JSON data. This url will be called in component tc-reporting-details to get query data.
   * Please define a backend domain before calling this method with function registerBackEndDomain().
   * @return string URL The pattern of the url is as it follows : {domain}/reporting/{query id}
   */
  public getBackEndUrl(reportingQuery: TcReportingQuery): string {
    if (this.domain === '') {
      throw Error('Please define a backlog domain before calling this method.');
    }

    return this.domain + '/reporting/' + reportingQuery.id;
  }

  /**
   * Get an TcTree array that can be used to display registered queries as a tree menu with TcTree component. Queries are sorted by name.
   * @return TcTree[]
   */
  public getTcTree() {
    // Filter the queries to display : no display param or display param true.
    const queries = this.list().filter(
      (item) =>
        item.hasOwnProperty('display') === false || item.display === true
    );

    const tree = [];
    const mappedArray = {};
    const node = 'node';
    const category = 'category';
    const parentCategory = 'parentCategory';
    const children = 'children';
    let mappedElement;
    let mappedCategory;
    let mappedParentCategory;
    let categoriesQueries;
    let queryNode;

    // Sort queries by category name
    queries.sort((a, b) => (a.category > b.category ? 1 : -1));

    // Map of all existings categories
    for (let i = 0, len = queries.length; i < len; i++) {
      const arrayElement = queries[i];
      if (arrayElement.category) {
        const categoryNode = {
          id: arrayElement.category,
          name: arrayElement.category,
          children: [],
        };
        // Put in arrays cells all that we need to build the tree : the node to display, it's children inside, category and parentCategory to be able to sort the array
        mappedArray[arrayElement.category] = {
          node: categoryNode,
          category: arrayElement.category,
          parentCategory: arrayElement.parentCategory,
        };
      }
    }

    // Check if all parent categories exists. If not, add parent category to root to create tree.
    for (const name in mappedArray) {
      if (mappedArray.hasOwnProperty(name)) {
        mappedParentCategory = mappedArray[name][parentCategory];
        if (mappedParentCategory && !mappedArray[mappedParentCategory]) {
          mappedArray[mappedParentCategory] = {
            node: {
              id: mappedParentCategory,
              name: mappedParentCategory,
              children: [],
            },
            category: mappedParentCategory,
            parentCategory: undefined,
          };
        }
      }
    }

    // Build of the tree
    for (const name in mappedArray) {
      if (mappedArray.hasOwnProperty(name)) {
        mappedElement = mappedArray[name][node];
        mappedCategory = mappedArray[name][category];
        mappedParentCategory = mappedArray[name][parentCategory];

        // Get the queries corresponding to the name of the category and push it into the array
        categoriesQueries = queries.filter(
          (item) => item.category === mappedCategory
        );
        for (const query of categoriesQueries) {
          queryNode = { id: query.id, name: query.name };
          mappedArray[mappedCategory][node][children].push(queryNode);
          mappedArray[mappedCategory][node][children].sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
        }

        if (mappedParentCategory !== undefined) {
          // If the element is not at the root level, add it to its parent array of children.
          mappedArray[mappedParentCategory][node][children].push(mappedElement);
          mappedArray[mappedParentCategory][node][children].sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
        } else {
          // If the element is at the root level, add it to first level elements array.
          tree.push(mappedElement);
        }
      }
    }

    // At the end of the process, put all queries wihout categories to the root of the tree
    const rootQueries = queries.filter(
      (item) => item.category === undefined && item.parentCategory === undefined
    );
    for (const rootQuery of rootQueries) {
      queryNode = { id: rootQuery.id, name: rootQuery.name };
      tree.push(queryNode);
    }
    tree.sort((a, b) => (a.name > b.name ? 1 : -1));

    return tree;
  }
}
