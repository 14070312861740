export function getValueByPath(obj: any, path: string, defaultValue?: any) {
  const keys = path.split('.');
  const levels = [obj];

  for (const level of keys) {
    levels.push(levels[levels.length - 1]?.[level]);
  }

  return levels[levels.length - 1] || defaultValue;
}
