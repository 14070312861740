import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { isObservable, Observable, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { TcMultiSelectOptions } from '../../../interfaces/tc-multi-select-options';

@Component({
  selector: 'tc-multi-select',
  templateUrl: './tc-multi-select.component.html',
  styleUrls: ['./tc-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcMultiSelectComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatAutocompleteTrigger) private trigger: MatAutocompleteTrigger;

  private readonly defaultOptions: TcMultiSelectOptions = {
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  @Input() selected: any[] = [];
  @Input() isFormlyComponent = false;
  @Input() options: TcMultiSelectOptions = this.defaultOptions;
  @Input() display;
  @Input() filter;

  @Output() blur = new EventEmitter<void>();
  @Output() changeSelected = new EventEmitter<any[]>();

  public value = '';
  public autocompleteItems: any[] = [];
  public itemControl = new FormControl();

  private filterList: any[];
  private onDestroy = new Subject<void>();
  private filterSubscription: Subscription;
  private componentInited: boolean;

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.completeMissingOptions();

    this.itemControl.valueChanges
      .pipe(takeUntil(this.onDestroy), startWith(''))
      .subscribe((term) => {
        this.onValueChange(term);
      });
  }

  ngAfterViewInit() {
    this.componentInited = true;
  }

  public onValueChange(value) {
    if (typeof value !== 'string' || !this.componentInited) {
      return;
    }

    this.autocompleteItems = this.removeSelectedValues(
      this.filterMethod(value || '')
    );
  }

  public onBlur() {
    this.blur.emit();
  }

  public remove(item: any) {
    const index = this.indexOfWithoutReference(this.selected, item);

    if (index >= 0) {
      let selectedItems = this.selected;
      selectedItems.splice(index, 1);
      if (this.selected.length === 0) {
        selectedItems = null;
      }
      this.selected = selectedItems;
      this.emitSelected();
    }
  }

  /**
   * Add an item to selection and resets the input
   */
  public selectItem(event: MatAutocompleteSelectedEvent) {
    this.addItemToSelection(event.option.value);
  }

  public defaultDisplayMethod(item) {
    return item?.label || '';
  }

  public defaultFilterMethod(value: string = '') {
    const items = this.options.items || [];

    if (!value) {
      return items;
    }

    const filterValue = value.toString().toLowerCase();

    return items.filter((item) => {
      const displayValue = (this.display(item) || '').toString();
      return displayValue.toLowerCase().includes(filterValue);
    });
  }

  /**
   * Remove values in the autocomplete results that are already selected
   * @param results array
   */
  private removeSelectedValues(results: any[]): any[] {
    return results.filter(
      (result) =>
        !(this.selected || []).some(
          (item) => this.display(item) === this.display(result)
        )
    );
  }

  /**
   * Open the autocomplete panel for displaying results if the plugin can do it
   */
  private openAutocompletePanel() {
    if (this.trigger) {
      setTimeout(() => {
        if (this.componentInited) {
          this.autocompleteItems = this.removeSelectedValues(this.filterList);
          this.trigger.openPanel();
        }
      }, 100);
    }
  }

  /**
   * Call the service to get the data and filter the list with the wanted term
   */
  private filterMethod(term: any): any[] {
    if (!this.filter) {
      return [];
    }

    // Here, we want to exclude chips objects on selection because it's also a change of the input. We only use the term if it's native value match.
    // If we don't do that, chips objects are sended to the filter function, resulting an error.
    const results: Observable<[]> | any[] = this.filter(term || '');

    // Process the result
    if (results) {
      // If this is an observable, we subscribe to it and put the result in filterList property
      if (isObservable(results)) {
        this.filterSubscription = results.subscribe((data: any[]) => {
          // Allow the data only if it's an array. Else, cast it into a empty array.
          if (Array.isArray(data)) {
            this.filterList = data;
            this.openAutocompletePanel();
          } else {
            this.filterList = [];
          }
        });
      } else {
        // If we have a array in results, we only check if he is different from filterList property. If it's the case, we use the new value.
        const currentData = JSON.stringify(this.filterList);
        const receivedData = JSON.stringify(results);
        if (currentData !== receivedData && Array.isArray(results)) {
          this.filterList = results;
        }
        this.openAutocompletePanel();
      }
    }

    return this.filterByValue(term);
  }

  private filterByValue(value = '') {
    // Check if filterList is an array (case of the subscription that has not yet send the data)
    if (!Array.isArray(this.filterList)) {
      return [];
    }

    // Return the array filtered with value
    return this.filterList.filter((item) => {
      const displayValue = (this.display(item) || '').toString();
      return displayValue.toLowerCase().includes((value || '').toLowerCase());
    });
  }

  /**
   * Add an item to selection and resets the input
   */
  private addItemToSelection(item: {}) {
    // We initiate form value as an array if it's not done already
    if (!this.selected) {
      this.selected = [];
    }

    // Only add the object in the array if is not present in it. We don't want duplicates.
    const index = this.indexOfWithoutReference(this.selected, item);
    if (index < 0) {
      this.selected = [...this.selected, item];
      this.emitSelected();
    }
  }

  private emitSelected() {
    this.changeSelected.emit(this.selected);

    this.resetInput();
  }

  private completeMissingOptions() {
    this.options = {
      ...this.defaultOptions,
      ...this.options,
    };

    this.display = this.display || this.defaultDisplayMethod.bind(this);
    this.filter = this.filter || this.defaultFilterMethod.bind(this);
  }

  /**
   * Reset the autocomplete term in the text input
   */
  private resetInput() {
    this.itemControl.setValue('');
    this.itemInput.nativeElement.value = '';
  }

  /**
   * Return index of array without relaying on references. It actually compares properties to define if you already have the object or not.
   * @param array The array to search the index
   * @param object The object you're searching
   * @return number Same return type as indexOf method
   */
  private indexOfWithoutReference(array: any[], object: {}): number {
    const objectJson = JSON.stringify(object);

    return array.findIndex((current) => {
      const currentObjectJson = JSON.stringify(current);

      return currentObjectJson === objectJson;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.componentInited = false;

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
