import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends TcPage {
  constructor(store?: Store<any>) {
    super(store);
    this.pageInfo = {
      title: "login-title"
    };
  }
}
