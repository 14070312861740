import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-login-mfa-form',
  templateUrl: './login-mfa-form.component.html',
  styleUrls: ['./login-mfa-form.component.scss'],
})
export class LoginMfaFormComponent
  extends TcFormComponent<any>
  implements OnInit
{
  @Output() login: EventEmitter<{ code: string }> = new EventEmitter();

  public form: FormGroup;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService
  ) {
    super(translate, elem);

    this.initFormFields();
  }

  ngOnInit() {
    this.model = { code: null };
  }

  public verify() {
    if (this.form.valid) {
      this.login.emit({ code: this.form.value.code.toString() });
    }
  }

  private initFormFields() {
    this.fields = [
      {
        key: 'code',
        type: 'ngx-input',
        templateOptions: {
          required: true,
          modelKey: 'code',
          mask: '000 000',
          label: this.translate.instant('login-mfa-form.labels.code'),
        },
      },
    ];
  }
}
