import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import {
  closeDialogAndNavigateButtonClicked,
  TcSmartDetailPopupComponent,
} from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import { TcComponentLookup, TcTranslateService, openTcGridDetailsPopup, TcConfirmDialogComponent } from '@tc/core';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormInvalidity,
} from '@tc/smart-form';
import { Subscription } from 'rxjs';

import { CustomRoutes } from '../../../../typings/custom-routes.enum';
import { IndicateursEtat } from '../../interfaces/indicateurs-etat.interface';
import {
  exportIndicatorsAsPdf,
  exportIndicatorsDetailCsv,
  loadIndicatorAssociationDetails,
  saveIndicatorsDetail,
  setInitialValuesVisible,
  validateIndicatorsDetail,
  validateTicadiIndicatorsDetail,
} from '../../store/indicators.actions';
import { IndicatorRowData } from '../../store/indicators.payloads';
import {
  getAssociationCodeDetails,
  getAssociationNameDetails,
  getTicadiIndicatorsToModify,
  isInitialValues
} from '../../store/indicators.selectors';
import { IndicatorType } from '../../typings/indicator-type.enum';
import { Trimestre } from '../../typings/trimestre.enum';
import {
  INDICATOR_DETAILS_MODIFY_TRIMESTRE_BUTTON_ID,
  INDICATOR_DETAILS_MODIFY_YEAR_BUTTON_ID,
  INDICATOR_DETAILS_SAVE_BUTTON_ID,
  INDICATOR_DETAILS_VALIDATE_BUTTON_ID,
} from '../../utils/constants';
import { AmsIndicatorTableComponent } from '../ams-indicator-table/ams-indicator-table.component';

import { getTcGridFilters } from '@tc/core';
import { take } from 'rxjs/operators';
import { refreshTcData } from '@tc/data-store';
import { MatDialog } from '@angular/material/dialog';

@TcComponentLookup('IndicatorsDetailComponent')
@Component({
  selector: 'app-indicators-detail',
  templateUrl: './indicators-detail.component.html',
  styleUrls: ['./indicators-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IndicatorsDetailComponent
  extends TcSmartDetailPopupComponent
  implements OnInit, OnDestroy
{
  @ViewChild('stateIndicators') stateIndicators: AmsIndicatorTableComponent;
  public getAssociationCode;
  public getAssociationName;

  private indicatorDetails: IndicatorRowData;

  private ticadiIndicatorsToModify: {
    indicators: IndicateursEtat[];
    trimestre?: Trimestre;
  };

  formStoreKey = 'indicators-grid';
  subscription: Subscription = new Subscription();

  indicatorTableVisible = true; // Indicator Table Details is visible by default
  selectedIndicators: IndicateursEtat[] | null = null;
  selectedTrimestre: Trimestre;
  validationPeriod = '';

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);
    this.indicatorDetails = data.entityData;
  }

  async ngOnInit() {
    this.getAssociationCode = getAssociationCodeDetails;
    this.getAssociationName = getAssociationNameDetails;

    this.store$.dispatch(
      loadIndicatorAssociationDetails({ payload: { indicator: this.data } })
    );

    this.store$
      .select(getTicadiIndicatorsToModify)
      .subscribe((stateIndicators) => {
        this.ticadiIndicatorsToModify = stateIndicators;
      });

    this.store$.select(isInitialValues).subscribe((value) => {
      this.indicatorTableVisible = !value;
    });

    super.ngOnInit();
  }

  async ngOnDestroy() {
    const filters = await this.store$
      .select(getTcGridFilters, { storeKey: 'indicators-grid'})
      .pipe(take(1))
      .toPromise();

    this.store$.dispatch(
      refreshTcData({
        storeKey: 'indicators-grid',
        filter: filters
      })
    );

    this.subscription?.unsubscribe();

    super.ngOnDestroy();
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasHeader = true;
    this.dialogConfig.headerConfig = {
      configType: TcConfigTypes.TcDetailTitle,
      title: this.indicatorTableVisible
        ? 'indicators-detail.title'
        : 'indicator-detail.initial-values.title',
      hasCloseButton: true,
    };
    this.dialogConfig.hasFooter = true;
    this.dialogConfig.footerConfig = {
      configType: TcConfigTypes.TcDetailHeader,
      buttonsList: this.getFooterButtons(),
    };
  }

  getFooterButtons(): TcSmartButton[] {
    const footerButtons: TcSmartButton[] = [];
    const isBaIndicatorType =
      this.indicatorDetails.type.toUpperCase() === IndicatorType.BA;

    // Export buttons
    footerButtons.push(
      {
        label: 'export-csv',
        color: MaterialColor.Primary,
        faIcon: 'fa-file-csv',
        action: () => {
          this.store$.dispatch(
            exportIndicatorsDetailCsv({ payload: this.indicatorDetails })
          );
        },
      },
      {
        label: isBaIndicatorType ? 'export-pdf' : 'imprimer-pdf',
        color: MaterialColor.Primary,
        faIcon: 'fa-file-pdf',
        action: () => {
          this.store$.dispatch(
            exportIndicatorsAsPdf({ payload: this.indicatorDetails })
          );
        },
      }
    );

    if (isBaIndicatorType) {
      // Simple Indicators Form Buttons
      footerButtons.push(...this.getBaIndicatorsFormButtons());
    } else {
      // Ticadi Indicators Form Buttons
      footerButtons.push(...this.getTicadiIndicatorsFormButtons());
    }

    return footerButtons;
  }

  private getBaIndicatorsFormButtons(): TcSmartButton[] {
    return [
      {
        label: 'save',
        color: MaterialColor.Primary,
        faIcon: 'fa-save',
        action: () => {
          this.store$.dispatch(saveIndicatorsDetail());
        },
        disableStoreKey: INDICATOR_DETAILS_SAVE_BUTTON_ID,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      },
      {
        label: 'cancel',
        color: MaterialColor.Primary,
        faIcon: 'fa-ban',
        action: closeDialogAndNavigateButtonClicked,
        actionPayload: {
          storeKey: this.formStoreKey,
          route: `/${CustomRoutes.ValidateIndicateurs}`,
          queryParams: {},
        },
      },
      {
        label: 'validate',
        color: MaterialColor.Accent,
        faIcon: 'fa-check',
        action: this.validateBaIndicator.bind(this),
        disableStoreKey: INDICATOR_DETAILS_VALIDATE_BUTTON_ID,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      },
    ];
  }

  private validateBaIndicator() {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        title: 'indicator-details.validate.dialog.title',
        message: 'indicator-details.validate.dialog.message',
        noText: 'non',
        yesText: 'oui'
      },
      autoFocus: true,
      panelClass: 'validate-confirm-dialog-panel'
    });

    const sub = dialog.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.store$.dispatch(validateIndicatorsDetail());
      }
    });
    this.subscription.add(sub);
  }

  private getTicadiIndicatorsFormButtons(): TcSmartButton[] {
    return [
      {
        label: 'indicators-details.footer-button.modifier.trimestre',
        color: MaterialColor.Primary,
        faIcon: 'fa-save',
        action: this.modifyTrimestreIndicatorsTicadi.bind(this),
        disableStoreKey: INDICATOR_DETAILS_MODIFY_TRIMESTRE_BUTTON_ID,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      },
      {
        label: 'indicators-details.footer-button.modifier.annuels',
        color: MaterialColor.Primary,
        faIcon: 'fa-save',
        action: this.modifyAnneeIndicatorsTicadi.bind(this),
        disableStoreKey: INDICATOR_DETAILS_MODIFY_YEAR_BUTTON_ID,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      },
      {
        label: 'indicators-details.footer-button.validerIePeriode',
        color: MaterialColor.Accent,
        faIcon: 'fa-check',
        action: this.validateTicadiIndicator.bind(this),
        disableStoreKey: INDICATOR_DETAILS_MODIFY_TRIMESTRE_BUTTON_ID,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      },
    ];
  }

  private validateTicadiIndicator() {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        title: 'indicator-details.validate.dialog.title',
        message: 'indicator-details.validate.dialog.message',
        noText: 'non',
        yesText: 'oui'
      },
      autoFocus: true,
      panelClass: 'validate-confirm-dialog-panel'
    });

    const sub = dialog.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.store$.dispatch(validateTicadiIndicatorsDetail());
      }
    });
    this.subscription.add(sub);
  }

  modifyTrimestreIndicatorsTicadi() {
    this.indicatorTableVisible = false;
    this.store$.dispatch(setInitialValuesVisible({ initialValuesVisible: true }));
    this.openInitialValuesForIndicator({
      indicators: this.ticadiIndicatorsToModify?.indicators ?? [],
      selectedTrimestre: this.ticadiIndicatorsToModify?.trimestre,
      isEditMode: true,
    });
  }

  modifyAnneeIndicatorsTicadi() {
    this.indicatorTableVisible = false;
    this.store$.dispatch(setInitialValuesVisible({ initialValuesVisible: true }));
    this.openInitialValuesForIndicator({
      indicators: this.ticadiIndicatorsToModify?.indicators ?? [],
      selectedTrimestre: Trimestre.Annee,
      isEditMode: true,
    });
  }

  openInitialValuesForIndicator({
    indicators,
    selectedTrimestre,
    isEditMode,
  }: {
    indicators: IndicateursEtat[];
    selectedTrimestre: Trimestre;
    isEditMode: boolean;
  }) {
    this.selectedIndicators = indicators;
    this.selectedTrimestre = selectedTrimestre;

    this.store$.dispatch(
      openTcGridDetailsPopup({
        detailsPopupComponent: 'AmsInitialValuesTable',
        data: {
          selectedIndicator: this.indicatorDetails,
          selectedTrimestre: this.selectedTrimestre,
          isEditingMode: isEditMode,
        },
        height: '95%',
        storeKey: 'indicators-grid-initial-values',
      })
    );
  }
}
