import { TcSpinnerService } from '@tc/store';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { TcDataService } from '@tc/data-store';

@Injectable()
export class TcPanelListEffects {
  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dataService: TcDataService,
    private readonly spinner: TcSpinnerService
  ) {}
}
