import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  ActionReducer,
} from '@ngrx/store';
import {
  routerReducer,
  RouterReducerState,
  RouterStateSerializer,
} from '@ngrx/router-store';

import { Params, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PageActionTypes } from './actions/page.actions';
import { TcPageState, IPageState } from './reducers/page.reducers';
import {
  initialSpinnerState,
  IVersionState,
  offlineModeReducer,
  SpinnerState,
  TcOfflineModeState,
  TcSpinnerState,
  TcVersionState,
} from './reducers';
import { SpinnerActionTypes } from './actions/spinner.actions';
import { VersionActionTypes } from './actions';
import { HttpErrorsActionTypes } from './actions';

export interface TcAppState
  extends TcSpinnerState,
    TcPageState,
    TcVersionState,
    TcOfflineModeState {
  router: RouterReducerState<RouterStateUrl>;
  lastError: any;
}

export const TcAppReducers: ActionReducerMap<TcAppState> = {
  router: routerReducer,
  loading: spinnerReducer,
  pageInfo: pageReducer,
  versionInfo: versionReducer,
  lastError: errorReducer,
  offlineModeInfo: offlineModeReducer,
};

export const TcMetaReducers: MetaReducer<TcAppState>[] = [actionReducer];

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export function actionReducer(
  reducer: ActionReducer<TcAppState>
): ActionReducer<TcAppState> {
  return (state, action) => {
    const newState = reducer(state, action);

    console.log(`[INFO] action: ${action.type}`, {
      action,
      payload: (action as any).payload,
      oldState: state,
      newState,
    });

    // logger.INFO(`[INFO] action: ${action.type}`, {
    //   payload: (<any>action).payload,
    //   oldState: state,
    //   newState
    // });

    return newState;
  };
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

// Reducer selectors
export const selectReducerState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterInfo = createSelector(
  selectReducerState,
  (state) => state.state
);

export function spinnerReducer(
  state = initialSpinnerState,
  action: any
): SpinnerState {
  switch (action.type) {
    case SpinnerActionTypes.ShowSpinner: {
      let actionsInProgress = state.actionsInProgress;
      actionsInProgress = actionsInProgress + 1;

      return {
        ...state,
        actionsInProgress,
      };
    }
    case SpinnerActionTypes.HideSpinner: {
      let actionsInProgress = state.actionsInProgress;
      if (actionsInProgress > 0) {
        actionsInProgress = actionsInProgress - 1;
      }
      return {
        ...state,
        actionsInProgress,
      };
    }
    case SpinnerActionTypes.ResetSpinner: {
      return {
        ...initialSpinnerState,
      };
    }
    default:
      return state;
  }
}

const initialPageState: TcPageState = {
  pageInfo: null,
};

export function pageReducer(
  state: any = initialPageState,
  action: any
): IPageState {
  switch (action.type) {
    case PageActionTypes.SET_PAGE_INFO:
      return action.payload;
    default:
      return state;
  }
}

const initialVersionState: TcVersionState = {
  versionInfo: null,
};

export function versionReducer(
  state: any = initialVersionState,
  action: any
): IVersionState {
  switch (action.type) {
    case VersionActionTypes.SET_VERSION_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const getLastError = createFeatureSelector<any>('lastError');

export function errorReducer(state: any = 'none', action: any): any {
  switch (action.type) {
    case HttpErrorsActionTypes.AddHttpError:
      return action.payload;
    default:
      return state;
  }
}
