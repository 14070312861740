import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { LoginFormComponent } from './components/smart/login-form/login-form.component';
import { LoginMfaFormComponent } from './components/smart/login-mfa-form/login-mfa-form.component';
import { LoginMfaComponent } from './components/smart/login-mfa/login-mfa.component';
import { LoginComponent } from './components/smart/login/login.component';
import { AuthEffects } from './store/auth.effects';
import { authReducer } from './store/auth.reducer';

const smartComponents = [
  LoginComponent,
  LoginMfaComponent,
  LoginFormComponent,
  LoginMfaFormComponent,
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    FormsModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature('auth', authReducer),
  ],
  exports: [...smartComponents],
})
export class AuthModule {}
