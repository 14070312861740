import { Component, Input, isDevMode } from '@angular/core';
import { TcDialogFooterConfig, TcDumbComponent } from '@tc/abstract';

/**
 * Displays a currently empty footer
 */
@Component({
  selector: 'tc-dialog-footer',
  templateUrl: './tc-dialog-footer.component.html',
  styleUrls: ['./tc-dialog-footer.component.scss'],
})
export class TcDialogFooterComponent extends TcDumbComponent {
  @Input() dialogStoreKey: string;
  @Input() config: TcDialogFooterConfig;

  /**
   * Checks to see the dialogStoreKey was defined
   */
  ngOnInit() {
    if (!this.dialogStoreKey && isDevMode())
      throw new Error('Missing dialog storeKey');
  }
}
